import actionCreators from "./actionCreators";

export const initialState = {
    notifications: [],
    notification: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.setNotifications.type:
            return {
                ...state,
                notification: null,
                notifications: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.setNotification.type:
            return {
                ...state,
                notification: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.removeNotification.type:
            return {
                ...state,
                notifications: state.notifications.filter(value => value.id !== payload),
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
