import { useEffect } from "react";
import GTM from "../../../common/services/GTM";
import {
    // useDispatch,
    useSelector,
} from "react-redux";
import HeaderMobile from "../../../common/components/Header/HeaderMobile";
import { Container } from "../../../common/components/UI/Container";
import NoResults from "../../../common/components/NoResults/";
import { myProgrammeStyles } from "../MyProgrammeStyles";
// import actionCreators from "../../../store/MyProgramme/actionCreators";
import NotificationPlaceholder from "../../../common/components/Skeletons/NotificationPlaceholder";
import StudentBar from "../components/StudentBar";
import moment from "moment";

/** @jsx jsx */
import { jsx } from "@emotion/core";

const MyProgrammeContainer = ({ history: { push } }) => {
    // const dispatch = useDispatch();
    const { studentsList } = useSelector(state => state.MyProgramme);

    useEffect(() => {
        // dispatch(actionCreators.getStudentsList.create());
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/myprogramme`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoaded = Boolean(studentsList);

    return (
        <div
            css={
                isLoaded
                    ? myProgrammeStyles.bodyNoPadding
                    : myProgrammeStyles.bodyPlaceholderPadding
            }
        >
            <HeaderMobile title="My Programme" />
            <NotificationPlaceholder isLoaded={isLoaded}>
                <Container width={592} noPadding>
                    <div css={myProgrammeStyles.innerNoPaddingOnMobile}>
                        {studentsList && studentsList[0] && (
                            <div css={myProgrammeStyles.titleBox}>
                                <p css={myProgrammeStyles.programmeYear}>{`${moment().format(
                                    "YYYY"
                                )}/${Number(moment().format("YY")) + 1}`}</p>
                                <p css={myProgrammeStyles.programme}>
                                    {studentsList[0].programmeName}
                                </p>
                                <p css={myProgrammeStyles.department}>
                                    {studentsList[0].departmentName}
                                </p>
                            </div>
                        )}
                        {studentsList &&
                        studentsList[0] &&
                        studentsList[0].students &&
                        studentsList[0].students.length > 0 ? (
                            studentsList[0].students.map(i => (
                                <StudentBar
                                    key={i.playRef}
                                    {...{
                                        id: i.playRef,
                                        photoUrl: i.photoUrl,
                                        firstName: i.firstName,
                                        lastName: i.lastName,
                                        push,
                                    }}
                                />
                            ))
                        ) : (
                            <NoResults
                                heading="No results!"
                                message="We couldn't find any fellow programme students at the time ..."
                            />
                        )}
                    </div>
                </Container>
            </NotificationPlaceholder>
        </div>
    );
};

export default MyProgrammeContainer;
