import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import { headerTextButton } from "./HeaderStyles";
import { connect } from "react-redux";
import { submit } from "redux-form";

class HeaderGroups extends Component {
    render() {
        const { title, submitForm, isLoading, formName } = this.props;
        return (
            <div css={[styles.body, styles.bodyCalendar]}>
                <Container width={592} noPadding>
                    <div css={styles.bodySpaceBetween}>
                        <div css={styles.bodyLeft}>
                            <HeaderBackButton icon="times" iconSize={18} onClick={history.goBack} />
                        </div>
                        <div css={styles.bodyCenter}>
                            <div css={styles.title}>{title}</div>
                        </div>
                        <div css={styles.bodyRight}>
                            <button
                                type="submit"
                                onClick={() => submitForm(formName)}
                                css={[
                                    headerTextButton.body,
                                    isLoading && headerTextButton.bodyIsLoading,
                                ]}
                                disabled={isLoading}
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({ MainView: { isLoading } }) => ({
    isLoading,
});

const mapDispatchToProps = {
    submitForm: submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGroups);
