/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/core";
import React from "react";
import { spacing } from "../../../../common/styles/Spacing";
import { fontFamily, fontSize, fontWeight } from "../../../../common/styles/Typography";
import { colors } from "../../../../common/styles/Colors";
import { Link } from "react-router-dom";

const AlldayEvents = ({ items }) => {
    return items && items.length > 0 ? (
        <div css={styles.root}>
            <div css={styles.left}>
                <div css={styles.leftLabel}>All day</div>
            </div>
            <div css={styles.right}>
                {items.map(item => {
                    let color = "#000000";
                    switch (item.type) {
                        case "my-events":
                            color = "#9051A6";
                            break;
                        case "timetables":
                            color = "#9A9896";
                            break;
                        case "exam-dates":
                            color = "#9A9896";
                            break;
                        case "key-academic-dates":
                            color = "#31C0AD";
                            break;
                        case "appointments":
                            color = "#115760";
                            break;
                        case "lse-events":
                            color = "#FF2D55";
                            break;
                        default:
                            break;
                    }
                    return (
                        <Link
                            to={`/calendar/event/${item.calendarType}/${item.uid}`}
                            key={item}
                            css={[{ borderLeftColor: color }, styles.item]}
                        >
                            <div css={styles.itemTitle}>{item.title}</div>
                            {item.location ? (
                                <div css={styles.itemLocation}>{item.location}</div>
                            ) : (
                                <></>
                            )}
                        </Link>
                    );
                })}
            </div>
        </div>
    ) : (
        <React.Fragment></React.Fragment>
    );
};

const styles = {
    root: {
        display: "flex",
        backgroundColor: colors.white,
        marginBottom: spacing.space1,
    },
    left: {
        width: 72,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center",
    },
    leftLabel: {
        fontFamily: fontFamily.primary,
        fontWeight: fontWeight.normal,
        fontSize: 12,
    },
    right: {},
    item: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        borderLeftWidth: 3,
        borderLeftStyle: "solid",
        paddingTop: spacing.space1comma5,
        paddingBottom: spacing.space1comma5,
        paddingRight: spacing.space4,
        paddingLeft: spacing.space2,
        overflow: "hidden",
        textDecoration: "none",
    },
    itemTitle: {
        fontFamily: fontFamily.primary,
        fontWeight: fontWeight.bold,
        fontSize: fontSize.text,
        lineHeight: 1,
        color: colors.black,
    },
    itemLocation: {
        fontSize: fontSize.textSmaller,
        lineHeight: 1.41,
        color: colors.grey3,
        marginTop: spacing.space0,
    },
};

export default AlldayEvents;
