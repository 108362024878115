import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import { headerTextButton } from "./HeaderStyles";

class HeaderOfficeHours extends Component {
    render() {
        const { title, submit, isLoading } = this.props;
        return (
            <div css={[styles.body, styles.bodyCalendar]}>
                <Container width={592} noPadding>
                    <div css={styles.bodySpaceBetween}>
                        <div css={styles.bodyLeft}>
                            <HeaderBackButton icon="times" iconSize={18} onClick={history.goBack} />
                        </div>
                        <div css={styles.bodyCenter}>
                            <div css={styles.title}>{title}</div>
                        </div>
                        <div css={styles.bodyRight}>
                            <button
                                type="submit"
                                disabled={isLoading}
                                onClick={submit}
                                css={[
                                    headerTextButton.body,
                                    isLoading && headerTextButton.bodyIsLoading,
                                ]}
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default HeaderOfficeHours;
