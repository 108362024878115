import ActionCreator from "../../actionCreator";
import calendarActionCreators from "../CalendarBoard/actionCreators";

export default {
    ...calendarActionCreators,
    getMonthEvents: ActionCreator("GET_MONTH_EVENTS"),
    updateMonthEvents: ActionCreator("UPDATE_MONTH_EVENTS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CALENDAR_MONTH"),
    clearMonthEvents: ActionCreator("CLEAR_MONTH_EVENTS"),
};
