import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarSync, calendarPage } from "./CalendarStyles";
import actionCreators from "../../store/Calendar/Sync/actionCreators";
import CalendarBar from "./CalendarBar";
import HeaderCalendarMobile from "../../common/components/Header/HeaderCalendarMobile";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { Container } from "../../common/components/UI/Container";
import GTM from "../../common/services/GTM";

class CalendarSyncOfficeOutlook extends Component {
    componentDidMount() {
        const { getSyncDetails } = this.props;
        getSyncDetails();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/sync-office-outlook`,
        });
    }

    render() {
        const { details } = this.props;

        return (
            <div css={calendarSync.body}>
                <HeaderCalendarMobile />
                <CalendarBar />
                <Container onMobileNoPadding width={592} css={calendarPage.content}>
                    <div css={calendarSync.paddingTabBar}>
                        {details && (
                            <div css={calendarSync.content} className="entryText">
                                <p>
                                    Here’s how to sync your LSE Student Hub calendars with your{" "}
                                    <strong>Office 365</strong> or <strong>Outlook Calendar</strong>
                                    .
                                </p>
                                <hr />
                                <p>
                                    1. Log in to your <strong>Office 365 (Outlook)</strong> account
                                    in your web browser:{" "}
                                    <a
                                        href="https://login.microsoftonline.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://login.microsoftonline.com
                                    </a>
                                </p>
                                <p>
                                    Click on the <strong>Calendar</strong> icon in the bottom-left
                                    corner of the browser window.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd5dd0f77c.png"
                                        css={{
                                            width: 400,
                                            height: 322,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd5f6686c7.png"
                                        css={{
                                            width: 400,
                                            height: 271,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    2. Click on the <strong>Add calendar</strong> menu and then on
                                    the <strong>From Internet</strong> option.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd640ba38f.png"
                                        css={{
                                            width: 400,
                                            height: 223,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    3. In the <strong>Link to the calendar</strong> field copy and
                                    paste the following link. This will sync all your Student Hub
                                    calendar events with your Outlook Calendar.
                                </p>
                                <div className="dataBlock">{details.export_all_calendars}</div>
                                <p>
                                    In <strong>Calendar name</strong> type in: &quot;
                                    <strong>LSE Student Hub</strong>&quot;
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd69773ebe.png"
                                        css={{
                                            width: 400,
                                            height: 283,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    4. <strong>Save</strong>, and you&apos;re done!
                                </p>
                                <hr />
                                <p>
                                    <strong>Advanced option</strong>
                                </p>
                                <p>
                                    If you have subscribed to the calendar URL in step 3, this will
                                    already include <strong>all</strong> the categories of events
                                    shown below.
                                </p>
                                <p>
                                    If instead you want to sync specific calendars (e.g. only your
                                    Timetables or Appointments calendars), then follow steps 1 to 3
                                    to subscribe to each calendar, using the following URLs:
                                </p>

                                <p>
                                    For <strong>Timetables</strong> copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_timetables}</div>
                                <p>
                                    Calendar name: &quot;<strong>Timetables</strong>&quot;
                                </p>
                                <p>
                                    For <strong>My Events</strong> copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_my_events}</div>
                                <p>
                                    Calendar name: &quot;<strong>My Events</strong>&quot;
                                </p>
                                <p>
                                    For <strong>Appointments</strong> copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_appointments}</div>
                                <p>
                                    Calendar name: &quot;<strong>Appointments</strong>&quot;
                                </p>
                                <p>
                                    For <strong>Moodle dates </strong> copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_key_academic_dates}</div>
                                <p>
                                    Calendar name: &quot;<strong>Key Academic Dates</strong>&quot;
                                </p>
                                <p>
                                    For <strong>LSE Events</strong> copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_lse_events}</div>
                                <p>
                                    Calendar name: &quot;<strong>LSE Events</strong>&quot;
                                </p>
                                <hr />

                                <p>
                                    <i>
                                        Need help? More information on adding Office 365 (Outlook)
                                        calendars:
                                    </i>
                                    <br />
                                    <a
                                        href="https://support.office.com/en-us/article/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://support.office.com/en-us/article/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c
                                    </a>
                                </p>
                            </div>
                        )}
                    </div>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        sync: { details },
    },
}) => ({
    details,
});

const mapDispatchToProps = {
    getSyncDetails: actionCreators.getSyncDetails.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncOfficeOutlook);
