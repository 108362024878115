import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { headerBackButton } from "./HeaderStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";
import { Link } from "react-router-dom";

class HeaderBackButton extends Component {
    render() {
        const { icon, iconSize, iconColor, onClick, href, ...other } = this.props;

        if (href) {
            return (
                <Link to={href} css={headerBackButton.body} {...other}>
                    <Icon
                        name={icon || "arrowLeft"}
                        fill={iconColor || colors.black87}
                        width={iconSize || 17}
                        height={iconSize || 17}
                    />
                </Link>
            );
        }

        return (
            <button type="button" onClick={onClick} css={headerBackButton.body} {...other}>
                <Icon
                    name={icon || "arrowLeft"}
                    fill={iconColor || colors.black87}
                    width={iconSize || 17}
                    height={iconSize || 17}
                />
            </button>
        );
    }
}

export default HeaderBackButton;
