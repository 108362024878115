import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import history from "../common/services/history";

import AuthReducer from "./Auth/reducer";
import FeedReducer from "./Feed/reducer";
import PostReducer from "./Feed/Post/reducer";
import MainMenuReducer from "./MainMenu/reducer";
import MainViewReducer from "./MainView/reducer";
import RegisterReducer from "./Auth/Register/reducer";
import ChannelReducer from "./Channel/reducer";
import CalendarReducer from "./Calendar/reducer";
import LinksReducer from "./Links/reducer";
import FeedbackReducer from "./Feedback/reducer";
import DiscussionReducer from "./Discussion/reducer";
import SearchReducer from "./Search/reducer";
import CampusMappingReducer from "./CampusMapping/reducer";
import MyGroupsReducer from "./Channel/Group/reducer";
import SupportMapReducer from "./SupportMap/reducer";
import NotificationsReducer from "./Notifications/reducer";
import MyProgrammeReducer from "./MyProgramme/reducer";
import UserReducer from "./User/reducer";
import SnackbarReducer from "./Snackbar/reducer";

export default combineReducers({
    Auth: AuthReducer,
    calendar: CalendarReducer,
    CampusMapping: CampusMappingReducer,
    Channel: ChannelReducer,
    Discussion: DiscussionReducer,
    Feed: FeedReducer,
    Feedback: FeedbackReducer,
    form: formReducer,
    Links: LinksReducer,
    MainMenu: MainMenuReducer,
    MainView: MainViewReducer,
    MyGroups: MyGroupsReducer,
    Post: PostReducer,
    Register: RegisterReducer,
    router: connectRouter(history),
    Search: SearchReducer,
    SupportMap: SupportMapReducer,
    Notifications: NotificationsReducer,
    MyProgramme: MyProgrammeReducer,
    User: UserReducer,
    Snackbar: SnackbarReducer,
});
