export const colors = {
    transparent: "rgba(255,255,255,0)",
    white: "rgb(255,255,255)",
    white95: "rgba(255,255,255,0.95)",
    white90: "rgba(255,255,255,0.9)",
    white80: "rgba(255,255,255,0.8)",
    white70: "rgba(255,255,255,0.7)",
    white60: "rgba(255,255,255,0.6)",
    white50: "rgba(255,255,255,0.5)",
    white40: "rgba(255,255,255,0.4)",
    white30: "rgba(255,255,255,0.3)",
    white20: "rgba(255,255,255,0.2)",
    white10: "rgba(255,255,255,0.1)",
    black: "rgb(0,0,0)",
    black87: "rgba(0,0,0,0.87)",
    black54: "rgba(0,0,0,0.54)",
    black50: "rgba(0,0,0,0.50)",
    black42: "rgba(0,0,0,0.42)",
    black40: "rgba(0,0,0,0.40)",
    black38: "rgba(0,0,0,0.38)",
    black20: "rgba(0,0,0,0.2)",
    black12: "rgba(0,0,0,0.12)",
    black10: "rgba(0,0,0,0.1)",
    black5: "rgba(0,0,0,0.05)",
    black4: "rgba(0,0,0,0.04)",
    black2: "rgba(0,0,0,0.02)",
    blue2: "rgb(236,239,241)", // ECEFF1
    greyDark: "rgb(51,51,51)", // 333333
    greyDark30: "rgba(51,51,51,0.3)",
    greyDark10: "rgba(51,51,51,0.1)",
    greyBlueDark: "rgb(29,29,38)",
    greyBlueDark05: "rgba(29,29,38,0.05)",
    greyLight95: "rgba(248,248,248,0.95)",
    greyLight82: "rgba(248,248,248,0.82)",
    lightGrey10: "rgba(51, 51, 51, 0.1)",
    grey: "rgb(240,241,243)", // F0F1F3
    grey2: "rgb(114,114,114)", // F0F1F3
    grey3: "rgb(151,151,151)", // F0F1F3
    accentA: "rgb(232,22,62)", // FF2D55
    accentA20: "rgba(232,22,62,0.2)",
    accentB: "rgb(170,72,210)", // AA48D2
    accentC: "rgb(255,149,0)", // FF9500
    accentD: "rgb(119,136,248)", // 7788F8
    accentE: "rgb(249,223,76)", // F9DF4C
    accentF: "rgb(124,230,216)", // 7CE6D8
    accentF50: "rgba(124,230,216,0.5)", // 7CE6D8
    error: "#E0112B", // F43131
    red: "#E0112B", // F43131
};

export const gradients = {
    black: "linear-gradient(0deg, rgba(0,0,0,0.60) 0%, rgba(0,0,0,0.30) 100%)",
};
