import { spacing } from "../../common/styles/Spacing";
import { fontWeight } from "../../common/styles/Typography";

export const serverError = {
    body: {
        padding: spacing.space2,
    },
};

export const welcomeStyle = {
    btnMore: {
        position: "absolute",
        top: spacing.space5,
        right: spacing.space3,
        zIndex: 100,
        "@media (max-width: 1023px)": {
            right: spacing.space2,
        },
    },
    title: {
        color: "#fff",
        fontSize: 36,
        fontWeight: fontWeight.black,
        lineHeight: 1,
    },
    subtitle: {
        color: "#fff",
        fontSize: 24,
        fontWeight: fontWeight.black,
        lineHeight: 1.5,
    },
    text: {
        color: "#fff",
        fontSize: 16,
        fontWeight: fontWeight.light,
        lineHeight: 1.5,
        marginTop: spacing.space3,
        "@media (max-width: 1023px)": {
            marginTop: spacing.space1,
        },
    },
};
