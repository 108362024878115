import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import { default as feedActionCreators } from "../../Feed/actionCreators";
import { default as channelActionCreators } from "../../Channel/actionCreators";
import { default as linksActionCreators } from "../../Links/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";

const postStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getPost.type,
            actionCreators.bookmarkPost.type,
            actionCreators.unbookmarkPost.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const postClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updatePost.type,
            actionCreators.updateBookmarkedPost.type,
            actionCreators.updateUnbookmarkedPost.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getPost.type),
        mergeMap(({ payload: { id, channelId } }) =>
            ajax.get(apiUrl(`api/post/${id}/channel/${channelId}/`)).pipe(
                map(res => res.response),
                mergeMap(res => {
                    return of(
                        actionCreators.updatePost.create({
                            ...res,
                            channel: res.channelContext,
                        })
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putBookEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putBookEvent.type),
        mergeMap(({ payload: { id, channelId } }) =>
            ajax.put(apiUrl(`api/post/${id}/book-event/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.getPost.create({ id, channelId })),
                        of(channelActionCreators.updateBookedChannelPost.create({ id })),
                        of(feedActionCreators.updateBookedFeedPost.create({ id }))
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const bookmarkPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.bookmarkPost.type),
        mergeMap(({ payload: { id, channelId } }) =>
            ajax.post(apiUrl(`api/post/${id}/channel/${channelId}/favourite/add/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.updateBookmarkedPost.create()),
                        of(feedActionCreators.updateBookmarkedFeedPost.create({ id })),
                        of(channelActionCreators.updateBookmarkedChannelPost.create({ id }))
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const unbookmarkPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.unbookmarkPost.type),
        mergeMap(({ payload: { id, channelId } }) =>
            ajax.post(apiUrl(`api/post/${id}/channel/${channelId}/favourite/remove/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.updateUnbookmarkedPost.create()),
                        of(feedActionCreators.updateUnbookmarkedFeedPost.create({ id })),
                        of(channelActionCreators.updateUnbookmarkedChannelPost.create({ id })),
                        of(linksActionCreators.updateUnbookmarkedSavedPost.create({ id }))
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const likePostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.likePost.type),
        mergeMap(({ payload: { id } }) =>
            ajax.put(apiUrl(`api/post/${id}/like/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.updateLikedPost.create()),
                        of(feedActionCreators.updateLikedFeedPost.create({ id })),
                        of(channelActionCreators.updateLikedChannelPost.create({ id }))
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const unlikePostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.unlikePost.type),
        mergeMap(({ payload: { id } }) =>
            ajax.put(apiUrl(`api/post/${id}/unlike/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.updateUnlikedPost.create()),
                        of(feedActionCreators.updateUnlikedFeedPost.create({ id })),
                        of(channelActionCreators.updateUnlikedChannelPost.create({ id }))
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    postStartLoadingEpic,
    postClearLoadingEpic,
    getPostEpic,
    putBookEventEpic,
    bookmarkPostEpic,
    unbookmarkPostEpic,
    likePostEpic,
    unlikePostEpic
);
