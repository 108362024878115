import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";

export const containerImg = {
    bg: {
        backgroundColor: colors.white,
        "@media (max-width: 1023px)": {
            backgroundColor: colors.white,
            minHeight: "100vh",
        },
    },
    body: {
        backgroundImage: "linear-gradient(0deg, #ABF2EC 4%, #34CCB8 100%)",
        backgroundColor: "#34CCB8",
        minHeight: "100vh",
        "@media (max-width: 1023px)": {
            minHeight: 0,
        },
    },
    inner: {
        maxWidth: 602,
        margin: "0 auto",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            justifyContent: "space-between",
        },
    },
    innerFullWidth: {
        maxWidth: "100%",
        paddingRight: 0,
        paddingLeft: 0,
    },
};
