import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import RenderCheckboxField from "../../../common/components/FormFields/CheckboxField";
import RenderTextField from "../../../common/components/FormFields/TextField";
import RenderPasswordInputField from "../../../common/components/FormFields/PasswordInputField";
import validate from "./validate";
import ContainerImg from "../../../common/components/UI/ContainerImg";
import { spacing } from "../../../common/styles/Spacing";
import TextError from "../../../common/components/UI/TextError";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import ButtonText from "../../../common/components/UI/ButtonText";
import { checkboxField } from "../../../common/components/FormFields/FormFieldsStyles";
import {
    ContainerHeader,
    Container,
    ContainerActions,
} from "../../../common/components/UI/Container";
import { welcomeStyle } from "../../Dashboard/DashboardStyles";
import AuthContextMenu from "../AuthContextMenu";
import ButtonShape from "../../../common/components/UI/ButtonShape";
import { auth } from "../AuthStyles";
import GTM from "../../../common/services/GTM";
import authActions from "../../../store/Auth/actionCreators";

class Credentials extends Component {
    render() {
        const {
            errorRes: { error },
            handleSubmit,
            submitting,
            isLoading,
        } = this.props;

        return (
            <ContainerImg fullWidth>
                <ContainerHeader height={208} justifyBottom>
                    <div css={welcomeStyle.btnMore}>
                        <AuthContextMenu />
                    </div>
                    <div css={welcomeStyle.subtitle}>Welcome to the</div>
                    <div css={welcomeStyle.title}>Student Hub</div>
                    <div css={welcomeStyle.text}>
                        Register to access the Student Hub <br />
                        with your LSE username and password
                    </div>
                </ContainerHeader>
                <form onSubmit={handleSubmit}>
                    <Container width={352} css={auth.content}>
                        <Field
                            name="username"
                            component={RenderTextField}
                            label="LSE Username"
                            autoComplete="off"
                        />
                        <Field
                            name="password"
                            component={RenderPasswordInputField}
                            label="LSE Password"
                            autoComplete="off"
                        />
                        <Field
                            name="toc"
                            component={RenderCheckboxField}
                            label={
                                <span css={checkboxField.checkboxLabelWhite}>
                                    I accept the{" "}
                                    <a
                                        href={process.env.REACT_APP_TERMS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        css={checkboxField.linkWhite}
                                        onClick={() => {
                                            GTM.dispatch({
                                                event: "genericClick",
                                                targetName: "Terms and conditions",
                                            });
                                            return true;
                                        }}
                                    >
                                        terms and conditions
                                    </a>
                                </span>
                            }
                            whiteCheck
                        />
                        <TextError
                            marginTopValue={spacing.space1}
                            marginBottomValue={spacing.space1}
                        >
                            {error}
                        </TextError>
                    </Container>
                    <ContainerActions>
                        <ButtonsGroup
                            marginBottomValue={spacing.space3}
                            marginBottomValueMobile={spacing.space1comma5}
                        >
                            <ButtonShape type="submit" disabled={submitting || isLoading} fullWidth>
                                Register
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup
                            marginBottomValue={spacing.space6}
                            marginBottomValueMobile={spacing.space1}
                        >
                            <ButtonText to="/login" bold textStyle={auth.actionLinkBold}>
                                Already registered? Log in
                            </ButtonText>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText
                                target="_blank"
                                rel="noopener noreferrer"
                                icon="link"
                                iconSize={9}
                                textStyle={auth.actionLink}
                                onClick={() => {
                                    this.props.displayResetPasswordModal();
                                    GTM.dispatch({
                                        event: "genericClick",
                                        targetName: "Forgotten password",
                                    });
                                    return true;
                                }}
                            >
                                Forgotten username or password?
                            </ButtonText>
                        </ButtonsGroup>
                    </ContainerActions>
                </form>
            </ContainerImg>
        );
    }
}

const mapStateToProps = ({ Auth, MainView }) => ({
    errorRes: Auth.errorRes,
    isLoading: MainView.isLoading,
});

const dispatchToProps = {
    displayResetPasswordModal: authActions.displayResetPasswordModal.create,
};

export default reduxForm({
    form: "credentials",
    enableReinitialize: true,
    validate,
})(connect(mapStateToProps, dispatchToProps)(Credentials));
