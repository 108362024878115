// eslint-disable-file no-unused-expressions
import { createCluster, createNode } from "./creators";
import { getDayMinutes } from "./date";

const TotalMinutesInDay = 24 * 60 + 1;

const clusterizer = (events, minutes) => {
    let nodeMap = {};
    let clusters = [];
    let cluster = null;

    if (events) {
        events.forEach(event => {
            nodeMap[event.key] = createNode(event.key, event.start, event.end, event);
        });
    }

    if (minutes) {
        minutes.forEach(minute => {
            if (minute?.length > 0) {
                minute.forEach(eventKey => {
                    if (cluster === null) cluster = createCluster();

                    if (!cluster.nodes[eventKey]) {
                        cluster.nodes[eventKey] = nodeMap[eventKey];
                        nodeMap[eventKey].cluster = cluster;
                    }
                });

                return;
            }

            if (cluster !== null) clusters.push(cluster);

            cluster = null;
        });
    }

    if (cluster !== null) clusters.push(cluster);

    if (minutes) {
        minutes.forEach(minute => {
            if (minute) {
                minute.forEach(eventKey => {
                    let findNode = nodeMap[eventKey];
                    findNode.biggestGroupSize = Math.max(findNode.biggestGroupSize, minute?.length);

                    minute.forEach(targetEventKey => {
                        if (eventKey !== targetEventKey) {
                            findNode.neighbours[targetEventKey] = nodeMap[targetEventKey];
                        }
                    });
                });
            }
        });
    }

    return { clusters, nodes: nodeMap };
};

function prepareTimetable(items, startProperty, endProperty, itemMinHeightInMinutes, day) {
    const minutes = [];

    for (let minute = 1; minute < TotalMinutesInDay; minute++) {
        minutes[minute] = [];
    }

    let preparedEvents = [];

    if (items) {
        items.forEach((item, index) => {
            if (typeof item !== "object" || item === null) {
                // eslint-disable-next-line no-console
                console.warn(
                    `Invalid item of type [${typeof item}] supplied to Timeline, expected [object]`
                );
                return;
            }

            const start = item[startProperty];
            const end = item[endProperty];

            for (const { name, value } of [
                { name: "start", value: start },
                { name: "end", value: end },
            ]) {
                if (
                    !value ||
                    (typeof value !== "string" &&
                        typeof value !== "number" &&
                        typeof value !== "object")
                ) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `Invalid ${name} date of item ${item}, expected ISO string, UNIX timestamp or Date object, got [${value}]`
                    );
                    return;
                }
            }

            let originalStart = new Date(start);
            let originalEnd = new Date(end);
            let countedStartMinutes = day.start > originalStart ? 0 : getDayMinutes(originalStart);
            let endMinutes = day.end < originalEnd ? TotalMinutesInDay : getDayMinutes(originalEnd);
            let countedEndMinutes = Math.max(
                endMinutes,
                countedStartMinutes + itemMinHeightInMinutes
            );

            const clonedObj = {
                ...item,
                key: "" + index + item[startProperty] + item[endProperty],
                start: countedStartMinutes,
                end: countedEndMinutes,
            };

            for (
                let eventMinute = countedStartMinutes;
                eventMinute <= countedEndMinutes;
                eventMinute++
            ) {
                if (minutes && eventMinute && minutes[eventMinute]) {
                    minutes[eventMinute].push(clonedObj.key);
                }
            }

            preparedEvents.push(clonedObj);
        });
    }

    return {
        preparedEvents,
        minutes,
    };
}

function setClusterWidth(timetable, columnWidth) {
    timetable.clusters.forEach(cluster => {
        let maxGroupSize = 1;
        // eslint-disable-next-line no-unused-vars
        let neighboursCount = 0;

        for (let nodeId in cluster.nodes) {
            maxGroupSize = Math.max(maxGroupSize, cluster.nodes[nodeId].biggestGroupSize);
            neighboursCount = Object.keys(cluster.nodes[nodeId].neighbours).length;
        }

        const clusterWidth = columnWidth / maxGroupSize;

        cluster.maxGroupSize = maxGroupSize;
        cluster.width = clusterWidth;
    });
}

function setNodesPosition(timetable) {
    timetable.clusters.forEach(cluster => {
        for (let nodeId in cluster.nodes) {
            let node = cluster.nodes[nodeId];
            if (!node.cluster) return;
            let positionArray = new Array(node.cluster.maxGroupSize);

            for (let neighborId in node.neighbours) {
                let neighbour = node.neighbours[neighborId];

                if (neighbour.position !== null) {
                    positionArray[neighbour.position] = true;
                }
            }

            for (let i = 0; i < positionArray.length; i++) {
                if (!positionArray[i]) {
                    node.position = i;
                    node.isLast = i === positionArray.length - 1;
                    break;
                }
            }
        }
    });
}

export { clusterizer, prepareTimetable, setClusterWidth, setNodesPosition };
