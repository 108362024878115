import { ofType, combineEpics } from "redux-observable";
import { of, concat, empty } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import actionCreators from "./actionCreators";
import { default as calendarCreators } from "../Event/actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";

import { apiUrl } from "../../../common/services/utils";
import { default as ajax } from "../../../common/services/utils";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { push } from "connected-react-router";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getSlotsByMonth.type,
            actionCreators.getBookablesList.type,
            actionCreators.getBookableInfo.type,
            actionCreators.getBookingLimits.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.setSlotsByMonth.type,
            actionCreators.setBookablesList.type,
            actionCreators.addBookableInfo.type,
            actionCreators.removeBookableInfo.type,
            actionCreators.setBookingLimits.type,
            actionCreators.errorResponse.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const apiOfficeHoursChannelAutocomplete = filter =>
    ajax.get(apiUrl(`api/autocomplete/bookables/`), { filter });

const getBookablesListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getBookablesList.type),
        mergeMap(({ payload: filter }) =>
            apiOfficeHoursChannelAutocomplete(filter).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.setBookablesList.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiOfficeHoursRecommendedBookables = () =>
    ajax.get(apiUrl(`api/office-hours/booking-suggestion/`));

const getRecommendedBookablesListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getRecommendedBookablesList.type),
        mergeMap(() =>
            apiOfficeHoursRecommendedBookables().pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.setRecommendedBookablesList.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const redirectToOfficeHoursEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToOfficeHours.type),
        mergeMap(({ payload }) => {
            return concat(of(push(`/office-hours/${payload.type}/${payload.id}`)));
        })
    );

const apiOfficeHoursUnavailableSlotsByMonth = (type, bookableId, query) =>
    ajax.get(
        apiUrl(
            `api/office-hours/${bookableId}/unavailable-sessions-in-month-with-shift-for-${type}/`
        ),
        query
    );

const getUnavailableSessionsByMonthEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUnavailableSessionsByMonth.type),
        mergeMap(({ payload: { type, bookableId, ...query } }) =>
            apiOfficeHoursUnavailableSlotsByMonth(type, bookableId, query).pipe(
                map(res => res.response),
                mergeMap(data =>
                    concat(
                        of(
                            actionCreators.setUnavailableSessionsByMonth.create({
                                subject: { type, bookableId, ...query },
                                data,
                            })
                        ),
                        of(actionCreators.getSlotsByMonth.create({ type, bookableId, ...query }))
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiOfficeHoursSlotsByMonth = (type, bookableId, query) =>
    ajax.get(
        apiUrl(`api/office-hours/${bookableId}/available-slots-in-month-with-shift-for-${type}/`),
        query
    );

const getSlotsByMonthEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSlotsByMonth.type),
        mergeMap(({ payload: { type, bookableId, ...query } }) =>
            apiOfficeHoursSlotsByMonth(type, bookableId, query).pipe(
                map(res => res.response),
                mergeMap(data =>
                    of(
                        actionCreators.setSlotsByMonth.create({
                            subject: { type, bookableId, ...query },
                            data,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiOfficeHoursBookAppointment = (sessionId, appointment, rebookSlotUid) =>
    ajax.post(
        apiUrl(
            `api/office-hours/${sessionId}/book-appointment/${
                rebookSlotUid ? `?rebookSlotUid=${rebookSlotUid}` : ""
            }`
        ),
        appointment
    );

const postAppointmentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postAppointment.type),
        mergeMap(({ payload: { id, type, sessionId, rebookSlotUid, ...appointment } }) =>
            apiOfficeHoursBookAppointment(sessionId, appointment, rebookSlotUid).pipe(
                map(res => res.response),
                mergeMap(res =>
                    of(
                        actionCreators.setAppointment.create({
                            data: res,
                            url: `/office-hours/${type}/${id}/confirmed/${res.id}`,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.handleAppointmentError.create))
            )
        )
    );

const setAppointmentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setAppointment.type),
        mergeMap(({ payload }) => (payload.url ? of(push(payload.url)) : empty()))
    );

const apiOfficeHoursRemoveAppointment = uid =>
    ajax.remove(apiUrl(`api/office-hours/appointment/${uid}/`));

const removeAppointmentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeAppointment.type),
        mergeMap(({ payload: uid }) =>
            apiOfficeHoursRemoveAppointment(uid).pipe(
                map(res => res.response),
                mergeMap(res =>
                    of(
                        actionCreators.setAppointment.create({
                            data: null,
                            url: null,
                        }), // reset office hours
                        calendarCreators.cancelEvent.create({
                            uid,
                            url: `/office-hours/${res.subjectType}/${
                                res.teamId ? res.teamId : res.academicId
                            }/cancelled/${uid}`,
                        })
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiOfficeHoursBookableInfo = (type, bookableId) =>
    ajax.get(apiUrl(`api/office-hours/details/${type}/${bookableId}/`));

const getBookableInfoEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getBookableInfo.type),
        mergeMap(({ payload: { type, bookableId } }) =>
            apiOfficeHoursBookableInfo(type, bookableId).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.addBookableInfo.create(res))),
                catchError(res => {
                    const handleError = errorHandler(actionCreators.errorResponse.create);
                    let errorResponse = handleError(res);
                    if (res.status === 404) {
                        errorResponse = concat(
                            errorResponse,
                            of(actionCreators.removeBookableInfo.create(bookableId))
                        );
                    }
                    return errorResponse;
                })
            )
        )
    );

const apiGetBookingLimits = (sessionId, start) =>
    ajax.get(apiUrl(`api/office-hours/${sessionId}/booking-limit-notices/?start=${start}`));

const getBookingLimitsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getBookingLimits.type),
        mergeMap(({ payload: { sessionId, start } }) =>
            apiGetBookingLimits(sessionId, start).pipe(
                map(res => res.response),
                mergeMap(data => of(actionCreators.setBookingLimits.create(data))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiCheckFutureAvailability = (type, bookableId) =>
    ajax.get(apiUrl(`api/office-hours/${bookableId}/has-future-availability-for-${type}/`));

const checkFutureAvailabilityEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.checkFutureAvailability.type),
        mergeMap(({ payload: { type, bookableId, ...query } }) =>
            apiCheckFutureAvailability(type, bookableId, query).pipe(
                map(res => res.response),
                mergeMap(res => empty()),
                catchError(res => {
                    const handleError = errorHandler(actionCreators.errorResponse.create);
                    let errorResponse = handleError(res);
                    if (res.status === 409) {
                        errorResponse = concat(
                            errorResponse,
                            of(actionCreators.setNoFutureAvailability.create())
                        );
                    }
                    return errorResponse;
                })
            )
        )
    );

const apiGetOfficeHoursUserCalendarEvent = uid =>
    ajax.get(apiUrl(`api/calendar/event/${uid}/`), { calendarType: "appointments" });

const getUserCalendarEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUserCalendarEvent.type),
        mergeMap(({ payload }) =>
            apiGetOfficeHoursUserCalendarEvent(payload).pipe(
                map(res => res.response),
                mergeMap(() => of(actionCreators.updateUserCalendarEvent.create(true))),
                catchError(() => of(actionCreators.updateUserCalendarEvent.create(false)))
            )
        )
    );

const apiAddOfficeHoursUserCalendarEvent = uid =>
    ajax.post(apiUrl(`api/office-hours/${uid}/add-to-calendar/`));

const addUserCalendarEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addUserCalendarEvent.type),
        mergeMap(({ payload }) =>
            apiAddOfficeHoursUserCalendarEvent(payload).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateUserCalendarEvent.create(true))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiAddFavouriteBookableAcademic = id =>
    ajax.post(apiUrl(`api/user/favourite/academic/${id}/`));

const addFavouriteBookableAcademicEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addFavouriteBookableAcademic.type),
        mergeMap(({ payload }) =>
            apiAddFavouriteBookableAcademic(payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.getRecommendedBookablesList.create()),
                        of(actionCreators.getFavouritseBookablesList.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiAddFavouriteBookableTeam = id =>
    ajax.post(apiUrl(`api/user/favourite/office-hours-team/${id}/`));

const addFavouriteBookableTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.addFavouriteBookableTeam.type),
        mergeMap(({ payload }) =>
            apiAddFavouriteBookableTeam(payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.getRecommendedBookablesList.create()),
                        of(actionCreators.getFavouritseBookablesList.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiDeleteFavouriteBookableAcademic = id =>
    ajax.remove(apiUrl(`api/user/favourite/academic/${id}/`));

const deleteFavouriteBookableAcademicEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteFavouriteBookableAcademic.type),
        mergeMap(({ payload }) =>
            apiDeleteFavouriteBookableAcademic(payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.getRecommendedBookablesList.create()),
                        of(actionCreators.getFavouritseBookablesList.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiDeleteFavouriteBookableTeam = id =>
    ajax.remove(apiUrl(`api/user/favourite/office-hours-team/${id}/`));

const deleteFavouriteBookableTeamEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteFavouriteBookableTeam.type),
        mergeMap(({ payload }) =>
            apiDeleteFavouriteBookableTeam(payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.getRecommendedBookablesList.create()),
                        of(actionCreators.getFavouritseBookablesList.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const apiGetFavouritseBookablesList = () => ajax.get(apiUrl(`api/user/favourite/bookings/`));

const getFavouritseBookablesListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getFavouritseBookablesList.type),
        mergeMap(({ payload }) =>
            apiGetFavouritseBookablesList(payload).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateFavouritseBookablesList.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    redirectToOfficeHoursEpic,
    startLoadingEpic,
    clearLoadingEpic,
    getBookablesListEpic,
    getSlotsByMonthEpic,
    postAppointmentEpic,
    removeAppointmentEpic,
    setAppointmentEpic,
    getBookableInfoEpic,
    getUnavailableSessionsByMonthEpic,
    getBookingLimitsEpic,
    checkFutureAvailabilityEpic,
    getRecommendedBookablesListEpic,
    getUserCalendarEventEpic,
    addUserCalendarEventEpic,
    addFavouriteBookableAcademicEpic,
    addFavouriteBookableTeamEpic,
    deleteFavouriteBookableAcademicEpic,
    deleteFavouriteBookableTeamEpic,
    getFavouritseBookablesListEpic
);
