import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import { colors } from "../../styles/Colors";
import history from "./../../../common/services/history";
import { headerMobile, headerTextButton } from "./HeaderStyles";
import groupActionCreators from "../../../store/Channel/Group/actionCreators";

class HeaderGroupMembers extends Component {
    constructor(props) {
        super(props);
        const HAS_TABS = history.location.pathname.includes("/members");
        if (HAS_TABS) {
            const { filters, initial } = props;
            this.state = {
                activeFilterName: initial > 0 ? filters[initial].name : filters[0].name,
                activeFilterValue: initial > 0 ? filters[initial].value : filters[0].value,
            };
        } else {
            this.state = { activeFilterName: null, activeFilterValue: null };
        }
    }
    componentDidMount() {
        const HAS_TABS = history.location.pathname.includes("/members");
        if (HAS_TABS) {
            const { activeFilterValue } = this.state;
            const { filters, initial } = this.props;
            const isInFilters = ({ value }) => value === activeFilterValue;
            if (!initial && filters.some(isInFilters)) {
                this.setState({
                    activeFilterName: filters.find(isInFilters).name,
                });
            }
        }
    }
    handleClick = (name, value) => {
        this.setState({ activeFilterName: name, activeFilterValue: value });
        const { filters } = this.props;
        const filterIndex = filters.findIndex(filter => filter.name === name);
        if (filters[filterIndex].handler) {
            filters[filterIndex].handler(value);
        }
    };

    showSearchBar = () => {
        const { toggleActiveForm, enableResults } = this.props;
        toggleActiveForm(true);
        enableResults();
    };

    handleInviteUsersToGroup = () => {
        const { selectedUsers, inviteUserToGroup, groupId } = this.props;
        const last = selectedUsers.slice(-1)[0];
        for (const user of selectedUsers) {
            inviteUserToGroup({
                groupId,
                userId: user.id,
                isLast: last.id === user.id,
            });
        }
    };

    render() {
        const { filters, relative, title, hideDesktopTitle, isLoading, id } = this.props;
        const { activeFilterName } = this.state;
        const { handleInviteUsersToGroup } = this;

        const HAS_TABS = history.location.pathname.includes("/members");
        const ADD_MEMBERS = history.location.pathname.includes("/add-member");

        return (
            <Fragment>
                <div css={[styles.body, styles.bodyCalendar]}>
                    <Container width={592} noPadding>
                        <div css={styles.bodySpaceBetween}>
                            <div css={styles.bodyLeftFixed}>
                                <HeaderBackButton
                                    icon="times"
                                    iconSize={18}
                                    onClick={() =>
                                        history.location.pathname.includes("/add-member")
                                            ? history.goBack()
                                            : history.push(`/channel/${id}`)
                                    }
                                />
                            </div>
                            {!hideDesktopTitle && (
                                <div css={[styles.bodyCenter, styles.bodyCenterPadding]}>
                                    <div css={styles.title}>{title}</div>
                                </div>
                            )}
                            {ADD_MEMBERS && (
                                <div css={styles.bodyRight}>
                                    <button
                                        type="submit"
                                        onClick={() => handleInviteUsersToGroup()}
                                        css={[
                                            headerTextButton.body,
                                            isLoading && headerTextButton.bodyIsLoading,
                                        ]}
                                        disabled={isLoading}
                                    >
                                        SAVE
                                    </button>
                                </div>
                            )}
                        </div>
                        {HAS_TABS && (
                            <div css={styles.bodySpaceBetween}>
                                <div css={styles.bodyLeft}>
                                    {filters.map(({ name, value }) => (
                                        <button
                                            onClick={() => {
                                                this.handleClick(name, value);
                                            }}
                                            key={name}
                                            css={[
                                                styles.btn,
                                                relative && styles.btnRelative,
                                                activeFilterName === name ? styles.btnActive : {},
                                            ]}
                                        >
                                            {name}
                                            <span
                                                css={[
                                                    styles.btnBorder,
                                                    activeFilterName === name
                                                        ? styles.btnBorderActive
                                                        : {},
                                                ]}
                                            >
                                                <svg
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 375 4"
                                                    width="100%"
                                                    height="4"
                                                >
                                                    <path
                                                        d="M375 0L0 4h375V0z"
                                                        fill={
                                                            window.innerWidth > 1024
                                                                ? colors.black87
                                                                : colors.white
                                                        }
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Container>
                </div>
                <div css={headerMobile.body}>
                    <div css={headerMobile.shape} />
                    <div css={headerMobile.inner}>
                        <div css={headerMobile.elementLeft}>
                            <HeaderBackButton
                                icon="times"
                                iconSize={18}
                                iconColor={colors.white}
                                onClick={history.goBack}
                            />
                            <div css={headerMobile.elementTitle}>
                                <div css={headerMobile.title}>{title}</div>
                            </div>
                        </div>
                        <div css={headerMobile.elementRight}>
                            {ADD_MEMBERS && (
                                <button
                                    type="submit"
                                    onClick={() => handleInviteUsersToGroup()}
                                    css={[
                                        headerTextButton.bodyMobile,
                                        isLoading && headerTextButton.bodyMobileIsLoading,
                                    ]}
                                >
                                    SAVE
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {HAS_TABS && (
                    <div css={[styles.body, styles.bodyGroupMembers]}>
                        <div css={styles.bodySpaceBetween}>
                            <div css={styles.bodyLeft}>
                                {filters.map(({ name, value }) => (
                                    <button
                                        onClick={() => {
                                            this.handleClick(name, value);
                                        }}
                                        key={name}
                                        css={[
                                            styles.btn,
                                            relative && styles.btnRelative,
                                            activeFilterName === name ? styles.btnActive : {},
                                        ]}
                                    >
                                        {name}
                                        <span
                                            css={[
                                                styles.btnBorder,
                                                activeFilterName === name
                                                    ? styles.btnBorderActive
                                                    : {},
                                            ]}
                                        >
                                            <svg
                                                preserveAspectRatio="none"
                                                viewBox="0 0 375 4"
                                                width="100%"
                                                height="4"
                                            >
                                                <path
                                                    d="M375 0L0 4h375V0z"
                                                    fill={
                                                        window.innerWidth > 1024
                                                            ? colors.black87
                                                            : colors.white
                                                    }
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = ({
    MyGroups: { selectedUsers },
    MainView: { isLoading },
    Channel: {
        channel: { id },
    },
}) => ({
    selectedUsers,
    isLoading,
    id,
});

const mapDispatchToProps = {
    inviteUserToGroup: groupActionCreators.inviteUserToGroup.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGroupMembers);
