import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import postActionCreators from "../Feed/Post/actionCreators";
import feedActionCreators from "../Feed/actionCreators";
import channelActionCreators from "../Channel/actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getComments.type, actionCreators.postComment.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateComments.type,
            actionCreators.updateCommentNew.type,
            actionCreators.updateCommentEdit.type,
            actionCreators.errorResponse.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getCommentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getComments.type),
        mergeMap(({ payload: { id, page } }) =>
            ajax.get(apiUrl(`api/comment/post/${id}/${page}/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateComments.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postCommentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postComment.type),
        mergeMap(({ payload: { id, body } }) =>
            ajax.post(apiUrl(`api/comment/post/${id}/`), body).pipe(
                map(res => res.response),
                mergeMap(comment => {
                    return concat(
                        of(actionCreators.updateCommentNew.create(comment)),
                        of(postActionCreators.updateNumCommentsPost.create({ count: 1 })),
                        of(feedActionCreators.updateNumCommentsPost.create({ id, count: 1 })),
                        of(channelActionCreators.updateNumCommentsPost.create({ id, count: 1 }))
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const putCommentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putComment.type),
        mergeMap(({ payload: { id, body } }) =>
            ajax.put(apiUrl(`api/comment/${id}/`), body).pipe(
                map(res => res.response),
                mergeMap(comment => of(actionCreators.updateCommentEdit.create(comment))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const deleteCommentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteComment.type),
        mergeMap(({ payload: { id, postId } }) =>
            ajax.remove(apiUrl(`api/comment/${id}/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.removeComment.create(id)),
                        of(postActionCreators.updateNumCommentsPost.create({ count: -1 })),
                        of(
                            feedActionCreators.updateNumCommentsPost.create({
                                id: postId,
                                count: -1,
                            })
                        ),
                        of(
                            channelActionCreators.updateNumCommentsPost.create({
                                id: postId,
                                count: -1,
                            })
                        )
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,
    getCommentsEpic,
    postCommentEpic,
    putCommentEpic,
    deleteCommentEpic
);
