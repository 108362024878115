// initialize bugsnag ASAP, before other imports
import bugsnag from "bugsnag-js";
import { store } from "../../store";

export const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    appVersion: `${process.env.REACT_APP_VERSION}${process.env.REACT_APP_VERSION_SUFFIX}`,
    releaseStage: process.env.REACT_APP_RELEASE_STAGE,
    beforeSend: report => {
        try {
            const {
                Auth: { user },
            } = store.getState();
            report.user = { id: user.id, avatar: user.photoUrl };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    },
});

export const bugsnagEnvironment = {
    app: bugsnagClient.app,
    device: bugsnagClient.device,
};
