import { Component } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import { headerTextButton } from "../../../common/components/Header/HeaderStyles";
import actionCreators from "../../../store/Calendar/Event/actionCreators";
import officeActionCreators from "../../../store/Calendar/OfficeHours/actionCreators";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";
import ContextMenu from "../ContextMenu";
import PromptDialog from "../PromptDialog";
import moment from "moment";

class HeaderCalendar extends Component {
    state = { promptOpen: false };

    handleModalOpen = () => {
        this.setState({ promptOpen: true });
    };

    handleModalClose = confirm => {
        const { uid, removeAppointment } = this.props;
        this.setState({ promptOpen: false });
        if (confirm) {
            removeAppointment(uid);
        }
    };

    render() {
        const {
            uid,
            calendarType,
            removeEvent,
            submitForm,
            origin,
            item,
            isLoading,
            setRebookApointment,
            mode,
            removeAppointment,
        } = this.props;
        const formName = history.location.pathname.includes("/calendar/event/edit")
            ? "editEvent"
            : "addEvent";

        const calendarEditMyEvents = [
            {
                to: `/calendar/event/edit/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
            {
                to: "",
                text: "Delete event",
                icon: "times",
                onClick: () =>
                    removeEvent({
                        uid,
                        origin: origin ? origin : "list",
                    }),
            },
        ];

        const calendarEditAppointments = [
            {
                to: `/calendar/event/edit-notes/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
        ];

        // disable cancelling if appointment already started
        if (calendarType === "appointments" && moment(item.startAt).isAfter()) {
            calendarEditAppointments.push({
                to: "",
                text: "Cancel appointment",
                icon: "times",
                onClick: () => this.handleModalOpen(),
            });

            if (item.teamId) {
                calendarEditAppointments.push({
                    to: "",
                    text: `Rebook with ${item.team}`,
                    icon: "calendarClock",
                    iconSize: 22,
                    onClick: () => {
                        setRebookApointment(item);
                        history.push(`/office-hours/team/${item.teamId}?r=1`);
                    },
                });
                if (item.publicAcademicName) {
                    calendarEditAppointments.push({
                        to: "",
                        text: `Rebook with ${item.publicAcademicName}`,
                        icon: "calendarClock",
                        iconSize: 22,
                        onClick: () => {
                            setRebookApointment(item);
                            history.push(`/office-hours/academic/${item.academicId}?r=1`);
                        },
                    });
                }
            } else {
                calendarEditAppointments.push({
                    to: "",
                    text: `Rebook with ${item.publicAcademicName}`,
                    icon: "calendarClock",
                    iconSize: 22,
                    onClick: () => {
                        setRebookApointment(item);
                        history.push(`/office-hours/academic/${item.academicId}?r=1`);
                    },
                });
            }
        }

        const calendarEditOtherAppointments = [
            {
                to: `/calendar/event/edit-notes/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
        ];

        return (
            <div css={[styles.body, styles.bodyCalendar]}>
                <Container width={592} noPadding>
                    {history.location.pathname.includes("/calendar/event/new") ||
                    history.location.pathname.includes("/calendar/event/edit") ||
                    history.location.pathname.includes("/calendar/invitees/add") ||
                    (history.location.pathname.includes("/calendar/event/") && uid) ? (
                        <div css={styles.bodySpaceBetween}>
                            <div css={styles.bodyLeft}>
                                {history.location.pathname.includes("/calendar/invitees/add") ? (
                                    <HeaderBackButton
                                        icon="times"
                                        iconSize={18}
                                        onClick={() =>
                                            history.push(
                                                item.uid
                                                    ? {
                                                          pathname: `/calendar/event/edit/`,
                                                          search: `?uid=${item.uid}&calendarType=${item.calendarType}`,
                                                          state: {
                                                              loadInvitees: true,
                                                          },
                                                      }
                                                    : {
                                                          pathname: `/calendar/event/new`,
                                                          search: "?",
                                                          state: {
                                                              loadInvitees: true,
                                                          },
                                                      }
                                            )
                                        }
                                    />
                                ) : (
                                    <HeaderBackButton
                                        icon="arrowLeft"
                                        iconSize={18}
                                        onClick={() =>
                                            origin
                                                ? history.push(`/calendar/${origin.toLowerCase()}`)
                                                : history.goBack()
                                        }
                                    />
                                )}
                            </div>
                            <div css={styles.bodyRight}>
                                {history.location.pathname.includes("/calendar/event/") && uid ? (
                                    history.location.pathname.includes(
                                        "/calendar/event/edit-notes/"
                                    ) ? (
                                        <button
                                            type="submit"
                                            onClick={() => submitForm("editEventNotes")}
                                            css={[
                                                headerTextButton.body,
                                                isLoading && headerTextButton.bodyIsLoading,
                                            ]}
                                            disabled={isLoading}
                                        >
                                            SAVE
                                        </button>
                                    ) : calendarType === "my-events" ? (
                                        <ContextMenu itemsList={calendarEditMyEvents}>
                                            <Icon
                                                name="editAlt"
                                                width={20}
                                                height={20}
                                                fill={colors.black87}
                                            />
                                        </ContextMenu>
                                    ) : calendarType === "appointments" ? (
                                        mode === "drop-in" ? (
                                            <button
                                                type="button"
                                                css={styles.btnSearch}
                                                onClick={() => removeAppointment(uid)}
                                            >
                                                <Icon
                                                    name="trash"
                                                    width={20}
                                                    height={20}
                                                    fill={colors.black87}
                                                />
                                            </button>
                                        ) : (
                                            <ContextMenu itemsList={calendarEditAppointments}>
                                                <Icon
                                                    name="editAlt"
                                                    width={20}
                                                    height={20}
                                                    fill={colors.black87}
                                                />
                                            </ContextMenu>
                                        )
                                    ) : calendarType !== "timetables" &&
                                      calendarType !== "lse-events" ? (
                                        <ContextMenu itemsList={calendarEditOtherAppointments}>
                                            <Icon
                                                name="editAlt"
                                                width={20}
                                                height={20}
                                                fill={colors.black87}
                                            />
                                        </ContextMenu>
                                    ) : (
                                        <div></div> /* no option for timetables */
                                    )
                                ) : history.location.pathname.includes("calendar/invitees/add") ? (
                                    <button
                                        type="submit"
                                        onClick={() =>
                                            history.push(
                                                item.uid
                                                    ? {
                                                          pathname: `/calendar/event/edit/`,
                                                          search: `?uid=${item.uid}&calendarType=${item.calendarType}`,
                                                          state: {
                                                              loadInvitees: true,
                                                          },
                                                      }
                                                    : {
                                                          pathname: `/calendar/event/new`,
                                                          search: "?",
                                                          state: {
                                                              loadInvitees: true,
                                                          },
                                                      }
                                            )
                                        }
                                        css={[
                                            headerTextButton.body,
                                            isLoading && headerTextButton.bodyIsLoading,
                                        ]}
                                        disabled={isLoading}
                                    >
                                        SAVE
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        onClick={() => submitForm(formName)}
                                        css={[
                                            headerTextButton.body,
                                            isLoading && headerTextButton.bodyIsLoading,
                                        ]}
                                        disabled={isLoading}
                                    >
                                        SAVE
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div css={styles.bodySpaceBetween} />
                    )}
                    {calendarType === "appointments" && (
                        <PromptDialog open={this.state.promptOpen} onClose={this.handleModalClose}>
                            Do you want to cancel this booking?
                        </PromptDialog>
                    )}
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { origin, item },
    },
    MainView: { isLoading },
}) => ({
    origin,
    item,
    isLoading,
});

const mapDispatchToProps = {
    setRebookApointment: officeActionCreators.setRebookApointment.create,
    removeEvent: actionCreators.removeEvent.create,
    removeAppointment: officeActionCreators.removeAppointment.create,
    submitForm: submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCalendar);
