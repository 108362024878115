import { colors } from "../../../common/styles/Colors";
import { fontWeight, fontSize } from "../../../common/styles/Typography";
import { spacing } from "../../styles/Spacing";
import { resetLineHeight } from "../../styles/Globals";

export default {
    body: {
        backgroundColor: colors.white,
        boxShadow: "0 2px 2px -2px rgba(0, 0, 0, 0.12)",
        position: "fixed",
        top: 0,
        right: 0,
        left: 300,
        zIndex: 105,
        "@media (max-width: 1023px)": {
            backgroundColor: "#00565F",
            boxShadow: "none",
            left: 0,
            top: 56,
        },
    },
    bodyRelative: {
        position: "relative",
        top: "auto",
        right: "auto",
        left: "auto",
        "@media (max-width: 1023px)": {
            left: "auto",
            top: "auto",
        },
    },
    bodyIsSearch: {
        top: 56,
    },
    bodySupportMap: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    bodyCalendar: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    bodyChat: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    bodyGroupMembers: {
        display: "none",
        "@media (max-width: 1023px)": {
            display: "block",
        },
    },
    bodyInner: {
        height: 56,
        display: "flex",
        justifyContent: "flex-start",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            height: 48,
            // paddingRight: 0,
            // paddingLeft: 0,
        },
    },
    bodySpaceBetween: {
        height: 56,
        display: "flex",
        justifyContent: "space-between",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            height: 48,
            // paddingRight: 0,
            // paddingLeft: 0,
        },
    },
    bodyNoPadding: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    bodyLeft: {
        height: 56,
        display: "flex",
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        "@media (max-width: 1023px)": {
            height: 48,
        },
    },
    bodyLeftFixed: {
        height: 56,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "@media (max-width: 1023px)": {
            height: 48,
        },
    },
    bodyCenter: {
        height: 56,
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        "@media (max-width: 1023px)": {
            height: 48,
        },
    },
    bodyCenterPadding: {
        paddingRight: 50,
        width: "calc(100% - 50px)",
    },
    bodyRight: {
        height: 56,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "@media (max-width: 1023px)": {
            height: 48,
        },
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textBig,
        fontWeight: fontWeight.medium,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
        // flex: 1,
        cursor: "pointer",
        background: "none",
        border: "0 none",
        outline: "0 none",
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: "1",
        textDecoration: "none",
        margin: 0,
        padding: "0 24px",
        position: "relative",
        "@media (max-width: 1023px)": {
            color: colors.white70,
        },
        "@media (max-width: 767px)": {
            flex: 1,
            padding: "0 10px",
        },
    },
    btnRelative: {
        flex: 1,
    },
    btnIcon: {
        marginRight: spacing.space1,
    },
    btnBorder: {
        ...resetLineHeight,
        opacity: 0,
        // backgroundColor: colors.black87,
        width: "100%",
        height: 4,
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        "@media (max-width: 1023px)": {
            // backgroundColor: "transparent",2
        },
    },
    btnBorderActive: {
        opacity: 1,
    },
    btnActive: {
        fontWeight: fontWeight.bold,
        "@media (max-width: 1023px)": {
            color: colors.white,
        },
    },
    btnSearch: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: 0,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    btnAbsoluteLeft: {
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: spacing.space2,
        transform: "translateY(-50%)",
    },
    btnAbsoluteRight: {
        position: "absolute",
        top: "50%",
        right: spacing.space2,
        transform: "translateY(-50%)",
    },
};
