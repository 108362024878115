import moment from "moment";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import ChannelTitle from "../ChannelTitle";
import { entry } from "../FeedStyles";
import Button from "../../../common/components/UI/Button";
import FeedItemSummary from "../FeedItemSummary";
import HTML from "../../../common/components/UI/Wysiwyg";
import GTM from "../../../common/services/GTM";

export const event = ({
    post: {
        isFavourite,
        isLiked,
        imageUrl,
        description,
        channel,
        name,
        location,
        startAt,
        endAt,
        signpostUrl,
        publicationDate,
        id,
        isBooked,
        numComments,
        numberOfLikes,
        author,
        kind,
    },
    putBookEvent,
}) => (
    <div css={entry.post}>
        <ChannelTitle channel={channel} author={author} postId={id} {...{ publicationDate }} />
        {imageUrl && (
            <div css={entry.imageWrapper}>
                <img alt="" src={imageUrl} css={entry.img} />
            </div>
        )}
        <div css={entry.text}>
            <div css={entry.title}>{name}</div>
            <div css={entry.details}>
                {location && <div css={entry.detailsLocation}>{location}</div>}
                <div css={entry.detailsDate}>
                    {`${moment(startAt).format("dddd D MMMM YYYY h.mma")} - `}
                    {moment(startAt).format("ddd D MMM") === moment(endAt).format("ddd D MMM")
                        ? moment(endAt).format("h.mma")
                        : moment(endAt).format("dddd D MMMM YYYY h.mma")}
                </div>
            </div>
            <div css={entry.actions}>
                {moment(endAt).isAfter(moment()) && (
                    <span>
                        {isBooked ? (
                            <Button
                                small
                                accent
                                textIcon={{
                                    name: "check",
                                    width: 10,
                                    height: 10,
                                }}
                                css={entry.detailsBtn}
                            >
                                Added to calendar
                            </Button>
                        ) : (
                            <Button
                                small
                                textIcon={{
                                    name: "add",
                                    width: 10,
                                    height: 10,
                                }}
                                css={entry.detailsBtn}
                                onClick={() => {
                                    putBookEvent({ id, channelId: channel.id });
                                    GTM.dispatch({
                                        event: "postClick",
                                        actionType: "Add to calendar",
                                        postId: `${id}`,
                                        postName: `${name}`,
                                        channelId: `${channel.id}`,
                                        channelName: `${channel.name}`,
                                    });
                                    return true;
                                }}
                            >
                                Add to calendar
                            </Button>
                        )}
                    </span>
                )}
                {signpostUrl && (
                    <Button
                        small
                        outline
                        textIcon={{
                            name: "linkExternal",
                            width: 10,
                            height: 10,
                        }}
                        // css={entry.detailsBtn}
                        rel="noopener noreferrer"
                        onClick={() => {
                            GTM.dispatch({
                                event: "postClick",
                                actionType: "Booking info",
                                postId: `${id}`,
                                postName: `${name}`,
                                channelId: `${channel.id}`,
                                channelName: `${channel.name}`,
                            });
                            window.open(signpostUrl, "_blank");
                            return true;
                        }}
                    >
                        Booking info
                    </Button>
                )}
            </div>
            {description && (
                <HTML
                    html={description}
                    onLinkClick={href => {
                        GTM.dispatch({
                            event: "postLinkClick",
                            actionType: href,
                            postId: `${id}`,
                            postName: `${name}`,
                            channelId: `${channel.id}`,
                            channelName: `${channel.name}`,
                        });
                    }}
                />
            )}
        </div>
        <FeedItemSummary
            postId={id}
            postName={name}
            channel={{ id: channel.id, name: channel.name }}
            {...{ isFavourite, isLiked, numberOfLikes, numComments, kind }}
        />
    </div>
);

export const Event = event;
