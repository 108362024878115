import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
// import FavouriteIcon from "material-ui/svg-icons/action/favorite";
// import FavouriteBorderIcon from "material-ui/svg-icons/action/favorite-border";
import { favourite } from "./FavouriteStyles";
import Icon from "./Icon";
import { colors } from "../../styles/Colors";

class Favourite extends Component {
    render() {
        const { isFavourite, icon = "heart" } = this.props;

        return (
            <div css={favourite.body}>
                {icon === "heart" && (
                    <Icon
                        name={isFavourite ? "heartSolid" : "heart"}
                        fill={isFavourite ? colors.accentA : colors.black54}
                        width={20}
                        height={20}
                    />
                )}
                {icon === "bookmark" && (
                    <Icon
                        name={isFavourite ? "bookmarkSolid" : "bookmark"}
                        fill={colors.black54}
                        width={20}
                        height={20}
                    />
                )}
            </div>
        );
    }
}

export default Favourite;
