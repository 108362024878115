import actionCreators from "./actionCreators";

export const initialState = {
    subjects: [],
    showFeedbackForm: false,
    feedbackSent: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateFeedback.type:
            return {
                ...state,
                feedbackSent: true,
            };
        case actionCreators.clearFeedback.type:
            return {
                ...state,
                feedbackSent: false,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.displayFeedbackForm.type:
            return {
                ...state,
                showFeedbackForm: true,
            };
        case actionCreators.hideFeedbackForm.type:
            return {
                ...state,
                showFeedbackForm: false,
            };
        case actionCreators.updateSubjects.type:
            return {
                ...state,
                subjects: payload,
            };
        default:
            return state;
    }
};
