import actionCreators from "./actionCreators";

export const initialState = {
    studentsList: null,
    studentProfile: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateStudentsList.type: {
            return {
                ...state,
                studentsList: payload,
            };
        }

        case actionCreators.updateStudentProfile.type: {
            return {
                ...state,
                studentProfile: payload,
            };
        }

        case actionCreators.clearStudentProfile.type: {
            return {
                ...state,
                studentProfile: null,
            };
        }

        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };

        default:
            return state;
    }
};
