import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Feed/Post/actionCreators";
import ChannelTitle from "../ChannelTitle";
import { entryShort } from "../FeedStyles";
import Button from "../../../common/components/UI/Button";
import FeedItemSummary from "../FeedItemSummary";
import HTML from "../../../common/components/UI/Wysiwyg";
import GTM from "../../../common/services/GTM";
import history from "../../../common/services/history";

const EventItem = ({
    event: {
        id,
        kind,
        description,
        imageUrl,
        location,
        name,
        publicationDate,
        startAt,
        endAt,
        isFavourite,
        numComments,
        isLiked,
        numberOfLikes,
        channel,
        author,
        isBooked,
    },
    putBookEvent,
}) => (
    <div css={entryShort.body}>
        <ChannelTitle
            channel={channel}
            author={author}
            postId={id}
            {...{ publicationDate, isFavourite }}
        />
        {imageUrl && (
            <div css={entryShort.imgWrapper}>
                <Link to={`/event/${id}/${channel.id}`}>
                    <span css={entryShort.imgShape}>
                        <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                            <path d="M375 0H0v8L375 0z" fill="#fff" />
                        </svg>
                    </span>
                    <img alt="" src={imageUrl} css={entryShort.img} />
                </Link>
            </div>
        )}
        <div css={entryShort.title}>{name}</div>
        <div css={entryShort.details}>
            <div css={entryShort.detailsText}>
                <div css={entryShort.detailsDate}>
                    {moment(startAt).format("dddd D MMMM YYYY h.mma")}
                </div>
                {location && <div css={entryShort.detailsLocation}>{location}</div>}
            </div>
        </div>
        <div css={entryShort.detailsBtns}>
            {moment(endAt).isAfter(moment()) && (
                <span>
                    {isBooked ? (
                        <Button
                            css={entryShort.continueReadingBtn}
                            small
                            accent
                            textIcon={{
                                name: "check",
                                width: 10,
                                height: 10,
                            }}
                        >
                            Added to calendar
                        </Button>
                    ) : (
                        <Button
                            css={entryShort.continueReadingBtn}
                            small
                            textIcon={{
                                name: "add",
                                width: 10,
                                height: 10,
                            }}
                            onClick={() => {
                                putBookEvent({ id, channelId: channel.id });
                                GTM.dispatch({
                                    event: "postClick",
                                    actionType: "Add to calendar",
                                    postId: `${id}`,
                                    postName: `${name}`,
                                    channelId: `${channel.id}`,
                                    channelName: `${channel.name}`,
                                });
                                return true;
                            }}
                        >
                            Add to calendar
                        </Button>
                    )}
                </span>
            )}
            {entryShort.findOutBtn && (
                <Button
                    css={entryShort.findOutBtn}
                    small
                    textIcon={{ name: "link", width: 8, height: 8 }}
                    onClick={() => {
                        GTM.dispatch({
                            event: "postClick",
                            actionType: "Find out more",
                            postId: `${id}`,
                            postName: `${name}`,
                            channelId: `${channel.id}`,
                            channelName: `${channel.name}`,
                        });
                        history.push(`/event/${id}/${channel.id}`);
                        return true;
                    }}
                >
                    Find out more
                </Button>
            )}
        </div>
        <div css={entryShort.event}>
            {description && (
                <HTML
                    html={description}
                    onLinkClick={href => {
                        GTM.dispatch({
                            event: "postLinkClick",
                            actionType: href,
                            postId: `${id}`,
                            postName: `${name}`,
                            channelId: `${channel.id}`,
                            channelName: `${channel.name}`,
                        });
                    }}
                />
            )}
        </div>
        <FeedItemSummary
            postId={id}
            channel={{ id: channel.id, name: channel.name }}
            postName={name}
            {...{ isFavourite, isLiked, numberOfLikes, numComments, kind }}
        />
        <div css={entryShort.bottomShape}>
            <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
            </svg>
        </div>
    </div>
);

const mapDispatchToProps = {
    putBookEvent: actionCreators.putBookEvent.create,
};

export default connect(undefined, mapDispatchToProps)(EventItem);
