import actionCreators from "./actionCreators";

export const initialState = {
    details: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateSyncDetails.type: {
            let processed = {};
            for (var key in payload) {
                processed[key.split("-").join("_")] = payload[key];
            }
            return {
                ...state,
                details: processed,
            };
        }
        default:
            return state;
    }
};
