import { Component } from "react";
import { reduxForm, Field, formValueSelector, reset, SubmissionError, change } from "redux-form";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Channel/Group/actionCreators";
import UIActionCreators from "../../../store/MainView/actionCreators";
import RenderTextFieldInline from "../../../common/components/FormFields/TextFieldInline";
import { Container } from "../../../common/components/UI/Container";
import { calendarPage } from "../../Calendar/CalendarStyles";
import { header } from "../../../common/components/Header/HeaderStyles";
import Avatar from "../../../common/components/UI/Avatar";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import HeaderChannel from "../../../common/components/Header/HeaderChannel";
import HeaderChannelMobile from "../../../common/components/Header/HeaderChannelMobile";
import { addPhotoBtn, addPostPage, photoBtn } from "../ChannelStyles";
import BaseFileInput from "../../../common/components/FormFields/FileInputBase64";
import validate from "./validate";
import TextError from "../../../common/components/UI/TextError";
import { textFieldInline } from "../../../common/components/FormFields/FormFieldsStyles";
import GTM from "../../../common/services/GTM";
import { formContainer } from "../../../common/styles/Globals";

class AddNewPost extends Component {
    componentDidMount() {
        this.props.resetForm();
        this.props.clearValidateError();

        const {
            match: {
                params: { id },
            },
        } = this.props;
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/add-new-post/${id}`,
        });
    }
    handleInput = () => {
        this.props.clearValidateError();
        document.querySelector("input[type=file]").click();
    };

    removePhoto = () => {
        const { clearValidateError, removePhoto } = this.props;
        clearValidateError();
        removePhoto();
    };

    onSubmit = values => {
        const {
            postPost,
            match: {
                params: { id },
            },
        } = this.props;
        if (Object.keys(values).length === 0) {
            throw new SubmissionError({
                _error: "Required description or Photo",
            });
        } else {
            postPost({ values, id });
        }
    };
    render() {
        const { handleSubmit, error, errorRes, formFields, userPhotoUrl, isLoading } = this.props;
        const { handleInput, onSubmit, removePhoto } = this;
        return (
            <div css={addPostPage.body}>
                <HeaderChannel submit={handleSubmit(onSubmit)} {...{ isLoading }} />
                <HeaderChannelMobile
                    submit={handleSubmit(onSubmit)}
                    title="Create new post"
                    {...{ isLoading }}
                />
                <div css={header.titleBig}>Create new post</div>
                <Container onMobileNoPadding width={592}>
                    <div css={calendarPage.event}>
                        <form>
                            <div css={formContainer.section}>
                                <div css={formContainer.avatar}>
                                    <Avatar
                                        src={userPhotoUrl}
                                        smallComments={window.innerWidth < 1023 && true}
                                    />
                                </div>
                                <div css={[formContainer.inner, formContainer.innerTextarea]}>
                                    <div css={formContainer.textarea}>
                                        <Field
                                            name="description"
                                            placeholder="Have your say"
                                            component={RenderTextFieldInline}
                                            multiline
                                            darkPlaceholder
                                        />
                                        <TextError
                                            css={[
                                                textFieldInline.error,
                                                textFieldInline.errorMarginBottom,
                                            ]}
                                        >
                                            {error}
                                        </TextError>
                                    </div>
                                </div>
                            </div>
                            <div
                                css={[
                                    addPostPage.btn,
                                    formFields.imageFile && addPostPage.btnRelative,
                                ]}
                            >
                                <div
                                    css={{
                                        display: "none",
                                    }}
                                >
                                    <Field name="imageFile" component={BaseFileInput} />
                                </div>
                                {errorRes.errors.imageFile && (
                                    <TextError
                                        css={[
                                            textFieldInline.error,
                                            textFieldInline.errorMarginBottom,
                                            textFieldInline.errorMarginLeft,
                                        ]}
                                    >
                                        {errorRes.errors.imageFile}
                                    </TextError>
                                )}
                                <div>
                                    {formFields.imageFile ? (
                                        <div css={photoBtn.body}>
                                            <button
                                                type="button"
                                                css={photoBtn.btn}
                                                onClick={handleInput}
                                            >
                                                <img
                                                    alt=""
                                                    src={formFields.imageFile.base64}
                                                    css={photoBtn.img}
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                css={photoBtn.btnRemove}
                                                onClick={removePhoto}
                                            >
                                                <Icon
                                                    name="times"
                                                    width="18"
                                                    height="18"
                                                    fill={colors.white}
                                                />
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            css={addPhotoBtn.body}
                                            onClick={handleInput}
                                        >
                                            <div css={addPhotoBtn.icon}>
                                                <Icon
                                                    name="channels"
                                                    width="20"
                                                    height="20"
                                                    fill={colors.black87}
                                                />
                                            </div>
                                            <span css={addPhotoBtn.text}>Add a photo</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        );
    }
}

const selector = formValueSelector("newPost");

const mapStateToProps = ({ Channel, form, MyGroups, Auth, MainView }) => ({
    formFields: selector({ form }, "description", "imageFile"),
    channel: Channel.channel,
    errorRes: MyGroups.errorRes,
    iconUrl: Channel.channel.iconUrl,
    userPhotoUrl: Auth.user.photoUrl,
    isLoading: MainView.isLoading,
});

const mapDispatchToProps = {
    removePhoto: () => change("newPost", "imageFile", null),
    resetForm: () => reset("newPost"),
    postPost: actionCreators.postPost.create,
    setValidateError: actionCreators.setValidateError.create,
    clearValidateError: actionCreators.clearValidateError.create,
    setLoading: UIActionCreators.setLoading.create,
    clearLoading: UIActionCreators.clearLoading.create,
};
const form = reduxForm({
    form: "newPost",
    enableReinitialize: true,
    validate,
})(AddNewPost);

export default connect(mapStateToProps, mapDispatchToProps)(form);
