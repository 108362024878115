/** @jsx jsx */
import { jsx } from "@emotion/core";
import moment from "moment";
import { calendarBoard } from "../CalendarStyles";

const WeekDayLabels = () => (
    <div css={calendarBoard.weekHead}>
        {[...moment.weekdays().slice(1), moment.weekdays()[0]].map((day, i) => (
            <div key={i} css={calendarBoard.dayHead}>
                <div
                    css={[
                        calendarBoard.dayHeadText,
                        day === "Saturday" || day === "Sunday"
                            ? calendarBoard.dayHeadTextWeekend
                            : null,
                    ]}
                >
                    {day[0]}
                </div>
            </div>
        ))}
    </div>
);

export default WeekDayLabels;
