import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Container } from "../../../../common/components/UI/Container";
import { calendarPage, ohListItem } from "../../CalendarStyles";
import { formContainer, list, listItem } from "../../../../common/styles/Globals";
import HeaderOfficeHoursMobile from "../../../../common/components/Header/HeaderOfficeHoursMobile";
import HeaderOfficeHours from "../../../../common/components/Header/HeaderOfficeHours";
import { TextFieldInline } from "../../../../common/components/FormFields/TextFieldInline";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import actionCreators from "../../../../store/Calendar/OfficeHours/actionCreators";
import Button from "../../../../common/components/UI/Button";
import GTM from "../../../../common/services/GTM";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";

class OfficeHoursSearchContainer extends Component {
    state = {
        filter$: new Subject(),
    };

    constructor(props) {
        super(props);
        const { getBookablesList, setBookablesList } = props;
        const { filter$ } = this.state;

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(term => {
            if (term !== "") {
                GTM.dispatch({
                    event: "newSearch",
                    actionType: "Office Hours search",
                    searchQuery: `${term}`,
                });
                getBookablesList(term);
            } else {
                setBookablesList([]);
            }
        });
    }

    componentDidMount() {
        const {
            getRecommendedBookablesList,
            recommendedBookablesList,
            favouritesBookablesList,
            getFavouritseBookablesList,
        } = this.props;
        if (recommendedBookablesList === null) getRecommendedBookablesList();
        if (favouritesBookablesList === null) getFavouritseBookablesList();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/office-hours`,
        });
    }

    componentWillUnmount() {
        this.props.setBookablesList([]);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleSearchInput = ({ target: { value } }) => {
        const { filter$ } = this.state;
        filter$.next(value);
    };

    render() {
        const {
            bookablesList,
            redirectToOfficeHours,
            recommendedBookablesList,
            favouritesBookablesList,
            addFavouriteBookableAcademic,
            addFavouriteBookableTeam,
            deleteFavouriteBookableAcademic,
            deleteFavouriteBookableTeam,
        } = this.props;
        return (
            <div css={[calendarPage.body, calendarPage.bodyMobileWhite]}>
                <HeaderOfficeHours hideLeftElement title="Make a booking" />
                <HeaderOfficeHoursMobile hideLeftElement title="Make a booking" />
                <Container onMobileNoPadding width={592}>
                    <div css={calendarPage.invitees}>
                        <div css={[formContainer.section, formContainer.sectionBorder]}>
                            <div css={formContainer.inner}>
                                <TextFieldInline
                                    placeholder="Search for an academic or team..."
                                    onInput={this.handleSearchInput}
                                    autoFocus={true}
                                />
                            </div>
                        </div>

                        {bookablesList.length > 0 && (
                            <div css={[list.list, calendarPage.officeHoursList]}>
                                <div css={list.title}>Select an academic or a team</div>
                                {bookablesList.map(item => (
                                    <div
                                        key={item.academicId ? item.academicId : item.teamId}
                                        css={ohListItem.body}
                                    >
                                        <div css={ohListItem.bodyLeft}>
                                            {favouritesBookablesList &&
                                            favouritesBookablesList.find(i =>
                                                item.academicId
                                                    ? i.academicId === item.academicId
                                                    : i.teamId === item.teamId
                                            ) ? (
                                                <button
                                                    onClick={() =>
                                                        item.academicId
                                                            ? deleteFavouriteBookableAcademic(
                                                                  item.academicId
                                                              )
                                                            : deleteFavouriteBookableTeam(
                                                                  item.teamId
                                                              )
                                                    }
                                                    css={ohListItem.actionLeft}
                                                >
                                                    <Icon
                                                        name="star"
                                                        width="18"
                                                        height="18"
                                                        fill={colors.black38}
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        item.academicId
                                                            ? addFavouriteBookableAcademic(
                                                                  item.academicId
                                                              )
                                                            : addFavouriteBookableTeam(item.teamId)
                                                    }
                                                    css={ohListItem.actionLeft}
                                                >
                                                    <Icon
                                                        name="starOutline"
                                                        width="18"
                                                        height="18"
                                                        fill={colors.black38}
                                                    />
                                                </button>
                                            )}
                                        </div>

                                        <div css={ohListItem.bodyCenter}>
                                            <div css={ohListItem.title}>
                                                {item.subjectName}
                                                {item.subjectType === "team" && (
                                                    <span> (Team)</span>
                                                )}
                                            </div>
                                            <Button
                                                small
                                                component="span"
                                                disableRipple
                                                onClick={() =>
                                                    redirectToOfficeHours({
                                                        type: item.subjectType,
                                                        id: item.academicId
                                                            ? item.academicId
                                                            : item.teamId,
                                                        data: item,
                                                    })
                                                }
                                            >
                                                Book
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {(!bookablesList || bookablesList.length === 0) && (
                            <div>
                                {favouritesBookablesList && favouritesBookablesList.length > 0 && (
                                    <div css={[list.list, calendarPage.officeHoursList]}>
                                        <div css={list.title}>Favourites</div>
                                        {favouritesBookablesList.map(item => (
                                            <div
                                                key={`fav-${
                                                    item.academicId ? item.academicId : item.teamId
                                                }`}
                                                css={ohListItem.body}
                                            >
                                                <div css={ohListItem.bodyLeft}>
                                                    <button
                                                        onClick={() =>
                                                            item.academicId
                                                                ? deleteFavouriteBookableAcademic(
                                                                      item.academicId
                                                                  )
                                                                : deleteFavouriteBookableTeam(
                                                                      item.teamId
                                                                  )
                                                        }
                                                        css={ohListItem.actionLeft}
                                                    >
                                                        <Icon
                                                            name="star"
                                                            width="18"
                                                            height="18"
                                                            fill={colors.black38}
                                                        />
                                                    </button>
                                                </div>
                                                <div css={ohListItem.bodyCenter}>
                                                    <div css={ohListItem.title}>
                                                        {item.subjectName}
                                                        {item.subjectType === "team" && (
                                                            <span> (Team)</span>
                                                        )}
                                                    </div>
                                                    <Button
                                                        small
                                                        component="span"
                                                        disableRipple
                                                        onClick={() =>
                                                            redirectToOfficeHours({
                                                                type: item.subjectType,
                                                                id: item.academicId
                                                                    ? item.academicId
                                                                    : item.teamId,
                                                                data: item,
                                                            })
                                                        }
                                                    >
                                                        Book
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {recommendedBookablesList && recommendedBookablesList.length > 0 && (
                                    <div css={[list.list, calendarPage.officeHoursList]}>
                                        <div css={list.title}>Recommended</div>
                                        {recommendedBookablesList.map(item => (
                                            <div
                                                key={`req-${
                                                    item.academicId ? item.academicId : item.teamId
                                                }`}
                                                css={listItem.body}
                                            >
                                                <div css={listItem.bodyCenter}>
                                                    <div css={listItem.title}>
                                                        {item.subjectName}
                                                        {item.subjectType === "team" && (
                                                            <span> (Team)</span>
                                                        )}
                                                    </div>
                                                    <Button
                                                        small
                                                        component="span"
                                                        disableRipple
                                                        onClick={() =>
                                                            redirectToOfficeHours({
                                                                type: item.subjectType,
                                                                id: item.academicId
                                                                    ? item.academicId
                                                                    : item.teamId,
                                                                data: item,
                                                            })
                                                        }
                                                    >
                                                        Book
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}
const mapStateToProps = ({
    calendar: {
        officeHours: { bookablesList, recommendedBookablesList, favouritesBookablesList },
    },
}) => ({
    bookablesList,
    recommendedBookablesList,
    favouritesBookablesList,
});

const mapDispatchToProps = {
    getBookablesList: actionCreators.getBookablesList.create,
    getRecommendedBookablesList: actionCreators.getRecommendedBookablesList.create,
    setBookablesList: actionCreators.setBookablesList.create,
    redirectToOfficeHours: actionCreators.redirectToOfficeHours.create,
    addFavouriteBookableAcademic: actionCreators.addFavouriteBookableAcademic.create,
    addFavouriteBookableTeam: actionCreators.addFavouriteBookableTeam.create,
    deleteFavouriteBookableAcademic: actionCreators.deleteFavouriteBookableAcademic.create,
    deleteFavouriteBookableTeam: actionCreators.deleteFavouriteBookableTeam.create,
    getFavouritseBookablesList: actionCreators.getFavouritseBookablesList.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeHoursSearchContainer);
