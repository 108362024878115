import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionCreator from "../../../store/Calendar/Notifications/actionCreators";
import { NotificationItem } from "./Item";
import NotificationDialog from "./Modal";

export const BannerNotifications = () => {
    const dispatch = useDispatch();
    const { notifications, notification } = useSelector(state => state.calendar.notifications);

    const getDetails = useCallback(
        id => {
            dispatch(actionCreator.getNotificationBanner.create(id));
        },
        [dispatch]
    );

    const onClose = useCallback(() => dispatch(actionCreator.setNotification.create(null)), [
        dispatch,
    ]);

    useEffect(() => {
        dispatch(actionCreator.getNotificationBanners.create());
    }, [dispatch]);

    return (
        <div>
            {notifications.map(notification => (
                <NotificationItem
                    key={notification.id}
                    title={notification.teaser}
                    onClick={() => getDetails(notification.id)}
                />
            ))}
            {notification && (
                <NotificationDialog
                    notification={notification}
                    isDisplayedContinuously={
                        notifications.find(val => val.id === notification.id)
                            .isDisplayedContinuously
                    }
                    handleClose={onClose}
                />
            )}
        </div>
    );
};
