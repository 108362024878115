import ActionCreator from "../actionCreator";

export default {
    initializeFeed: ActionCreator("INITIALIZE_FEED"),
    refreshFeed: ActionCreator("REFRESH_FEED"),
    getFeed: ActionCreator("GET_FEED"),
    cleanUpdateFeed: ActionCreator("CLEAN_UPDATE_FEED"),
    createFeed: ActionCreator("CREATE_FEED"),
    errorResponse: ActionCreator("ERROR_RESPONSE_FEED"),
    updateBookmarkedFeedPost: ActionCreator("UPDATE_BOOKMARKED_FEED_POST"),
    updateUnbookmarkedFeedPost: ActionCreator("UPDATE_UNBOOKMARKED_FEED_POST"),
    updateLikedFeedPost: ActionCreator("UPDATE_LIKED_FEED_POST"),
    updateUnlikedFeedPost: ActionCreator("UPDATE_UNLIKED_FEED_POST"),
    updateBookedFeedPost: ActionCreator("UPDATE_BOOKED_POST"),
    setFeedFilter: ActionCreator("SET_FEED_FILTER"),
    updateNumCommentsPost: ActionCreator("UPDATE_NUM_COMMENTS_FEED_POST"),
    resetFeed: ActionCreator("RESET_FEED"),
};
