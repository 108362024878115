import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";

class HeaderOfficeHours extends Component {
    render() {
        const { title, hideLeftElement, customBackAction } = this.props;
        return (
            <div css={[styles.body, styles.bodyCalendar]}>
                {!hideLeftElement && (
                    <div css={styles.btnAbsoluteLeft}>
                        <HeaderBackButton
                            icon="times"
                            iconSize={18}
                            onClick={customBackAction ? customBackAction : history.goBack}
                        />
                    </div>
                )}
                <Container width={592} noPadding>
                    <div css={styles.bodySpaceBetween}>
                        <div css={styles.bodyCenter}>
                            <div css={styles.title}>{title}</div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default HeaderOfficeHours;
