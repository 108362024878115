/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import actionCreator from "../../../store/SupportMap/actionCreators";
import SupportMapItem from "../components/SupportMapItem";
import ButtonFloating from "../../../common/components/UI/ButtonFloating";
import { BeatLoader } from "react-spinners";
import { supportMap } from "../SupportMapStyles";
import HeaderSupportMap from "../../../common/components/Header/HeaderSupportMap";
import HeaderSupportMapMobile from "../../../common/components/Header/HeaderSupportMapMobile";
import { Container } from "../../../common/components/UI/Container";
import { Fragment } from "react";
import lsesu from "../../../common/images/logo-lsesu-purple.svg";
import GTM from "../../../common/services/GTM";
import { Link } from "react-router-dom";
import SuppportMapField from "../components/SearchField";
import { selectSearchResults } from "./searchUtils";
import { ROOT_PAGE_SLUG, EMERGENCY_HELP_SLUG } from "../config";
import NoResults from "../../../common/components/NoResults";

const SupportMapContainer = () => {
    const wilcard = useParams();
    const path = wilcard ? wilcard[0] : "";

    const elements = path.length > 1 ? path.substring(1).split("/") : "";
    const currentElement = elements.length > 0 ? elements[elements.length - 1] : ROOT_PAGE_SLUG;

    const supportMapStore = useSelector(state => state.SupportMap);
    const currentPage = supportMapStore.items.find(i => i.slug === currentElement);
    const rootPage = supportMapStore.items.find(i => i.slug === ROOT_PAGE_SLUG);

    const querySearch = supportMapStore.querySearch;
    const isSearchActive = supportMapStore.isSearchActive;
    const searchResults = selectSearchResults(querySearch, supportMapStore.items);

    const dispatch = useDispatch();

    const [breadcrumb, setBreadcrumb] = useState(false);

    // get all entries...
    useEffect(() => {
        dispatch(actionCreator.getAllItems.create());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update breadcrumb
    useEffect(() => {
        let breadcrumbArray =
            elements && elements.length > 0 ? [{ path: "", label: "Find support" }] : [];
        if (supportMapStore.items && elements && elements.length > 0) {
            let breadcrumbPath = "";
            elements.forEach(e => {
                const cached = supportMapStore.items.find(c => c.slug === e);
                if (cached) {
                    breadcrumbPath = `${breadcrumbPath}/${e}`;
                    breadcrumbArray.push({
                        path: breadcrumbPath,
                        label: cached.name,
                    });
                }
            });
        }
        setBreadcrumb(breadcrumbArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, supportMapStore.items]);

    useEffect(() => {
        GTM.dispatch({
            event: "pageView",
            pageUrl: path ? `/support-map${path}` : "/support-map/",
        });
        window.scrollTo(0, 0);
    }, [path]);

    const toggleSearch = () => {
        dispatch(actionCreator.toggleSearchActive.create());
    };

    return (
        <div css={[supportMap.body, supportMap.bodyMobileWhite]}>
            <HeaderSupportMap
                hideLeftElement={!isSearchActive && !path}
                customBackAction={
                    path ? `/support-map${path.substring(0, path.lastIndexOf("/"))}` : undefined
                }
                isSearchActive={isSearchActive}
                title={currentElement !== ROOT_PAGE_SLUG && rootPage ? rootPage.name : ""}
                toggleSearch={toggleSearch}
            />

            <HeaderSupportMapMobile
                hideLeftElement={!isSearchActive && !path}
                customBackAction={
                    path ? `/support-map${path.substring(0, path.lastIndexOf("/"))}` : undefined
                }
                isSearchActive={isSearchActive}
                title={currentElement !== ROOT_PAGE_SLUG && rootPage ? rootPage.name : ""}
                toggleSearch={toggleSearch}
            />

            {isSearchActive && <SuppportMapField />}

            <Container onMobileNoPadding width={812}>
                {!supportMapStore.items ? (
                    <div css={supportMap.loading}>
                        <BeatLoader color="#000" width="70px" />
                    </div>
                ) : (
                    <Fragment>
                        {!!querySearch && searchResults ? (
                            searchResults && searchResults.length > 0 ? (
                                <Fragment>
                                    <h1 css={supportMap.title}>Search Results</h1>

                                    <div css={supportMap.list}>
                                        {searchResults.map(item => (
                                            <SupportMapItem
                                                key={item.slug}
                                                item={item}
                                                path={path}
                                                isSearchResult={true}
                                                toggleSearch={toggleSearch}
                                            />
                                        ))}
                                    </div>
                                </Fragment>
                            ) : (
                                <NoResults
                                    heading="No results!"
                                    message={
                                        <Fragment>
                                            <strong>{querySearch}</strong> - did not match any
                                            documents.
                                        </Fragment>
                                    }
                                />
                            )
                        ) : currentPage ? (
                            <Fragment>
                                <h1 css={supportMap.title}>{currentPage.entryTitle}</h1>
                                <p css={supportMap.intro}>{currentPage.intro}</p>
                                <div css={supportMap.breadcrumbs}>
                                    {breadcrumb &&
                                        breadcrumb.length > 0 &&
                                        breadcrumb.map((b, i) => (
                                            <Fragment key={`b${i}`}>
                                                {i + 1 === breadcrumb.length ? (
                                                    <span>{b.label}</span>
                                                ) : (
                                                    <Link to={`/support-map${b.path}`}>
                                                        {b.label}
                                                    </Link>
                                                )}

                                                {i + 1 < breadcrumb.length && <b>&rsaquo;</b>}
                                            </Fragment>
                                        ))}
                                </div>
                                {currentPage.subitem && (
                                    <div css={supportMap.list}>
                                        {currentPage.subitem.map(item => (
                                            <SupportMapItem
                                                key={item.slug}
                                                item={item}
                                                path={path}
                                                isSearchResult={false}
                                                toggleSearch={toggleSearch}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div>{/* data inconsistency: no link & no sub-items */}.</div>
                        )}

                        <div css={supportMap.footer}>
                            <span>Produced in collaboration with</span>
                            <img alt="LSESU logo" src={lsesu} />
                        </div>
                    </Fragment>
                )}
            </Container>
            {currentElement !== EMERGENCY_HELP_SLUG && !!currentPage && (
                <ButtonFloating
                    to={`/support-map/${EMERGENCY_HELP_SLUG}`}
                    onClick={() => {
                        if (isSearchActive) toggleSearch();
                    }}
                    icon="lifebuoy"
                    iconSize={20}
                    label="Emergency Help"
                />
            )}
        </div>
    );
};

export default SupportMapContainer;
