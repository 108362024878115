import actionCreators from "./actionCreators";
import { formatDate } from "../../../app/Calendar/utils";

export const initialState = {
    bookableInfo: {},
    bookablesList: [],
    recommendedBookablesList: null,
    favouritesBookablesList: null,
    subject: {},
    slotsByMonth: [],
    slotsByDay: null,
    unavailableSessionsByMonth: [],
    unavailableSessionsByDay: null,
    hasFutureAvailability: true,
    confirmedAppointment: null,
    bookingError: null,
    rebookAppointment: {},
    bookingLimits: null,
    userCalendarEvent: null,
    errorRes: { error: "", errors: {} },
};

const deleteById = (initialState, payload) => {
    const newState = initialState;
    delete newState[payload];
    return newState;
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateUserCalendarEvent.type:
            return {
                ...state,
                userCalendarEvent: payload,
            };
        case actionCreators.clearUserCalendarEvent.type:
            return {
                ...state,
                userCalendarEvent: initialState.userCalendarEvent,
            };

        case actionCreators.redirectToOfficeHours.type:
            return {
                ...state,
                bookableInfo: {
                    ...state.bookableInfo,
                    [payload.data.academicId
                        ? payload.data.academicId
                        : payload.data.teamId]: payload.data,
                },
                hasFutureAvailability: initialState.hasFutureAvailability,
                errorRes: initialState.errorRes,
            };

        case actionCreators.setBookablesList.type:
            return {
                ...state,
                bookablesList: payload,
            };

        case actionCreators.addBookableInfo.type:
            return {
                ...state,
                bookableInfo: {
                    ...state.bookableInfo,
                    [payload.academicId ? payload.academicId : payload.teamId]: payload,
                },
                errorRes: initialState.errorRes,
            };

        case actionCreators.removeBookableInfo.type:
            return {
                ...state,
                bookableInfo:
                    !!state.bookableInfo && state.bookableInfo[payload]
                        ? deleteById(state.bookableInfo, payload)
                        : state.bookableInfo,
                errorRes: initialState.errorRes,
            };

        case actionCreators.getUnavailableSessionsByMonth.type: {
            return {
                ...state,
                subject: payload,
                slotsByMonth: initialState.slotsByMonth,
                slotsByDay: initialState.slotsByDay,
                unavailableSessionsByMonth: initialState.unavailableSessionsByMonth,
                unavailableSessionsByDay: initialState.unavailableSessionsByDay,
            };
        }

        case actionCreators.setUnavailableSessionsByMonth.type: {
            const { subject, data } = payload;

            const unavailableSessionsByMonth = [];
            const unavailableSessionsByDay = {};

            data.forEach(entry => {
                // only if date returned from server in UTC is within currently selected calendar board month afer conversion to local time
                if (
                    Number(formatDate("M", entry.start)) === subject.month &&
                    Number(formatDate("YYYY", entry.start)) === subject.year
                ) {
                    const day = Number(formatDate("D", entry.start));

                    //unavailableSessionsByMonth
                    if (!unavailableSessionsByMonth.includes(day))
                        unavailableSessionsByMonth.push(day);

                    // unavailableSessionsByDay
                    if (!unavailableSessionsByDay[day]) unavailableSessionsByDay[day] = [];
                    unavailableSessionsByDay[day].push(entry);
                }
            });

            return {
                ...state,
                unavailableSessionsByMonth,
                unavailableSessionsByDay,
            };
        }

        case actionCreators.setSlotsByMonth.type: {
            const { subject, data } = payload;

            const slotsByMonth = [];
            const slotsByDay = {};

            data.forEach(entry => {
                // only if date returned from server in UTC is within currently selected calendar board month afer conversion to local time
                if (
                    Number(formatDate("M", entry.start)) === subject.month &&
                    Number(formatDate("YYYY", entry.start)) === subject.year
                ) {
                    const day = Number(formatDate("D", entry.start));

                    //slotsByMonth
                    if (!slotsByMonth.includes(day)) slotsByMonth.push(day);

                    // slotsByDay
                    if (!slotsByDay[day]) slotsByDay[day] = [];
                    slotsByDay[day].push(entry);
                }
            });

            return {
                ...state,
                subject,
                slotsByMonth,
                slotsByDay,
            };
        }

        case actionCreators.postAppointment.type: {
            return {
                ...state,
                confirmedAppointment: initialState.confirmedAppointment,
                errorRes: initialState.errorRes,
            };
        }
        case actionCreators.setAppointment.type:
            return {
                ...state,
                confirmedAppointment: payload.data,
                // reset subject to refresh slots
                subject: initialState.subject,
            };
        case actionCreators.resetAppointmentError.type:
            return {
                ...state,
                bookingError: initialState.bookingError,
            };
        case actionCreators.handleAppointmentError.type: {
            const { error, errors } = payload;
            return {
                ...state,
                bookingError: error || Object.values(errors).join(" / "),
            };
        }
        case actionCreators.setRebookApointment.type:
            return {
                ...state,
                rebookAppointment: payload,
                slotsByMonth: initialState.slotsByMonth,
                slotsByDay: initialState.slotsByDay,
                unavailableSessionsByMonth: initialState.unavailableSessionsByMonth,
                unavailableSessionsByDay: initialState.unavailableSessionsByDay,
                hasFutureAvailability: initialState.hasFutureAvailability,
                subject: initialState.subject,
            };
        case actionCreators.removeRebookApointment.type:
            return {
                ...state,
                rebookAppointment: initialState.rebookAppointment,
                slotsByMonth: initialState.slotsByMonth,
                slotsByDay: initialState.slotsByDay,
                unavailableSessionsByMonth: initialState.unavailableSessionsByMonth,
                unavailableSessionsByDay: initialState.unavailableSessionsByDay,
                hasFutureAvailability: initialState.hasFutureAvailability,
                subject: initialState.subject,
            };

        case actionCreators.setBookingLimits.type:
            return {
                ...state,
                bookingLimits: payload,
            };

        case actionCreators.clearBookingLimits.type:
            return {
                ...state,
                bookingLimits: initialState.bookingLimits,
            };
        case actionCreators.setNoFutureAvailability.type:
            return {
                ...state,
                hasFutureAvailability: false,
            };
        case actionCreators.setRecommendedBookablesList.type:
            return {
                ...state,
                recommendedBookablesList: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.updateFavouritseBookablesList.type:
            return {
                ...state,
                favouritesBookablesList: payload,
            };

        default:
            return state;
    }
};
