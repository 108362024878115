export const fontFamily = {
    primary: "Roboto",
};

export const fontSize = {
    h1: 28,
    h2: 35,
    h3: 30,
    h4: 22,
    title: 20,
    text: 16,
    textSmall: 14,
    textSmaller: 12,
    textBig: 20,
    textHuge: 24,
};

export const lineHeight = {
    h1: "1.17",
    h2: "1.14",
    h3: "1.16",
    h4: "1.09",
    title: "1.2",
    text: "1.25",
    textSmall: "1",
    textSmaller: "1",
    textBig: "1.2",
};

export const fontWeight = {
    black: "900",
    bold: "700",
    medium: "500",
    normal: "400",
    light: "300",
};
