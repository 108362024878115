import { validateRequiredFields, isValidEmail } from "../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        firstName: "Required",
        lastName: "Required",
    };
    const errors = validateRequiredFields(requiredFields, values);

    if (values.email && !isValidEmail(values.email)) {
        errors.email = "Invalid email address";
    }

    // whatsapp / phone number
    if (values.whatsapp) {
        if (!values.whatsapp.match(/^\+(?:[0-9] ?){6,14}[0-9]$/)) {
            errors.whatsapp =
                "Please provide a valid telepone number in international format eg. +447788123123";
        }
    }

    return errors;
};
