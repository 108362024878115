export default handler => [
    {
        nameDesktop: "Feed",
        nameMobile: "Feed",
        value: "feed",
        handler,
    },
    {
        nameDesktop: "About",
        nameMobile: "About",
        value: "about",
        handler,
    },
];
