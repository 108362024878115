import { Component } from "react";
import { Field, reduxForm } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import validate from "./validate";
import { spacing } from "../../../../common/styles/Spacing";
import ButtonShape from "../../../../common/components/UI/ButtonShape";
import ButtonsGroup from "../../../../common/components/UI/ButtonsGroup";
import RenderTextFieldGrey from "../../../../common/components/FormFields/TextFieldGrey";
import { ModalBoxActions } from "../../../../common/components/UI/ModalBox";
import ButtonText from "../../../../common/components/UI/ButtonText";
import { GroupFeedbackFormStyle } from "./GroupFeedbackStyles";
import { connect } from "react-redux";
import { colors } from "../../../../common/styles/Colors";

const subjects = [
    { id: null, name: "Please select" },
    { id: 1, name: "It's spam" },
    { id: 2, name: "It's unauthorised sales" },
    { id: 3, name: "It's hate speech" },
    { id: 4, name: "It's sexually inappropriate" },
    { id: 5, name: "It's violent or prohibited content" },
    { id: 6, name: "It violates the community guidelines" },
    { id: 7, name: "Something else" },
];

class GroupFeedbackForm extends Component {
    render() {
        const { handleSubmit, onSubmit, closeModal, isLoading } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)} css={GroupFeedbackFormStyle.body}>
                <div css={GroupFeedbackFormStyle.selectWrap}>
                    <Field
                        name="requestType"
                        component={RenderTextFieldGrey}
                        label="Tell us what's not right"
                        select
                    >
                        <option value="" disabled>
                            Please select
                        </option>

                        {subjects.map(option => (
                            <option key={option.id} value={option.id > 0 ? option.name : option.id}>
                                {option.name}
                            </option>
                        ))}
                    </Field>
                </div>
                <div>
                    <Field
                        name="body"
                        component={RenderTextFieldGrey}
                        multiline
                        rows={5}
                        label="Share more information about why you’re reporting this post or group"
                    />
                </div>
                <ModalBoxActions
                    marginTopValue={spacing.space5}
                    css={{ paddingRight: 0, paddingLeft: 0 }}
                >
                    <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                        <ButtonShape primary fullWidth type="submit" disabled={isLoading}>
                            Send
                        </ButtonShape>
                    </ButtonsGroup>
                    <ButtonsGroup>
                        <ButtonText bold onClick={() => closeModal()} color={colors.black50}>
                            Cancel
                        </ButtonText>
                    </ButtonsGroup>
                </ModalBoxActions>
            </form>
        );
    }
}

const form = reduxForm({
    form: "groupFeedback",
    validate,
})(GroupFeedbackForm);

const mapStateToProps = state => {
    return {
        isLoading: state.MainView.isLoading,
    };
};

export default connect(mapStateToProps)(form);
