import ActionCreator from "../actionCreator";

export default {
    getUser: ActionCreator("GET_USER"),
    setUser: ActionCreator("SET_USER"),
    updateUser: ActionCreator("UPDATE_USER"),
    errorResponse: ActionCreator("ERROR_RESPONSE_USER"),
    postProfilePhoto: ActionCreator("POST_PROFILE_PHOTO"),
    updatedProfilePhoto: ActionCreator("UPDATED_PROFILE_PHOTO"),
};
