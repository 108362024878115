import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";
import { fontWeight, fontFamily, fontSize } from "../../styles/Typography";

export const buttonShape = {
    body: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        paddingBottom: spacing.space1,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        textDecoration: "none",
        position: "relative",
        WebkitTapHighlightColor: "transparent",
        outline: "none",
        border: 0,
        cursor: "pointer",
        userSelect: "none",
    },
    bodyFullWidth: {
        width: "100%",
    },
    bodyMain: {
        backgroundColor: "#00565F",
    },
    bodyDisabled: {
        backgroundColor: colors.black10,
        opacity: 0.5,
    },
    bodyPrimary: {
        backgroundColor: "#00866F",
    },
    text: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.black,
        lineHeight: 1.14,
        color: colors.white,
    },
    textUpper: {
        textTransform: "uppercase",
    },
    textDisabled: {
        color: colors.black20,
    },
    topShape: {
        height: 16,
        lineHeight: 1,
        fontSize: 1,
        position: "absolute",
        bottom: "100%",
        right: 0,
        left: 0,
        zIndex: 10,
    },
};
