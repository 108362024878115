/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "../UI/Icon";
import { mainMenuButton } from "./MainMenuStyles";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

const getButton = (children, style, icon, size, color, tabbar) => {
    return (
        icon && (
            <span
                css={
                    children
                        ? tabbar
                            ? mainMenuButton.iconBig
                            : mainMenuButton.icon
                        : mainMenuButton.iconBig
                }
            >
                <Icon name={icon} fill={color || colors.black87} width={size} height={size} />
            </span>
        )
    );
};

const getChildren = (children, style, icon, size, color, tabbar) => {
    return (
        children && (
            <span
                css={[
                    mainMenuButton.text,
                    tabbar && mainMenuButton.textTabbar,
                    color && { color: `${color}` },
                ]}
            >
                {children}
            </span>
        )
    );
};

const MainMenuTabbar = props => {
    const { link, children, style, icon, size, color, target, isActive, tabbar, ...other } = props;

    return link === "" ? (
        <div
            css={[
                mainMenuButton.body,
                tabbar && mainMenuButton.bodyTabbar,
                !children && mainMenuButton.bodyIcon,
            ]}
            {...other}
        >
            {getButton(children, style, icon, size, isActive ? colors.accentA : color, tabbar)}
            {getChildren(children, style, icon, size, isActive ? colors.accentA : color, tabbar)}
        </div>
    ) : (
        <a
            css={[
                mainMenuButton.body,
                tabbar && mainMenuButton.bodyTabbar,
                !children && mainMenuButton.bodyIcon,
            ]}
            // to={link}
            onClick={() => history.push(link)}
            target={target ? target : "_self"}
            {...other}
        >
            {getButton(children, style, icon, size, isActive ? colors.accentA : color, tabbar)}
            {getChildren(children, style, icon, size, isActive ? colors.accentA : color, tabbar)}
        </a>
    );
};

export default MainMenuTabbar;
