import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../../store/Calendar/Event/actionCreators";
import { calendarPage, eventFull } from "../../CalendarStyles";
import HeaderCalendar from "../../../../common/components/Header/HeaderCalendar";
import { Container } from "../../../../common/components/UI/Container";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";
import HeaderCalendarMobile from "../../../../common/components/Header/HeaderCalendarMobile";
import EventPlaceholder from "../../../../common/components/Skeletons/EventPlaceholder";
import GTM from "../../../../common/services/GTM";
import { CalendarType } from "../../components/CalendarType";

class EventDetails extends Component {
    componentDidMount() {
        const {
            getEvent,
            match: {
                params: { uid, calendarType },
            },
            setStateStatus,
            clearEvent,
        } = this.props;

        setStateStatus("PREVIEW");
        clearEvent();

        getEvent({ uid, calendarType });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/event/${calendarType}/${uid}`,
        });
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        css={eventFull.text}
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {part}
                    </a>
                );
            }
            return part;
        });
    }

    render() {
        const {
            item: {
                name,
                startAt,
                endAt,
                location,
                notes,
                inviteesData,
                uid,
                calendarType,
                isAllDay,
                mode,
            },
        } = this.props;
        return (
            <div css={[calendarPage.body, calendarPage.bodyMobileWhite]}>
                <HeaderCalendar {...{ uid, calendarType, mode }} />
                <HeaderCalendarMobile {...{ uid, calendarType, mode }} />
                <Container onMobileNoPadding width={592}>
                    <EventPlaceholder isLoaded={Boolean(name)}>
                        {name ? (
                            <div css={calendarPage.event}>
                                <div css={[eventFull.section, eventFull.sectionBorder]}>
                                    <div css={eventFull.inner}>
                                        <div css={eventFull.text}>{name}</div>
                                        <div css={eventFull.calendarType}>
                                            <CalendarType type={calendarType} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    css={[
                                        eventFull.section,
                                        !inviteesData &&
                                            !notes &&
                                            !location &&
                                            eventFull.sectionBorder,
                                    ]}
                                >
                                    <div css={eventFull.icon}>
                                        <Icon
                                            name="clock"
                                            fill={colors.black87}
                                            width={18}
                                            height={18}
                                        />
                                    </div>
                                    <div css={eventFull.inner}>
                                        {/* all-day event  */}
                                        {isAllDay ? (
                                            <div css={eventFull.text}>
                                                {startAt === endAt
                                                    ? moment(startAt).format("dddd, DD MMM YYYY")
                                                    : `from ${moment(startAt).format(
                                                          "dddd D MMMM YYYY"
                                                      )} \nto ${moment(endAt).format(
                                                          "dddd D MMMM YYYY"
                                                      )}`}
                                            </div>
                                        ) : /* NON-all-day event  */
                                        moment(startAt).format("DD MMM YYYY") ===
                                          moment(endAt).format("DD MMM YYYY") ? (
                                            /* short event  */
                                            <div>
                                                <div css={eventFull.text}>
                                                    {moment(startAt).format("dddd D MMMM YYYY")}
                                                </div>
                                                <div css={eventFull.text}>
                                                    {`from ${moment(startAt).format(
                                                        "h.mma"
                                                    )} to ${moment(endAt).format("h.mma")}`}
                                                    {` (${
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone
                                                    })`}
                                                </div>
                                            </div>
                                        ) : (
                                            /* long event  */
                                            <div>
                                                <div css={eventFull.text}>
                                                    {`from ${moment(startAt).format(
                                                        "dddd D MMMM YYYY h.mma"
                                                    )}`}
                                                </div>
                                                <div css={eventFull.text}>
                                                    {`to ${moment(endAt).format(
                                                        "dddd D MMMM YYYY h.mma"
                                                    )}`}
                                                    {` (${
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone
                                                    })`}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {location ? (
                                    <div
                                        css={[
                                            eventFull.section,
                                            !inviteesData && !notes && eventFull.sectionBorder,
                                        ]}
                                    >
                                        <div css={eventFull.icon}>
                                            <Icon
                                                name="marker"
                                                fill={colors.black87}
                                                width={19}
                                                height={19}
                                            />
                                        </div>
                                        <div css={eventFull.inner}>
                                            <div css={eventFull.text}>{location}</div>
                                        </div>
                                    </div>
                                ) : null}
                                {notes && notes !== " " ? (
                                    <div
                                        css={[
                                            eventFull.section,
                                            !inviteesData && eventFull.sectionBorder,
                                        ]}
                                    >
                                        <div css={eventFull.icon}>
                                            <Icon
                                                name="notes"
                                                fill={colors.black87}
                                                width={19}
                                                height={19}
                                            />
                                        </div>
                                        <div css={eventFull.inner}>
                                            <div
                                                css={[eventFull.text, eventFull.textBigLineHeight]}
                                            >
                                                {this.urlify(notes)}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {inviteesData && inviteesData.length > 0 ? (
                                    <div css={[eventFull.section, eventFull.sectionBorder]}>
                                        <div css={eventFull.icon}>
                                            <Icon
                                                name="users"
                                                fill={colors.black87}
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div>
                                            <div css={eventFull.text}>
                                                {inviteesData.map((invitee, index, arr) => (
                                                    <span key={invitee.id}>
                                                        {index === arr.length - 1
                                                            ? `${invitee.firstName} ${invitee.lastName}`
                                                            : `${invitee.firstName} ${invitee.lastName}, `}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </EventPlaceholder>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { item },
    },
}) => ({
    item,
});

const mapDispatchToProps = {
    getEvent: actionCreators.getEvent.create,
    clearEvent: actionCreators.clearEvent.create,
    setStateStatus: actionCreators.setStateStatus.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
