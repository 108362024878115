import ActionCreator from "../actionCreator";

export default {
    sendFeedback: ActionCreator("POST_FEEDBACK"),
    updateFeedback: ActionCreator("UPDATE_FEEDBACK"),
    clearFeedback: ActionCreator("CLEAR_FEEDBACK"),
    displayFeedbackForm: ActionCreator("SHOW_FEEDBACK"),
    hideFeedbackForm: ActionCreator("HIDE_FEEDBACK"),
    getSubjects: ActionCreator("FEEDBACK_SUBJECTS"),
    updateSubjects: ActionCreator("UPDATE_FEEDBACK_SUBJECTS"),
    startLoadingSubjects: ActionCreator("START_LOADING_SUBJECTS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SIGNPOSTINGS"),
};
