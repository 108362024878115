import { spacing } from "../../common/styles/Spacing";
import { colors } from "../../common/styles/Colors";
import { fontSize, lineHeight, fontWeight } from "../../common/styles/Typography";

export const user = {
    hiddenInput: {
        display: "none",
    },
    inputFields: {
        marginTop: spacing.space3,
        marginBottom: spacing.space3,
    },
    textMainAccount: {
        color: "#2A6157",
        fontSize: 15,
        letterSpacing: 0.23,
        lineHeight: 1.33,
        marginTop: spacing.space2,
        marginBottom: spacing.space3,
    },
    fieldError: {
        // marginTop: -spacing.space2,
        marginBottom: spacing.space2,
    },
    programme: {
        marginTop: spacing.space2,
        color: colors.accentA,
        fontSize: fontSize.text,
        fontWeight: fontWeight.black,
        lineHeight: lineHeight.textSmaller,
        textAlign: "center",
    },
    department: {
        color: colors.black87,
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.light,
        marginTop: spacing.space1,
        lineHeight: lineHeight.text,
        textAlign: "center",
    },
    divider: {
        margin: "24px 0",
        borderColor: colors.black10,
        border: "none",
        height: 1,
        color: colors.black10,
        backgroundColor: colors.black10,

        "@media (max-width: 767px)": {
            margin: "16px 0",
        },
    },
    subtitle: {
        color: colors.black87,
        fontSize: fontSize.title,
    },
};
