export const markerColors = [
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#966F13",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
    "#2A8825",
    "#B65F00",
    "#1E7CBB",
    "#AF6400",
    "#D64200",
    "#897607",
    "#E9173F",
    "#ffb300",
    "#5a205d",
    "#7d26a1",
    "#3F6FE4",
    "#aa48d2",
    "#B147C3",
    "#008476",
    "#4557c3",
    "#ff7f50",
    "#4db6ac",
    "#8c9eff",
    "#7ce6d8",
    "#1a237e",
];
