import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { textField } from "./FormFieldsStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

class RenderPasswordInputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            secureTextEntry: false,
        };
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState({ secureTextEntry: !this.state.secureTextEntry });
    };

    render() {
        const {
            input,
            label,
            meta: { touched, error },
            classes,
            ...custom
        } = this.props;

        const { secureTextEntry } = this.state;

        const iconPasswordName = secureTextEntry ? "eye" : "eye";
        const iconPasswordColor = secureTextEntry ? colors.black87 : colors.black20;

        return (
            <FormControl
                classes={{
                    root: classes.root,
                }}
                fullWidth
                error={touched && error && true}
                variant="filled"
            >
                {label && (
                    <InputLabel
                        FormLabelClasses={{
                            root: classes.labelRoot,
                            error: classes.labelError,
                            focused: classes.labelFocused,
                            filled: classes.labelFilled,
                        }}
                        variant="filled"
                    >
                        {label}
                    </InputLabel>
                )}
                <FilledInput
                    type={this.state.secureTextEntry ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    classes={{
                        root: classes.inputRoot,
                        focused: classes.inputFocused,
                        disabled: classes.inputDisabled,
                        underline: classes.inputUnderline,
                        error: classes.inputError,
                        input: classes.inputInput,
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <button
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                type="button"
                                css={textField.iconPassword}
                            >
                                <Icon
                                    name={iconPasswordName}
                                    fill={touched && error ? "#E0112B" : iconPasswordColor}
                                    width={18}
                                    height={18}
                                    viewBox="0 -4 24 24"
                                />
                            </button>
                        </InputAdornment>
                    }
                    {...input}
                    {...custom}
                />
                {touched && error && (
                    <FormHelperText
                        classes={{
                            root: classes.helperRoot,
                            error: classes.helperError,
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(textField)(RenderPasswordInputField);
