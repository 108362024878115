import actionCreators from "./actionCreators";
import { post } from "../../../fixtures/Feed/Post/index";

export const initialState = {
    post,
    comments: [],
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updatePost.type:
            return {
                ...state,
                post: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateBookmarkedPost.type:
            return {
                ...state,
                post: {
                    ...state.post,
                    isFavourite: true,
                },
            };
        case actionCreators.updateUnbookmarkedPost.type:
            return {
                ...state,
                post: {
                    ...state.post,
                    isFavourite: false,
                },
            };
        case actionCreators.updateLikedPost.type:
            return {
                ...state,
                post: {
                    ...state.post,
                    isLiked: true,
                    numberOfLikes: state.numberOfLikes + 1,
                },
            };
        case actionCreators.updateUnlikedPost.type:
            return {
                ...state,
                post: {
                    ...state.post,
                    isLiked: false,
                    numberOfLikes: state.numberOfLikes - 1,
                },
            };
        case actionCreators.clearPost.type:
            return initialState;
        case actionCreators.updateNumCommentsPost.type:
            return {
                ...state,
                post: {
                    ...state.post,
                    numComments: state.post.numComments + payload.count,
                },
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
