import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { icon } from "./IconStyles";
import IconsList from "./IconsList";
import { colors } from "../../styles/Colors";

const Icon = props => {
    const { name, fill, viewBox, style, ...other } = props;

    const svgName = IconsList[name];
    const svgEl = svgName.svg;

    return (
        <svg
            focusable="false"
            viewBox={viewBox}
            fill={fill || colors.black87}
            css={[icon.body, style]}
            {...other}
        >
            {React.cloneElement(svgEl)}
        </svg>
    );
};

Icon.defaultProps = {
    color: "inherit",
    viewBox: "0 0 24 24",
};

export default Icon;
