import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import actionCreators from "./actionCreators";
import { apiUrl } from "../../common/services/utils";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const getSidelinksEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSidelinks.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/side-link/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.createSidelinks.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(getSidelinksEpic);
