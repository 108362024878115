import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Container } from "../UI/Container";
import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const FeedPlaceholder = ({ children, isLoaded }) => {
    return isLoaded ? (
        children
    ) : (
        <Container width={592} onMobileNoPadding>
            <div css={[feedPage.infiniteScroll, skeletonStyles.feedContainer]}>
                <div css={feedPage.inner}>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.feedPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                                css={{ marginBottom: spacing.space2 }}
                            >
                                <div />
                            </ReactPlaceholder>
                            <ReactPlaceholder
                                showLoadingAnimation={true}
                                type="text"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                    <div css={entryShort.bottomShape}>
                        <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                            <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                        </svg>
                    </div>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.feedPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                                css={{ marginBottom: spacing.space2 }}
                            >
                                <div />
                            </ReactPlaceholder>
                            <ReactPlaceholder
                                showLoadingAnimation={true}
                                type="text"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                    <div css={entryShort.bottomShape}>
                        <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                            <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                        </svg>
                    </div>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.feedPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                                css={{ marginBottom: spacing.space2 }}
                            >
                                <div />
                            </ReactPlaceholder>
                            <ReactPlaceholder
                                showLoadingAnimation={true}
                                type="text"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                    <div css={entryShort.bottomShape}>
                        <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                            <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                        </svg>
                    </div>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.feedPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                                css={{ marginBottom: spacing.space2 }}
                            >
                                <div />
                            </ReactPlaceholder>
                            <ReactPlaceholder
                                showLoadingAnimation={true}
                                type="text"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default FeedPlaceholder;
