import actionCreators from "./actionCreators";
import { mergeByIds } from "../../common/services/storeHelpers";
import { addComment, editComment, removeComment } from "./reducer.helpers";

export const initialState = {
    comments: [],
    comment: null,
    page: 1,
    postId: null,
    hasMore: false,
    listParentId: null,
    formParentId: 0,
    activeForm: null,
    formType: "NEW",
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateComments.type:
            return {
                ...state,
                page: state.page + 1,
                comments: mergeByIds(state.comments || [], payload.data),
                hasMore: payload.nextPage > 0,
                listParentId: payload.parentId,
            };
        case actionCreators.updateCommentNew.type:
            return {
                ...state,
                comments: addComment(state.comments, payload),
                formParentId: 0,
            };
        case actionCreators.updateCommentEdit.type:
            return {
                ...state,
                comments: editComment(state.comments, payload),
                formParentId: 0,
            };
        case actionCreators.removeComment.type:
            return {
                ...state,
                comments: removeComment(state.comments, payload),
            };
        case actionCreators.setComment.type:
            return {
                ...state,
                comment: state.comments.find(({ id }) => id === payload),
            };
        case actionCreators.setFormParrentId.type:
            return {
                ...state,
                formParentId: payload,
            };
        case actionCreators.setFormType.type:
            return {
                ...state,
                formType: payload,
            };
        case actionCreators.toggleActiveForm.type:
            return {
                ...state,
                activeForm: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearComments.type:
            return initialState;
        default:
            return state;
    }
};
