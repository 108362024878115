import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { textFieldInline } from "./FormFieldsStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";
import TextError from "../UI/TextError";

const TextFieldInlineComponent = ({
    input,
    label,
    touched,
    error,
    classes,
    darkPlaceholder,
    helperText,
    InputProps,
    ...custom
}) => (
    <FormControl fullWidth>
        <InputBase
            classes={{
                root: classes.inputUnderlineRoot,
                error: classes.inputUnderlineError,
                focused: classes.inputUnderlineFocused,
                input: classes.inputBody,
            }}
            endAdornment={
                touched &&
                error && (
                    <InputAdornment position="end">
                        <span css={textFieldInline.iconError}>
                            <Icon name="error" fill={colors.error} width={18} height={18} />
                        </span>
                    </InputAdornment>
                )
            }
            {...input}
            {...custom}
            className={darkPlaceholder && "dark-placeholder"}
        />
        {touched && error && <TextError css={textFieldInline.error}>{error}</TextError>}
    </FormControl>
);

export const TextFieldInline = withStyles(textFieldInline)(TextFieldInlineComponent);

class RenderTextFieldInline extends Component {
    render() {
        const {
            input,
            label,
            meta: { touched, error },
            classes,
            darkPlaceholder,
            ...custom
        } = this.props;

        return (
            <FormControl css={textFieldInline.body} fullWidth error={touched && error && true}>
                <InputBase
                    classes={{
                        root: classes.inputUnderlineRoot,
                        error: classes.inputUnderlineError,
                        focused: classes.inputUnderlineFocused,
                        input: classes.inputBody,
                    }}
                    endAdornment={
                        touched &&
                        error && (
                            <InputAdornment position="end">
                                <span css={textFieldInline.iconError}>
                                    <Icon name="error" fill={colors.error} width={18} height={18} />
                                </span>
                            </InputAdornment>
                        )
                    }
                    {...input}
                    {...custom}
                    className={darkPlaceholder ? "dark-placeholder" : undefined}
                />
                {touched && error && <TextError css={textFieldInline.error}>{error}</TextError>}
            </FormControl>
        );
    }
}

export default withStyles(textFieldInline)(RenderTextFieldInline);
