import { combineEpics } from "redux-observable";

import { epics as AuthEpics } from "./Auth/epics";
import { epics as RegisterEpics } from "./Auth/Register/epics";
import { epics as FeedEpics } from "./Feed/epics";
import { epics as PostEpics } from "./Feed/Post/epics";
import { epics as MainMenuEpics } from "./MainMenu/epics";
import { epics as MainViewEpics } from "./MainView/epics";
import { epics as ChannelEpics } from "./Channel/epics";
import { epics as MonthEpics } from "./Calendar/ScheduleDay/epics";
import { epics as FullListEpics } from "./Calendar/FullList/epics";
import { epics as CalendarSyncEpics } from "./Calendar/Sync/epics";
import { epics as EventEpics } from "./Calendar/Event/epics";
import { epics as NotificationEpics } from "./Calendar/Notifications/epics";
import { epics as LinksEpics } from "./Links/epics";
import { epics as FeedbackEpics } from "./Feedback/epics";
import { epics as UserEpics } from "./User/epics";
import { epics as DiscussionEpics } from "./Discussion/epics";
import { epics as SearchEpics } from "./Search/epics";
import { epics as OfficeHoursEpics } from "./Calendar/OfficeHours/epics";
import { epics as CampusMappingEpics } from "./CampusMapping/epics";
import { epics as MyGroupsEpics } from "./Channel/Group/epics";
import { epics as SupportMapEpics } from "./SupportMap/epics";
import { epics as NotificationsEpics } from "./Notifications/epics";
import { epics as MyProgrammeEpics } from "./MyProgramme/epics";

export const rootEpics = combineEpics(
    AuthEpics,
    FeedEpics,
    MainMenuEpics,
    MainViewEpics,
    PostEpics,
    RegisterEpics,
    ChannelEpics,
    MonthEpics,
    FullListEpics,
    CalendarSyncEpics,
    EventEpics,
    LinksEpics,
    FeedbackEpics,
    UserEpics,
    DiscussionEpics,
    SearchEpics,
    OfficeHoursEpics,
    CampusMappingEpics,
    MyGroupsEpics,
    NotificationEpics,
    SupportMapEpics,
    NotificationsEpics,
    MyProgrammeEpics
);
