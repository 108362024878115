import { validateRequiredFields } from "../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        subject: "Required",
        requestType: "Required",
        body: "Required",
        name: "Required",
        email: "Required",
    };
    const errors = validateRequiredFields(requiredFields, values);

    return errors;
};
