import { ofType, combineEpics } from "redux-observable";
import { of, concat, empty } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import { default as listActionCreators } from "../FullList/actionCreators";
import { default as monthActionCreators } from "../ScheduleDay/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";

const monthStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.postEvent.type,
            actionCreators.getEvent.type,
            actionCreators.putEvent.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const monthClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateEvent.type, actionCreators.errorResponse.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const postEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postEvent.type),
        mergeMap(({ payload }) =>
            ajax.post(apiUrl("api/calendar/event/"), payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(push(`/calendar/${payload.origin.toLowerCase()}`)),
                        of(actionCreators.setStateStatus.create("NONE")),
                        of(listActionCreators.clearEventsList.create()),
                        of(monthActionCreators.clearMonthEvents.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );
const confirmEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.confirmEvent.type),
        mergeMap(({ payload }) =>
            ajax.post(apiUrl(`api/calendar/confirm-event/${payload}/`)).pipe(
                map(res => res.response),
                mergeMap(() => of(push("/calendar/list"))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putEvent.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    apiUrl(
                        `api/calendar/event/${payload.uid}/?calendarType=${payload.calendarType}`
                    ),
                    payload
                )
                .pipe(
                    map(res => res.response),
                    mergeMap(() =>
                        concat(
                            of(push(`/calendar/event/${payload.calendarType}/${payload.uid}`)),
                            of(actionCreators.setStateStatus.create("NONE")),
                            of(listActionCreators.clearEventsList.create()),
                            of(monthActionCreators.clearMonthEvents.create())
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const apiRemoveCalendarEvent = uid => ajax.remove(apiUrl(`api/calendar/event/${uid}/`));

const removeEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeEvent.type),
        mergeMap(({ payload }) =>
            apiRemoveCalendarEvent(payload.uid).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(push(`/calendar/${payload.origin.toLowerCase()}`)),
                        of(actionCreators.setStateStatus.create("NONE")),
                        of(listActionCreators.clearEventsList.create()),
                        of(monthActionCreators.clearMonthEvents.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getEvent.type),
        mergeMap(({ payload: { uid, calendarType } }) =>
            ajax.get(apiUrl(`api/calendar/event/${uid}/?calendarType=${calendarType}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateEvent.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const searchInviteesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.searchInvitees.type),
        mergeMap(({ payload: { page, filter } }) =>
            ajax.get(apiUrl(`api/calendar/search-invitees/?page=${page}&filter=${filter}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateInvitees.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const putEventNotesEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putEventNotes.type),
        mergeMap(({ payload }) =>
            ajax
                .put(
                    apiUrl(
                        `api/calendar/update-note/event/${payload.uid}/?calendarType=${payload.calendarType}`
                    ),
                    payload
                )
                .pipe(
                    map(res => res.response),
                    mergeMap(res => {
                        return concat(
                            of(push(`/calendar/event/${payload.calendarType}/${payload.uid}`)),
                            of(actionCreators.updateEventNotes.create(res))
                        );
                    }),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const cancelEventEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.cancelEvent.type),
        mergeMap(({ payload }) => (payload.url ? of(push(payload.url)) : empty()))
    );

export const epics = combineEpics(
    monthStartLoadingEpic,
    monthClearLoadingEpic,
    postEventEpic,
    putEventEpic,
    getEventEpic,
    removeEventEpic,
    searchInviteesEpic,
    putEventNotesEpic,
    confirmEventEpic,
    cancelEventEpic
);
