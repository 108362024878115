import { useEffect } from "react";
import GTM from "../../../common/services/GTM";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import HeaderMobile from "../../../common/components/Header/HeaderMobile";
import { Container } from "../../../common/components/UI/Container";
import NoResults from "../../../common/components/NoResults/";
import { notificationsStyles } from "../NotificationsStyles";
import actionCreators from "../../../store/Notifications/actionCreators";
import NotificationItem from "../components/NotificationItem";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import NotificationPlaceholder from "../../../common/components/Skeletons/NotificationPlaceholder";

const NotificationsContainer = ({ history: { push } }) => {
    const dispatch = useDispatch();
    const { notifications, page, hasMore } = useSelector(state => state.Notifications);

    useEffect(() => {
        if (hasMore) dispatch(actionCreators.getNotifications.create({ page }));
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/notifications`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div css={notificationsStyles.bodyNoPadding}>
            <HeaderMobile title="Notifications" />
            <NotificationPlaceholder isLoaded={Boolean(notifications)}>
                {notifications &&
                    (notifications.length > 0 ? (
                        <Container width={592}>
                            <InfiniteScroll
                                dataLength={notifications.length}
                                next={() =>
                                    dispatch(actionCreators.getNotifications.create({ page }))
                                }
                                {...{ hasMore }}
                            >
                                <div css={notificationsStyles.innerNoPaddingOnMobile}>
                                    {notifications.map(notification => (
                                        <NotificationItem
                                            key={`${notification.id}`}
                                            push={push}
                                            {...{ notification }}
                                        />
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </Container>
                    ) : (
                        <Container width={592} noPadding>
                            {
                                <NoResults
                                    heading="No results!"
                                    message="You don't have any pending notifications yet ..."
                                />
                            }
                        </Container>
                    ))}
            </NotificationPlaceholder>
        </div>
    );
};

export default NotificationsContainer;
