import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const notificationsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getNotifications.type,
            actionCreators.setAsReadNotification.type,
            actionCreators.getUnseenNotificationsCount.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const notificationsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateNotifications.type,
            actionCreators.updateAsReadNotification.type,
            actionCreators.updateUnseenNotificationsCount.type,
            actionCreators.errorResponse
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getNotificationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotifications.type),
        mergeMap(({ payload: { page } }) =>
            ajax.get(apiUrl(`api/notification/${page}/`)).pipe(
                map(res => res.response),
                mergeMap(res =>
                    concat(
                        of(actionCreators.updateNotifications.create(res)),
                        of(actionCreators.getUnseenNotificationsCount.create())
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const setAsReadNotificationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setAsReadNotification.type),
        mergeMap(({ payload: { id } }) =>
            ajax.patch(apiUrl(`api/notification/${id}/`)).pipe(
                map(res => res.response),
                mergeMap(() => of(actionCreators.updateAsReadNotification.create({ id }))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getUnseenNotificationsCountEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUnseenNotificationsCount.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/user/number-of-unseen-notifications/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateUnseenNotificationsCount.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    notificationsStartLoadingEpic,
    notificationsClearLoadingEpic,
    getNotificationsEpic,
    setAsReadNotificationEpic,
    getUnseenNotificationsCountEpic
);
