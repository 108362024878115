/** @jsx jsx */
import { jsx } from "@emotion/core";
import { searchResultBar } from "./SearchResultBarStyles";
import Avatar from "../../components/UI/Avatar";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channel/actionCreators";

const SearchResultBar = ({ id, iconUrl, name, redirectToChannel }) => {
    return (
        <button onClick={() => redirectToChannel({ id })} css={searchResultBar.body}>
            {iconUrl ? (
                <span css={searchResultBar.bodyLeft}>
                    <Avatar src={iconUrl} />
                </span>
            ) : null}
            <span css={searchResultBar.bodyCenter}>
                <span css={searchResultBar.title}>{name}</span>
            </span>
        </button>
    );
};

const mapDispatchToProps = {
    redirectToChannel: actionCreators.redirectToChannel.create,
};

export default connect(null, mapDispatchToProps)(SearchResultBar);
