/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "../UI/Icon";
import { mainMenuButton } from "./MainMenuStyles";
import history from "../../services/history";

const getButton = (children, style, icon, size, color, isActive) => {
    return (
        icon && (
            <span
                css={[
                    children ? mainMenuButton.icon : mainMenuButton.iconBig,
                    isActive && mainMenuButton.isActive,
                ]}
            >
                <Icon name={icon} fill="inherit" width={size} height={size} style={style} />
            </span>
        )
    );
};

const getChildren = (children, style, icon, size, color, isActive) => {
    return (
        children && (
            <span
                css={[
                    mainMenuButton.text,
                    isActive && mainMenuButton.isActive,
                    // color && { color: `${color}` },
                ]}
            >
                {children}
            </span>
        )
    );
};

const MainMenuButton = props => {
    const {
        link,
        children,
        style,
        icon,
        size,
        color,
        target,
        isActive,
        isLogoutButton,
        isSubSubMenuButton,
        badge,
        onClick,
        ...other
    } = props;

    return link.includes("http") ? (
        <a
            tabIndex={0}
            css={[
                mainMenuButton.body,
                !children && mainMenuButton.bodyIcon,
                isActive && mainMenuButton.isActive,
                isLogoutButton && mainMenuButton.isLogoutButton,
                // isSubSubMenuButton && mainMenuButton.isSubSubMenuButton,
            ]}
            href={link}
            target={target ? target : "_self"}
            {...other}
        >
            {getButton(children, style, icon, size, isActive)}
            {getChildren(children, style, icon, size, isActive)}
            {badge && <span css={mainMenuButton.badge}>{badge}</span>}
        </a>
    ) : (
        <button
            tabIndex={0}
            css={[
                mainMenuButton.body,
                !children && mainMenuButton.bodyIcon,
                isActive && mainMenuButton.isActive,
                isLogoutButton && mainMenuButton.isLogoutButton,
                // isSubSubMenuButton && mainMenuButton.isSubSubMenuButton,
            ]}
            // to={link}
            onClick={() => {
                if (link) history.push(link);
                if (onClick) onClick();
            }}
            // target={target ? target : "_self"}
            {...other}
        >
            {getButton(children, style, icon, size, isActive)}
            {getChildren(children, style, icon, size, isActive)}
            {badge && badge > 0 ? <span css={mainMenuButton.badge}>{badge}</span> : undefined}
        </button>
    );
};

export default MainMenuButton;
