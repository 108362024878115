import { colors } from "../../common/styles/Colors";
import { resetLineHeight } from "../../common/styles/Globals";
import { spacing } from "../../common/styles/Spacing";
import { fontSize, fontWeight } from "../../common/styles/Typography";

export const notificationsStyles = {
    bodyNoPadding: {
        position: "relative",
        paddingTop: spacing.space4,
        paddingBottom: spacing.space4,
        "@media (max-width: 1023px)": {
            padding: `64px 0 ${spacing.space6 - 1}px`,
        },
    },
    notification: {
        display: "flex",
        backgroundColor: colors.white,
        paddingTop: spacing.space2,
        paddingRight: spacing.space3,
        paddingBottom: spacing.space4,
        paddingLeft: spacing.space3,
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        position: "relative",
        fontWeight: fontWeight.normal,
    },
    bottomShape: {
        ...resetLineHeight,
        height: 8,
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
    },
    notSeen: {
        fontWeight: fontWeight.bold,
    },
};
