import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Portal } from "react-portal";
import { Manager, Reference, Popper } from "react-popper";
import { contextMenu, contextMenuTrigger } from "./ContextMenuStyles";
import ContextMenuLink from "./ContextMenuLink";
import history from "./../../../common/services/history";

class ContextMenu extends Component {
    constructor(...args) {
        super(...args);

        this.attachRef = target => this.setState({ target });
        this.state = {
            showModal: false,
        };
    }

    close = () => {
        this.setState({ showModal: false });
        document.body.style.overflow = null;
    };

    open = () => {
        this.setState({ showModal: true });
        document.body.style.overflow = "hidden";
    };

    render() {
        const { children, itemsList } = this.props;

        return (
            <div css={contextMenuTrigger.body}>
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <button
                                type="button"
                                onClick={this.open}
                                ref={ref}
                                css={contextMenuTrigger.btn}
                            >
                                {children}
                            </button>
                        )}
                    </Reference>
                    <Portal>
                        {this.state.showModal && (
                            <div css={contextMenu.backdrop} onClick={this.close}>
                                <Popper
                                    placement="top-end"
                                    positionFixed
                                    modifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, -24],
                                            },
                                        },
                                    ]}
                                >
                                    {({ ref, style, placement }) => {
                                        return (
                                            <div
                                                ref={ref}
                                                css={{ ...contextMenu.dialog, ...style }}
                                                data-placement={placement}
                                            >
                                                {itemsList.map((item, index) => {
                                                    return (
                                                        <ContextMenuLink
                                                            key={index}
                                                            // to={item.to}  // handled by onClick to keep session state
                                                            to=""
                                                            text={item.text}
                                                            icon={item.icon}
                                                            iconColor={item.iconColor}
                                                            iconSize={item.iconSize}
                                                            color={item.color}
                                                            onClick={
                                                                item.to
                                                                    ? () => history.push(item.to)
                                                                    : () => {
                                                                          item.onClick();
                                                                          this.close();
                                                                      }
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        );
                                    }}
                                </Popper>
                            </div>
                        )}
                    </Portal>
                </Manager>
            </div>
        );
    }
}

export default ContextMenu;
