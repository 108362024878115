import { validateRequiredFields } from "../../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        firstName: "Required",
        lastName: "Required",
        email: "Required",
        toc: "Required",
    };
    const errors = validateRequiredFields(requiredFields, values);

    return errors;
};
