export const testError = { error: "Error", errors: { name: "Error name", status: "Error status" } };

export const channel = {
    id: 0,
    category: "",
    name: "",
    imageUrl: "",
    iconUrl: "",
    description: "",
    numFollowers: 0,
    email: "",
};

export const testChannel = {
    id: 1,
    category: "category",
    name: "name",
    imageUrl: "imageUrl",
    iconUrl: "iconUrl",
    description: "description",
    email: "email",
    numFollowers: 0,
    isUserTracking: false,
};

export const testParams = { id: 1, param: `page=1&kind=kind` };
