/** @jsx jsx */
import { jsx } from "@emotion/core";
import { slotItem } from "../../CalendarStyles";
import { formatDate } from "../../utils";
import Button from "../../../../common/components/UI/Button";
const DaySlot = ({ slot, subtitleProp, onClick }) => (
    <div css={slotItem.container}>
        <div css={slotItem.inner}>
            <div css={slotItem.body}>
                {!slot.fullWidth && (
                    <div css={slotItem.time}>
                        {formatDate("h.mma", slot.start)} - {formatDate("h.mma", slot.end)}
                    </div>
                )}
                <div css={[slotItem.text, !slot.fullWidth && slotItem.textBorder]}>
                    {((slot.session && slot.session.title) || slot.title) && (
                        <div css={slotItem.title}>
                            {slot.session && slot.session.title ? slot.session.title : slot.title}
                        </div>
                    )}
                    {slot.message && (
                        <div css={slotItem.textItem}>
                            {slot.message}
                            {slot.messageDate
                                ? ` ${formatDate(
                                      "dddd D MMMM YYYY",
                                      slot.messageDate
                                  )} at ${formatDate("h.mma", slot.messageDate, slot.start)}`
                                : ""}
                        </div>
                    )}
                    {subtitleProp && slot.session && slot.session[subtitleProp] && (
                        <div css={slotItem.subtitle}>{slot.session[subtitleProp]}</div>
                    )}
                    {slot.session && slot.session.location && (
                        <div css={slotItem.textItem}>
                            <strong>Location:</strong>
                            <br />
                            {slot.session.location}
                        </div>
                    )}
                    {slot.session && slot.session.appointmentForm && (
                        <div css={slotItem.textItem}>
                            <strong>Appointment type:</strong>
                            <br />
                            {slot.session.appointmentForm}
                        </div>
                    )}
                </div>
            </div>
            {onClick && (
                <div css={slotItem.actions}>
                    <Button small onClick={onClick}>
                        {slot.session.mode === "bookable" ? "Book" : "Details"}
                    </Button>
                </div>
            )}
        </div>
        <div css={slotItem.bottomShape}>
            <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
            </svg>
        </div>
    </div>
);

export default DaySlot;
