import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Truncate from "react-truncate";
import actionCreators from "../../store/Channel/actionCreators";
import groupActionCreators from "../../store/Channel/Group/actionCreators";
import FeedItem from "../Feed/FeedItem/";
import Avatar from "../../common/components/UI/Avatar";
import Icon from "../../common/components/UI/Icon";
import {
    channel,
    channelCover,
    channelFollow,
    channelContact,
    bookableInfo,
    addPostBtn,
} from "./ChannelStyles";
import { colors } from "../../common/styles/Colors";
import { Container } from "../../common/components/UI/Container";
import Button from "../../common/components/UI/Button";
import FilterBar from "../../common/components/FilterBar";
import filters from "./filters";
import ContextMenu from "../../common/components/ContextMenu";
import ChannelPlaceholder from "../../common/components/Skeletons/ChannelPlaceholder";
import { withLastLocation } from "react-router-last-location";
import GTM from "../../common/services/GTM";
import * as config from "./Group/values";
import moment from "moment";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../common/components/UI/ModalBox";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../common/components/UI/ButtonShape";
import ButtonText from "../../common/components/UI/ButtonText";
import { spacing } from "../../common/styles/Spacing";
import ReactVisibilitySensor from "react-visibility-sensor";

export const channelMore = ({
    owner,
    editGroup,
    redirectToGroupMembers,
    displayGroupFeedbackForm,
    id,
    handleLeaveGroupModalOpen,
}) => {
    const menu = [
        {
            to: "",
            text: "Leave group",
            icon: "times",
            onClick: () => {
                handleLeaveGroupModalOpen();
            },
        },

        {
            to: "",
            text: "Report inappropriate behaviour",
            icon: "envelope",
            onClick: () => {
                displayGroupFeedbackForm();
            },
        },
        {
            to: "",
            text: "Group members",
            icon: "users",
            onClick: () => {
                redirectToGroupMembers({ id });
            },
        },
    ];
    const edit = {
        to: "",
        text: "Group settings",
        icon: "edit",
        onClick: () => {
            editGroup({ id });
        },
    };

    if (owner) menu.push(edit);
    return menu.reverse();
};

class ChannelContainer extends Component {
    state = {
        activeTab: "feed",
        kind: "all",
        isLeaveGroupModalOpen: false,
        trackedIds: [],
    };

    componentDidMount() {
        const {
            getChannel,
            setChannelOrigin,
            match: {
                params: { id },
            },
        } = this.props;
        getChannel({ id });

        if (this.props.lastLocation) {
            if (
                this.props.lastLocation.pathname.includes("/feed") ||
                this.props.lastLocation.pathname.includes("/my-channels") ||
                this.props.lastLocation.pathname.includes("/my-saved-items")
            ) {
                setChannelOrigin(this.props.lastLocation.pathname);
            }
        }

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/channel/${id}`,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            channel: { category, name, isUserTracking, groupType, userGroupStatus },
        } = this.props;

        // channel details were loaded
        if (prevProps.channel.name === "" && name !== "") {
            if (category === "group") {
                if (isUserTracking || groupType === config.GROUP_TYPE_OPEN) this.onLoadMore();
                else this.setState({ activeTab: "about" });
            } else {
                this.onLoadMore();
            }
        }

        // user accepted invitation to a group
        if (
            category === "group" &&
            prevProps.channel.isUserTracking === false &&
            prevProps.channel.userGroupStatus !== null &&
            isUserTracking === true &&
            userGroupStatus === null &&
            groupType !== config.GROUP_TYPE_OPEN
        ) {
            this.setState({ activeTab: "feed" });
            this.onLoadMore();
        }
    }

    switchTab = value => {
        this.setState({ activeTab: value });
    };

    onLoadMore = () => {
        const { kind } = this.state;
        const {
            getChannelPosts,
            page,
            match: {
                params: { id },
            },
        } = this.props;
        getChannelPosts({
            id,
            param: `page=${page}&kind=${kind}`,
        });
    };

    goToPreviousScene = () => {
        const { history, origin } = this.props;
        if (origin) history.push(origin);
        else history.goBack();
    };

    handleLeaveGroupModalClose = () => {
        this.setState({ isLeaveGroupModalOpen: false });
    };

    handleLeaveGroupModalOpen = () => {
        this.setState({ isLeaveGroupModalOpen: true });
    };

    confirmLeaveGroup = () => {
        const {
            trackingGroup,
            channel: { id, name },
        } = this.props;
        trackingGroup({ id, action: "unfollow" });
        GTM.dispatch({
            event: "channelClick",
            actionType: `Leave group`,
            channelId: `${id}`,
            channelName: `${name}`,
        });
        this.handleLeaveGroupModalClose();
    };

    checkFeedVisible = (isVisible, post) => {
        const { trackedIds } = this.state;
        const { channel } = this.props;

        if (isVisible && post && !trackedIds.includes(`${post.id}`)) {
            const gtmTypeDesc =
                post.kind === "text"
                    ? post.article
                        ? "long post"
                        : post.imageUrl
                        ? "image post"
                        : "short post"
                    : "event";

            GTM.dispatch({
                event: "postAppearance",
                actionType: `${gtmTypeDesc} appeared on channel feed (page ${this.props.page - 1})`,
                postId: `${post.id}`,
                postName: `${
                    post.kind === "text"
                        ? post.description
                              .replace(/<[^>]+>/g, " ")
                              .replace(/\s\s+/g, " ")
                              .trim()
                              .split(/\s+/)
                              .slice(0, 10)
                              .join(" ")
                              .concat([" ..."].join(""))
                        : post.name
                }`,
                channelId: `${channel.id}`,
                channelName: `${channel.name}`,
            });

            this.setState({ trackedIds: [...trackedIds, `${post.id}`] });
        }
    };

    getFeedItems = feed =>
        feed.map((post, index) => (
            <ReactVisibilitySensor
                key={`${index}-${post.id}`}
                onChange={isVisible => this.checkFeedVisible(isVisible, post)}
                partialVisibility="bottom"
            >
                <FeedItem
                    key={`${index}-${post.id}`}
                    {...{
                        item: {
                            ...post,
                            channel: {
                                id: this.props.channel.id,
                                name: this.props.channel.name,
                                iconUrl: this.props.channel.iconUrl,
                            },
                        },
                    }}
                />
            </ReactVisibilitySensor>
        ));

    render() {
        const {
            channel: {
                id,
                iconUrl,
                imageUrl,
                name,
                category,
                email,
                description,
                numFollowers,
                isUserTracking,
                isUserOwner,
                isSchoolChannel,
                groupType,
                userGroupStatus,
                lastActiveDate,
                canUnfollow,
            },
            hasMore,
            posts,
            postFollowStatus,
            trackingGroup,
            redirectToEditGroup,
            redirectToGroupMembers,
            displayGroupFeedbackForm,
            answerGroupInvitation,
            sendJoinRequestToGroup,
            firstName,
            lastName,
            photoUrl,
        } = this.props;

        const {
            switchTab,
            onLoadMore,
            goToPreviousScene,
            handleLeaveGroupModalClose,
            handleLeaveGroupModalOpen,
            confirmLeaveGroup,
        } = this;

        const { isLeaveGroupModalOpen } = this.state;

        const followStatus = !isUserTracking ? "follow" : "unfollow";

        return (
            <Container width={592} onMobileNoPadding>
                <ChannelPlaceholder isLoaded={Boolean(id > 0)}>
                    <div css={channel.body} key={"ChannelBody"}>
                        <div css={channelCover.actions}>
                            <div css={channel.backButtonContainer}>
                                <button
                                    onClick={() => goToPreviousScene()}
                                    css={channel.backButton}
                                >
                                    <Icon
                                        name="arrowLeft"
                                        fill={colors.white}
                                        width={18}
                                        height={18}
                                    />
                                </button>
                            </div>
                            {category === "group" && isUserTracking && (
                                <ContextMenu
                                    itemsList={channelMore({
                                        owner: isUserOwner,
                                        id,
                                        editGroup: redirectToEditGroup,
                                        redirectToGroupMembers,
                                        displayGroupFeedbackForm,
                                        handleLeaveGroupModalOpen,
                                    })}
                                >
                                    <Icon name="more" width={20} height={20} fill={colors.white} />
                                </ContextMenu>
                            )}
                        </div>
                        <div
                            css={[
                                channelCover.body,
                                imageUrl && {
                                    backgroundImage: `url(${imageUrl})`,
                                },
                            ]}
                        >
                            <div
                                css={[
                                    channelCover.shape,
                                    imageUrl ? channelCover.shapeImg : channelCover.shapeDefault,
                                ]}
                            />
                            <div css={channelCover.gradient} />
                            <div css={channelCover.inner}>
                                {category !== "page" && (
                                    <div css={channelCover.avatar}>
                                        <Avatar medium src={iconUrl} />
                                    </div>
                                )}
                                <div css={channelCover.title}>
                                    <div>
                                        <Truncate lines={3} ellipsis={<span>...</span>}>
                                            {name}
                                        </Truncate>
                                    </div>
                                </div>
                            </div>
                            <div css={channelFollow.body}>
                                <div css={channelFollow.inner}>
                                    <div css={channelFollow.elementLeft}>
                                        {numFollowers && numFollowers > 0 ? (
                                            <div css={channelFollow.numFollowers}>
                                                <b>{numFollowers}</b> Follower
                                                {numFollowers > 1 ? "s" : ""}
                                            </div>
                                        ) : (
                                            <span />
                                        )}
                                        {category === "group" &&
                                            lastActiveDate &&
                                            !isUserTracking && (
                                                <div css={channelFollow.activity}>
                                                    Last active:{" "}
                                                    {moment(lastActiveDate).format(
                                                        "dddd D MMMM YYYY"
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                    <div css={channelFollow.elementRight}>
                                        {!isSchoolChannel && category !== "group" && !!canUnfollow && (
                                            <Button
                                                onClick={() => {
                                                    postFollowStatus({
                                                        id,
                                                        action: followStatus,
                                                    });
                                                    GTM.dispatch({
                                                        event: "channelClick",
                                                        actionType: `${
                                                            isUserTracking ? "Unfollow" : "Follow"
                                                        } channel`,
                                                        channelId: `${id}`,
                                                        channelName: `${name}`,
                                                    });
                                                    return true;
                                                }}
                                                small
                                                channel
                                                textIcon={{
                                                    name: "add",
                                                    width: 10,
                                                    height: 10,
                                                    fill: colors.white,
                                                }}
                                                css={channelFollow.followBtn}
                                            >
                                                {isUserTracking ? "Unfollow" : "Follow"}
                                            </Button>
                                        )}

                                        {category === "group" &&
                                            !isUserTracking &&
                                            (groupType === config.GROUP_TYPE_OPEN ? (
                                                <Button
                                                    onClick={() => {
                                                        trackingGroup({
                                                            id,
                                                            action: "follow",
                                                        });
                                                        GTM.dispatch({
                                                            event: "channelClick",
                                                            actionType: `Join group`,
                                                            channelId: `${id}`,
                                                            channelName: `${name}`,
                                                        });
                                                        return true;
                                                    }}
                                                    small
                                                    channel
                                                    textIcon={{
                                                        name: "add",
                                                        width: 7,
                                                        height: 7,
                                                        fill: colors.white,
                                                    }}
                                                    css={channelFollow.followBtn}
                                                >
                                                    Join group
                                                </Button>
                                            ) : groupType === config.GROUP_TYPE_CLOSED ||
                                              groupType === config.GROUP_TYPE_PRIVATE ? (
                                                userGroupStatus ===
                                                config.USER_GROUP_STATUS_INVITATION ? (
                                                    <div css={channelFollow.actions}>
                                                        <Button
                                                            onClick={() => {
                                                                answerGroupInvitation({
                                                                    groupId: id,
                                                                    status:
                                                                        config.ROLE_ACTION_APPROVE,
                                                                });
                                                                GTM.dispatch({
                                                                    event: "channelClick",
                                                                    actionType: `Accept group invitation`,
                                                                    channelId: `${id}`,
                                                                    channelName: `${name}`,
                                                                });
                                                                return true;
                                                            }}
                                                            small
                                                            channel
                                                            textIcon={{
                                                                name: "check",
                                                                width: 10,
                                                                height: 10,
                                                                fill: colors.white,
                                                            }}
                                                            css={channelFollow.invitationBtn}
                                                        >
                                                            Accept
                                                            <span css={channelFollow.hideOnMobile}>
                                                                {" "}
                                                                invitation
                                                            </span>
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                answerGroupInvitation({
                                                                    groupId: id,
                                                                    status:
                                                                        config.ROLE_ACTION_DECLINE,
                                                                });
                                                                GTM.dispatch({
                                                                    event: "channelClick",
                                                                    actionType: `Decline group invitation`,
                                                                    channelId: `${id}`,
                                                                    channelName: `${name}`,
                                                                });
                                                                return true;
                                                            }}
                                                            small
                                                            channel
                                                            textIcon={{
                                                                name: "times",
                                                                width: 9,
                                                                height: 9,
                                                                fill: colors.white,
                                                            }}
                                                            css={channelFollow.invitationBtn}
                                                        >
                                                            Decline
                                                            <span css={channelFollow.hideOnMobile}>
                                                                {" "}
                                                                invitation
                                                            </span>
                                                        </Button>
                                                    </div>
                                                ) : userGroupStatus ===
                                                  config.USER_GROUP_STATUS_JOINREQUEST ? (
                                                    <div css={channelFollow.memberLabel}>
                                                        <div css={channelFollow.iconText}>
                                                            <div css={channelFollow.iconText.icon}>
                                                                <Icon
                                                                    name="clock"
                                                                    width="12"
                                                                    height="12"
                                                                    fill={colors.white}
                                                                />
                                                            </div>
                                                            <div
                                                                css={
                                                                    channelFollow.iconText
                                                                        .textItalic
                                                                }
                                                            >
                                                                Invitation pending...
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            sendJoinRequestToGroup({
                                                                groupId: id,
                                                            });
                                                            GTM.dispatch({
                                                                event: "channelClick",
                                                                actionType: `Ask to join group`,
                                                                channelId: `${id}`,
                                                                channelName: `${name}`,
                                                            });
                                                            return true;
                                                        }}
                                                        small
                                                        channel
                                                        textIcon={{
                                                            name: "add",
                                                            width: 7,
                                                            height: 7,
                                                            fill: colors.white,
                                                        }}
                                                        css={channelFollow.followBtn}
                                                    >
                                                        Ask to join group
                                                    </Button>
                                                )
                                            ) : (
                                                <div />
                                            ))}

                                        {category === "group" && isUserTracking && (
                                            <div css={channelFollow.memberLabel}>
                                                <div css={channelFollow.iconText}>
                                                    <div css={channelFollow.iconText.icon}>
                                                        <Icon
                                                            name="check"
                                                            width="10"
                                                            height="10"
                                                            fill={colors.white}
                                                        />
                                                    </div>
                                                    <div css={channelFollow.iconText.text}>
                                                        Member
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {category === "group" && lastActiveDate && !isUserTracking && (
                                    <div css={channelFollow.activityMobile}>
                                        Last active:{" "}
                                        {moment(lastActiveDate).format("dddd D MMMM YYYY")}
                                    </div>
                                )}
                            </div>
                        </div>
                        <FilterBar expanded={2} filters={filters(switchTab)} relative />
                    </div>

                    {/* ABOUT */}
                    {this.state.activeTab === filters(switchTab)[1].value && (
                        <div css={bookableInfo.body} key={"ChannelTabActive"}>
                            {email && (
                                <div css={channelContact.body}>
                                    {email && (
                                        <div css={channelContact.item}>
                                            <a css={channelContact.link} href={`mailto:${email}`}>
                                                <span css={channelContact.linkIcon}>
                                                    <Icon
                                                        name="envelope"
                                                        fill={colors.black87}
                                                        width={20}
                                                        height={20}
                                                    />
                                                </span>
                                                <span css={channelContact.linkText}>
                                                    <span css={channelContact.linkDetails}>
                                                        {email}
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}

                            {description && <div css={bookableInfo.text}>{description}</div>}

                            {category === "group" && (
                                <div css={bookableInfo.disclaimer}>
                                    The views expressed in this group do not reflect those of LSE
                                    &amp; LSESU. Users have full responsibility for the content
                                    published in this group. Members of the group have accepted
                                    Student Hub community guidelines which can be viewed{" "}
                                    <a
                                        href={process.env.REACT_APP_TERMS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            GTM.dispatch({
                                                event: "genericClick",
                                                targetName: "Terms and conditions",
                                            });
                                            return true;
                                        }}
                                        css={bookableInfo.disclaimerLink}
                                    >
                                        here
                                    </a>
                                </div>
                            )}
                        </div>
                    )}

                    {/* FEED */}

                    {category === "group" &&
                        isUserTracking &&
                        this.state.activeTab === filters(switchTab)[0].value && (
                            <Link
                                to={`/add-new-post/${id}`}
                                css={addPostBtn.body}
                                key={"ChannelAddPost"}
                            >
                                <div css={addPostBtn.avatar}>
                                    <Avatar
                                        src={photoUrl}
                                        initials={firstName.charAt(0) + lastName.charAt(0)}
                                    />
                                </div>
                                <div css={addPostBtn.label}>
                                    <span css={addPostBtn.labelText}>Write a post...</span>
                                </div>
                            </Link>
                        )}

                    {this.state.activeTab === filters(switchTab)[0].value && posts && (
                        <InfiniteScroll
                            dataLength={posts.length}
                            next={() => {
                                // prevent double-1st page load
                                if (this.props.page > 1) onLoadMore();
                            }}
                            {...{ hasMore }}
                        >
                            <div css={channel.inner}>{this.getFeedItems(posts)}</div>
                        </InfiniteScroll>
                    )}
                </ChannelPlaceholder>
                <ModalBox
                    open={isLeaveGroupModalOpen}
                    onClose={handleLeaveGroupModalClose}
                    modalWidth={310}
                >
                    <ModalBoxText>
                        <p>Are you sure you want to leave this group?</p>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space6}>
                        <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                            <ButtonShape primary onClick={() => confirmLeaveGroup()} fullWidth>
                                Yes
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText bold onClick={handleLeaveGroupModalClose}>
                                Cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
            </Container>
        );
    }
}

const mapStateToProps = ({
    Channel: {
        page,
        hasMore,
        posts,
        origin,
        groupType,
        isUserTracking,
        userGroupStatus,
        lastActiveDate,
        ...rest
    },
    Auth: {
        user: { firstName, lastName, photoUrl },
    },
    calendar: {
        officeHours: { bookableInfo },
    },
}) => ({
    channel: rest.channel,
    bookableInfo,
    page,
    hasMore,
    posts,
    origin,
    groupType,
    isUserTracking,
    userGroupStatus,
    lastActiveDate,
    firstName,
    lastName,
    photoUrl,
});

const mapDispatchToProps = {
    getChannel: actionCreators.getChannel.create,
    trackingGroup: groupActionCreators.trackingGroup.create,
    redirectToEditGroup: groupActionCreators.redirectToEditGroup.create,
    getChannelPosts: actionCreators.getChannelPosts.create,
    postFollowStatus: actionCreators.postFollowStatus.create,
    setChannelOrigin: actionCreators.setChannelOrigin.create,
    redirectToGroupMembers: groupActionCreators.redirectToGroupMembers.create,
    answerGroupInvitation: groupActionCreators.answerGroupInvitation.create,
    sendJoinRequestToGroup: groupActionCreators.sendJoinRequestToGroup.create,
    displayGroupFeedbackForm: groupActionCreators.displayGroupFeedbackForm.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLastLocation(ChannelContainer));
