import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Container } from "../UI/Container";
import { colors } from "../../styles/Colors";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const ChannelsPlaceholder = ({ children, isLoaded }) => {
    return isLoaded ? (
        children
    ) : (
        <Container width={592}>
            <div css={[feedPage.infiniteScroll, skeletonStyles.channelsContainer]}>
                <div css={feedPage.inner}>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.channelsPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={2}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.channelsPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={2}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>

                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.channelsPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={2}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>

                    <div css={entryShort.body}>
                        <div css={[skeletonStyles.channelsPlaceholder]}>
                            <ReactPlaceholder
                                type="media"
                                ready={false}
                                rows={2}
                                color={colors.greyDark10}
                                showLoadingAnimation={true}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ChannelsPlaceholder;
