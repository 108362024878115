import { colors } from "../../../common/styles/Colors";
import { spacing } from "../../../common/styles/Spacing";
import { fontSize, lineHeight, fontWeight } from "../../../common/styles/Typography";

export const studentBar = {
    body: {
        width: "100%",
        cursor: "pointer",
        display: "flex",
        backgroundColor: colors.white,
        padding: spacing.space2,
        alignItems: "stretch",
        border: 0,
        borderBottomWidth: 1,
        borderBottomColor: colors.greyDark10,
        borderBottomStyle: "solid",
        textDecoration: "none",
        margin: 0,
        marginRight: spacing.space2,
        marginLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    bodyLeft: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: spacing.space2,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        alignItems: "center",
    },
    bodyRight: {
        display: "flex",
        flex: 1,
        alignItems: "center",
    },
    bodyRow: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",

        "@media (max-width: 1023px)": {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        },
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
        "@media (max-width: 1023px)": {
            marginTop: spacing.space1,
        },
    },
    actions: {
        paddingLeft: spacing.space2,

        "@media (max-width: 1023px)": {
            paddingLeft: 0,
            marginTop: spacing.space1,
            marginLeft: -spacing.space1,
        },
    },
};
