import React, { Component } from "react";
import { FileBase64 } from "./FileInputButton";
// import FileBase64 from "react-file-base64";

class FileInputBase64 extends Component {
    handleChange = fileOnChange => file => {
        fileOnChange(file);
    };

    render() {
        const { id, style, input, ...rest } = this.props;
        return (
            <FileBase64
                onDone={this.handleChange(input.onChange)}
                type="file"
                id={id}
                style={style}
                {...{ input, rest }}
            />
        );
    }
}

export default FileInputBase64;
