import ActionCreator from "../actionCreator";

export default {
    getSearchResults: ActionCreator("GET_SEARCH_RESULTS"),
    updateSearchResults: ActionCreator("UPDATE_SEARCH_RESULTS"),
    enableResults: ActionCreator("ENABLE_RESULTS"),
    disableResults: ActionCreator("DISABLE_RESULTS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SEARCH"),
    toggleSearchBar: ActionCreator("TOGGLE_SEARCH_BAR"),
    hideSearchBar: ActionCreator("HIDE_SEARCH_BAR"),
    setQuerySearch: ActionCreator("SET_QUERY_SEARCH"),
    clearSearchInput: ActionCreator("CLEAR_SEARCH_INPUT"),
};
