import actionCreators from "./actionCreators";

export const initialState = {
    results: null,
    show: false,
    errorRes: { error: "", errors: {} },
    toggleSearchBar: false,
    querySearch: "",
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateSearchResults.type:
            return {
                ...state,
                results: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                errorRes: payload,
            };
        case actionCreators.enableResults.type:
            return {
                ...state,
                show: true,
            };
        case actionCreators.disableResults.type:
            return {
                ...state,
                results: initialState.results,
                querySearch: "",
                show: false,
            };
        case actionCreators.toggleSearchBar.type:
            return {
                ...state,
                toggleSearchBar: payload,
            };
        case actionCreators.hideSearchBar.type:
            return {
                ...state,
                toggleSearchBar: false,
                show: false,
            };
        case actionCreators.setQuerySearch.type:
            return {
                ...state,
                querySearch: payload,
            };
        case actionCreators.clearSearchInput.type:
            return {
                ...state,
                results: initialState.results,
                querySearch: "",
            };
        default:
            return state;
    }
};
