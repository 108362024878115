import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextError from "../UI/TextError";
import { checkboxField } from "./FormFieldsStyles";
import Icon from "../UI/Icon";

class RenderCheckboxField extends Component {
    render() {
        const {
            input,
            label,
            meta: { touched, error },
            errorRes,
            topAlign,
            classes,
            whiteCheck,
            ...custom
        } = this.props;

        const { value, ...remainingInput } = input;

        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value}
                            {...remainingInput}
                            {...custom}
                            // classes={{
                            //     default: classes.root,
                            //     checked: classes.checkboxChecked,
                            // }}
                            css={topAlign ? checkboxField.checkboxTopAlign : checkboxField.checkbox}
                            icon={<Icon name="checkbox" width="18" height="18" fill="#8d8d8d" />}
                            checkedIcon={
                                <Icon
                                    name={whiteCheck ? "checkboxCheckedWhite" : "checkboxChecked"}
                                    fill="transparent"
                                    width="18"
                                    height="18"
                                />
                            }
                        />
                    }
                    classes={{
                        label: whiteCheck ? classes.checkboxLabelWhite : classes.checkboxLabel,
                    }}
                    label={label}
                    css={topAlign ? checkboxField.bodyTopAlign : checkboxField.body}
                />
                {touched && (error || errorRes) && (
                    <div css={checkboxField.errorText}>
                        <TextError>{error || errorRes}</TextError>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(checkboxField)(RenderCheckboxField);
