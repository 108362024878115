/** @jsx jsx */
import { jsx } from "@emotion/core";
import EventItem from "./EventItem";
import { calendarList, dayItem } from "../CalendarStyles";
import { formatDate } from "../utils";

const DayItemEvents = ({ day, events, details }) => (
    <div css={dayItem.body}>
        <div css={dayItem.date}>
            <div css={dayItem.dateNumber}>{formatDate("DD", day)}</div>
            <div css={dayItem.dateDay}>{formatDate("ddd", day)}</div>
        </div>
        <div css={dayItem.event}>
            {events.map(event => (
                <EventItem key={event.uid} item={event} details={details} />
            ))}
        </div>
    </div>
);

const DayItem = ({ item: { days, month } }) => (
    <div css={calendarList.body}>
        <div css={calendarList.month}>
            <div css={calendarList.monthText}>{month}</div>
        </div>
        {days.map(({ day, events }) => (
            <DayItemEvents key={day} {...{ day, events }} />
        ))}
    </div>
);

export default DayItem;
