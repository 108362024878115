import ActionCreator from "../actionCreator";

export default {
    getNotifications: ActionCreator("GET_NOTIFICATIONS"),
    updateNotifications: ActionCreator("UPDATE_NOTIFICATIONS"),
    setAsReadNotification: ActionCreator("SET_AS_READ_NOTIFICATION"),
    updateAsReadNotification: ActionCreator("UPDATE_AS_READ_NOTIFICATION"),
    getUnseenNotificationsCount: ActionCreator("GET_UNSEEN_NOTIFICATIONS_COUNT"),
    updateUnseenNotificationsCount: ActionCreator("UPDATE_UNSEEN_NOTIFICATIONS_COUNT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_NOTIFICATIONS"),
};
