import TagManager from "react-gtm-module";
import { bugsnagClient } from "./bugsnagClient";
import { store } from "../../store";

export default class GTM {
    static initialized = false;
    static userId = null;

    static initialize() {
        if (!GTM.initialized) {
            try {
                const {
                    Auth: { user },
                } = store.getState();

                const tagManagerArgs = () => ({
                    gtmId: process.env.REACT_APP_GTM_CONTAINER,
                    ...(user && user.id ? { user_id: user.id } : {}),
                });

                if (user && user.id) GTM.userId = user.id;

                TagManager.initialize(tagManagerArgs());
            } catch (err) {
                bugsnagClient.notify(err);
            }
            GTM.initialized = true;
        }
    }

    static setUserId(id) {
        GTM.userId = id;
    }

    static dispatch(eventObj) {
        if (process.env.REACT_APP_GTM_CONTAINER && window.name !== "nodejs") {
            GTM.initialize();
            const args = {
                dataLayer: {
                    ...eventObj,
                    ...(GTM.userId > 0 ? { user_id: GTM.userId } : {}),
                },
            };

            TagManager.dataLayer(args);
        }
    }
}
