import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";

const fullListStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getEventsList.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const fullListClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateEventsList.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getEventsList.type),
        mergeMap(({ payload: { start, calendarType } }) =>
            ajax
                .get(
                    apiUrl(
                        `api/calendar/events/list/?calendarType=${
                            calendarType && calendarType !== "all" ? calendarType : "" || ""
                        }&start=${start || ""}`
                    )
                )
                .pipe(
                    map(res => res.response),
                    mergeMap(res =>
                        of(
                            actionCreators.updateEventsList.create(
                                Object.assign({}, res, { queryStart: start })
                            )
                        )
                    ),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

export const epics = combineEpics(
    fullListStartLoadingEpic,
    fullListClearLoadingEpic,
    getEventsEpic
);
