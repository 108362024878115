import React from "react";
import ModalBox, { ModalBoxText, ModalBoxActions } from "./UI/ModalBox";
import ButtonsGroup from "./UI/ButtonsGroup";
import { spacing } from "../styles/Spacing";
import ButtonShape from "./UI/ButtonShape";
import ButtonText from "./UI/ButtonText";
import { colors } from "../styles/Colors";

const PromptDialog = ({ open, title, onClose, children, yesLabel = "Yes", noLabel = "No" }) => {
    const actions = [
        yesLabel && (
            <ButtonsGroup key="confirm" marginBottomValue={spacing.space1comma5}>
                <ButtonShape onClick={() => onClose(true)} primary fullWidth>
                    {yesLabel}
                </ButtonShape>
            </ButtonsGroup>
        ),
        noLabel && (
            <ButtonsGroup key="cancel">
                <ButtonText onClick={() => onClose(false)} bold color={colors.black50}>
                    {noLabel}
                </ButtonText>
            </ButtonsGroup>
        ),
    ];

    return (
        <ModalBox open={open} title={title} onClose={() => onClose(false)}>
            <ModalBoxText>{children}</ModalBoxText>
            <ModalBoxActions marginTopValue={children && spacing.space5}>{actions}</ModalBoxActions>
        </ModalBox>
    );
};

export default PromptDialog;
