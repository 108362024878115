import actionCreators from "./actionCreators";

export const initialState = {
    events: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateMonthEvents.type:
            return {
                ...state,
                events: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearMonthEvents.type:
            return {
                ...state,
                events: initialState.events,
            };
        default:
            return state;
    }
};
