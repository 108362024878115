import { Component } from "react";
import { Route, Switch } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import { headerMobile } from "./HeaderStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

class HeaderMobile extends Component {
    render() {
        const { title, transparent, titleDark } = this.props;

        const BackCloseButton = () => (
            <button onClick={history.goBack} css={[headerMobile.actionLeft]}>
                <Icon
                    name="times"
                    fill={titleDark ? colors.black87 : colors.white}
                    width={18}
                    height={18}
                />
            </button>
        );

        const BackButton = () => (
            <button onClick={history.goBack} css={[headerMobile.actionLeft]}>
                <Icon
                    name="arrowLeft"
                    fill={titleDark ? colors.black87 : colors.white}
                    width={18}
                    height={18}
                />
            </button>
        );

        return (
            <div css={[headerMobile.body, transparent && headerMobile.bodyTransparent]}>
                {!transparent && <div css={headerMobile.shape} />}
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        <Switch>
                            <Route exact path="/my-channels" component={BackButton} />
                            <Route exact path="/my-saved-items" component={BackButton} />
                        </Switch>
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>
                                <div
                                    css={[headerMobile.title, titleDark && headerMobile.titleDark]}
                                >
                                    {title}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div css={headerMobile.elementRight}>
                        <Switch>
                            <Route exact path="/feedback" component={BackCloseButton} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderMobile;
