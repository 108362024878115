import actionCreators from "./actionCreators";
import moment from "moment";
import { formatdateLength } from "../../../app/Calendar/utils";

const STATE_STATUSES = {
    NEW: "NEW",
    EDIT: "EDIT",
    PREVIEW: "PREVIEW",
    NONE: "NONE",
};

export const getNewMoment = payload =>
    new moment(
        `${payload.currentDay} ${payload.currentMonth} ${payload.currentYear} ${new moment().format(
            "HH"
        )}:${formatdateLength(Math.floor(new moment().format("mm") / 5) * 5)}`,
        "DD MM YYYY HH:mm"
    );

export const initialState = {
    item: {
        uid: null,
        type: "",
        name: "",
        isAllDay: false,
        startAt: new moment().toDate(),
        endAt: new moment().add(1, "h").toDate(),
        location: "",
        notes: "",
        invitees: [],
    },
    filter: "",
    page: 1,
    invitees: null,
    selectedInvitees: [],
    stateStatus: STATE_STATUSES.NONE,
    origin: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateEvent.type:
            return {
                ...state,
                item: {
                    ...payload,
                    invitees:
                        typeof payload.invitees === "string"
                            ? JSON.parse(payload.invitees)
                            : payload.invitees,
                },
            };
        case actionCreators.cancelEvent.type:
            return {
                ...state,
                item:
                    state.item.uid === payload.uid
                        ? { ...state.item, cancelled: true }
                        : initialState.item,
            };
        case actionCreators.setFilter.type:
            return {
                ...state,
                filter: payload,
            };
        case actionCreators.updateInvitees.type:
            return {
                ...state,
                invitees: payload,
            };
        case actionCreators.clearInvitees.type:
            return {
                ...state,
                invitees: null,
            };
        case actionCreators.addInviteeToSelected.type:
            return {
                ...state,
                selectedInvitees: state.selectedInvitees.concat(payload),
            };
        case actionCreators.removeInviteeFromSelected.type:
            return {
                ...state,
                selectedInvitees: state.selectedInvitees.filter(invitee => invitee.id !== payload),
            };
        case actionCreators.saveFormData.type:
            return {
                ...state,
                item: payload,
            };
        case actionCreators.setStateStatus.type:
            return {
                ...state,
                stateStatus: STATE_STATUSES[payload],
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearEvent.type:
            return {
                ...initialState,
                origin: state.origin,
            };
        case actionCreators.setOrigin.type:
            return {
                ...state,
                origin: payload,
            };
        case actionCreators.loadInvitees.type:
            return {
                ...state,
                selectedInvitees: payload ? payload : [],
            };
        case actionCreators.updateEventNotes.type:
            return {
                ...state,
                item: {
                    ...state.item,
                    notes: payload.notes,
                },
            };
        case actionCreators.recalculateNewEventDate.type: {
            return {
                ...state,
                item: {
                    ...state.item,
                    startAt: getNewMoment(payload).toDate(),
                    endAt: getNewMoment(payload)
                        .add(1, "h")
                        .toDate(),
                },
            };
        }
        default:
            return state;
    }
};
