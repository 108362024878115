import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { formatDate } from "../../utils";
import ButtonsGroup from "../../../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../../../common/components/UI/ButtonShape";
import { spacing } from "../../../../common/styles/Spacing";
import HeaderOfficeHours from "../../../../common/components/Header/HeaderOfficeHours";
import { calendarPage, bookingConfirmedContainer } from "../../CalendarStyles";
import { Container } from "../../../../common/components/UI/Container";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";
import HeaderOfficeHoursMobile from "../../../../common/components/Header/HeaderOfficeHoursMobile";
import GTM from "../../../../common/services/GTM";

class AppointmentConfirmedContainer extends Component {
    componentDidMount() {
        const {
            confirmedAppointment,
            history: { push },
            match: {
                params: { id, appointment },
            },
        } = this.props;

        // redirect on no-data
        if (
            !confirmedAppointment ||
            // `${confirmedAppointment.channelId}` !== id ||    // id is sometimes academic and sometimes team ID
            `${confirmedAppointment.id}` !== appointment
        ) {
            push(`/office-hours/${id}`);
        }

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/office-hours/${id}/confirmed/${appointment}`,
        });
    }

    render() {
        const {
            confirmedAppointment,
            history: { push },
        } = this.props;

        return (
            <div css={calendarPage.bodyMonth}>
                <HeaderOfficeHours
                    title="Booking confirmed"
                    customBackAction={() => push("/office-hours/")}
                />
                <HeaderOfficeHoursMobile
                    title="Booking confirmed"
                    customBackAction={() => push("/office-hours/")}
                />
                {confirmedAppointment && confirmedAppointment.start && (
                    <Container width={376}>
                        <div css={bookingConfirmedContainer.body}>
                            <div css={bookingConfirmedContainer.card}>
                                <div css={bookingConfirmedContainer.icon}>
                                    <Icon
                                        name="circleCheck"
                                        fill={colors.accentF}
                                        width={28}
                                        height={28}
                                    />
                                </div>
                                <div css={{ paddingRight: spacing.space5 }}>
                                    <div css={bookingConfirmedContainer.label}>Booking with</div>
                                    <div css={bookingConfirmedContainer.content}>
                                        {confirmedAppointment.publicAcademicName
                                            ? confirmedAppointment.publicAcademicName
                                            : confirmedAppointment.academicName
                                            ? confirmedAppointment.academicName
                                            : confirmedAppointment.teamName}
                                    </div>
                                </div>
                                <div css={bookingConfirmedContainer.labelTwo}>on</div>
                                <div css={bookingConfirmedContainer.content}>
                                    {formatDate("dddd D MMMM YYYY", confirmedAppointment.start)}
                                </div>
                                <div css={bookingConfirmedContainer.content}>
                                    {formatDate("h.mma", confirmedAppointment.start)} -{" "}
                                    {formatDate("h.mma", confirmedAppointment.end)} (
                                    {Intl.DateTimeFormat().resolvedOptions().timeZone})
                                </div>
                            </div>
                            <div css={bookingConfirmedContainer.title}>
                                <div css={bookingConfirmedContainer.titleText}>
                                    This appointment has been added to your calendar.
                                </div>
                            </div>
                            <ButtonsGroup marginTopValue={spacing.space4}>
                                <ButtonShape
                                    primary
                                    fullWidth
                                    onClick={() =>
                                        push(
                                            `/calendar/event/${confirmedAppointment.calendarType}/${confirmedAppointment.eventUid}`
                                        )
                                    }
                                >
                                    View booking in calendar
                                </ButtonShape>
                            </ButtonsGroup>
                        </div>
                    </Container>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        officeHours: { confirmedAppointment },
    },
}) => ({
    confirmedAppointment,
});

export default connect(mapStateToProps, null)(AppointmentConfirmedContainer);
