import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import SearchMapResultBar from "../../common/components/SearchResultBar/SearchMapResultBar";
import { connect } from "react-redux";
import { Container } from "../../common/components/UI/Container";
import NoResults from "../../common/components/NoResults";

class ResultsMapList extends Component {
    render() {
        const { searchResults, querySearch, selectBuilding } = this.props;
        return (
            <Fragment>
                <Container width={592}>
                    {searchResults ? (
                        searchResults.length > 0 ? (
                            <div css={searchResults.body}>
                                {searchResults.map(item => (
                                    <SearchMapResultBar
                                        selectBuilding={selectBuilding}
                                        key={item.id}
                                        {...{ item }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <NoResults
                                heading="No results!"
                                message={`We couldn't find any building that matches your search query: "${querySearch}"`}
                            />
                        )
                    ) : (
                        <NoResults
                            cardNoMarginTop
                            heading="Please start typing"
                            message="Search for LSE buildings..."
                        />
                    )}
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ CampusMapping: { searchResults, querySearch } }) => ({
    searchResults,
    querySearch,
});

export default connect(mapStateToProps, null)(ResultsMapList);
