import { combineReducers } from "redux";
import CalendarBoardReducer from "./CalendarBoard/reducer";
import FullListReducer from "./FullList/reducer";
import EventReducer from "./Event/reducer";
import SyncReducer from "./Sync/reducer";
import OfficeHoursReducer from "./OfficeHours/reducer";
import NotificationReducer from "./Notifications/reducer";
import ScheduleDayReducer from "./ScheduleDay/reducer";

export default combineReducers({
    fullList: FullListReducer,
    scheduleDay: ScheduleDayReducer,
    event: EventReducer,
    sync: SyncReducer,
    board: CalendarBoardReducer,
    officeHours: OfficeHoursReducer,
    notifications: NotificationReducer,
});
