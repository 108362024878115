import ActionCreator from "../actionCreator";

export default {
    getStudentsList: ActionCreator("GET_STUDENTS_LIST_MY_PROFILE"),
    updateStudentsList: ActionCreator("UPDATE_STUDENTS_LIST_MY_PROFILE"),
    getStudentProfile: ActionCreator("GET_STUDENT_PROFILE"),
    updateStudentProfile: ActionCreator("UPDATE_STUDENT_PROFILE"),
    clearStudentProfile: ActionCreator("CLEAR_STUDENT_PROFILE"),
    errorResponse: ActionCreator("ERROR_RESPONSE_NOTIFICATIONS"),
};
