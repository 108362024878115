import { validateRequiredFields } from "../../../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        name: "Enter a group name",
        description: "Enter a group description",
    };
    const errors = validateRequiredFields(requiredFields, values);

    return errors;
};
