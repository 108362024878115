import { colors } from "../../../common/styles/Colors";
import { spacing } from "../../../common/styles/Spacing";
import { fontSize, lineHeight, fontWeight } from "../../../common/styles/Typography";
import { resetLineHeight } from "../../styles/Globals";

export const channelBar = {
    body: {
        width: "100%",
        cursor: "pointer",
        display: "flex",
        backgroundColor: colors.white,
        padding: spacing.space2,
        alignItems: "stretch",
        border: 0,
        borderBottomWidth: 1,
        borderBottomColor: colors.greyDark10,
        borderBottomStyle: "solid",
        textDecoration: "none",
        margin: 0,
        marginRight: spacing.space2,
        marginLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    bodyLeft: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: spacing.space2,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        alignItems: "center",
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.normal,
    },
    bodyRight: {
        display: "flex",
        alignItems: "center",
    },
    bodyRow: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",

        "@media (max-width: 1023px)": {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        },
    },
    note: {
        lineHeight: 1,
        paddingLeft: spacing.space2,

        "@media (max-width: 1023px)": {
            paddingLeft: 0,
            marginTop: spacing.space0,
        },
    },
    status: {
        color: colors.black40,
        fontSize: fontSize.textSmaller,
        fontStyle: "italic",
        display: "inline-flex",
        alignItems: "center",
    },
    statusIcon: {
        ...resetLineHeight,
        marginRight: spacing.space0,
    },
};
