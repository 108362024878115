/** @jsx jsx */
import { jsx } from "@emotion/core";
import { buttonsGroup } from "./ButtonsGroupStyles";

const ButtonsGroup = props => {
    const {
        children,
        style,
        marginValue,
        marginTopValue,
        marginTopValueMobile,
        marginRightValue,
        marginBottomValue,
        marginBottomValueMobile,
        marginLeftValue,
        paddingValue,
        paddingTopValue,
        paddingRightValue,
        paddingBottomValue,
        paddingLeftValue,
        center,
        column,
    } = props;

    return (
        <div
            css={[
                buttonsGroup.body,
                marginValue && { margin: marginValue },
                marginTopValue && { marginTop: marginTopValue },
                marginTopValueMobile && {
                    "@media (max-width: 1023px)": { marginTop: marginTopValueMobile },
                },
                marginRightValue && { marginRight: marginRightValue },
                marginBottomValue && { marginBottom: marginBottomValue },
                marginBottomValueMobile && {
                    "@media (max-width: 1023px)": { marginBottom: marginBottomValueMobile },
                },
                marginLeftValue && { marginLeft: marginLeftValue },
                paddingValue && { padding: paddingValue },
                paddingTopValue && { paddingTop: paddingTopValue },
                paddingRightValue && { paddingRight: paddingRightValue },
                paddingBottomValue && { paddingBottom: paddingBottomValue },
                paddingLeftValue && { paddingLeft: paddingLeftValue },
                center && buttonsGroup.bodyCenter,
                column && buttonsGroup.bodyColumn,
                style && style,
            ]}
        >
            {children}
        </div>
    );
};

export default ButtonsGroup;
