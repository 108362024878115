import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { formatDate } from "../../utils";
import ButtonsGroup from "../../../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../../../common/components/UI/ButtonShape";
import { spacing } from "../../../../common/styles/Spacing";
import HeaderOfficeHours from "../../../../common/components/Header/HeaderOfficeHours";
import { calendarPage, bookingConfirmedContainer } from "../../CalendarStyles";
import { Container } from "../../../../common/components/UI/Container";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";
import HeaderOfficeHoursMobile from "../../../../common/components/Header/HeaderOfficeHoursMobile";
import GTM from "../../../../common/services/GTM";

class AppointmentCancelledContainer extends Component {
    componentDidMount() {
        const {
            item,
            history: { push },
            match: {
                params: { id, event },
            },
        } = this.props;

        // redirect on no-data
        if (!item || `${item.uid}` !== event || !item.cancelled) {
            push(`/office-hours/${id}`);
        }

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/office-hours/${id}/cancelled/${event}`,
        });
    }

    render() {
        const {
            item,
            history: { push },
            match: {
                params: { event, id, type },
            },
        } = this.props;
        if (!item || `${item.uid}` !== event || !item.cancelled) {
            return null;
        }

        return (
            <div css={calendarPage.bodyMonth}>
                <HeaderOfficeHours
                    title="Booking cancelled"
                    customBackAction={() => push("/office-hours/")}
                />
                <HeaderOfficeHoursMobile
                    title="Booking cancelled"
                    customBackAction={() => push("/office-hours/")}
                />
                {item && item.uid === event && item.cancelled && (
                    <Container width={376}>
                        <div css={bookingConfirmedContainer.body}>
                            <div css={bookingConfirmedContainer.card}>
                                <div css={bookingConfirmedContainer.icon}>
                                    <Icon
                                        name="circleTimes"
                                        fill={colors.accentF}
                                        width={28}
                                        height={28}
                                    />
                                </div>
                                <div
                                    css={[
                                        bookingConfirmedContainer.content,
                                        { paddingRight: spacing.space5 },
                                    ]}
                                >
                                    {item.name}
                                </div>
                                <div css={bookingConfirmedContainer.labelTwo}>on</div>
                                <div css={bookingConfirmedContainer.content}>
                                    {formatDate("dddd D MMMM YYYY", item.startAt)}
                                </div>
                                <div css={bookingConfirmedContainer.content}>
                                    {formatDate("h.mma", item.startAt)} -{" "}
                                    {formatDate("h.mma", item.endAt)} (
                                    {Intl.DateTimeFormat().resolvedOptions().timeZone})
                                </div>
                                <div css={bookingConfirmedContainer.content}>
                                    has been cancelled
                                </div>
                            </div>
                            <div css={bookingConfirmedContainer.title}>
                                <div css={bookingConfirmedContainer.titleText}>
                                    This appointment has been removed from your calendar
                                </div>
                            </div>
                            <ButtonsGroup marginTopValue={spacing.space4}>
                                <ButtonShape
                                    primary
                                    fullWidth
                                    onClick={() => push(`/office-hours/${type}/${id}`)}
                                >
                                    Make a boooking for another day
                                </ButtonShape>
                            </ButtonsGroup>
                        </div>
                    </Container>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { item },
    },
}) => ({
    item,
});

export default connect(mapStateToProps, null)(AppointmentCancelledContainer);
