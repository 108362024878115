import { Component } from "react";
import moment from "moment";
import Avatar from "../../common/components/UI/Avatar";
import { channelTitle } from "./FeedStyles";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import actionCreators from "../../store/Channel/actionCreators";

class ChannelTitle extends Component {
    render() {
        const {
            channel: { iconUrl, name, id },
            author,
            publicationDate,
            redirectToChannel,
        } = this.props;

        return (
            <div css={channelTitle.body}>
                <div css={channelTitle.bodyLeft}>
                    <div onClick={() => redirectToChannel({ id })} css={channelTitle.btn}>
                        {author ? (
                            <Avatar
                                src={author.photoUrl}
                                initials={author.firstName.charAt(0) + author.lastName.charAt(0)}
                            />
                        ) : (
                            <Avatar src={iconUrl} />
                        )}
                    </div>
                </div>
                <div css={channelTitle.bodyCenter}>
                    <div css={channelTitle.name}>
                        <button onClick={() => redirectToChannel({ id })} css={channelTitle.btn}>
                            {author ? (
                                <div>
                                    {`${author.firstName} ${author.lastName}`}
                                    <span css={channelTitle.sign}> &#9656; </span>
                                    {`${name}`}
                                </div>
                            ) : (
                                name
                            )}
                            {/* <span css={channelTitle.sign}>&#9656;</span> */}
                        </button>
                    </div>
                    <time css={channelTitle.date} dateTime={publicationDate}>
                        {moment(publicationDate).fromNow()}
                    </time>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ Post: { post } }) => ({
    post,
});

const mapDispatchToProps = {
    redirectToChannel: actionCreators.redirectToChannel.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelTitle);
