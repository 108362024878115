import actionCreators from "./actionCreators";
import { channel } from "../../fixtures/Channel";
import GTM from "../../common/services/GTM";
import { mergeByIds } from "../../common/services/storeHelpers";

export const initialState = {
    channel,
    posts: [],
    recommended: null,
    page: 1,
    hasMore: true,
    origin: null,
    errorRes: { error: "", errors: {} },
};

const trackAddedChannelPosts = (posts, channel) => {
    posts.map(post => {
        const gtmTypeDesc =
            post.kind === "text"
                ? post.article
                    ? "long post"
                    : post.imageUrl
                    ? "image post"
                    : "short post"
                : "event";
        GTM.dispatch({
            event: "postAddedToFeed",
            actionType: `Added to channel feed - ${gtmTypeDesc}`,
            postId: `${post.id}`,
            postName: `${
                post.kind === "text"
                    ? post.description
                          .replace(/<[^>]+>/g, " ")
                          .replace(/\s\s+/g, " ")
                          .trim()
                          .split(/\s+/)
                          .slice(0, 10)
                          .join(" ")
                          .concat([" ..."].join(""))
                    : post.name
            }`,
            channelId: `${channel.id}`,
            channelName: `${channel.name}`,
        });

        return true;
    });
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createChannel.type:
            return {
                ...state,
                channel: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.getChannel.type:
            return {
                ...state,
                channel: initialState.channel,
                posts: initialState.posts,
                page: initialState.page,
            };
        case actionCreators.createChannelPosts.type:
            trackAddedChannelPosts(payload, state.channel);
            return {
                ...state,
                posts: mergeByIds(state.posts || [], payload),
                page: state.page + 1,
                hasMore: payload && payload.length === 25,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.updateBookmarkedChannelPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post => {
                          return post.id === payload.id ? { ...post, isFavourite: true } : post;
                      })
                    : state.posts,
            };
        case actionCreators.updateUnbookmarkedChannelPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post => {
                          return post.id === payload.id ? { ...post, isFavourite: false } : post;
                      })
                    : state.posts,
            };
        case actionCreators.updateLikedChannelPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post => {
                          return post.id === payload.id
                              ? { ...post, isLiked: true, numberOfLikes: post.numberOfLikes + 1 }
                              : post;
                      })
                    : state.posts,
            };
        case actionCreators.updateUnlikedChannelPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post => {
                          return post.id === payload.id
                              ? { ...post, isLiked: false, numberOfLikes: post.numberOfLikes - 1 }
                              : post;
                      })
                    : state.posts,
            };

        case actionCreators.updateBookedChannelPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post => {
                          return post.id === payload.id
                              ? { ...post, isLiked: false, isBooked: true }
                              : post;
                      })
                    : state.posts,
            };

        case actionCreators.redirectToChannel.type:
            return state.channel.id !== payload.id
                ? {
                      ...initialState,
                      origin: state.origin,
                  }
                : state;
        case actionCreators.updateNumCommentsPost.type:
            return {
                ...state,
                posts: state.posts
                    ? state.posts.map(post =>
                          post.id === payload.id
                              ? { ...post, numComments: post.numComments + payload.count }
                              : post
                      )
                    : initialState.posts,
            };
        case actionCreators.toggleChannelTracking.type:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    isUserTracking: !state.channel.isUserTracking,
                },
            };
        case actionCreators.setChannelOrigin.type:
            return {
                ...state,
                origin: payload,
            };
        case actionCreators.setChannelAsFollowed.type:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    isUserTracking: true,
                    isUserOwner: false,
                    userGroupStatus: null,
                },
            };
        case actionCreators.updateRecommendedChannels.type:
            return {
                ...state,
                recommended: payload,
            };

        default:
            return state;
    }
};
