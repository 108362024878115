import { Component } from "react";
import { connect } from "react-redux";
import { Subject } from "rxjs/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/distinctUntilChanged";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import HeaderGroupMembers from "../../../../common/components/Header/HeaderGroupMembers";
import channelActionCreators from "../../../../store/Channel/actionCreators";
import GTM from "../../../../common/services/GTM";
import { TextFieldInline } from "../../../../common/components/FormFields/TextFieldInline";
import actionCreators from "../../../../store/Channel/Group/actionCreators";
import { Container } from "../../../../common/components/UI/Container";
import { colors } from "../../../../common/styles/Colors";
import Avatar from "../../../../common/components/UI/Avatar";
import Icon from "../../../../common/components/UI/Icon";
import NoResults from "../../../../common/components/NoResults/";

// ¯\_(ツ)_/¯
import {
    calendarPage,
    eventFull,
    inviteesList,
    invitedPerson,
} from "../../../Calendar/CalendarStyles";
import { header } from "../../../../common/components/Header/HeaderStyles";
import { groupMembersPage } from "../../ChannelStyles";

class AddMember extends Component {
    state = {
        filter$: new Subject(),
    };
    componentDidMount() {
        const {
            name,
            getChannel,
            searchForUsers,
            clearSearchedUsers,
            match: {
                params: { id },
            },
        } = this.props;
        const { filter$ } = this.state;

        if (!name) getChannel({ id });

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
            filter !== "" ? searchForUsers({ id, filter }) : clearSearchedUsers();
        });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/group/${id}/add-member`,
        });
    }

    componentWillUnmount() {
        const { resetAddMembers } = this.props;
        resetAddMembers();
    }

    onInput = ({ target: { value } }) => {
        const { setSearchFilter } = this.props;
        const { filter$ } = this.state;
        setSearchFilter(value);
        filter$.next(value);
    };

    handleUserSelected = user => {
        const { addUserToSelected, removeUserFromSelected, selectedUsers } = this.props;
        let remove = false;
        selectedUsers.forEach(function(selectedUser, key) {
            if (selectedUser.id === user.id) {
                remove = true;
            }
        });
        remove ? removeUserFromSelected(user.id) : addUserToSelected(user);
    };

    render() {
        const { searchFilter, searchedUsers, selectedUsers, id } = this.props;
        const { onInput, handleUserSelected } = this;

        return (
            <div css={groupMembersPage.bodyAddPeople} className="dupa">
                <HeaderGroupMembers title={"Add People to Group"} hideDesktopTitle groupId={id} />
                <div css={header.titleBig}>Add People to Group</div>
                <Container onMobileNoPadding width={592}>
                    <div css={calendarPage.invitees}>
                        <div css={[eventFull.section, eventFull.sectionBorder]}>
                            <div css={eventFull.inner}>
                                <TextFieldInline
                                    placeholder="Search..."
                                    value={searchFilter}
                                    onInput={onInput}
                                />
                            </div>
                        </div>
                        {searchFilter && searchedUsers ? (
                            <div css={inviteesList.list}>
                                {searchedUsers.map(foundUser => (
                                    <button
                                        onClick={() => handleUserSelected(foundUser)}
                                        key={foundUser.id}
                                        css={invitedPerson.body}
                                    >
                                        <div css={invitedPerson.bodyLeft}>
                                            <Avatar
                                                src={foundUser.photoUrl}
                                                initials={
                                                    foundUser.firstName.charAt(0) +
                                                    foundUser.lastName.charAt(0)
                                                }
                                            />
                                        </div>
                                        <div css={invitedPerson.bodyCenter}>
                                            <div css={invitedPerson.title}>
                                                {foundUser.firstName} {foundUser.lastName}
                                            </div>
                                            {selectedUsers &&
                                                selectedUsers.map(selectedUser =>
                                                    selectedUser.id === foundUser.id ? (
                                                        <Icon
                                                            name="check"
                                                            width="18"
                                                            height="18"
                                                            fill={colors.black40}
                                                            key={foundUser.id}
                                                        />
                                                    ) : null
                                                )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        ) : null}
                        {!searchFilter && selectedUsers && selectedUsers.length > 0 && (
                            <div css={inviteesList.list}>
                                <div css={inviteesList.title}>Users that will be invited:</div>
                                {selectedUsers &&
                                    selectedUsers.map(selectedUser => (
                                        <div key={selectedUser.id} css={invitedPerson.body}>
                                            <div css={invitedPerson.bodyLeft}>
                                                <Avatar
                                                    uri={selectedUser.photoUrl}
                                                    initials={
                                                        selectedUser.firstName.charAt(0) +
                                                        selectedUser.lastName.charAt(0)
                                                    }
                                                />
                                            </div>
                                            <div css={invitedPerson.bodyCenter}>
                                                <div css={invitedPerson.title}>
                                                    {selectedUser.firstName} {selectedUser.lastName}
                                                </div>
                                            </div>
                                            <div css={invitedPerson.bodyRight}>
                                                <button
                                                    onClick={() => handleUserSelected(selectedUser)}
                                                    css={invitedPerson.btnRemove}
                                                >
                                                    <Icon
                                                        name="times"
                                                        width="16"
                                                        height="16"
                                                        fill={colors.black40}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                        {searchFilter !== "" && searchedUsers && searchedUsers.length === 0 && (
                            <NoResults
                                heading="Sorry!"
                                message={`We couldn't find any user(s) matching your search query ...`}
                                onMobileMargin
                            />
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    Channel: {
        channel: { id, name, groupType },
    },
    MyGroups: { searchedUsers, selectedUsers, searchFilter },
}) => ({
    id,
    name,
    groupType,
    searchedUsers,
    selectedUsers,
    searchFilter,
});

const mapDispatchToProps = {
    getChannel: channelActionCreators.getChannel.create,
    searchForUsers: actionCreators.searchForUsers.create,
    addUserToSelected: actionCreators.addUserToSelected.create,
    removeUserFromSelected: actionCreators.removeUserFromSelected.create,
    setSearchFilter: actionCreators.setSearchFilter.create,
    clearSearchedUsers: actionCreators.clearSearchedUsers.create,
    resetAddMembers: actionCreators.resetAddMembers.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
