import React from "react";
// import lseThemeV0 from "../common/styles/lseTheme";
import ServerErrorPage from "./common/components/ServerError";
import MaintenancePage from "./common/components/Maintenance";
import LoginPage from "./app/Auth/Login";
import RegisterForm from "./app/Auth/Register";
import DashboardContainer from "./app/Dashboard";
import PrivateRoute from "./common/components/PrivateRoute";
import MainView from "./common/components/MainView";
import { Route } from "react-router";
import FeedbackModal from "./app/Feedback";
import App from "./app/App";
import ResetForgottenPasswordModal from "./app/Auth/ResetForgottenPasswordModal";
import { withStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import Snackbar from "./common/components/Snackbar";
import { colors } from "./common/styles/Colors";

const styles = {
    variantInfo: {
        backgroundColor: colors.white90,
        boxShadow: "rgba(204, 204, 204, 0.5) 0px 10px 30px, rgba(204, 204, 204, 0.6) 0px 6px 10px",
        borderLeft: `8px solid #106C76`,
        color: colors.black87,
        borderRadius: 2,
        paddingBottom: 12,
    },
};

const AppContent = ({ classes }) => {
    return (
        <SnackbarProvider
            classes={{
                variantInfo: classes.variantInfo,
            }}
        >
            <Snackbar />
            <FeedbackModal />
            <ResetForgottenPasswordModal />
            <MainView />
            <Route exact path="/welcome" component={DashboardContainer} />
            <Route exact path="/error" component={ServerErrorPage} />
            <Route exact path="/maintenance" component={MaintenancePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterForm} />
            <PrivateRoute path="/" component={App} />
        </SnackbarProvider>
    );
};

export default withStyles(styles)(AppContent);
