import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../store/Discussion/actionCreators";
import Avatar from "../../common/components/UI/Avatar";
import { comment } from "../Feed/FeedStyles";
import { change } from "redux-form";

class Comment extends Component {
    enableForm = type => {
        const {
            setComment,
            setFormType,
            setFormParrentId,
            comment: { commentary, id },
            formUpdate,
            toggleActiveForm,
        } = this.props;
        setFormType(type);
        if (type === "EDIT") {
            formUpdate("comment", "comment", commentary);
        }
        setComment(id);
        setFormParrentId(id);
        toggleActiveForm(id);
    };

    disableForm = () => {
        const {
            setComment,
            setFormType,
            setFormParrentId,
            toggleActiveForm,
            formUpdate,
        } = this.props;
        setFormType("NEW");
        formUpdate("comment", "comment", "");
        toggleActiveForm(null);
        setComment(null);
        setFormParrentId(0);
    };
    render() {
        const {
            comment: {
                id,
                commentary,
                lvl,
                createdAt,
                isDeleted,
                isEdited,
                user: { firstName, lastName, photoUrl, ...userRest },
            },
            user,
            activeForm,
            deleteComment,
            postId,
        } = this.props;
        const { enableForm, disableForm } = this;

        return (
            <div css={[comment.body, lvl !== 0 && comment.bodyLvl]}>
                <div css={[comment.avatar, lvl !== 0 && comment.avatarLvl]}>
                    <Avatar
                        smallComments={lvl === 0}
                        smaller={lvl !== 0}
                        src={photoUrl}
                        initials={firstName.charAt(0) + lastName.charAt(0)}
                    />
                </div>
                <div css={comment.inner}>
                    <div css={comment.user}>
                        {firstName} {lastName}
                    </div>
                    <div css={[comment.text, isDeleted && comment.textItalic]}>
                        {isDeleted ? "Comment removed" : commentary}
                        {!isDeleted && isEdited && <span css={comment.textItalic}> (edited)</span>}
                    </div>
                    <div css={comment.footer}>
                        <div css={comment.date}>{moment(createdAt).fromNow()}</div>
                        {!isDeleted && (
                            <div css={comment.actions}>
                                {!isDeleted &&
                                    (id !== activeForm ? (
                                        <div css={comment.actionsInline}>
                                            <div css={comment.btnItem}>
                                                {user.id === userRest.id ? (
                                                    <button
                                                        css={comment.btn}
                                                        onClick={() =>
                                                            deleteComment({ id, postId })
                                                        }
                                                        type="button"
                                                    >
                                                        Delete
                                                    </button>
                                                ) : (
                                                    <button
                                                        css={comment.btn}
                                                        onClick={() => enableForm("NEW")}
                                                        type="button"
                                                    >
                                                        Reply
                                                    </button>
                                                )}
                                            </div>
                                            {user.id === userRest.id && (
                                                <div css={comment.btnItem}>
                                                    <button
                                                        css={comment.btn}
                                                        onClick={() => enableForm("EDIT")}
                                                        type="button"
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div css={comment.btnItem}>
                                            <button
                                                type="button"
                                                css={comment.btn}
                                                onClick={() => disableForm()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ Discussion: { formParentId, activeForm }, Auth: { user } }) => ({
    formParentId,
    user,
    activeForm,
});
const mapDispatchToProps = {
    postComment: actionCreators.postComment.create,
    setFormParrentId: actionCreators.setFormParrentId.create,
    setComment: actionCreators.setComment.create,
    formUpdate: change,
    toggleActiveForm: actionCreators.toggleActiveForm.create,
    setFormType: actionCreators.setFormType.create,
    deleteComment: actionCreators.deleteComment.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
