import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { formatdateLength } from "../utils";
import actionCreators from "../../../store/Calendar/ScheduleDay/actionCreators";
import CalendarBoard from "../CalendarBoard";
import { Container } from "../../../common/components/UI/Container";
import ButtonFloating from "../../../common/components/UI/ButtonFloating";
import { calendarPage } from "../CalendarStyles";
import CalendarBar from "../CalendarBar";
import HeaderCalendarMobile from "../../../common/components/Header/HeaderCalendarMobile";
import eventActionCreator from "../../../store/Calendar/Event/actionCreators";
import calendarBoardActionCreators from "../../../store/Calendar/CalendarBoard/actionCreators";
import moment from "moment";
import GTM from "../../../common/services/GTM";
import { BannerNotifications } from "../Notifications";
import listActionCreator from "../../../store/Calendar/FullList/actionCreators";
import AlldayEvents from "./components/AllDayEvents";
import Timetable from "./components/Timetable";
import TimetableItem from "./components/TimetableItem";
import { NotificationItem } from "../Notifications/Item";

class MonthContainer extends Component {
    componentDidMount() {
        const {
            getMonthEvents,
            currentMonth,
            currentYear,
            setOrigin,
            clearEvent,
            setDefaultStatus,
        } = this.props;

        setDefaultStatus(false);
        getMonthEvents({
            month: `${formatdateLength(currentMonth + 1)}.${currentYear}`,
        });
        clearEvent();
        setOrigin("day-schedule");

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/month`,
        });

        localStorage.setItem("calendarLast", "day-schedule");
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentMonth !== prevProps.currentMonth) {
            this.props.getMonthEvents({
                month: `${formatdateLength(this.props.currentMonth + 1)}.${this.props.currentYear}`,
            });
        }
    }

    componentWillUnmount() {
        const { toggleCalendarBoard, calendarBoardVisible } = this.props;
        if (calendarBoardVisible) {
            toggleCalendarBoard();
        }
    }

    getEventsForDay = (events, currentDate, isAllDayEvent) => {
        if (events) {
            const evntsList = events
                .filter(
                    event =>
                        moment(event.startAt).format("YYYY-MM-DD") === currentDate &&
                        event.isAllDay === isAllDayEvent
                )
                .map(e => ({
                    title: e.name,
                    startDate: e.startAt,
                    endDate: e.endAt,
                    type: e.calendarType,
                    location: e.location,
                    uid: e.uid,
                    calendarType: e.calendarType,
                }));
            return evntsList;
        }
        return [];
    };

    render() {
        const {
            events,
            currentDay,
            currentMonth,
            currentYear,
            calendarBoardHeight,
            setYear,
            setMonth,
            setDay,
            userCreatedAt,
        } = this.props;
        const { getEventsForDay } = this;
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        const currentDate = `${currentYear}-${formatdateLength(
            currentMonth + 1
        )}-${formatdateLength(currentDay)}`;

        let userCreatedHours = userCreatedAt
            ? moment().diff(moment(userCreatedAt), "hours")
            : undefined;

        return (
            <div css={[calendarPage.bodyMonth, { paddingTop: calendarBoardHeight }]}>
                <HeaderCalendarMobile {...{ currentMonth }} />
                <CalendarBar />
                {userCreatedHours < 24 && (
                    <NotificationItem
                        key={"0"}
                        title={
                            "Congratulations, you've successfully registered with Student Hub. Calendars update overnight, so you may not see all your events until tomorrow."
                        }
                        onClick={undefined}
                    />
                )}
                <BannerNotifications />
                <div>
                    <CalendarBoard {...{ events }} isScheduleDayCalendar />

                    <Container width={592}>
                        <div css={calendarPage.listActions}>
                            <button
                                onClick={() => {
                                    const newDate = moment(currentDate).subtract(1, "days");
                                    setYear(Number(newDate.format("YYYY")));
                                    setMonth(Number(newDate.format("M")) - 1);
                                    setDay(Number(newDate.format("D")));
                                }}
                            >
                                <span>&lsaquo;</span>
                                <b>
                                    {moment(currentDate)
                                        .subtract(1, "days")
                                        .format("dddd")}
                                </b>
                            </button>
                            <button
                                onClick={() => {
                                    const newDate = moment(currentDate).add(1, "days");
                                    setYear(Number(newDate.format("YYYY")));
                                    setMonth(Number(newDate.format("M")) - 1);
                                    setDay(Number(newDate.format("D")));
                                }}
                                small
                            >
                                <b>
                                    {moment(currentDate)
                                        .add(1, "days")
                                        .format("dddd")}
                                </b>
                                <span>&rsaquo;</span>
                            </button>
                        </div>
                        <AlldayEvents items={getEventsForDay(events, currentDate, true)} />
                        <div css={calendarPage.list}>
                            <Timetable
                                items={getEventsForDay(events, currentDate, false) || []}
                                renderItem={props => {
                                    return <TimetableItem {...props} />;
                                }}
                                date={currentDate}
                                is12Hour
                                showNowLine={todayDate === currentDate}
                                isLoading={Boolean(!events)}
                            />
                        </div>
                    </Container>
                </div>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        scheduleDay: { events },
        board: { currentMonth, currentDay, currentYear, calendarBoardHeight, calendarBoardVisible },
    },
    Auth: {
        user: { createdAt },
    },
}) => ({
    currentMonth,
    currentDay,
    currentYear,
    events,
    calendarBoardHeight,
    calendarBoardVisible,
    userCreatedAt: createdAt,
});

const mapDispatchToProps = {
    getMonthEvents: actionCreators.getMonthEvents.create,
    clearEvent: eventActionCreator.clearEvent.create,
    setOrigin: eventActionCreator.setOrigin.create,
    setDefaultStatus: listActionCreator.setDefaultStatus.create,
    toggleCalendarBoard: calendarBoardActionCreators.toggleCalendarBoard.create,
    setYear: calendarBoardActionCreators.setYear.create,
    setMonth: calendarBoardActionCreators.setMonth.create,
    setDay: calendarBoardActionCreators.setDay.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthContainer);
