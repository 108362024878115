import { createMuiTheme } from "@material-ui/core/styles";
import { colors } from "../../styles/Colors";
import { fontFamily, fontSize, fontWeight } from "../../styles/Typography";
import { spacing } from "../../styles/Spacing";

const greenFieldColour = "rgba(46,183,165,0.45)";
const greenFieldColourDisabled = "rgba(200,205,200,1)";
const greenFieldTextColour = "#2A6157";
const greenFieldTextColourDisabled = "rgba(109,138,133,1)";

export const textField = {
    root: {
        marginBottom: spacing.space1,
    },
    inputRoot: {
        backgroundColor: greenFieldColour,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        "&:hover": {
            backgroundColor: greenFieldColour,
        },
    },
    inputFocused: {
        backgroundColor: `${greenFieldColour} !important`,
        "&:hover": {
            backgroundColor: `${greenFieldColour} !important`,
        },
    },
    inputDisabled: {
        backgroundColor: `${greenFieldColourDisabled} !important`,
        color: `${greenFieldTextColourDisabled} !important`,
        "&:hover": {
            backgroundColor: `${greenFieldColourDisabled} !important`,
        },
        "&:before": {
            borderBottom: `1px dotted ${greenFieldColourDisabled} !important`,
        },
    },
    inputUnderline: {
        "&:after": {
            borderBottom: `2px solid #fff`,
        },
        "&:before": {
            borderBottom: `1px solid ${colors.black10}`,
        },
        "&:hover:before": {
            borderBottomColor: `${colors.black10} !important`,
        },
    },
    inputError: {
        "&:after": {
            borderBottom: `2px solid #E0112B !important`,
        },
    },
    inputInput: {
        padding: "20px 16px 9px",
        fontSize: 16,
        fontWeight: fontWeight.bold,
        color: greenFieldTextColour,
    },
    inputPrefixContainer: {
        margin: "0 !important",
        marginRight: "-16px !important",
    },
    inputPrefix: {
        fontSize: 16,
        fontWeight: fontWeight.bold,
        color: greenFieldTextColour,
        display: "block",
        margin: 0,
        padding: "20px 0px 9px",
    },
    labelRoot: {
        color: colors.white,
        fontSize: 16,
        fontWeight: fontWeight.bold,
        textTransform: "uppercase",
        transform: "translate(16px, 18px) scale(1)",
    },
    labelError: {
        color: "white !important",
    },
    labelFocused: {
        color: "white !important",
        transform: "translate(8px, 6px) scale(0.75) !important",
    },
    labelFilled: {
        transform: "translate(8px, 6px) scale(0.75) !important",
    },
    iconError: {
        lineHeight: 1,
    },
    iconPassword: {
        cursor: "pointer",
        margin: 0,
        padding: 0,
        outline: "none",
        border: "0 none",
        backgroundColor: "transparent",
        lineHeight: 1,
    },
    helperRoot: {
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.medium,
        margin: "4px 0 0",
    },
    helperError: {
        color: "#E0112B !important",
    },
};

export const textFieldGrey = {
    root: {
        marginBottom: spacing.space2,
        // paddingTop: 22,
    },
    inputRoot: {
        backgroundColor: colors.black4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        "&:hover": {
            backgroundColor: colors.black4,
        },
    },
    inputFocused: {
        backgroundColor: `${colors.black4} !important`,
        "&:hover": {
            backgroundColor: `${colors.black4} !important`,
        },
    },
    inputDisabled: {
        backgroundColor: `${colors.black4} !important`,
        color: `${colors.black38} !important`,
        "&:hover": {
            backgroundColor: `${colors.black4} !important`,
        },
        "&:before": {
            borderBottom: `1px dotted ${colors.black12} !important`,
        },
    },
    inputUnderline: {
        "&:after": {
            borderBottom: `2px solid #7CE6D8`,
        },
        "&:before": {
            borderBottom: `1px solid ${colors.black12}`,
        },
        "&:hover:before": {
            borderBottomColor: `${colors.black12} !important`,
        },
    },
    inputError: {
        "&:after": {
            borderBottom: `2px solid #E0112B !important`,
        },
    },
    inputMultiline: {
        padding: "0 12px 0 0",
    },
    inputInput: {
        padding: "14px 16px",
        color: colors.black87,
        fontSize: 16,
        fontWeight: fontWeight.normal,
    },
    inputInputMultiline: {
        padding: "14px 16px",
    },
    selectRoot: {
        backgroundColor: colors.black4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 0,
        "&:hover": {
            backgroundColor: colors.black4,
        },
    },
    selectSelect: {
        boxSizing: "border-box",
        backgroundColor: "transparent !important",
        padding: "14px 16px",
        minHeight: 47,
        "&:hover": {
            backgroundColor: "transparent !important",
        },
        "&:focus": {
            backgroundColor: "transparent !important",
        },
    },
    selectFilled: {
        // width: "100%",
    },
    selectIcon: {
        width: 12,
        height: 12,
        lineHeight: 1,
        fontSize: 1,
        position: "absolute",
        top: "calc(50% - 6px)",
        right: spacing.space1comma5,
        pointerEvents: "none",
    },
    selectMenu: {
        "&:hover": {
            backgroundColor: "transparent !important",
        },
    },
    labelRoot: {
        display: "block",
        color: colors.black87,
        fontSize: 16,
        fontWeight: fontWeight.bold,
        transform: "translate(0, 0) scale(1) !important",
        position: "relative !important",
        top: "auto !important",
        left: "auto !important",
        marginBottom: spacing.space1,
    },
    labelError: {
        color: `${colors.black87} !important`,
    },
    labelFocused: {
        color: `${colors.black87} !important`,
        transform: "translate(0, 0) scale(1) !important",
    },
    labelFilled: {
        transform: "translate(0, 0) scale(1) !important",
    },
    labelShrink: {
        transform: "translate(0, 0) scale(1) !important",
    },
    iconError: {
        lineHeight: 1,
    },
    iconPassword: {
        cursor: "pointer",
        margin: 0,
        padding: 0,
        outline: "none",
        border: "0 none",
        backgroundColor: "transparent",
        lineHeight: 1,
    },
    helperRoot: {
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.medium,
        margin: "4px 0 0",
    },
    helperError: {
        color: "#E0112B !important",
    },
};

export const textFieldInline = {
    body: {},
    placeholder: {
        color: "blue",
        fontSize: 16,
    },
    inputLabelFocused: {
        color: colors.black42,
    },
    inputLabelError: {
        color: colors.black42,
    },
    inputBody: {
        padding: 0,
        backgroundColor: "transparent",
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
        // "&::placeholder": {
        //     color: "#6F6F6F",
        //     opacity: 1,
        // },
    },
    inputUnderlineRoot: {
        padding: 0,
        "&:after": {
            backgroundColor: "transparent",
            height: 0,
        },
        "&:before": {
            backgroundColor: "transparent",
            height: 0,
        },
        "&:hover:not(.fake):before": {
            backgroundColor: "transparent",
            height: 0,
        },
    },
    inputUnderlineFocused: {
        "&:after": {
            backgroundColor: "transparent",
            height: 0,
        },
        "&:before": {
            backgroundColor: "transparent",
            height: 0,
        },
    },
    inputUnderlineError: {
        "&:after": {
            backgroundColor: "transparent",
            height: 0,
        },
        "&:before": {
            backgroundColor: "transparent",
            height: 0,
        },
    },
    iconError: {
        lineHeight: 1,
    },
    iconPassword: {
        cursor: "pointer",
        margin: 0,
        padding: 0,
        outline: "none",
        border: "0 none",
        backgroundColor: "transparent",
        lineHeight: 1,
    },
    error: {
        marginTop: spacing.space1,
    },
    errorMarginBottom: {
        marginBottom: spacing.space1,
    },
    errorMarginLeft: {
        marginLeft: spacing.space2,
    },
};

export const textareaAutosize = {
    body: {
        paddingTop: spacing.space0,
        paddingBottom: spacing.space0,
        width: "100%",
    },
    textarea: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: spacing.space2,
        border: "0 none",
        outline: "0 none",
        resize: "none",
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        maxHeight: 51,
        width: "100%",
    },
    textareaNoPadding: {
        addingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
    },
};

export const checkboxField = {
    body: {
        marginLeft: -10,
    },
    errorText: {
        marginLeft: 30,
        marginTop: 10,
    },
    bodyTopAlign: {
        marginLeft: -10,
        alignItems: "flex-start",
        // marginTop: -10,
    },
    checkbox: {
        color: colors.black12,
        width: 40,
        height: 40,
    },
    checkboxTopAlign: {
        color: colors.black12,
        width: 40,
        height: 40,
        paddingTop: 1,
        paddingBottom: 0,
        alignItems: "flex-start",
    },
    checked: {},
    checkboxLabel: {
        color: colors.black87,
        // paddingLeft: 6,
    },
    checkboxLabelWhite: {
        color: "#2A6157",
    },
    link: {
        color: colors.black87,
        textDecoration: "underline",
    },
    linkWhite: {
        color: "#2A6157",
        textDecoration: "underline",
    },
};

export const selectField = {
    root: {
        padding: 0,
    },
    select: {
        paddingTop: 0,
        paddingRight: 25,
        paddingBottom: 0,
        width: "calc(100% - 25px)",
        "&:focus": {
            backgroundColor: "transparent",
        },
    },
};

export const fileField = {
    hidden: {
        display: "none",
    },
};

export const lseTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            light: colors.black87,
            main: colors.black87,
            dark: colors.black87,
            contrastText: colors.black87,
        },
        secondary: {
            light: colors.black87,
            main: colors.black87,
            dark: colors.black87,
            contrastText: colors.black87,
        },
        error: {
            light: colors.black87,
            main: colors.black87,
            dark: colors.black87,
            contrastText: colors.black87,
        },
    },
    // overrides: {
    //     MuiPickersToolbar: {
    //         toolbar: {
    //             backgroundColor: colors.black87,
    //         },
    //     },
    //     MuiPickersCalendarHeader: {
    //         switchHeader: {
    //             backgroundColor: colors.white,
    //         },
    //     },
    //     MuiPickersDay: {
    //         day: {
    //             color: colors.black87,
    //         },
    //         "&$selected": {
    //             backgroundColor: colors.black5,
    //             color: colors.error,
    //             fontWeight: fontWeight.bold,
    //         },
    //         current: {
    //             color: colors.black87,
    //         },
    //     },
    //     MuiPickersModal: {
    //         dialogAction: {
    //             "& > button": {
    //                 color: "green",
    //             },
    //         },
    //     },
    // },
});
