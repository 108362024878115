/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import { colors } from "../../../../common/styles/Colors";

const NowLine = ({ left, width, calculateTopOffset }) => {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        let timeout;

        const update = () => {
            timeout = setTimeout(() => {
                setNow(new Date());
                update();
            }, 60000);
        };
        update();

        return () => clearTimeout(timeout);
    }, []);

    const size = 7;
    const color = colors.accentA20;

    return (
        <div
            pointerEvents="none"
            css={{
                top: calculateTopOffset(+now),
                left: left - size / 2 + 0.5,
                width: width + size / 2 - 1,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                zIndex: 50,
            }}
        >
            {/* Dot */}
            <div
                css={{
                    backgroundColor: color,
                    height: size,
                    width: size,
                    borderRadius: size * 2,
                    elevation: 0,
                }}
            />
            {/* Line */}
            <div
                css={{
                    backgroundColor: color,
                    elevation: 0,
                    height: 1,
                    width: `calc(100% + ${size}px)`,
                }}
            />
        </div>
    );
};

export default NowLine;
