/** @jsx jsx */
import { jsx } from "@emotion/core";
import { colors } from "../../../../common/styles/Colors";
import { spacing } from "../../../../common/styles/Spacing";
import { fontFamily, fontSize, fontWeight } from "../../../../common/styles/Typography";
import { CalendarType } from "../../components/CalendarType";
import { Link } from "react-router-dom";

const TimetableItem = ({ style, item }) => {
    let color = "#000000";

    switch (item.type) {
        case "my-events":
            color = "#9051A6";
            break;
        case "timetables":
            color = "#9A9896";
            break;
        case "exam-dates":
            color = "#9A9896";
            break;
        case "key-academic-dates":
            color = "#31C0AD";
            break;
        case "appointments":
            color = "#115760";
            break;
        case "lse-events":
            color = "#FF2D55";
            break;
        default:
            break;
    }

    const itemHeight = style.height;

    return (
        <Link
            to={`/calendar/event/${item.calendarType}/${item.uid}`}
            css={[{ ...style, borderLeftColor: color }, styles.root]}
        >
            <div
                css={[
                    styles.header,
                    itemHeight > 30 && styles.headerAbove30,
                    itemHeight > 45 && styles.headerAbove45,
                ]}
            >
                <div css={[styles.title, itemHeight > 15 && styles.titleBig]}>{item.title}</div>
            </div>
            {itemHeight > 30 && (
                <div css={styles.footer}>
                    <CalendarType type={item.type} />
                </div>
            )}
        </Link>
    );
};

const styles = {
    root: {
        display: "block",
        backgroundColor: colors.white,
        borderLeftWidth: 3,
        borderLeftStyle: "solid",
        paddingRight: spacing.space4,
        paddingLeft: spacing.space2,
        overflow: "hidden",
        position: "absolute",
        textDecoration: "none",
    },
    header: {
        flex: 1,
        flexShrink: 0,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        height: "100%",
        minHeight: 17,
        maxHeight: 30,
        // backgroundColor: "pink",
    },
    headerAbove30: {
        maxHeight: 26,
    },
    headerAbove45: {
        maxHeight: 30,
        marginTop: 4,
    },
    title: {
        fontFamily: fontFamily.primary,
        fontWeight: fontWeight.bold,
        fontSize: fontSize.textSmaller,
        lineHeight: 1.25,
        color: colors.black,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    titleBig: {
        fontSize: fontSize.text,
    },
    footer: {
        // marginTop: spacing.space0,
    },
};

export default TimetableItem;
