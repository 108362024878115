import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import DashboardContainer from "./Dashboard";
import actionCreators from "../store/Auth/actionCreators";
import userActionCreators from "../store/User/actionCreators";
import MainMenu from "../common/components/MainMenu";
import { publicRoutes } from "../common/components/PrivateRoute";
import FeedContainer from "./Feed";
import PostContainer from "./Feed/Post";
import EventContainer from "./Feed/Event";
import ChannelContainer from "./Channel";
import AddNewPost from "./Channel/AddNewPost";
import MyChannels from "./Links/Channels";
import EditGroup from "./Channel/Group/EditGroup";
import AddMember from "./Channel/Group/Members/AddMember";
import GroupMembersContainer from "./Channel/Group/Members/";
import GiveGroupFeedbackModal from "./Channel/Group/GiveFeedbackModal";
import FavouritePosts from "./Links/FavouritePosts";
import AddEvent from "./Calendar/Event/AddEvent";
import EditEvent from "./Calendar/Event/EditEvent";
import EditEventNotes from "./Calendar/Event/EditEventNotes";
import EventDetails from "./Calendar/Event/Details";
import AddInvitees from "./Calendar/Event/AddInvitees";
import CalendarSyncContainer from "./Calendar/CalendarSync";
import FullListContainer from "./Calendar/FullList";
import EditProfile from "./User";
import MapContainer from "./CampusMapping";
import OfficeHoursCalendarContainer from "./Calendar/OfficeHours/containers/OfficeHoursCalendarContainer";
import AppointmentConfirmedContainer from "./Calendar/OfficeHours/containers/AppointmentConfirmedContainer";
import CalendarSynciOS from "./Calendar/CalendarSynciOS";
import CalendarSyncMacOS from "./Calendar/CalendarSyncMacOS";
import CalendarSyncAndroidPC from "./Calendar/CalendarSyncAndroidPC";
import CalendarSyncOfficeOutlook from "./Calendar/CalendarSyncOfficeOutlook";
import OfficeHoursSearchContainer from "./Calendar/OfficeHours/containers/OfficeHoursSearchContainer";
import AppointmentCancelledContainer from "./Calendar/OfficeHours/containers/AppointmentCancelledContainer";
import SupportMapContainer from "./SupportMap/containers/SupportMapContainer";
import NotificationsContainer from "./Notifications/containers/NotificationsContainer";
import MyProgrammeContainer from "./MyProgramme/containers/MyProgrammeContainer";
import StudentPublicProfileContainer from "./MyProgramme/containers/StudentPublicProfileContainer";
import ScheduleDayContainer from "./Calendar/ScheduleDay";

export class App extends Component {
    componentDidMount() {
        const { checkStorage, getUser } = this.props;
        checkStorage();
        getUser();
    }

    render() {
        const {
            isAuthenticated,
            location: { pathname },
        } = this.props;
        const showMenu = () => isAuthenticated && !publicRoutes.includes(pathname);
        const isGroup = () => this.props.location.pathname.includes("/channel/");
        return (
            <div
                css={{
                    padding: "0 0 0 300px",
                    "@media (max-width: 1023px)": { padding: 0 },
                }}
            >
                {isAuthenticated && (
                    <Fragment>
                        {showMenu() && <MainMenu />}
                        {isGroup() && <GiveGroupFeedbackModal />}
                        <Switch>
                            <Route exact path="/feed" component={FeedContainer} />
                            <Route exact path="/profile" component={EditProfile} />
                            <Route exact path="/post/:id/:channelId" component={PostContainer} />
                            <Route exact path="/event/:id/:channelId" component={EventContainer} />
                            <Route exact path="/channel/:id" component={ChannelContainer} />
                            <Route exact path="/add-new-post/:id" component={AddNewPost} />
                            <Route exact path="/calendar/list" component={FullListContainer} />
                            <Route
                                exact
                                path="/calendar/day-schedule"
                                component={ScheduleDayContainer}
                            />
                            <Route exact path="/calendar/event/new" component={AddEvent} />
                            <Route exact path="/calendar/event/edit/" component={EditEvent} />
                            <Route
                                exact
                                path="/calendar/event/edit-notes/"
                                component={EditEventNotes}
                            />
                            <Route
                                exact
                                path="/calendar/event/:calendarType/:uid"
                                component={EventDetails}
                            />
                            <Route exact path="/calendar/invitees/add" component={AddInvitees} />
                            <Route exact path="/calendar/sync" component={CalendarSyncContainer} />
                            <Route exact path="/calendar/sync-ios" component={CalendarSynciOS} />
                            <Route
                                exact
                                path="/calendar/sync-macos"
                                component={CalendarSyncMacOS}
                            />
                            <Route
                                exact
                                path="/calendar/sync-android"
                                component={CalendarSyncAndroidPC}
                            />
                            <Route
                                exact
                                path="/calendar/sync-office-outlook"
                                component={CalendarSyncOfficeOutlook}
                            />
                            <Route exact path="/campus-mapping" component={MapContainer} />
                            <Route exact path="/my-channels" component={MyChannels} />
                            <Route exact path="/group/:id/edit-group" component={EditGroup} />
                            <Route
                                exact
                                path="/group/:id/members"
                                component={GroupMembersContainer}
                            />
                            <Route exact path="/group/:id/add-member" component={AddMember} />
                            <Route exact path="/my-saved-items" component={FavouritePosts} />
                            <Route
                                path="/office-hours/:type/:id/confirmed/:appointment"
                                component={AppointmentConfirmedContainer}
                            />
                            <Route
                                path="/office-hours/:type/:id/cancelled/:event"
                                component={AppointmentCancelledContainer}
                            />
                            <Route
                                path="/office-hours/:type/:id"
                                component={OfficeHoursCalendarContainer}
                            />
                            <Route path="/office-hours" component={OfficeHoursSearchContainer} />
                            <Route
                                exact
                                path="/"
                                component={
                                    isAuthenticated
                                        ? () => <Redirect to="/feed" />
                                        : () => <DashboardContainer />
                                }
                            />
                            <Route path="/support-map*" component={SupportMapContainer} />

                            <Route path="/notifications" component={NotificationsContainer} />

                            <Route
                                path="/myprogramme/student/:id"
                                component={StudentPublicProfileContainer}
                            />

                            <Route path="/myprogramme" component={MyProgrammeContainer} />
                        </Switch>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    location: state.router.location,
});
export const dispatchToProps = {
    checkStorage: actionCreators.checkStorage.create,
    validate: actionCreators.validate.create,
    getUser: userActionCreators.getUser.create,
};
export default connect(mapStateToProps, dispatchToProps)(App);
