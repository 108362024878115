export const feedFilters = handler => [
    {
        nameDesktop: "All",
        nameMobile: "All",
        value: "source=all",
        handler,
    },
    {
        nameDesktop: "School News",
        nameMobile: "News",
        value: "source=school-news",
        handler,
    },
    {
        nameDesktop: "Departments",
        nameMobile: "Depts",
        value: "source=departments",
        handler,
    },
    {
        nameDesktop: "Services",
        nameMobile: "Services",
        value: "source=services",
        handler,
    },
];
