import actionCreators from "./actionCreators";

export const initialState = {
    sidelinks: [],
    title: null,
    isLoading: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.setTitle.type:
            return {
                ...state,
                title: payload,
            };
        case actionCreators.clearTitle.type:
            return {
                ...state,
                title: null,
            };
        case actionCreators.createSidelinks.type:
            return {
                ...state,
                sidelinks: payload,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
