import ActionCreator from "../../actionCreator";
import calendarActionCreators from "../CalendarBoard/actionCreators";

export default {
    ...calendarActionCreators,
    getEventsList: ActionCreator("GET_EVENTS_LIST"),
    updateEventsList: ActionCreator("UPDATE_EVENTS_LIST"),
    errorResponse: ActionCreator("ERROR_RESPONSE_LIST"),
    clearEventsList: ActionCreator("CLEAR_EVENTS_LIST"),
    setDefaultStatus: ActionCreator("SET_CAL_DEFAULT_STATUS_LIST"),
};
