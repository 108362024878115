import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import actionCreators from "./actionCreators";

const pushErrorPageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.errorPageRedirect.type),
        mergeMap(() => {
            return concat(of(push("/error")), of(actionCreators.clearLoading.create()));
        })
    );

const pushMaintenancePageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.maintenancePageRedirect.type),
        mergeMap(() => {
            return concat(of(push("/maintenance")), of(actionCreators.clearLoading.create()));
        })
    );

export const epics = combineEpics(pushErrorPageEpic, pushMaintenancePageEpic);
