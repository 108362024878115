import moment from "moment";
import { Calendar } from "calendar";
import actionCreators from "./actionCreators";

const calendar = new Calendar(1);

export const formatDate = (format, date) => moment(date).format(format);
export const incrementMonth = current => (current === 11 ? 0 : current + 1);
export const decrementMonth = current => (current === 0 ? 11 : current - 1);
export const incrementYear = (incMonth, currentYear) =>
    incMonth === 0 ? currentYear + 1 : currentYear;
export const decrementYear = (decMonth, currentYear) =>
    decMonth === 11 ? currentYear - 1 : currentYear;

const resetState = () => ({
    currentYear: Number(formatDate("YYYY")),
    currentMonth: Number(formatDate("M")) - 1,
    currentDay: Number(formatDate("D")),
    weeks: calendar.monthDays(Number(formatDate("YYYY")), Number(formatDate("M")) - 1),
    errorRes: { error: "", errors: {} },
    calendarBoardHeight: null,
});

export const initialState = resetState();

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.setYear.type:
            return {
                ...state,
                currentYear: payload,
                weeks: calendar.monthDays(payload, state.currentMonth),
            };
        case actionCreators.setMonth.type:
            return {
                ...state,
                currentMonth: payload,
                weeks: calendar.monthDays(state.currentYear, payload),
            };
        case actionCreators.setWeeks.type:
            return {
                ...state,
                weeks: payload,
            };
        case actionCreators.setDay.type:
            return {
                ...state,
                currentDay: payload,
            };
        case actionCreators.swipeLeft.type:
            return {
                ...state,
                weeks: calendar.monthDays(
                    incrementYear(incrementMonth(state.currentMonth), state.currentYear),
                    incrementMonth(state.currentMonth)
                ),
                currentMonth: incrementMonth(state.currentMonth),
                currentYear: incrementYear(incrementMonth(state.currentMonth), state.currentYear),
            };
        case actionCreators.swipeRight.type:
            return {
                ...state,
                weeks: calendar.monthDays(
                    decrementYear(decrementMonth(state.currentMonth), state.currentYear),
                    decrementMonth(state.currentMonth)
                ),
                currentMonth: decrementMonth(state.currentMonth),
                currentYear: decrementYear(decrementMonth(state.currentMonth), state.currentYear),
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setCalendarBoardHeight.type:
            return {
                ...state,
                calendarBoardHeight: payload,
            };
        case actionCreators.resetBoard.type:
            return resetState();
        case actionCreators.toggleCalendarBoard.type:
            return {
                ...state,
                calendarBoardVisible: !state.calendarBoardVisible,
            };
        default:
            return state;
    }
};
