import { Component } from "react";
import { Field, reduxForm } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import validate from "./validate";
import TextError from "../../common/components/UI/TextError";
import { spacing } from "../../common/styles/Spacing";
import ButtonShape from "../../common/components/UI/ButtonShape";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import RenderTextFieldGrey from "../../common/components/FormFields/TextFieldGrey";
import { textFieldInline } from "../../common/components/FormFields/FormFieldsStyles";
import { ModalBoxActions } from "../../common/components/UI/ModalBox";
import ButtonText from "../../common/components/UI/ButtonText";
import { feedbackFormStyle } from "./FeedbackStyles";
import { connect } from "react-redux";
import { colors } from "../../common/styles/Colors";

class FeedbackForm extends Component {
    render() {
        const {
            handleSubmit,
            onSubmit,
            subjects,
            errors,
            closeModal,
            isLoading,
            initialValues,
        } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)} css={feedbackFormStyle.body}>
                <div css={feedbackFormStyle.selectWrap}>
                    <Field
                        name="requestType"
                        component={RenderTextFieldGrey}
                        label="What can we help you with?"
                        select
                    >
                        <option value="" disabled>
                            Please select
                        </option>

                        {subjects.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </Field>
                </div>
                <div css={feedbackFormStyle.row}>
                    <div css={feedbackFormStyle.col}>
                        {/* <label>Full name</label> */}
                        <Field
                            name="name"
                            component={RenderTextFieldGrey}
                            label="Full name"
                            disabled={!!initialValues.firstName && !!initialValues.lastName}
                        />
                    </div>
                    <div css={feedbackFormStyle.col}>
                        {/* <label>LSE email address</label> */}
                        <Field
                            name="email"
                            component={RenderTextFieldGrey}
                            label="LSE email address"
                            disabled={!!initialValues.email}
                        />
                        {errors.email && (
                            <TextError
                                css={[
                                    textFieldInline.error,
                                    textFieldInline.errorMarginBottom,
                                    textFieldInline.errorMarginLeft,
                                ]}
                            >
                                {errors.email}
                            </TextError>
                        )}
                    </div>
                </div>
                <div>
                    {/* <label>Summary</label> */}
                    <Field name="subject" component={RenderTextFieldGrey} label="Summary" />
                </div>
                <div>
                    {/* <label>Message</label> */}
                    <Field
                        name="body"
                        component={RenderTextFieldGrey}
                        multiline
                        rows={5}
                        label="Message"
                    />
                </div>
                <ModalBoxActions
                    marginTopValue={spacing.space5}
                    css={{ paddingRight: 0, paddingLeft: 0 }}
                >
                    <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                        <ButtonShape primary fullWidth type="submit" disabled={isLoading}>
                            Send
                        </ButtonShape>
                    </ButtonsGroup>
                    <ButtonsGroup>
                        <ButtonText bold onClick={() => closeModal()} color={colors.black50}>
                            Cancel
                        </ButtonText>
                    </ButtonsGroup>
                </ModalBoxActions>
            </form>
        );
    }
}

const form = reduxForm({
    form: "feedback",
    validate,
})(FeedbackForm);

const mapStateToProps = state => {
    return {
        isLoading: state.MainView.isLoading,
    };
};

export default connect(mapStateToProps)(form);
