import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Container } from "../UI/Container";
import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const CalendarPlaceholder = ({ children, isLoaded, short = false }) => {
    return isLoaded ? (
        children
    ) : (
        <Container width={592}>
            <div css={[feedPage.infiniteScroll, skeletonStyles.calendarContainer]}>
                <div css={feedPage.inner}>
                    <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                        <div css={[skeletonStyles.calendarPlaceholder]}>
                            <ReactPlaceholder
                                showLoadingAnimation={true}
                                type="text"
                                ready={false}
                                rows={3}
                                color={colors.greyDark10}
                            >
                                <div />
                            </ReactPlaceholder>
                        </div>
                    </div>

                    {!short && (
                        <div>
                            <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                                <div css={[skeletonStyles.calendarPlaceholder]}>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>

                            <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                                <div css={[skeletonStyles.calendarPlaceholder]}>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>

                            <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                                <div css={[skeletonStyles.calendarPlaceholder]}>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default CalendarPlaceholder;
