import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import EventForm from "../Form";
import actionCreators from "../../../../store/Calendar/Event/actionCreators";
import HeaderCalendar from "../../../../common/components/Header/HeaderCalendar";
import { Container } from "../../../../common/components/UI/Container";
import { calendarPage } from "../../CalendarStyles";
import HeaderCalendarMobile from "../../../../common/components/Header/HeaderCalendarMobile";
import calendarBoardActionCreators from "../../../../store/Calendar/CalendarBoard/actionCreators";
import moment from "moment";
import GTM from "../../../../common/services/GTM";
import { getUrlSearchParams } from "../../utils";

class AddEvent extends Component {
    componentDidMount() {
        const {
            getEvent,
            location: { search, state },
            initialValues,
            loadInvitees,
            setStateStatus,
        } = this.props;

        setStateStatus("EDIT");

        const urlParams = getUrlSearchParams(search);

        if (!initialValues.uid) getEvent(urlParams);

        if (!(state && state.loadInvitees && state.loadInvitees === true)) {
            loadInvitees(initialValues.inviteesData);
        }

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/event/edit/${urlParams.calendarType}/${urlParams.uid}`,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            location: { state },
            initialValues,
            loadInvitees,
        } = this.props;

        if (
            !(state && state.loadInvitees && state.loadInvitees === true) &&
            !prevProps.initialValues.uid &&
            this.props.initialValues.uid
        ) {
            loadInvitees(initialValues.inviteesData);
        }
    }

    onSubmit = values => {
        const {
            putEvent,
            putEventNotes,
            selectedInvitees,
            origin,
            setYear,
            setMonth,
            setDay,
            initialValues,
        } = this.props;

        if (origin && origin === "day-schedule") {
            const eventDate = moment(values.startAt);
            setYear(Number(eventDate.format("YYYY")));
            setMonth(Number(eventDate.format("M")) - 1);
            setDay(Number(eventDate.format("D")));
        }

        // non-editable event?
        if (initialValues.source === "Channel" || initialValues.calendarType !== "my-events") {
            // notes update
            putEventNotes({
                ...values,
            });
        } else {
            // full update
            const eventInvitees = [];
            selectedInvitees && selectedInvitees.map(user => eventInvitees.push(user.id));
            putEvent({
                ...values,
                invitees: eventInvitees,
            });
        }
    };

    render() {
        const {
            form,
            initialValues,
            selectedInvitees,
            saveFormData,
            errorRes,
            history: { push },
        } = this.props;

        const { onSubmit } = this;
        const formName = "editEvent";
        return (
            <div css={[calendarPage.body, calendarPage.bodyMobileWhite]}>
                <HeaderCalendar />
                <HeaderCalendarMobile />
                <Container onMobileNoPadding width={592}>
                    <EventForm
                        formState={form}
                        form={formName}
                        {...{
                            onSubmit,
                            selectedInvitees,
                            initialValues,
                            saveFormData,
                            push,
                            errorRes,
                        }}
                    />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    form,
    calendar: {
        event: { item, origin, selectedInvitees, errorRes },
    },
}) => ({
    form,
    initialValues: item,
    origin,
    selectedInvitees,
    errorRes,
});

const mapDispatchToProps = {
    putEvent: actionCreators.putEvent.create,
    putEventNotes: actionCreators.putEventNotes.create,
    setStateStatus: actionCreators.setStateStatus.create,
    saveFormData: actionCreators.saveFormData.create,
    loadInvitees: actionCreators.loadInvitees.create,
    getEvent: actionCreators.getEvent.create,
    setYear: calendarBoardActionCreators.setYear.create,
    setMonth: calendarBoardActionCreators.setMonth.create,
    setDay: calendarBoardActionCreators.setDay.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
