import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import EventForm from "../Form";
import actionCreators from "../../../../store/Calendar/Event/actionCreators";
import { Container } from "../../../../common/components/UI/Container";
import HeaderCalendar from "../../../../common/components/Header/HeaderCalendar";
import { calendarPage } from "../../CalendarStyles";
import { header } from "../../../../common/components/Header/HeaderStyles";
import HeaderCalendarMobile from "../../../../common/components/Header/HeaderCalendarMobile";
import calendarBoardActionCreators from "../../../../store/Calendar/CalendarBoard/actionCreators";
import moment from "moment";
import { formatdateLength } from "../../utils";
import GTM from "../../../../common/services/GTM";
import { BannerNotifications } from "../../Notifications";

class AddEvent extends Component {
    componentDidMount() {
        const {
            setStateStatus,
            recalculateNewEventDate,
            currentMonth,
            currentDay,
            currentYear,
            location: { state },
        } = this.props;
        setStateStatus("NEW");

        if (!(state && state.loadInvitees && state.loadInvitees === true)) {
            recalculateNewEventDate({
                currentDay: formatdateLength(currentDay),
                currentMonth: formatdateLength(currentMonth + 1),
                currentYear,
            });
        }
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/event/new`,
        });
    }

    onSubmit = ({ uid, type, ...payload }) => {
        const { postEvent, selectedInvitees, origin, setYear, setMonth, setDay } = this.props;

        if (origin && origin === "day-schedule") {
            const eventDate = moment(payload.startAt);
            setYear(Number(eventDate.format("YYYY")));
            setMonth(Number(eventDate.format("M")) - 1);
            setDay(Number(eventDate.format("D")));
        }

        const eventInvitees = [];
        selectedInvitees && selectedInvitees.map(user => eventInvitees.push(user.id));
        postEvent({
            ...payload,
            invitees: eventInvitees,
            origin: origin ? origin : "list",
        });
    };

    render() {
        const {
            form,
            initialValues,
            selectedInvitees,
            saveFormData,
            errorRes,
            history: { push },
            location: { state },
        } = this.props;

        const { onSubmit } = this;
        const formName = "addEvent";

        return (
            <div css={[calendarPage.bodyAddEvent, calendarPage.bodyMobileWhite]}>
                <HeaderCalendar />
                <HeaderCalendarMobile />
                <BannerNotifications />
                <div css={header.titleBig}>Add an event</div>
                <Container onMobileNoPadding width={592}>
                    <EventForm
                        selectedInvitees={
                            state && state.loadInvitees && state.loadInvitees === true
                                ? selectedInvitees
                                : null
                        }
                        formState={form}
                        form={formName}
                        {...{
                            onSubmit,
                            saveFormData,
                            initialValues,
                            push,
                            errorRes,
                        }}
                    />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { item, origin, selectedInvitees, errorRes },
        board: { currentMonth, currentDay, currentYear },
    },
    form,
}) => ({
    form,
    initialValues: item,
    origin,
    selectedInvitees,
    errorRes,
    currentMonth,
    currentDay,
    currentYear,
});

const mapDispatchToProps = {
    postEvent: actionCreators.postEvent.create,
    setStateStatus: actionCreators.setStateStatus.create,
    saveFormData: actionCreators.saveFormData.create,
    clearEvent: actionCreators.clearEvent.create,
    setYear: calendarBoardActionCreators.setYear.create,
    setMonth: calendarBoardActionCreators.setMonth.create,
    setDay: calendarBoardActionCreators.setDay.create,
    recalculateNewEventDate: actionCreators.recalculateNewEventDate.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
