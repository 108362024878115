import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import actionCreator from "../../../store/Calendar/Notifications/actionCreators";
import parse from "html-react-parser";
import { colors } from "../../../common/styles/Colors";
import Icon from "../../../common/components/UI/Icon";
import { withMobileDialog } from "@material-ui/core";
import ButtonText from "../../../common/components/UI/ButtonText";

const styles = () => ({
    root: {
        margin: 0,
        padding: "40px 30px 16px",
    },
    title: {
        margin: 0,
        fontSize: 20,
    },
    closeButton: {
        border: "0 none",
        outline: "0 none",
        background: "none",
        marign: 0,
        padding: 0,
        color: "rgba(0,0,0,.87)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 25,
        height: 25,
        position: "absolute",
        top: 20,
        right: 20,
    },
    content: {
        "& > *": {
            color: colors.black87,
            fontSize: 16,
            lineHeight: 1.5,
        },
    },
    checkbox: {
        marginTop: 24,
    },
    checkboxLabel: {
        color: "rgba(0,0,0, 0.58)",
        fontSize: 16,
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <h2 className={classes.title}>{children}</h2>
            {onClose ? (
                <button aria-label="close" onClick={onClose} className={classes.closeButton}>
                    <Icon name="times" width="18" height="18" fill={colors.black87} />
                </button>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: "0 30px 40px",
        "& a": {
            color: "#FF004F",
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        justifyContent: "center",
        margin: "24px 40px",
    },
}))(MuiDialogActions);

function NotificationDialog({
    notification,
    isDisplayedContinuously,
    handleClose,
    classes,
    fullScreen,
}) {
    const [checked, setChecked] = React.useState(false);
    const dispatch = useDispatch();

    const onClose = () => {
        if (!isDisplayedContinuously && checked) {
            dispatch(actionCreator.setNoShowAgain.create(notification.id));
        }
        handleClose();
    };

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                {notification.teaser}
            </DialogTitle>
            <DialogContent>
                <div className={classes.content}>{parse(notification.content)}</div>
                {!isDisplayedContinuously && (
                    <div className={classes.checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                            }
                            label={
                                <span className={classes.checkboxLabel}>
                                    Don’t show this message again
                                </span>
                            }
                        />
                    </div>
                )}
            </DialogContent>
            {fullScreen && (
                <DialogActions>
                    <ButtonText bold onClick={onClose} color={colors.black50}>
                        CLOSE
                    </ButtonText>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default withStyles(styles)(withMobileDialog()(NotificationDialog));
