import actionCreators from "./actionCreators";

export const initialState = {
    items: [],
    isSearchActive: false,
    querySearch: undefined,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.saveItems.type:
            return {
                ...state,
                items: payload,
            };
        case actionCreators.setQuerySearch.type:
            return {
                ...state,
                querySearch: payload,
            };
        case actionCreators.toggleSearchActive.type:
            return {
                ...state,
                isSearchActive: !state.isSearchActive,
            };
        default:
            return state;
    }
};
