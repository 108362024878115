import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { colors } from "../../styles/Colors";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const EventPlaceholder = ({ children, isLoaded }) => {
    return isLoaded ? (
        children
    ) : (
        <div css={[feedPage.infiniteScroll, skeletonStyles.eventContainer]}>
            <div css={feedPage.inner}>
                <div css={entryShort.body}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            type="text"
                            ready={false}
                            rows={3}
                            color={colors.greyDark10}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventPlaceholder;
