import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import { default as searchActionCreators } from "../Search/actionCreators";
import { default as feedActionCreators } from "../Feed/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";
import { push } from "connected-react-router";

const channelStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannel.type, actionCreators.loadMoreChannel.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const channelClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createChannel.type,
            actionCreators.updateChannel.type,
            actionCreators.toggleChannelTracking.type,
            actionCreators.setChannelAsFollowed.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getChannelEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannel.type),
        mergeMap(({ payload: { id, param } }) =>
            ajax.get(apiUrl(`api/channel/${id}/?${param || ""}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.createChannel.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getChannelPosts = action$ =>
    action$.pipe(
        ofType(actionCreators.getChannelPosts.type),
        mergeMap(({ payload: { id, param } }) =>
            ajax.get(apiUrl(`api/channel/${id}/posts/?${param || ""}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.createChannelPosts.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postChannelFollow = action$ =>
    action$.pipe(
        ofType(actionCreators.postFollowStatus.type),
        mergeMap(({ payload: { id, action } }) =>
            ajax.post(apiUrl(`api/channel/${id}/tracking/${action}/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    return concat(
                        of(actionCreators.getChannel.create({ id })),
                        of(searchActionCreators.toggleSearchBar.create(false)),
                        of(searchActionCreators.disableResults.create()),
                        of(feedActionCreators.initializeFeed.create())
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const redirectToChannelEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToChannel.type),
        mergeMap(({ payload: { id, meta } }) => of(push(`/channel/${id}`, meta)))
    );

const getRecommendedChannelsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getRecommendedChannels.type),
        mergeMap(() =>
            ajax.get(apiUrl("api/user/recommended-channels/")).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateRecommendedChannels.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    channelStartLoadingEpic,
    channelClearLoadingEpic,
    getChannelEpic,
    getChannelPosts,
    postChannelFollow,
    redirectToChannelEpic,
    getRecommendedChannelsEpic
);
