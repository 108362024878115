import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "./Icon";
import { buttonFloating } from "./ButtonFloatingStyle";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

class ButtonFloating extends Component {
    render() {
        const { to, icon, iconColor, iconSize, style, link, label, onClick, ...other } = this.props;

        return link ? (
            <a
                css={[buttonFloating.body, label && buttonFloating.bodyLabel, style && style]}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                {...other}
            >
                <Icon
                    name={icon}
                    fill={iconColor || colors.white}
                    width={iconSize || 12}
                    height={iconSize || 12}
                    css={buttonFloating.icon}
                />
                {label && <span css={buttonFloating.label}>{label}</span>}
            </a>
        ) : (
            <button
                css={[buttonFloating.body, label && buttonFloating.bodyLabel, style && style]}
                onClick={() => {
                    if (onClick) onClick();
                    history.push(to);
                }}
                {...other}
            >
                <Icon
                    name={icon}
                    fill={iconColor || colors.white}
                    width={iconSize || 12}
                    height={iconSize || 12}
                    css={buttonFloating.icon}
                />
                {label && <span css={buttonFloating.label}>{label}</span>}
            </button>
        );
    }
}

export default ButtonFloating;
