import ActionCreator from "../../actionCreator";

export default {
    redirectToOfficeHours: ActionCreator("REDIRECT_TO_OFFICE_HOURS"),
    getSlotsByMonth: ActionCreator("OFFICE_HOURS_GET_SLOTS_BY_MONTH"),
    setSlotsByMonth: ActionCreator("OFFICE_HOURS_SET_SLOTS_BY_MONTH"),
    postAppointment: ActionCreator("OFFICE_HOURS_POST_APPOINTMENT"),
    setAppointment: ActionCreator("OFFICE_HOURS_SET_APPOINTMENT"),
    handleAppointmentError: ActionCreator("OFFICE_HOURS_HANDLE_APPOINTMENT_ERROR"),
    resetAppointmentError: ActionCreator("OFFICE_HOURS_RESET_APPOINTMENT_ERROR"),
    removeAppointment: ActionCreator("OFFICE_HOURS_REMOVE_APPOINTMENT"),
    getBookablesList: ActionCreator("OFFICE_HOURS_GET_BOOKABLES_LIST"),
    setBookablesList: ActionCreator("OFFICE_HOURS_SET_BOOKABLES_LIST"),
    getBookableInfo: ActionCreator("OFFICE_HOURS_GET_BOOKABLE_INFO"),
    addBookableInfo: ActionCreator("OFFICE_HOURS_ADD_BOOKABLE_INFO"),
    removeBookableInfo: ActionCreator("OFFICE_HOURS_REMOVE_BOOKABLE_INFO"),
    errorResponse: ActionCreator("OFFICE_HOURS_ERROR_RESPONSE"),
    getUnavailableSessionsByMonth: ActionCreator("OFFICE_HOURS_GET_UNAVAILABLE_SESSIONS_BY_MONTH"),
    setUnavailableSessionsByMonth: ActionCreator("OFFICE_HOURS_SET_UNAVAILABLE_SESSIONS_BY_MONTH"),
    setRebookApointment: ActionCreator("OFFICE_HOURS_SET_REBOOK_APOINTMENT"),
    removeRebookApointment: ActionCreator("OFFICE_HOURS_CLEAR_REBOOK_APOINTMENT"),
    getBookingLimits: ActionCreator("OFFICE_HOURS_GET_BOOKING_LIMITS"),
    setBookingLimits: ActionCreator("OFFICE_HOURS_SET_BOOKING_LIMITS"),
    clearBookingLimits: ActionCreator("OFFICE_HOURS_CLEAR_BOOKING_LIMITS"),
    checkFutureAvailability: ActionCreator("OFFICE_HOURS_CHECK_FUTURE_AVAILABILITY"),
    setNoFutureAvailability: ActionCreator("OFFICE_HOURS_SET_NO_FUTURE_AVAILABILITY"),
    getRecommendedBookablesList: ActionCreator("OFFICE_HOURS_GET_RECOMMENDED_BOOKABLES_LIST"),
    setRecommendedBookablesList: ActionCreator("OFFICE_HOURS_SET_RECOMMENDED_BOOKABLES_LIST"),

    getUserCalendarEvent: ActionCreator("OFFICE_HOURS_GET_USER_DROPIN_CALENDAR_EVENT"),
    updateUserCalendarEvent: ActionCreator("OFFICE_HOURS_UPDATE_USER_DROPIN_CALENDAR_EVENT"),
    clearUserCalendarEvent: ActionCreator("OFFICE_HOURS_CLEAR_USER_DROPIN_CALENDAR_EVENT"),
    addUserCalendarEvent: ActionCreator("OFFICE_HOURS_ADD_USER_DROPIN_CALENDAR_EVENT"),

    addFavouriteBookableAcademic: ActionCreator("OFFICE_HOURS_ADD_FAVOURITE_BOOKABLE_ACADEMIC"),
    addFavouriteBookableTeam: ActionCreator("OFFICE_HOURS_ADD_FAVOURITE_BOOKABLE_TEAM"),
    deleteFavouriteBookableAcademic: ActionCreator(
        "OFFICE_HOURS_DELETE_FAVOURITE_BOOKABLE_ACADEMIC"
    ),
    deleteFavouriteBookableTeam: ActionCreator("OFFICE_HOURS_DELETE_FAVOURITE_BOOKABLE_TEAM"),
    getFavouritseBookablesList: ActionCreator("OFFICE_HOURS_GET_FAVOURITES_BOOKABLES"),
    updateFavouritseBookablesList: ActionCreator("OFFICE_HOURS_UPDATE_FAVOURITES_BOOKABLES"),
};
