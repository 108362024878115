import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { textFieldGrey } from "./FormFieldsStyles";
import Icon from "../UI/Icon";

class RenderTextFieldGrey extends Component {
    render() {
        const {
            input,
            label,
            meta: { touched, error },
            classes,
            select,
            children,
            ...custom
        } = this.props;

        return (
            <FormControl
                classes={{
                    root: classes.root,
                }}
                fullWidth
                error={touched && error && true}
                variant="filled"
            >
                {label && (
                    <InputLabel
                        classes={{
                            shrink: classes.labelShrink,
                        }}
                        FormLabelClasses={{
                            root: classes.labelRoot,
                            error: classes.labelError,
                            focused: classes.labelFocused,
                            filled: classes.labelFilled,
                        }}
                        variant="filled"
                        shrink
                    >
                        {label}
                    </InputLabel>
                )}
                {select ? (
                    <div className="materialUISelect">
                        <NativeSelect
                            classes={{
                                root: classes.selectRoot,
                                select: classes.selectSelect,
                                icon: classes.selectIcon,
                                filled: classes.selectFilled,
                            }}
                            IconComponent={() => (
                                <div css={textFieldGrey.selectIcon}>
                                    <Icon name="select" fill="#292B2C" width={12} height={12} />
                                </div>
                            )}
                            variant="filled"
                            {...input}
                            {...custom}
                        >
                            {children}
                        </NativeSelect>
                    </div>
                ) : (
                    <FilledInput
                        classes={{
                            root: classes.inputRoot,
                            focused: classes.inputFocused,
                            disabled: classes.inputDisabled,
                            underline: classes.inputUnderline,
                            multiline: classes.inputMultiline,
                            error: classes.inputError,
                            input: classes.inputInput,
                            inputMultiline: classes.inputInputMultiline,
                        }}
                        endAdornment={
                            touched &&
                            error && (
                                <InputAdornment position="end">
                                    <span css={textFieldGrey.iconError}>
                                        <Icon name="error" fill="#E0112B" width={18} height={18} />
                                    </span>
                                </InputAdornment>
                            )
                        }
                        {...input}
                        {...custom}
                    />
                )}

                {touched && error && (
                    <FormHelperText
                        classes={{
                            root: classes.helperRoot,
                            error: classes.helperError,
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(textFieldGrey)(RenderTextFieldGrey);
