import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import actionCreators from "../../store/Feed/Post/actionCreators";
import Favourite from "../../common/components/UI/Favourite";
import { feedItemSummary } from "./FeedStyles";
import { colors } from "../../common/styles/Colors";
import Icon from "../../common/components/UI/Icon";
import GTM from "../../common/services/GTM";

class FeedItemSummary extends Component {
    handleBookmarkClick = () => {
        const {
            isFavourite,
            postId,
            postName,
            unbookmarkPost,
            bookmarkPost,
            kind,
            article,
            imageUrl,
            channel,
        } = this.props;

        isFavourite
            ? unbookmarkPost({ id: postId, channelId: channel.id })
            : bookmarkPost({ id: postId, channelId: channel.id });

        const gtmTypeDesc =
            kind === "text"
                ? article
                    ? "long post"
                    : imageUrl
                    ? "image post"
                    : "short post"
                : "event";

        GTM.dispatch({
            event: "savePost",
            actionType: `${isFavourite ? "Unsave" : "Save"} ${gtmTypeDesc}`,
            postId: `${postId}`,
            postName: `${postName}`,
            channelId: `${channel.id}`,
            channelName: `${channel.name}`,
        });
    };

    handleLikeClick = () => {
        const {
            isLiked,
            postId,
            postName,
            likePost,
            unlikePost,
            kind,
            article,
            imageUrl,
            channel,
        } = this.props;

        isLiked ? unlikePost({ id: postId }) : likePost({ id: postId });

        const gtmTypeDesc =
            kind === "text"
                ? article
                    ? "long post"
                    : imageUrl
                    ? "image post"
                    : "short post"
                : "event";

        GTM.dispatch({
            event: "likePost",
            actionType: `${isLiked ? "Unlike" : "Like"} ${gtmTypeDesc}`,
            postId: `${postId}`,
            postName: `${postName}`,
            channelId: `${channel.id}`,
            channelName: `${channel.name}`,
        });
    };

    render() {
        const {
            isFavourite,
            isLiked,
            postId,
            numComments,
            numberOfLikes,
            kind,
            channel,
        } = this.props;
        const CommentsElement = kind ? Link : "div";
        const kindLink =
            kind === "text" ? `/post/${postId}/${channel.id}` : `/event/${postId}/${channel.id}`;

        return (
            <div css={feedItemSummary.body}>
                <button
                    type="button"
                    onClick={() => this.handleLikeClick()}
                    css={feedItemSummary.favourite}
                >
                    <Favourite isFavourite={isLiked} />
                    <span>
                        {numberOfLikes
                            ? `${numberOfLikes} like${numberOfLikes > 1 ? "s" : ""}`
                            : "Like"}
                    </span>
                </button>

                <CommentsElement to={kind && kindLink} css={feedItemSummary.comments}>
                    <Icon
                        name="chat"
                        fill={colors.black54}
                        width={18}
                        height={18}
                        css={feedItemSummary.commentsIcon}
                    />
                    <div css={feedItemSummary.commentsText}>
                        {numComments
                            ? `${numComments} comment${numComments > 1 ? "s" : ""}`
                            : "Comment"}
                    </div>
                </CommentsElement>

                <button
                    type="button"
                    onClick={() => this.handleBookmarkClick()}
                    css={feedItemSummary.favourite}
                >
                    <Favourite isFavourite={isFavourite} icon="bookmark" />
                    <span>{isFavourite ? "Unsave" : "Save"}</span>
                </button>
            </div>
        );
    }
}

const mapStateToProps = ({ Post: { post } }) => ({
    post,
});
const mapDispatchToProps = {
    bookmarkPost: actionCreators.bookmarkPost.create,
    unbookmarkPost: actionCreators.unbookmarkPost.create,
    likePost: actionCreators.likePost.create,
    unlikePost: actionCreators.unlikePost.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedItemSummary);
