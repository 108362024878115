import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { containerImg } from "./ContainerImgStyles";

class ContainerImg extends Component {
    render() {
        const { children, fullWidth, style } = this.props;

        return (
            <div css={containerImg.bg}>
                <div css={[containerImg.body, style && style]}>
                    <div css={[containerImg.inner, fullWidth && containerImg.innerFullWidth]}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContainerImg;
