import { useCallback, useRef, useState } from "react";

export const useMeasure = () => {
    const [dimensions, setDimensions] = useState({
        measureWidth: null,
        measureHeight: null,
    });

    const previousObserver = useRef(null);

    const customRef = useCallback(node => {
        if (previousObserver.current) {
            previousObserver.current.disconnect();
            previousObserver.current = null;
        }

        if (node?.nodeType === Node.ELEMENT_NODE) {
            const observer = new ResizeObserver(([entry]) => {
                if (entry && entry.borderBoxSize) {
                    const {
                        inlineSize: measureWidth,
                        blockSize: measureHeight,
                    } = entry.borderBoxSize[0];

                    setDimensions({ measureWidth, measureHeight });
                }
            });

            observer.observe(node);
            previousObserver.current = observer;
        }
    }, []);

    return [customRef, dimensions];
};
