import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { default as ajax } from "../../common/services/utils";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as UIActionCreators } from "../../store/MainView/actionCreators";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSearchResults.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateSearchResults.type, actionCreators.errorResponse.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getSearchResultsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSearchResults.type),
        mergeMap(({ payload: { filter } }) => {
            return ajax.get(apiUrl(`api/search/?filter=${filter}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateSearchResults.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            );
        })
    );

export const epics = combineEpics(startLoadingEpic, clearLoadingEpic, getSearchResultsEpic);
