import React, { Component } from "react";
import { DatePicker } from "material-ui-pickers";
import { TextFieldInline } from "./TextFieldInline";

class RenderDatePicker extends Component {
    render() {
        const {
            timezone,
            showErrorsInline,
            dispatch,
            dateFormat,
            input: { onChange, value },
            meta: { touched, error },
            classes,
            ...other
        } = this.props;

        const showError = showErrorsInline || touched;

        return (
            <DatePicker
                error={!!(showError && error)}
                helperText={showError && error}
                value={value}
                onChange={onChange}
                format={dateFormat}
                TextFieldComponent={TextFieldInline}
                {...other}
            />
        );
    }
}

export default RenderDatePicker;
