import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";
import { fontWeight, fontFamily, fontSize } from "../../styles/Typography";
import { resetLineHeight } from "../../styles/Globals";

export const button = {
    body: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 50,
        backgroundColor: colors.accentF,
        borderRadius: spacing.space0,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        textDecoration: "none",
        position: "relative",
        WebkitTapHighlightColor: "transparent",
        outline: "none",
        border: 0,
        cursor: "pointer",
        userSelect: "none",
    },
    bodySmall: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 32,
        backgroundColor: colors.black10,
        borderRadius: spacing.space0,
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
    },
    bodySmaller: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 24,
        backgroundColor: colors.black10,
        borderRadius: spacing.space0,
        paddingTop: spacing.space0comma5,
        paddingRight: spacing.space1,
        paddingBottom: spacing.space0comma5,
        paddingLeft: spacing.space1,
    },
    bodyChannel: {
        backgroundColor: colors.white20,
    },
    bodySmallDark: {
        backgroundColor: colors.black40,
    },
    bodyOutline: {
        backgroundColor: colors.transparent,
        borderWidth: 1,
        borderColor: "#949494",
        borderStyle: "solid",
    },
    bodyFullWidth: {
        width: "100%",
    },
    bodyAccent: {
        backgroundColor: colors.transparent,
        paddingLeft: 0,
        paddingRight: 0,
    },
    bodyDisabled: {
        backgroundColor: colors.black20,
        opacity: 0.5,
    },
    text: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.medium,
        lineHeight: 1.375,
        color: colors.black87,
    },
    textSmall: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1,
        letterSpacing: "0.4px",
        color: colors.black87,
    },
    textSmaller: {
        fontSize: fontSize.textSmaller,
    },
    textChannel: {
        color: colors.white,
    },
    textAccent: {
        fontWeight: fontWeight.bold,
        color: colors.accentA,
    },
    textIcon: {
        ...resetLineHeight,
        marginRight: spacing.space1,
        display: "flex",
        lineHeight: 1,
    },
    hideOnMobile: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
};
