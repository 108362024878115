import { ofType, combineEpics } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import actionCreators from "./actionCreators";
import { Client } from "contensis-delivery-api";
import { ROOT_PAGE_SLUG, EMERGENCY_HELP_SLUG } from "./../../app/SupportMap/config";

const client = Client.create({
    rootUrl: process.env.REACT_APP_CONTENSIS_API_URL,
    accessToken: process.env.REACT_APP_CONTENSIS_ACCESS_TOKEN,
    projectId: process.env.REACT_APP_CONTENSIS_PROJECT_ID,
    language: "en-GB",
    versionStatus: "published",
    pageSize: 500,
});

const getAllItemsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getAllItems.type),
        mergeMap(() => {
            return from(
                client.entries
                    .list({
                        contentTypeId: "SupportMapItem",
                        fields: [
                            "name",
                            "link",
                            "slug",
                            "entryTitle",
                            "entryDescription",
                            "intro",
                            "subitem",
                            "colourHex",
                            "image",
                            "imagefull",
                            "imagesquare",
                        ],
                        linkDepth: 1,
                    })
                    .then(SupportMapList => {
                        const results = SupportMapList.items.map(item => {
                            return {
                                ...item,
                                subitem:
                                    item.slug === ROOT_PAGE_SLUG
                                        ? item.subitem.filter(s => s.slug !== EMERGENCY_HELP_SLUG)
                                        : item.subitem,
                            };
                        });

                        return results;
                    })
            ).pipe(
                map(res => res),
                mergeMap(res => of(actionCreators.saveItems.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            );
        })
    );

export const epics = combineEpics(getAllItemsEpic);
