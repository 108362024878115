import { Component, Fragment } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Links/actionCreators";
import { Container } from "../../../common/components/UI/Container";
import PostItem from "../../Feed/PostItem";
import EventItem from "../../Feed/EventItem";
import HeaderMobile from "../../../common/components/Header/HeaderMobile";
import { linksPage } from "../LinksStyles";
import NoResults from "../../../common/components/NoResults/";
import FeedPlaceholder from "../../../common/components/Skeletons/FeedPlaceholder";
import GTM from "../../../common/services/GTM";
import FilterBar from "../../../common/components/FilterBar";
import filters from "./filters";

const PostItems = ({ favouritePosts }) => (
    <Fragment>
        {favouritePosts.map((post, index) =>
            post.kind === "text" ? (
                <PostItem key={`${index}-${post.id}`} post={post} />
            ) : (
                <EventItem key={`${index}-${post.id}`} event={post} />
            )
        )}
    </Fragment>
);

class UserFavouritePosts extends Component {
    componentDidMount() {
        this.props.initializeFavoritePosts();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/my-saved-items`,
        });
    }

    onLoadMore = () => {
        const { getUserFavouritePosts, page, favouritePostsFilter } = this.props;
        getUserFavouritePosts({ page, filter: favouritePostsFilter });
    };
    render() {
        const { favouritePosts, hasMore, setFavouritePostsFilter } = this.props;
        const { onLoadMore } = this;
        return (
            <div css={linksPage.body}>
                <HeaderMobile title="Saved Items" />
                <FilterBar filters={filters(setFavouritePostsFilter)} />
                <FeedPlaceholder isLoaded={Boolean(favouritePosts)}>
                    {favouritePosts &&
                        (favouritePosts.length > 0 ? (
                            <Container width={592}>
                                <InfiniteScroll
                                    dataLength={favouritePosts.length}
                                    next={onLoadMore}
                                    {...{ hasMore }}
                                >
                                    <div css={linksPage.inner}>
                                        <PostItems {...{ favouritePosts }} />
                                    </div>
                                </InfiniteScroll>
                            </Container>
                        ) : (
                            <Container width={592}>
                                <NoResults
                                    heading="There are no saved items on your list!"
                                    message="Please try to bookmark some posts first ..."
                                />
                            </Container>
                        ))}
                </FeedPlaceholder>
            </div>
        );
    }
}

const mapStateToProps = ({
    Links: { favouritePostsPage, favouritePosts, favouritePostsHasMore, favouritePostsFilter },
}) => ({
    page: favouritePostsPage,
    favouritePosts,
    hasMore: favouritePostsHasMore,
    favouritePostsFilter,
});

const mapDispatchToProps = {
    getUserFavouritePosts: actionCreators.getUserFavouritePosts.create,
    initializeFavoritePosts: actionCreators.initializeFavoritePosts.create,
    setFavouritePostsFilter: actionCreators.setFavouritePostsFilter.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFavouritePosts);
