import ActionCreator from "../actionCreator";

export default {
    validate: ActionCreator("AUTH_VALIDATE"),
    checkStorage: ActionCreator("CHECK_STORAGE"),
    login: ActionCreator("LOGIN"),
    loginUserSuccess: ActionCreator("LOGIN_USER_SUCCESS"),
    forgotPassword: ActionCreator("FORGOT_PASSWORD"),
    setUser: ActionCreator("SET_USER"),
    setAuthNotRegistered: ActionCreator("SET_AUTH_NOT_REGISTERED"),
    setAuthAndRegistered: ActionCreator("SET_AUTH_AND_REGISTERED"),
    updateUserProfile: ActionCreator("SET_PROFILE_PHOTO"),
    updateUser: ActionCreator("UPDATE_USER"),
    errorResponse: ActionCreator("ERROR_RESPONSE_AUTH"),
    logout: ActionCreator("LOGOUT"),
    redirectToHome: ActionCreator("REDIRECT_TO_HOME"),
    requireRegistrationsTerms: ActionCreator("REQUIRE_REGISTRATION_TERMS"),
    displayResetPasswordModal: ActionCreator("SHOW_RESET_PASSWORD_MODAL"),
    hideResetPasswordModal: ActionCreator("HIDE_RESET_PASSWORD_MODAL"),
};
