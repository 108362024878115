import { colors } from "../../../common/styles/Colors";
import { spacing } from "../../../common/styles/Spacing";
import { fontSize, lineHeight, fontWeight } from "../../../common/styles/Typography";
import { resetLineHeight } from "../../styles/Globals";

export const searchResultBar = {
    body: {
        width: "100%",
        cursor: "pointer",
        display: "flex",
        backgroundColor: colors.white,
        padding: spacing.space2,
        alignItems: "stretch",
        border: 0,
        borderBottomWidth: 1,
        borderBottomColor: colors.greyDark10,
        borderBottomStyle: "solid",
        textDecoration: "none",
        margin: 0,
        // marginRight: spacing.space2,
        // marginLeft: spacing.space2,
        // "@media (max-width: 1023px)": {
        //     marginRight: 0,
        //     marginLeft: 0,
        // },
    },
    bodyLeft: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: spacing.space2,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        alignItems: "center",
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.normal,
    },
    bodyRight: {},
};

export const searchMapResultBar = {
    body: {
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        border: 0,
        width: "100%",
        display: "block",
        marginBottom: spacing.space1,
        textDecoration: "none",
        color: colors.black87,
        cursor: "pointer",
        textAlign: "left",
    },
    bodyInner: {
        display: "block",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
        backgroundColor: colors.white,
    },
    name: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: 1.42,
    },
    address: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1.42,
    },
    bottomShape: {
        ...resetLineHeight,
        height: 8,
        backgroundColor: "#fff",
    },
};
