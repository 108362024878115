import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import InfiniteScroll from "react-infinite-scroll-component";
import actionCreators from "../../store/Feed/actionCreators";
import eventActionCreators from "../../store/Calendar/Event/actionCreators";
import FilterBar from "../../common/components/FilterBar";
import { Container } from "../../common/components/UI/Container";
import { feedPage } from "./FeedStyles";
import HeaderFeedMobile from "../../common/components/Header/HeaderFeedMobile";
import FeedItem from "./FeedItem/";
import { feedFilters } from "./filters";
import SearchField from "../Search/SearchField";
import SearchResults from "../Search/ResultsList";
import NoResults from "../../common/components/NoResults/";
import FeedPlaceholder from "../../common/components/Skeletons/FeedPlaceholder";
import GTM from "../../common/services/GTM";
import ReactVisibilitySensor from "react-visibility-sensor";

class FeedContainer extends Component {
    state = { trackedIds: [] };

    componentDidMount() {
        const { page, confirmEvent, setFeedFilter } = this.props;

        const uid = localStorage.getItem("uid");
        const isActionPost = localStorage.getItem("redirectAction") === "post";

        localStorage.removeItem("uid");
        localStorage.removeItem("redirectAction");

        if (isActionPost) {
            confirmEvent(uid);
        }

        setFeedFilter("source=all"); // on load reset feed and starts anew

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/feed/${page}`,
        });
    }

    onLoadMore = () => {
        const { getFeed, page, filter } = this.props;
        getFeed({ page, param: filter });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/feed/${page}`,
        });
    };

    checkFeedVisible = (isVisible, post) => {
        const { trackedIds } = this.state;
        if (isVisible && post && !trackedIds.includes(`${post.channel.id}-${post.id}`)) {
            const gtmTypeDesc =
                post.kind === "text"
                    ? post.article
                        ? "long post"
                        : post.imageUrl
                        ? "image post"
                        : "short post"
                    : "event";
            GTM.dispatch({
                event: "postAppearance",
                actionType: `${gtmTypeDesc} appeared on main feed (page ${this.props.page - 1})`,
                postId: `${post.id}`,
                postName: `${
                    post.kind === "text"
                        ? post.description
                              .replace(/<[^>]+>/g, " ")
                              .replace(/\s\s+/g, " ")
                              .trim()
                              .split(/\s+/)
                              .slice(0, 10)
                              .join(" ")
                              .concat([" ..."].join(""))
                        : post.name
                }`,
                channelId: `${post.channel.id}`,
                channelName: `${post.channel.name}`,
            });

            this.setState({ trackedIds: [...trackedIds, `${post.channel.id}-${post.id}`] });
        }
    };

    getFeedItems = feed =>
        feed.map((post, index) => (
            <ReactVisibilitySensor
                key={`${index}-${post.id}`}
                onChange={isVisible => this.checkFeedVisible(isVisible, post)}
                partialVisibility="bottom"
            >
                <FeedItem item={post} />
            </ReactVisibilitySensor>
        ));

    render() {
        const { feed, hasMore, setFeedFilter, filter, toggleSearchBar, show } = this.props;
        const { onLoadMore } = this;

        return (
            <div css={[feedPage.body, show && feedPage.bodyIsSearch]}>
                <HeaderFeedMobile />
                {toggleSearchBar && <SearchField {...{ filter }} />}
                <FilterBar filters={show ? [] : feedFilters(setFeedFilter)} />
                {show ? (
                    <SearchResults />
                ) : (
                    <div>
                        <FeedPlaceholder isLoaded={Boolean(feed)}>
                            {feed && feed.length > 0 ? (
                                <Container width={592} onMobileNoPadding>
                                    <InfiniteScroll
                                        dataLength={feed.length}
                                        next={onLoadMore}
                                        {...{ hasMore }}
                                    >
                                        <div css={feedPage.infiniteScroll}>
                                            <div css={feedPage.inner}>
                                                {this.getFeedItems(feed)}
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                </Container>
                            ) : (
                                <Container width={592}>
                                    {
                                        <NoResults
                                            heading="No results!"
                                            message="Please try to follow some channels first ..."
                                        />
                                    }
                                </Container>
                            )}
                        </FeedPlaceholder>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    Feed: { feed, page, hasMore, filter },
    Search: { results, show, toggleSearchBar },
}) => ({
    feed,
    page,
    hasMore,
    filter,
    results,
    show,
    toggleSearchBar,
});

const mapDispatchToProps = {
    getFeed: actionCreators.getFeed.create,
    refreshFeed: actionCreators.refreshFeed.create,
    setFeedFilter: actionCreators.setFeedFilter.create,
    confirmEvent: eventActionCreators.confirmEvent.create,
    resetFeed: actionCreators.resetFeed.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedContainer);
