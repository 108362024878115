import actionCreators from "./actionCreators";

export const initialState = {
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };

        default:
            return state;
    }
};
