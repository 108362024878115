import ActionCreator from "../../actionCreator";

export default {
    getEvent: ActionCreator("GET_EVENT"),
    postEvent: ActionCreator("POST_EVENT"),
    confirmEvent: ActionCreator("CONFIRM_EVENT"),
    putEvent: ActionCreator("PUT_EVENT"),
    removeEvent: ActionCreator("REMOVE_EVENT"),
    cancelEvent: ActionCreator("CALENDAR_CANCEL_EVENT"),
    updateEvent: ActionCreator("UPDATE_EVENT"),
    searchInvitees: ActionCreator("SEARCH_INVITEES"),
    clearInvitees: ActionCreator("CLEAR_INVITEES"),
    updateInvitees: ActionCreator("UPDATE_INVITEES"),
    addInviteeToSelected: ActionCreator("ADD_INVITEE_TO_SELECTED"),
    removeInviteeFromSelected: ActionCreator("REMOVE_INVITEE_FROM_SELECTED"),
    setFilter: ActionCreator("SET_FILTER"),
    saveFormData: ActionCreator("SAVE_FORM_DATA"),
    setStateStatus: ActionCreator("SET_STATE_STATUS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CALENDAR"),
    clearEvent: ActionCreator("CLEAR_EVENT"),
    setOrigin: ActionCreator("SET_EVENT_ORIGIN"),
    loadInvitees: ActionCreator("LOAD_INVITEES"),
    putEventNotes: ActionCreator("PUT_EVENT_NOTES"),
    updateEventNotes: ActionCreator("UPDATE_EVENT_NOTES"),
    recalculateNewEventDate: ActionCreator("RECALCULATE_NEW_EVENT_DATE"),
};
