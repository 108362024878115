import React from "react";
import { RadioGroup } from "@material-ui/core";

export const RenderRadioGroup = ({
    input,
    meta: { touched, error },
    errorRes,
    children,
    ...rest
}) => (
    <RadioGroup {...input} {...rest} onChange={(event, value) => input.onChange(value)}>
        {children}
        {touched && (error || errorRes) && <span>{touched && (error || errorRes)}</span>}
    </RadioGroup>
);
