import { spacing } from "../../styles/Spacing";
import { fontWeight } from "../../styles/Typography";
import { colors } from "../../styles/Colors";

export const buttonText = {
    body: {
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        fontSize: 14,
        fontWeight: fontWeight.normal,
        color: colors.black87,
        textDecoration: "none",
    },
    text: {},
    textBold: {
        fontWeight: fontWeight.medium,
    },
    textUnderline: {
        textDecoration: "underline",
    },
    icon: {
        marginLeft: spacing.space1,
    },
};
