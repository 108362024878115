import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const campusMappingStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getLocations.type,
            actionCreators.getLocationDetails.type,
            actionCreators.getSearchResults.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const campusMappingClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateLocations.type,
            actionCreators.updateLocationDetails.type,
            actionCreators.updateSearchResults.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getLocationsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getLocations.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/campus-mapping/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateLocations.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getLocationDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getLocationDetails.type),
        mergeMap(({ payload: { id } }) =>
            ajax.get(apiUrl(`api/campus-mapping/${id}/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateLocationDetails.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getSearchResultsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSearchResults.type),
        mergeMap(({ payload: { filter } }) =>
            ajax.get(apiUrl(`api/campus-mapping/search/?filter=${filter}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateSearchResults.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    campusMappingStartLoadingEpic,
    campusMappingClearLoadingEpic,
    getLocationsEpic,
    getLocationDetailsEpic,
    getSearchResultsEpic
);
