import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../../common/components/UI/ModalBox";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../../common/components/UI/ButtonShape";
import { spacing } from "../../../common/styles/Spacing";
import ButtonText from "../../../common/components/UI/ButtonText";
import Icon from "../../../common/components/UI/Icon";
import { checkboxField } from "../../../common/components/FormFields/FormFieldsStyles";
import { colors } from "../../../common/styles/Colors";
import GTM from "../../../common/services/GTM";

class AskForRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = { tocDisabled: true, isModalOpen: true };
    }

    handleToggle = () => this.setState({ tocDisabled: !this.state.tocDisabled });

    handleClose = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { askForRegistration, push, classes } = this.props;
        return (
            askForRegistration && (
                <ModalBox open={this.state.isModalOpen} onClose={this.handleClose} modalWidth={400}>
                    <ModalBoxText>
                        <p css={{ color: "#2A6157" }}>
                            <strong>
                                It looks like you haven&apos;t registered to use the Student Hub app
                                yet.
                            </strong>
                        </p>
                        <p css={{ color: "#2A6157" }}>
                            Tap &apos;Get connected&apos; to register now.
                        </p>
                        <div
                            css={{
                                marginTop: spacing.space6,
                                marginBottom: spacing.space6,
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() => this.handleToggle()}
                                        classes={{
                                            checked: classes.checkboxChecked,
                                        }}
                                        css={checkboxField.checkbox}
                                        icon={
                                            <Icon
                                                name="checkbox"
                                                width="18"
                                                height="18"
                                                fill={colors.black12}
                                            />
                                        }
                                        checkedIcon={
                                            <Icon
                                                name="checkboxChecked"
                                                fill="transparent"
                                                width="18"
                                                height="18"
                                            />
                                        }
                                    />
                                }
                                label={
                                    <span css={checkboxField.checkboxLabel}>
                                        I accept the{" "}
                                        <a
                                            href={process.env.REACT_APP_TERMS_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            css={checkboxField.link}
                                            onClick={() => {
                                                GTM.dispatch({
                                                    event: "genericClick",
                                                    targetName: "Terms and conditions",
                                                });
                                                return true;
                                            }}
                                        >
                                            terms and conditions
                                        </a>
                                    </span>
                                }
                                css={checkboxField.body}
                            />
                        </div>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space6}>
                        <ButtonsGroup marginBottomValue={spacing.space3}>
                            <ButtonShape
                                primary
                                onClick={() => push("/register")}
                                disabled={this.state.tocDisabled}
                                fullWidth
                            >
                                Get Connected
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText bold onClick={this.handleClose} color={colors.black50}>
                                Cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
            )
        );
    }
}

export default withStyles(checkboxField)(AskForRegistration);
