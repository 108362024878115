import { spacing } from "../../common/styles/Spacing";
import { colors } from "../../common/styles/Colors";
import { fontSize, lineHeight, fontWeight } from "../../common/styles/Typography";

export const searchBar = {
    body: {
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.white,
        height: 56,
        paddingLeft: 300,
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 111,
        "@media (max-width: 1023px)": {
            backgroundColor: "transparent",
            paddingLeft: 0,
        },
    },
    bodyMap: {
        backgroundColor: "transparent",
        height: 0,
        top: 36,
        "@media (max-width: 1023px)": {
            justifyContent: "center",
            height: 56,
            top: 0,
        },
    },
    bodySupportMap: {
        backgroundColor: "transparent",
        height: 0,
        top: 28,
        "@media (max-width: 1023px)": {
            justifyContent: "center",
            height: 56,
            top: 0,
        },
    },
    bodyIsSearchActive: {
        backgroundColor: "#fafafa",
        "@media (max-width: 1023px)": {
            backgroundColor: "#fafafa",
        },
    },
    bodyIsSearchActiveSupportMap: {
        backgroundColor: "#fafafa",
        "@media (max-width: 1023px)": {
            backgroundColor: "transparent",
        },
    },
    inner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: colors.white,
        boxShadow: "0 0 2px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24)",
        borderRadius: 2,
        width: "100%",
        maxWidth: 560,
        height: 40,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        marginTop: 0,
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        "@media (max-width: 1023px)": {
            marginRight: spacing.space1,
            marginLeft: spacing.space1,
        },
    },
    elementLeft: {
        display: "flex",
        alignItems: "center",
        minWidth: 24,
    },
    elementCenter: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        paddingRight: spacing.space3,
        paddingLeft: spacing.space3,
    },
    elementRight: {
        display: "flex",
        alignItems: "center",
    },
    input: {
        color: colors.black87,
        fontSize: fontSize.text,
        border: "0 none",
        outline: "0 none",
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        width: "100%",
    },
    btn: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: 0,
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    btnBack: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: 0,
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: 300 + spacing.space2,
        transform: "translateY(-50%)",
        "@media (max-width: 1023px)": {
            position: "relative",
            left: "auto",
            transform: "none",
        },
    },
    btnBackMap: {
        top: "50%",
        left: 300 + spacing.space2,
        transform: "translateY(-50%)",
        "@media (max-width: 1023px)": {
            position: "relative",
            left: "auto",
            transform: "none",
        },
    },
    iconSearch: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    iconSearchMobile: {
        display: "none",
        "@media (max-width: 1023px)": {
            display: "block",
        },
    },
};

export const searchResults = {
    body: {
        maxWidth: 592,
        marginRight: "auto",
        marginLeft: "auto",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingRight: 0,
            paddingBottom: 48,
            paddingLeft: 0,
        },
    },
    recommendedHeading: {
        color: colors.black40,
        fontSize: fontSize.textMedium,
        lineHeight: lineHeight.textMedium,
        fontWeight: fontWeight.bold,
        paddingLeft: spacing.space2,
        marginBottom: spacing.space2,
        marginTop: spacing.space4,
    },
};
