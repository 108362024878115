import { spacing } from "../../styles/Spacing";
import { fontWeight } from "../../styles/Typography";

export const modal = {
    rootModal: {},
    paperModal: {
        // margin: spacing.space1,
    },
    paperScrollBodyModal: {
        margin: `${spacing.space1}px auto !important`,
    },
    inner: {
        paddingTop: spacing.space4,
        paddingBottom: spacing.space3,
        marginRight: "auto",
        marginLeft: "auto",
    },
    title: {
        fontSize: 24,
        fontWeight: fontWeight.bold,
        lineHeight: 1.16,
        letterSpacing: 0.4,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        marginBottom: spacing.space2,
    },
    subtitle: {
        fontSize: 20,
        fontWeight: fontWeight.bold,
        lineHeight: 1.16,
        letterSpacing: 0.4,
        marginBottom: spacing.space2,
    },
    text: {
        fontSize: 16,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        letterSpacing: "0.4px",
        lineHeight: 1.48,
    },
    actions: {
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    closeBtn: {
        padding: spacing.space0comma5,
        border: 0,
        outline: 0,
        margin: 0,
        background: "none",
        position: "absolute",
        top: spacing.space4,
        right: spacing.space2,
    },
};
