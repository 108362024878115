import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const myProgrammeStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStudentsList.type, actionCreators.getStudentProfile.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const myProgrammeClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateStudentsList.type,
            actionCreators.updateStudentProfile.type,
            actionCreators.errorResponse
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getStudentsListEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStudentsList.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/user/my-programme/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateStudentsList.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getStudentProfileEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getStudentProfile.type),
        mergeMap(({ payload: { playRef } }) =>
            ajax.get(apiUrl(`api/user/${playRef}/public-profile/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateStudentProfile.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    myProgrammeStartLoadingEpic,
    myProgrammeClearLoadingEpic,
    getStudentsListEpic,
    getStudentProfileEpic
);
