import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset, change } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../../store/Channel/Group/actionCreators";
import validate from "./validate";
import RenderTextFieldInline from "../../../../common/components/FormFields/TextFieldInline";
import { addGroupPage } from "../../../Links/LinksStyles";
import Avatar from "../../../../common/components/UI/Avatar";
import { imageForm } from "../../../Auth/AuthStyles";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";
import BaseFileInput from "../../../../common/components/FormFields/FileInputBase64";
import { Container } from "../../../../common/components/UI/Container";
import TextError from "../../../../common/components/UI/TextError";
import { textFieldInline } from "../../../../common/components/FormFields/FormFieldsStyles";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RenderRadioGroup } from "../../../../common/components/FormFields/RadioGroupField";
import FormPlaceholder from "../../../../common/components/Skeletons/FormPlaceholder";
import { channelCover } from "../../ChannelStyles";
import * as config from "../values";
import { formContainer } from "../../../../common/styles/Globals";

class GroupForm extends Component {
    state = {
        customPhotoUploaded: false,
        isModalOpen: false,
        isEdited: false,
        iconFile: null,
        imageFile: null,
    };

    changePhoto = (key, photoUrl) => {
        this.setState({ [key]: photoUrl });
    };

    handleInput = key => {
        this.props.clearValidateError();
        Array.from(document.querySelectorAll("input[type=file]"))[key].click();
    };

    componentDidMount() {
        this.props.resetForm();
        this.props.clearValidateError();
    }

    componentWillUnmount() {
        this.props.clearValidateError();
    }

    componentDidUpdate({ formFields }) {
        if (
            (formFields.iconFile &&
                formFields.iconFile.name !== this.props.formFields.iconFile.name) ||
            (!formFields.iconFile && this.props.formFields.iconFile)
        ) {
            this.changePhoto("iconFile", this.props.formFields.iconFile.base64);
        }
        if (
            (formFields.imageFile &&
                formFields.imageFile.name !== this.props.formFields.imageFile.name) ||
            (!formFields.imageFile && this.props.formFields.imageFile)
        ) {
            this.changePhoto("imageFile", this.props.formFields.imageFile.base64);
        }

        if (formFields.groupType && formFields.groupType !== this.props.formFields.groupType) {
            if (
                this.props.formFields.groupType !== config.GROUP_TYPE_OPEN &&
                !this.props.formFields.groupManagedBy
            ) {
                this.props.dispatch(
                    change(this.props.form, "groupManagedBy", config.MANAGED_BY_OWNERS)
                );
            } else if (this.props.formFields.groupType === config.GROUP_TYPE_OPEN) {
                this.props.dispatch(change(this.props.form, "groupManagedBy", null));
            }
        }
    }
    handleChange = event => {
        this.setState({ selectedValue: event.target.value });
    };
    render() {
        const {
            form,
            formFields,
            handleSubmit,
            onSubmit,
            initialValues,
            errorRes: { errors },
        } = this.props;
        const imageAvatar = initialValues.iconUrl;
        const imageCover = initialValues.imageUrl;

        const { handleInput } = this;
        const { iconFile, imageFile } = this.state;
        return (
            <Container onMobileNoPadding width={592}>
                <FormPlaceholder isLoaded={Boolean(form === "addGroup" || initialValues.name)}>
                    <div css={addGroupPage.form}>
                        <form
                            onSubmit={() => {
                                this.setState({ isEdited: false });
                                this.setState({ customPhotoUploaded: false });
                                return handleSubmit(onSubmit);
                            }}
                        >
                            <div css={[formContainer.section, formContainer.sectionBorder]}>
                                <div css={formContainer.inner}>
                                    {errors.name && (
                                        <TextError
                                            css={[textFieldInline.error, { paddingBottom: 16 }]}
                                        >
                                            {errors.name}
                                        </TextError>
                                    )}
                                    <Field
                                        name="name"
                                        placeholder="Group name"
                                        component={RenderTextFieldInline}
                                        darkPlaceholder
                                    />
                                </div>
                            </div>
                            <div css={[formContainer.section, formContainer.sectionBorder]}>
                                <div css={formContainer.inner}>
                                    {errors.description && (
                                        <TextError
                                            css={[textFieldInline.error, { paddingBottom: 16 }]}
                                        >
                                            {errors.description}
                                        </TextError>
                                    )}
                                    <Field
                                        name="description"
                                        placeholder="Add a description"
                                        component={RenderTextFieldInline}
                                        multiline
                                        darkPlaceholder
                                    />
                                </div>
                            </div>
                            <div
                                css={[
                                    formContainer.section,
                                    formContainer.sectionBig,
                                    formContainer.sectionBorder,
                                    formContainer.sectionColumn,
                                ]}
                            >
                                <div css={formContainer.label}>
                                    <div css={formContainer.labelText}>Select group type:</div>
                                </div>
                                {errors.groupType && (
                                    <TextError css={[textFieldInline.error, { paddingBottom: 16 }]}>
                                        {errors.groupType}
                                    </TextError>
                                )}
                                <Field name="groupType" component={RenderRadioGroup}>
                                    <FormControlLabel
                                        value={config.GROUP_TYPE_OPEN}
                                        label={
                                            <span css={formContainer.selectionLabel}>
                                                <strong css={formContainer.selectionLabelTitle}>
                                                    Open group
                                                </strong>
                                                <span css={formContainer.selectionLabelText}>
                                                    Default group type. <br />
                                                    Anyone can find the group, and see its content.
                                                </span>
                                            </span>
                                        }
                                        control={<Radio />}
                                        css={formContainer.selectionItem}
                                    />
                                    <FormControlLabel
                                        value={config.GROUP_TYPE_CLOSED}
                                        label={
                                            <span css={formContainer.selectionLabel}>
                                                <strong css={formContainer.selectionLabelTitle}>
                                                    Closed group
                                                </strong>
                                                <span css={formContainer.selectionLabelText}>
                                                    Anyone can find the group. <br />
                                                    Users need to be invited or request access
                                                    before they become members and can see content.
                                                </span>
                                            </span>
                                        }
                                        control={<Radio />}
                                        css={formContainer.selectionItem}
                                    />
                                    <FormControlLabel
                                        value={config.GROUP_TYPE_PRIVATE}
                                        label={
                                            <span>
                                                <strong css={formContainer.selectionLabelTitle}>
                                                    Private group
                                                </strong>
                                                <span css={formContainer.selectionLabelText}>
                                                    Only members can find the group. <br />
                                                    Owners or members can invite users to join and
                                                    become members.
                                                </span>
                                            </span>
                                        }
                                        control={<Radio />}
                                        css={formContainer.selectionItemNoMargin}
                                    />
                                </Field>
                            </div>
                            {formFields.groupType && formFields.groupType !== "open" && (
                                <div
                                    css={[
                                        formContainer.section,
                                        formContainer.sectionBig,
                                        formContainer.sectionBorder,
                                        formContainer.sectionColumn,
                                    ]}
                                >
                                    <div css={formContainer.label}>
                                        <div css={formContainer.labelText}>
                                            {formFields.groupType === config.GROUP_TYPE_PRIVATE && (
                                                <div>Who can invite members:</div>
                                            )}
                                            {formFields.groupType === config.GROUP_TYPE_CLOSED && (
                                                <div>Who can accept or invite new members:</div>
                                            )}
                                        </div>
                                    </div>
                                    {errors.groupManagedBy && (
                                        <TextError
                                            css={[textFieldInline.error, { paddingBottom: 16 }]}
                                        >
                                            {errors.groupManagedBy}
                                        </TextError>
                                    )}
                                    <Field name="groupManagedBy" component={RenderRadioGroup}>
                                        <FormControlLabel
                                            value={config.MANAGED_BY_OWNERS}
                                            label={
                                                <strong css={formContainer.selectionLabelTitle}>
                                                    Owners
                                                </strong>
                                            }
                                            control={<Radio />}
                                            css={formContainer.selectionItemNoMargin}
                                        />
                                        <FormControlLabel
                                            value={config.MANAGED_BY_MEMBERS}
                                            label={
                                                <strong css={formContainer.selectionLabelTitle}>
                                                    All members
                                                </strong>
                                            }
                                            control={<Radio />}
                                            css={formContainer.selectionItemNoMargin}
                                        />
                                    </Field>
                                </div>
                            )}
                            <div css={formContainer.sectionUpload}>
                                <div css={formContainer.label}>
                                    <div css={formContainer.labelText}>Profile picture</div>
                                    {errors.iconFile && (
                                        <TextError css={textFieldInline.error}>
                                            {errors.iconFile}
                                        </TextError>
                                    )}
                                </div>
                                <div>
                                    <div
                                        css={{
                                            display: "none",
                                        }}
                                    >
                                        <Field name="iconFile" component={BaseFileInput} />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleInput(0)}
                                        css={imageForm.photoUpload}
                                    >
                                        {!formFields.iconFile && imageAvatar ? (
                                            <div
                                                css={[
                                                    imageForm.userPhoto,
                                                    { backgroundImage: `url(${imageAvatar})` },
                                                ]}
                                            />
                                        ) : (
                                            <Avatar src={formFields.iconFile && iconFile} huge />
                                        )}
                                        <span css={imageForm.icoEdit}>
                                            <Icon
                                                name="edit"
                                                fill={colors.black}
                                                width={17}
                                                height={17}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div css={formContainer.sectionUpload}>
                                <div css={formContainer.label}>
                                    <div css={formContainer.labelText}>Cover picture</div>
                                    {errors.imageFile && (
                                        <TextError css={textFieldInline.error}>
                                            {errors.imageFile}
                                        </TextError>
                                    )}
                                </div>
                                <div>
                                    <div
                                        css={{
                                            display: "none",
                                        }}
                                    >
                                        <Field
                                            name="imageFile"
                                            imageAvatar
                                            component={BaseFileInput}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleInput(1)}
                                        css={imageForm.photoBgUpload}
                                    >
                                        <span css={imageForm.icoBgEdit}>
                                            <Icon
                                                name="edit"
                                                fill={colors.black}
                                                width={17}
                                                height={17}
                                            />
                                        </span>
                                        {!formFields.imageFile && imageCover ? (
                                            <div
                                                css={[
                                                    imageForm.photoBgUploadImg,
                                                    {
                                                        backgroundImage: `url(${imageCover})`,
                                                    },
                                                ]}
                                            >
                                                <div
                                                    css={[
                                                        channelCover.shape,
                                                        channelCover.shapeImg,
                                                    ]}
                                                />
                                                <div css={channelCover.gradient} />
                                            </div>
                                        ) : (
                                            <div
                                                css={[
                                                    imageForm.photoBgUploadImg,
                                                    formFields.imageFile && {
                                                        backgroundImage: `url(${imageFile})`,
                                                    },
                                                ]}
                                            >
                                                <div
                                                    css={[
                                                        channelCover.shape,
                                                        channelCover.shapeImg,
                                                    ]}
                                                />
                                                <div css={channelCover.gradient} />
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </FormPlaceholder>
            </Container>
        );
    }
}

const mapStateToProps = ({ form }, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
        formFields: selector(
            { form },
            "name",
            "description",
            "iconFile",
            "imageFile",
            "groupManagedBy",
            "groupType"
        ),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    resetForm: () => reset(ownProps.form),
    clearValidateError: () => dispatch(actionCreators.clearValidateError.create()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        enableReinitialize: true,
        validate,
    })(GroupForm)
);
