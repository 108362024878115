import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";

export const container = {
    body: {
        maxWidth: 992,
        width: "100%",
        marginTop: 0,
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        paddingTop: 0,
        paddingRight: spacing.space2,
        paddingBottom: 0,
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingRight: spacing.space1,
            paddingLeft: spacing.space1,
        },
    },
    bodyNoPadding: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        "@media (max-width: 1023px)": {
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    onMobileNoPadding: {
        "@media (max-width: 1023px)": {
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
};

export const containerHeader = {
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "relative",
    },
    inner: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#00565F",

        paddingTop: spacing.space7,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
        zIndex: 10,
    },
    innerContainer: {
        maxWidth: 320,
        width: "100%",
        margin: "0 auto",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    justifyBottom: {
        justifyContent: "flex-end",
    },
    shapeTop: {
        pointerEvents: "none",
        width: "100%",
        height: 84,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 15,
    },
    shapeTopRectangle: {
        backgroundColor: "rgba(0,54,60,0.5)",
        width: "100%",
        height: 8,
    },
    shapeBottom: {
        width: "100%",
        height: 30,
        zIndex: 5,
    },
};

export const containerActions = {
    body: {
        position: "relative",
    },
    inner: {
        paddingBottom: "calc(16px + env(safe-area-inset-bottom))",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 1023px)": {
            backgroundColor: colors.white,
            paddingTop: spacing.space2,
        },
    },
    innerContainer: {
        maxWidth: 352,
        margin: "0 auto",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    shapeTop: {
        width: "100%",
        height: 18,
        display: "none",
        "@media (max-width: 1023px)": {
            display: "block",
        },
    },
};
