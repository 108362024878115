import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "../../services/history";
import styles from "../FilterBar/FilterBarStyles";
import { Container } from "../UI/Container";
import HeaderBackButton from "./HeaderBackButton";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

class HeaderSupportMap extends Component {
    render() {
        const {
            title,
            hideLeftElement,
            isSearchActive,
            customBackAction,
            toggleSearch,
        } = this.props;

        return (
            <div css={[styles.body, styles.bodySupportMap]}>
                {!hideLeftElement && !isSearchActive && (
                    <div css={styles.btnAbsoluteLeft}>
                        <HeaderBackButton
                            icon="arrowLeft"
                            iconSize={18}
                            href={customBackAction ? customBackAction : history.goBack}
                        />
                    </div>
                )}
                <Container width={592} noPadding>
                    <div css={styles.bodySpaceBetween}>
                        <div css={styles.bodyCenter}>
                            <div css={styles.title}>{title}</div>
                        </div>
                        <div css={styles.bodyRight}>
                            {!isSearchActive && (
                                <button
                                    type="button"
                                    css={styles.btnSearch}
                                    onClick={() => toggleSearch()}
                                >
                                    <Icon
                                        name="search"
                                        width={18}
                                        height={18}
                                        fill={colors.black87}
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default HeaderSupportMap;
