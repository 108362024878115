import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { headerMobile, headerTextButton } from "./HeaderStyles";
import HeaderBackButton from "./HeaderBackButton";
import { colors } from "../../styles/Colors";
import history from "../../services/history";
import { connect } from "react-redux";
import { submit } from "redux-form";

class HeaderGroupsMobile extends Component {
    render() {
        const { title, submitForm, isLoading, formName } = this.props;

        return (
            <div css={headerMobile.body}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        {history.location.pathname.includes("/add-new-group") && (
                            <HeaderBackButton
                                icon="times"
                                iconColor={colors.white}
                                onClick={() => history.goBack()}
                            />
                        )}
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>{title}</div>
                        </div>
                    </div>
                    <div css={headerMobile.elementRight}>
                        {history.location.pathname.includes("/add-new-group") && (
                            <button
                                type="submit"
                                onClick={() => submitForm(formName)}
                                css={[
                                    headerTextButton.bodyMobile,
                                    isLoading && headerTextButton.bodyMobileIsLoading,
                                ]}
                                disabled={isLoading}
                            >
                                SAVE
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ MainView: { isLoading } }) => ({
    isLoading,
});

const mapDispatchToProps = {
    submitForm: submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGroupsMobile);
