import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { styles } from "./styles";

class NoResults extends Component {
    render() {
        const { heading, message, onMobileMargin, cardNoMarginTop, children, style } = this.props;
        return (
            <div
                css={[
                    styles.card,
                    onMobileMargin && styles.cardOnMobileMargin,
                    cardNoMarginTop && styles.cardNoMarginTop,
                    style,
                ]}
            >
                <div css={styles.inner}>
                    <div css={styles.heading}>{heading}</div>
                    <div css={styles.message}>{message}</div>
                    {children && children}
                </div>
                <div css={styles.bottomShape}>
                    <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                        <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                    </svg>
                </div>
            </div>
        );
    }
}

export default NoResults;
