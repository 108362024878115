import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import actionCreators from "./actionCreators";
import { apiUrl } from "../../common/services/utils";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";
import { default as UIActionCreators } from "../MainView/actionCreators";

const feedbackStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.displayFeedbackForm.type,
            actionCreators.sendFeedback.type,
            actionCreators.getSubjects.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const feedbackClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateFeedback.type,
            actionCreators.errorResponse.type,
            actionCreators.updateSubjects.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const sendFeedbackEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.sendFeedback.type),
        mergeMap(action =>
            ajax.post(apiUrl(`all/frontend/report-issue/`), action.payload).pipe(
                map(res => res.response),
                mergeMap(() => of(actionCreators.updateFeedback.create(action.payload))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getSubjectsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.displayFeedbackForm.type),
        mergeMap(() =>
            ajax.get(apiUrl(`all/frontend/request-types/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateSubjects.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    sendFeedbackEpic,
    feedbackStartLoadingEpic,
    feedbackClearLoadingEpic,
    getSubjectsEpic
);
