import { validateRequiredFields } from "../../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        username: "Required",
        password: "Required",
    };
    const errors = validateRequiredFields(requiredFields, values);

    return errors;
};
