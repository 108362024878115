import { fontWeight, fontFamily } from "../../styles/Typography";
import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";
import headingBackground from "../../images/header.svg";
import { defaultBorder } from "../../styles/Globals";

export const mainMenu = {
    body: {
        backgroundColor: "#106C76",
        backgroundImage: "linear-gradient(0deg, #106C76 4%, #00565F 75%)",
        width: 300,
        height: "100vh",
        display: "flex",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 112,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    mobileVisible: {
        backgroundColor: colors.white,
        backgroundImage: "none",
        display: "flex",
        zIndex: 150,
        width: "100vw",
        "@media (max-width: 1023px)": {
            display: "flex",
        },
    },
    inner: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "auto",
    },
    section: {
        flex: "0 0 auto",
        // backgroundColor: colors.white,
        paddingTop: spacing.space1,
        paddingBottom: spacing.space1 + spacing.space0,
        borderBottomWidth: 1,
        borderBottomColor: "rgb(52, 204, 184, .3)",
        borderBottomStyle: "solid",
        "@media (max-width: 1023px)": {
            borderBottomColor: colors.lightGrey10,
        },
    },
    logout: {
        "@media (max-width: 1023px)": {
            paddingBottom: spacing.space9,
        },
    },
    sectionHideOnMobile: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    sectionHeading: {
        backgroundColor: "transparent",
        border: "0 none",
        display: "block",
        width: "100%",
        textAlign: "left",
        margin: 0,
        color: colors.white,
        fontSize: 14,
        fontWeight: fontWeight.medium,
        padding: spacing.space2,
        "@media (max-width: 1023px)": {
            color: "#767676",
        },
        "&:first-letter": {
            textTransform: "uppercase",
        },
    },
    sectionHeadingIcon: {
        position: "relative",
        "&:after": {
            content: "'▶'",
            color: colors.white50,
            fontSize: 10,
            position: "absolute",
            top: "50%",
            right: 20,
            transform: "translateY(-50%)",
        },
        "@media (max-width: 1023px)": {
            "&:after": {
                color: "#767676",
            },
        },
    },
    sectionHeadingIconActive: {
        "&:after": {
            content: "'▼'",
        },
        "@media (max-width: 1023px)": {
            "&:after": {
                color: "#767676",
            },
        },
    },
    sectionHeadingBig: {
        fontSize: 24,
        fontWeight: fontWeight.black,
    },
    subSection: {
        // paddingLeft: 44,
        // paddingBottom: spacing.space2,
        "@media (max-width: 1023px)": {
            // backgroundColor: colors.black2,
        },
    },
};

export const mainMenuHeader = {
    body: {
        display: "flex",
        alignItems: "flex-start",
        gap: 16,
        backgroundImage: `url(${headingBackground})`,
        backgroundPosition: "0 100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundColor: "#00565F",
        paddingTop: spacing.space4 + 56,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space4,
        paddingLeft: spacing.space2,
        position: "relative",
        "@media (max-width: 1023px)": {
            // height: 220,
            // backgroundImage: `url(${headingBackgroundMobile})`,
        },
    },
    logo: {
        backgroundColor: "#26A99A",
        display: "flex",
        alignItems: "center",
        paddingLeft: spacing.space2,
        paddingRight: spacing.space2,
        gap: 8,
        height: 56,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 10,
        "& p": {
            color: "#fff",
            fontWeight: fontWeight.black,
        },
    },
    logoShape: {
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "6px 300px 0 0",
        borderColor: "#26A99A transparent transparent transparent",
        position: "absolute",
        top: "100%",
        left: 0,
        pointerEvents: "none",
    },
    avatar: {
        width: 80,
        height: 80,
    },
    right: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minWidth: 1,
    },
    userName: {
        color: colors.white,
        fontSize: 16,
        fontWeight: fontWeight.bold,
        marginBottom: spacing.space0comma5,
    },
    userEmail: {
        color: colors.white,
        fontFamily: fontFamily.primary,
        fontSize: 12,
        fontStyle: "italic",
        marginBottom: spacing.space2,
    },
    ellipsis: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    profileBtn: {
        backgroundColor: colors.white20,
        borderRadius: 4,
        margin: 0,
        padding: 0,
        border: 0,
        outline: 0,
        height: 32,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        fontSize: 16,
        fontFamily: fontFamily.primary,
        fontWeight: fontWeight.bold,
        color: colors.white,
    },
};

export const mainMenuButton = {
    body: {
        margin: 0,
        backgroundColor: "transparent",
        border: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        // backgroundColor: colors.white,
        textDecoration: "none",
        paddingTop: 10,
        paddingRight: spacing.space2,
        paddingBottom: 10,
        paddingLeft: spacing.space2,
        cursor: "pointer",
        color: "#5DF5E1",
        fontWeight: fontWeight.light,
        fontSize: 14,
        "@media (max-width: 1023px)": {
            color: colors.black87,
            fontWeight: fontWeight.medium,
        },
        ":hover": {
            // backgroundColor: colors.black2,
        },
        "&:first-letter": {
            textTransform: "uppercase",
        },
    },
    bodySub: {
        backgroundColor: "transparent",
        border: "0 none",
        width: "100%",
        textAlign: "left",
        margin: 0,
        display: "flex",
        alignItems: "center",
        // backgroundColor: colors.white,
        textDecoration: "none",
        paddingTop: 10,
        paddingRight: spacing.space2,
        paddingBottom: 10,
        paddingLeft: spacing.space2,
        cursor: "pointer",
        color: "#ffffff",
        fontWeight: fontWeight.light,
        fontSize: 14,
        "@media (max-width: 1023px)": {
            color: colors.black38,
            fontWeight: fontWeight.medium,
        },
        ":hover": {
            // backgroundColor: colors.black2,
        },
        "span:first-letter": {
            textTransform: "uppercase",
        },
    },
    bodyTabbar: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        cursor: "pointer",
    },
    bodyIcon: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transaprent",
        paddingTop: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        cursor: "pointer",
    },
    icon: {
        display: "flex",
        width: 24,
        height: 24,
        alignItems: "center",
        justifyContent: "center",
        marginRight: spacing.space2 + spacing.space0,
        cursor: "pointer",
        svg: {
            fill: "#5DF5E1",
        },
        "@media (max-width: 1023px)": {
            svg: {
                fill: colors.black87,
            },
        },
    },
    iconBig: {
        display: "flex",
        width: 26,
        height: 26,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "#5DF5E1",
        "@media (max-width: 1023px)": {
            color: colors.black87,
        },
    },
    text: {
        // color: "#5DF5E1",
        fontFamily: fontFamily.primary,
        fontSize: 14,
        lineHeight: 1.125,
        "&:first-letter": {
            textTransform: "uppercase",
        },
    },
    textTabbar: {
        fontFamily: fontFamily.primary,
        fontWeight: 400,
        fontSize: 11,
        lineHeight: 1.125,
        marginTop: 4,
    },
    isActive: {
        color: colors.white,
        fill: colors.white,
        svg: {
            fill: colors.white,
        },
        "@media (max-width: 1023px)": {
            color: colors.accentA,
            svg: {
                fill: colors.accentA,
            },
        },
    },
    isLogoutButton: {
        color: "#FFD3E3",
        svg: {
            fill: "#FFD3E3",
        },
        "@media (max-width: 1023px)": {
            color: colors.accentA,
            svg: {
                fill: colors.accentA,
            },
        },
    },
    isSubSubMenuButton: {
        color: "#fff",
        paddingLeft: spacing.space1 + 1,
        svg: {
            fill: "#fff",
        },
        "@media (max-width: 1023px)": {
            color: colors.black50,
            svg: {
                fill: colors.black50,
            },
        },
    },
    badge: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 14,
        height: 14,
        borderRadius: 7,
        paddingRight: 4,
        paddingLeft: 4,
        backgroundColor: colors.red,
        color: colors.white,
        fontFamily: fontFamily.primary,
        fontSize: 9,
        fontWeight: fontWeight.bold,
        marginLeft: spacing.space1comma5,
    },
};

export const mainMenuTabBar = {
    body: {
        display: "none",
        alignItems: "stretch",
        height: "calc(54px + env(safe-area-inset-bottom))",
        backgroundColor: colors.greyLight95,
        borderTop: defaultBorder,
        borderTopColor: colors.black10,
        position: "fixed",
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 200,
        "@media (max-width: 1023px)": {
            display: "flex",
        },
    },
    hideOnMobile: {
        "@media (max-width: 1023px)": {
            bottom: "-100%",
        },
    },
    inner: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
};
