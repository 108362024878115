import React, { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const HtmlToReact = require("html-to-react");
const HtmlToReactParser = require("html-to-react").Parser;

class HTML extends Component {
    render() {
        const { html, style, onLinkClick } = this.props;

        const htmlToReactParser = new HtmlToReactParser();
        const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
        const reactElement = htmlToReactParser.parseWithInstructions(html, () => true, [
            {
                replaceChildren: false,
                shouldProcessNode: node => node.name === "a",
                processNode: (node, children, idx) => {
                    const { href, ...props } = node.attribs;
                    const isExternal = !/^\/[\w-]/.test(href);
                    return (
                        <a
                            href={href}
                            onClick={() => {
                                if (onLinkClick) onLinkClick(href);
                            }}
                            key={idx}
                            rel={isExternal ? "noopener" : undefined}
                            target={isExternal ? "_blank" : undefined}
                            {...props}
                        >
                            {children}
                        </a>
                    );
                },
            },
            {
                shouldProcessNode: () => true,
                processNode: processNodeDefinitions.processDefaultNode,
            },
        ]);

        return <div css={style}>{reactElement}</div>;
    }
}

export default HTML;
