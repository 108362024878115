import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import ContainerImg from "../../common/components/UI/ContainerImg";
import ButtonShape from "../../common/components/UI/ButtonShape";
import { spacing } from "../../common/styles/Spacing";
import ButtonText from "../../common/components/UI/ButtonText";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import { welcomeStyle } from "./DashboardStyles";
import { ContainerHeader, ContainerActions } from "../../common/components/UI/Container";
import AuthContextMenu from "../Auth/AuthContextMenu";
import { auth } from "../Auth/AuthStyles";
import GTM from "../../common/services/GTM";
import authActions from "../../store/Auth/actionCreators";

class DashboardContainer extends Component {
    autoLogin = () => {
        const { isAuthenticated, history } = this.props;
        if (history && isAuthenticated) {
            history.push("/feed");
        }
    };

    componentDidMount() {
        this.autoLogin();
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/welcome`,
        });
    }

    componentDidUpdate() {
        this.autoLogin();
    }

    render() {
        return (
            <ContainerImg fullWidth>
                <ContainerHeader height={208} justifyBottom>
                    <div css={welcomeStyle.btnMore}>
                        <AuthContextMenu />
                    </div>
                    <div css={welcomeStyle.subtitle}>Welcome to the</div>
                    <div css={welcomeStyle.title}>Student Hub</div>
                    <div css={welcomeStyle.text}>
                        The best way to communicate with <br />
                        and connect to the LSE community
                    </div>
                </ContainerHeader>
                <ContainerActions>
                    <ButtonsGroup
                        marginTopValue={40}
                        marginTopValueMobile={1}
                        marginBottomValue={spacing.space3}
                        marginBottomValueMobile={spacing.space1comma5}
                    >
                        <ButtonShape to="/register" fullWidth>
                            Register
                        </ButtonShape>
                    </ButtonsGroup>
                    <ButtonsGroup
                        marginBottomValue={spacing.space6}
                        marginBottomValueMobile={spacing.space1}
                    >
                        <ButtonText to="/login" bold textStyle={auth.actionLinkBold}>
                            Already registered? Log in
                        </ButtonText>
                    </ButtonsGroup>
                    <ButtonsGroup>
                        <ButtonText
                            target="_blank"
                            rel="noopener noreferrer"
                            icon="link"
                            iconSize={9}
                            textStyle={auth.actionLink}
                            onClick={() => {
                                this.props.displayResetPasswordModal();
                                GTM.dispatch({
                                    event: "genericClick",
                                    targetName: "Forgotten password",
                                });
                                return true;
                            }}
                        >
                            Forgotten username or password?
                        </ButtonText>
                    </ButtonsGroup>
                </ContainerActions>
            </ContainerImg>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
});

const dispatchToProps = {
    displayResetPasswordModal: authActions.displayResetPasswordModal.create,
};

export default connect(mapStateToProps, dispatchToProps)(DashboardContainer);
