import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import userActionCreators from "../../store/User/actionCreators";
import { colors } from "../../common/styles/Colors";
import Avatar from "../../common/components/UI/Avatar";
import Icon from "../../common/components/UI/Icon";
import RenderTextField from "../../common/components/FormFields/TextField";
import BaseFileInput from "../../common/components/FormFields/FileInputBase64";
import { Container } from "../../common/components/UI/Container";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../common/components/UI/ButtonShape";
import ButtonText from "../../common/components/UI/ButtonText";
import { imageForm } from "../Auth/AuthStyles";
import { spacing } from "../../common/styles/Spacing";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../common/components/UI/ModalBox";
import { user } from "./UserStyles";
import GTM from "../../common/services/GTM";
import TextError from "../../common/components/UI/TextError";
import validate from "./validate";

class Profile extends Component {
    state = {
        customPhotoUploaded: false,
        isModalOpen: false,
        canEditLastname: true,
        isEdited: false,
        photoUrl: null,
        savedSuccessfully: false,
    };

    componentDidMount() {
        if (this.props.lastName) {
            this.setState({ canEditLastname: false });
        }

        const { photoUrl } = this.props;
        this.setState({ photoUrl });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/profile`,
        });
    }
    changePhoto = photoUrl => {
        this.setState({ photoUrl });
    };

    onSubmit = values => {
        const { postProfilePhoto } = this.props;
        this.setState({ isEdited: false, customPhotoUploaded: false, savedSuccessfully: true });
        postProfilePhoto(values);
    };

    confirmRemovePhoto = () => {
        this.props.formUpdate("profile", "profilePhoto", null);
        this.setState({ customPhotoUploaded: false });
        this.setState({ isModalOpen: false });
        this.setState({ photoUrl: this.props.photoUrl });
    };

    handleInput = () => {
        document.querySelector("input[type=file]").click();
    };

    changePhoto = photoUrl => {
        if (photoUrl) {
            this.setState({ photoUrl });
            this.setState({ customPhotoUploaded: true });
            this.setState({ isEdited: true });
        }
    };

    componentDidUpdate({ profile }) {
        if (
            profile !== this.props.profile &&
            !this.props.profile.fields &&
            this.props.profile.values.profilePhoto
        ) {
            this.changePhoto(this.props.profile.values.profilePhoto.base64);
        }
    }

    handleClose = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { onSubmit, handleInput, confirmRemovePhoto, handleClose } = this;

        const {
            firstName,
            lastName,
            firstNameLocked,
            lastNameLocked,
            emailLocked,
            handleSubmit,
            errorRes: { errors },
            courses,
        } = this.props;

        const {
            canEditLastname,
            isModalOpen,
            customPhotoUploaded,
            isEdited,
            photoUrl,
            savedSuccessfully,
        } = this.state;

        return (
            <Container paddingTopValue={spacing.space5} width={480}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div css={user.hiddenInput}>
                        <Field name="profilePhoto" component={BaseFileInput} />
                    </div>
                    <button type="button" onClick={handleInput} css={imageForm.photoUpload}>
                        <span css={imageForm.icoEdit}>
                            <Icon name="edit" fill={colors.black} width={17} height={17} />
                        </span>
                        {photoUrl ? (
                            <div
                                css={[imageForm.userPhoto, { backgroundImage: `url(${photoUrl})` }]}
                            />
                        ) : (
                            <Avatar
                                uri={photoUrl}
                                initials={firstName.charAt(0) + lastName.charAt(0)}
                                huge
                            />
                        )}
                    </button>

                    <div css={user.inputFields}>
                        <Field
                            label="First name"
                            name="firstName"
                            component={RenderTextField}
                            disabled={firstNameLocked}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.firstName && (
                            <div css={user.fieldError}>
                                <TextError>{errors.firstName}</TextError>
                            </div>
                        )}

                        <Field
                            label="Last name"
                            name="lastName"
                            component={RenderTextField}
                            disabled={lastNameLocked || !canEditLastname}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.lastName && (
                            <div css={user.fieldError}>
                                <TextError>{errors.lastName}</TextError>
                            </div>
                        )}

                        {courses && courses.length > 0 && (
                            <div>
                                <p css={user.programme}>{courses[0].programme}</p>
                                <p css={user.department}>{courses[0].department}</p>
                            </div>
                        )}

                        <hr css={user.divider} />

                        <strong css={user.subtitle}>Settings</strong>
                        <p css={user.textMainAccount}>
                            Student Hub will send emails to your LSE email address unless you enter
                            a different preferred email address below
                        </p>
                        <Field
                            name="email"
                            component={RenderTextField}
                            label="Preferred email adress"
                            disabled={emailLocked}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.email && (
                            <div css={user.fieldError}>
                                <TextError>{errors.email}</TextError>
                            </div>
                        )}
                        <hr css={user.divider} />
                        <strong css={user.subtitle}>Optional information</strong>
                        <p css={user.textMainAccount}>
                            The following will be visible to other students in your programme in the
                            directory listing. You can update or remove your information at any
                            time.
                        </p>

                        <Field
                            label="About me"
                            name="aboutMe"
                            component={RenderTextField}
                            multiline
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.aboutMe && (
                            <div css={user.fieldError}>
                                <TextError>{errors.aboutMe}</TextError>
                            </div>
                        )}

                        <Field
                            label="WhatsApp / Phone number"
                            name="whatsapp"
                            component={RenderTextField}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.whatsapp && (
                            <div css={user.fieldError}>
                                <TextError>{errors.whatsapp}</TextError>
                            </div>
                        )}
                        <Field
                            label="Instagram"
                            name="instagram"
                            prefix="instagram.com/"
                            component={RenderTextField}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.instagram && (
                            <div css={user.fieldError}>
                                <TextError>{errors.instagram}aaa</TextError>
                            </div>
                        )}

                        <Field
                            label="LinkedIn"
                            name="linkedin"
                            prefix="linkedin.com/in/"
                            component={RenderTextField}
                            onChange={() => {
                                this.setState({ isEdited: true, savedSuccessfully: false });
                            }}
                        />
                        {errors && errors.linkedin && (
                            <div css={user.fieldError}>
                                <TextError>{errors.linkedin}</TextError>
                            </div>
                        )}
                    </div>
                    <ButtonsGroup
                        marginBottomValue={customPhotoUploaded ? spacing.space3 : spacing.space7}
                        paddingTopValue={spacing.space3}
                    >
                        <ButtonShape
                            primary={!savedSuccessfully}
                            type="submit"
                            fullWidth
                            disabled={!isEdited && !savedSuccessfully}
                        >
                            {savedSuccessfully ? "Saved!" : "Save"}
                        </ButtonShape>
                    </ButtonsGroup>
                    {customPhotoUploaded && (
                        <ButtonsGroup marginBottomValue={spacing.space7}>
                            <ButtonText onClick={() => this.setState({ isModalOpen: true })} bold>
                                Remove photo
                            </ButtonText>
                        </ButtonsGroup>
                    )}
                </form>
                <ModalBox open={isModalOpen} onClose={handleClose} modalWidth={310}>
                    <ModalBoxText>
                        <p>
                            <strong>Remove your photo</strong>
                        </p>
                        <p>Are you sure that you want to remove your profile photo?</p>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space6}>
                        <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                            <ButtonShape primary onClick={() => confirmRemovePhoto()} fullWidth>
                                Yes, remove my profile photo
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText bold onClick={handleClose}>
                                Cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    postProfilePhoto: userActionCreators.postProfilePhoto.create,
    formUpdate: change,
};

const mapStateToProps = ({
    Auth: {
        user: {
            firstName,
            lastName,
            email,
            photoUrl,
            firstNameLocked,
            lastNameLocked,
            emailLocked,
            aboutMe,
            instagram,
            linkedin,
            whatsapp,
            courses,
        },
    },
    User: { errorRes },
    form: { profile },
}) => ({
    firstName,
    lastName,
    email,
    firstNameLocked,
    lastNameLocked,
    emailLocked,
    courses,
    photoUrl,
    profile,
    initialValues: {
        firstName,
        lastName,
        email,
        profilePhoto: null,
        photoUrl,
        aboutMe,
        instagram,
        linkedin,
        whatsapp,
    },
    errorRes,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "profile",
        enableReinitialize: true,
        validate,
    })(Profile)
);
