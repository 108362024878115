/** @jsx jsx */
import { jsx } from "@emotion/core";
import { container, containerHeader, containerActions } from "./ContainerStyles";

const Container = props => {
    const {
        children,
        width,
        paddingTopValue,
        paddingBottomValue,
        noPadding,
        onMobileNoPadding,
        minHeight,
        style,
        ...other
    } = props;

    return (
        <div
            ref={other.setRef}
            css={[
                container.body,
                width && { maxWidth: width },
                noPadding && container.bodyNoPadding,
                onMobileNoPadding && container.onMobileNoPadding,
                paddingTopValue && { paddingTop: paddingTopValue },
                paddingBottomValue && {
                    paddingBottom: paddingBottomValue,
                },
                minHeight && { minHeight: minHeight },
                style && style,
            ]}
            {...other}
        >
            {children}
        </div>
    );
};

const ContainerHeader = props => {
    const { children, height, justifyBottom, justifyCenter, style } = props;

    return (
        <div css={[containerHeader.body, style && style]}>
            <div css={containerHeader.shapeTop}>
                <div css={containerHeader.shapeTopRectangle} />
                <svg preserveAspectRatio="none" viewBox="0 0 360 76" width="100%" height="76">
                    <path d="M360 0H0l360 76z" fill="#00363C" opacity="0.5" />
                </svg>
            </div>
            <div
                css={[
                    containerHeader.inner,
                    justifyCenter && containerHeader.justifyCenter,
                    justifyBottom && containerHeader.justifyBottom,
                    { height: height },
                ]}
            >
                <div css={containerHeader.innerContainer}>{children}</div>
            </div>
            <div css={containerHeader.shapeBottom}>
                <svg preserveAspectRatio="none" viewBox="0 0 360 30" width="100%" height="30">
                    <path d="M0 0h360L0 30z" fill="#00565F" />
                </svg>
            </div>
        </div>
    );
};

const ContainerActions = props => {
    const { children, style } = props;

    return (
        <div css={[containerActions.body, style && style]}>
            <div css={containerActions.shapeTop}>
                <svg preserveAspectRatio="none" viewBox="0 0 360 18" width="100%" height="18">
                    <path d="M0 0l360 18H0z" fill="#fff" />
                </svg>
            </div>
            <div css={containerActions.inner}>
                <div css={containerActions.innerContainer}>{children}</div>
            </div>
        </div>
    );
};

export { Container, ContainerHeader, ContainerActions };
