import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
// import Modal from "react-modal";
import Form from "./Form";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../common/components/UI/ModalBox";
import { bugsnagEnvironment } from "../../common/services/bugsnagClient";

import actionCreators from "../../store/Feedback/actionCreators";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import { spacing } from "../../common/styles/Spacing";
import ButtonShape from "../../common/components/UI/ButtonShape";
import GTM from "../../common/services/GTM";
import browser from "browser-detect";

class FeedbackModal extends Component {
    componentDidUpdate(prevProps) {
        if (
            prevProps.showFeedbackForm !== this.props.showFeedbackForm &&
            this.props.showFeedbackForm === true
        ) {
            GTM.dispatch({
                event: "pageView",
                pageUrl: `/feedback`,
            });
        }
    }

    onSubmit = values => {
        const { sendFeedback } = this.props;

        const userAgent = browser();

        const capitalize = s => {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        };

        sendFeedback({
            subject: values.subject,
            requestType: Number(values.requestType),
            body: values.body,
            name: values.name,
            email: values.email,
            environment: bugsnagEnvironment,
            system: capitalize(userAgent.os),
            platform: `${userAgent.mobile ? "Mobile web" : "Desktop web"} v.${
                process.env.REACT_APP_VERSION
            }`,
            browser: capitalize(`${userAgent.name} ${userAgent.version}`),
        });
    };

    openModal = () => {
        const { showFeedbackForm } = this.props;
        showFeedbackForm();
    };

    closeModal = () => {
        const { hideFeedbackForm, clearFeedback } = this.props;
        hideFeedbackForm();
        clearFeedback();
    };

    render() {
        const {
            subjects,
            feedbackSent,
            showFeedbackForm,
            errorRes: { errors },
            email,
            firstName,
            lastName,
        } = this.props;
        const { closeModal, onSubmit } = this;

        return (
            <Fragment>
                <ModalBox
                    open={showFeedbackForm}
                    // open={true}
                    onClose={closeModal}
                    modalWidth={feedbackSent === false ? 768 : 400}
                    disableBackdropClick
                    maxWidth="md"
                    scroll="body"
                >
                    {feedbackSent === false ? (
                        <Fragment>
                            <ModalBoxText>
                                <h2>Send us feedback</h2>
                                <p>
                                    Whether you&apos;ve found a bug in the app, a broken chair in
                                    the Library, or just want to tell us how best to improve your
                                    LSE experience, get in touch with us!
                                </p>
                            </ModalBoxText>
                            <Form
                                {...{
                                    onSubmit,
                                    subjects,
                                    errors,
                                    closeModal,
                                    initialValues: {
                                        email,
                                        name: `${firstName} ${lastName}`,
                                        firstName,
                                        lastName,
                                    },
                                }}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <ModalBoxText>
                                <h2>Thank you</h2>
                                <p>
                                    Thank you for your comment - we really appreciate your feedback!
                                    We&apos;ll get back to you with a response within 5 working
                                    days.
                                </p>
                            </ModalBoxText>
                            <ModalBoxActions marginTopValue={spacing.space6}>
                                <ButtonsGroup marginBottomValue={spacing.space3}>
                                    <ButtonShape primary fullWidth onClick={() => closeModal()}>
                                        Close
                                    </ButtonShape>
                                </ButtonsGroup>
                            </ModalBoxActions>
                        </Fragment>
                    )}
                </ModalBox>
                {/* <Modal
                    isOpen={showFeedbackForm}
                    onRequestClose={closeModal}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <button type="button" onClick={() => closeModal()}>
                        X
                    </button>

                    {feedbackSent === false ? (
                        <Fragment>
                            <h2>Send us feedback</h2>
                            <p>
                                If you need additional help or have ideas about how to improve the
                                Hub, please share your feedback with us. We&apos;d love to hear your
                                suggestions.{" "}
                            </p>
                            <Form {...{ onSubmit, subjects, errors }} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>Thank you</h2>
                            <p>
                                Your message has been sent and a copy has been sent to your email
                                address.
                            </p>
                            <div>
                                <button type="button" onClick={() => closeModal()}>
                                    close
                                </button>
                            </div>
                        </Fragment>
                    )}
                </Modal> */}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    subjects: state.Feedback.subjects,
    showFeedbackForm: state.Feedback.showFeedbackForm,
    errorRes: state.Feedback.errorRes,
    feedbackSent: state.Feedback.feedbackSent,
    firstName: state.Auth.user.firstName,
    lastName: state.Auth.user.lastName,
    email: state.Auth.user.email,
});

const dispatchToProps = {
    sendFeedback: actionCreators.sendFeedback.create,
    clearFeedback: actionCreators.clearFeedback.create,
    hideFeedbackForm: actionCreators.hideFeedbackForm.create,
    displayFeedbackForm: actionCreators.displayFeedbackForm.create,
    getSubjects: actionCreators.getSubjects.create,
};

export default connect(mapStateToProps, dispatchToProps)(FeedbackModal);
