/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import NowLine from "./NowLine";
import {
    clusterizer,
    prepareTimetable,
    setClusterWidth,
    setNodesPosition,
} from "../helpers/eventsPreparer";
import { dateRangesOverlap, daysDiff, minDiff, normalizeTime } from "../helpers/date";
import Headers from "./Headers";
import Hours from "./Hours";
import { useMeasure } from "../../../../common/hooks/useMeasure";
import moment from "moment";
import TimelinePlaceholder from "../../../../common/components/Skeletons/TimelinePlaceholder";

const withDefault = (value, defaultValue) => {
    return typeof value === "undefined" ? defaultValue : value;
};

const Timetable = ({
    items,
    renderItem,
    date,
    range: rangeProp,
    style,
    width,
    timeWidth = 72,
    itemMinHeightInMinutes = 15,
    hourHeight = 60,
    linesTopOffset = 18,
    linesLeftInset = 0,
    columnHorizontalPadding = 0,
    stickyHours,
    renderHeader,
    startProperty = "startDate",
    endProperty = "endDate",
    showNowLine,
    isLoading,
    ...props
}) => {
    const [ref, { measureWidth }] = useMeasure();
    const screenWidth = measureWidth;

    const [fromTo, setFromTo] = useState({ from: 8, to: 20 });

    useEffect(() => {
        let startHour = 8;
        let endHour = 20;

        items.forEach(event => {
            const eventStartHour = moment(event.startDate).hour();
            const eventEndHour = moment(event.endDate).hour();
            if (eventStartHour < startHour) {
                startHour = eventStartHour;
            }

            if (eventEndHour > endHour) {
                endHour = eventEndHour;
            }
        });
        const newFromTo = {
            from: startHour,
            to: endHour,
        };

        setFromTo(newFromTo);
    }, [items]);

    const range = {
        from: normalizeTime(date || rangeProp?.from),
        till: normalizeTime(date || rangeProp?.till, 23, 59, 59, 999),
    };

    const columnDays = useMemo(() => {
        const amountOfDays = daysDiff(range.till, range.from) + 1;
        const days = [];

        for (let i = 0; i < amountOfDays; i++) {
            const date = new Date(range.from);
            date.setDate(date.getDate() + i);

            const start = new Date(date);
            start.setHours(fromTo.from, 0, 0, 0);

            const end = new Date(date);
            end.setHours(fromTo.to - 1, 59, 59, 999);

            days.push({ date, start, end });
        }

        return days;
    }, [range.from, range.till, fromTo]);

    // const scrollX = useRef(new Animated.Value(0)).current;

    const linesLeftOffset = timeWidth - linesLeftInset;
    const minuteHeight = hourHeight / 60;
    const columnWidth = withDefault(
        props.columnWidth,
        (width || screenWidth) - (timeWidth - linesLeftInset)
    );

    const calculateTopOffset = useCallback(
        date => {
            const d = new Date(date);
            return (
                (Math.max(d.getHours() - fromTo.from, 0) * 60 + d.getMinutes()) * minuteHeight +
                linesTopOffset
            );
        },
        [fromTo.from, linesTopOffset, minuteHeight]
    );

    const cards = useMemo(() => {
        if (!Array.isArray(items)) return [];

        const positionedEvents = [];
        const itemMinHeight = Math.max(itemMinHeightInMinutes, 15);

        columnDays.forEach((columnDay, columnIndex) => {
            const filteredItems = items.filter(item =>
                dateRangesOverlap(
                    columnDay.start,
                    columnDay.end,
                    new Date(item[startProperty]),
                    new Date(item[endProperty])
                )
            );

            if (!filteredItems?.length) return;

            const { preparedEvents, minutes } = prepareTimetable(
                filteredItems,
                startProperty,
                endProperty,
                itemMinHeight,
                columnDay
            );
            const clusteredTimetable = clusterizer(preparedEvents, minutes);
            setClusterWidth(clusteredTimetable, columnWidth);
            setNodesPosition(clusteredTimetable);

            for (let nodeId in clusteredTimetable.nodes) {
                let node = clusteredTimetable.nodes[nodeId];

                if (!node.cluster || node.position === null) continue;

                let data = node?.data;

                const itemStart = new Date(data[startProperty]);
                const itemEnd = new Date(data[endProperty]);
                const itemMinEnd = new Date(itemStart);
                itemMinEnd.setMinutes(itemStart.getMinutes() + itemMinHeight);
                const neighboursCount = Object.keys(node?.neighbours).length;

                const start = Math.max(+columnDay.start, +itemStart);
                const end = Math.min(+columnDay.end + 1, Math.max(+itemEnd, +itemMinEnd));

                let width =
                    neighboursCount > 0
                        ? columnIndex > 0
                            ? node.cluster.width - columnHorizontalPadding
                            : node.cluster.width
                        : node.cluster.width - columnHorizontalPadding * 2;
                let left =
                    linesLeftOffset +
                    columnIndex * columnWidth +
                    columnHorizontalPadding +
                    node.cluster.width * node.position;

                if (neighboursCount > 0 && node?.isLast) {
                    width = node.cluster.width - columnHorizontalPadding * 2;
                }

                if (columnIndex === 0) {
                    width = width - linesLeftInset - 1;
                    left = left + linesLeftInset + 1;
                }

                positionedEvents.push({
                    key: columnIndex + node.key,
                    item: node.data,
                    style: {
                        position: "absolute",
                        zIndex: 2,
                        top: calculateTopOffset(start),
                        left,
                        height: minDiff(start, end) * minuteHeight,
                        width,
                    },
                });
            }
        });

        return positionedEvents;
    }, [
        items,
        columnDays,
        startProperty,
        endProperty,
        columnWidth,
        columnHorizontalPadding,
        linesLeftOffset,
        linesLeftInset,
        minuteHeight,
        calculateTopOffset,
        itemMinHeightInMinutes,
    ]);

    return (
        <div ref={ref}>
            <div css={styles.container}>
                <Headers
                    headersContainer={style?.headersContainer}
                    columnDays={columnDays}
                    columnWidth={columnWidth}
                    linesTopOffset={linesTopOffset}
                    linesLeftOffset={linesLeftOffset}
                    renderHeader={renderHeader}
                    headerContainerStyle={style?.headerContainer}
                    headerTextStyle={style?.headerText}
                />

                <div css={styles.contentContainer}>
                    {isLoading && <TimelinePlaceholder />}
                    <Hours
                        // offsetX={scrollX}
                        offsetX={0}
                        columnDays={columnDays}
                        columnWidth={columnWidth}
                        linesTopOffset={linesTopOffset}
                        linesLeftOffset={linesLeftOffset}
                        fromHour={fromTo && fromTo.from ? fromTo.from : 0}
                        toHour={fromTo && fromTo.to ? fromTo.to : 0}
                        hourHeight={hourHeight}
                        timeWidth={timeWidth}
                        timeStyle={style?.time}
                        timeContainerStyle={style?.timeContainer}
                        is12Hour={props?.is12Hour}
                    />

                    {showNowLine && (
                        <NowLine
                            calculateTopOffset={calculateTopOffset}
                            left={linesLeftOffset}
                            width={columnWidth * columnDays.length}
                        />
                    )}

                    {renderItem && cards.map(renderItem)}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: "relative",
    },
    contentContainer: {
        position: "relative",
    },
};

export default Timetable;
