import { fontFamily, fontWeight, fontSize, lineHeight } from "../../styles/Typography";
import { colors } from "../../styles/Colors";

export const textError = {
    body: {
        fontFamily: fontFamily.primary,
        fontWeight: fontWeight.bold,
        fontSize: fontSize.textSmaller,
        lineHeight: lineHeight.textSmaller,
        color: colors.error,
        letterSpacing: 0.08,
    },
    bodyCenter: {
        textAlign: "center",
    },
};
