import { colors } from "./Colors";
import { spacing } from "./Spacing";
import { fontSize, fontFamily, fontWeight, lineHeight } from "./Typography";

export const defaultBorder = ["1px", "solid", colors.black10].join(" ");

export const truncate = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
};

export const resetLineHeight = {
    fontSize: 1,
    lineHeight: 1,
};

export const formContainer = {
    body: {
        backgroundColor: colors.white,
        flexGrow: 1,
        flex: 1,
    },
    paddingTabBar: {
        paddingBottom: spacing.space6,
    },
    section: {
        width: "100%",
        display: "flex",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        margin: 0,
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: "0 none",
        borderLeft: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        textAlign: "left",
        whiteSpace: "pre-line",
    },
    sectionColumn: {
        flexDirection: "column",
    },
    sectionBig: {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space3,
    },
    sectionDate: {
        paddingTop: spacing.space2comma5 - 8,
        paddingBottom: spacing.space1,
    },
    sectionBorder: {
        borderBottom: `1px solid #8d8d8d`,
    },
    sectionUpload: {
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    icon: {
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        marginRight: spacing.space2,
    },
    avatar: {
        width: 40,
        height: 40,
        alignItems: "center",
        // justifyContent: "center",
        marginRight: spacing.space2,
        "@media (max-width: 1023px)": {
            width: 32,
            height: 32,
        },
    },
    inner: {
        flex: 1,
    },
    innerTextarea: {
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${colors.black12}`,
        "@media (max-width: 1023px)": {
            borderBottom: " 0 none",
        },
    },
    innerIndent: {
        paddingLeft: 20 + spacing.space2,
    },
    label: {
        color: colors.black54,
        fontSize: fontSize.text,
        lineHeight: 1.42,
        marginBottom: spacing.space1comma5,
    },
    text: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
        lineHeight: 1.25,
    },
    textPlaceholder: {
        color: colors.black50,
    },
    textBigLineHeight: {
        lineHeight: 1.5,
    },
    input: {
        width: "100%",
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: "0 none",
        borderLeft: "0 none",
        outline: "0 none",
        padding: 0,
        backgroundColor: "transparent",
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
    },
    textarea: {
        width: "100%",
    },
    selectionItem: {
        alignItems: "start",
        marginBottom: spacing.space2,
        marginRight: 0,
        marginTop: -16,
    },
    selectionItemNoMargin: {
        alignItems: "start",
        marginBottom: 0,
        marginRight: 0,
        marginTop: -16,
    },
    selectionLabel: {
        display: "block",
    },
    selectionLabelTitle: {
        display: "block",
        lineHeight: 1.2,
        paddingTop: 16,
    },
    selectionLabelText: {
        display: "block",
        fontSize: fontSize.textSmall,
        color: colors.black54,
        lineHeight: 1.3,
        marginTop: spacing.space0,
    },
};

export const list = {
    list: {
        flex: 1,
    },
    listInner: {
        paddingBottom: spacing.space6,
    },
    title: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        padding: spacing.space2,
        paddingTop: spacing.space3,
        backgroundColor: colors.black2,
        borderBottom: `1px solid ${colors.greyDark10}`,
    },
};

export const listItem = {
    body: {
        display: "flex",
        width: "100%",
        backgroundColor: colors.white,
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        alignItems: "stretch",
        outline: "0 none",
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: `1px solid ${colors.greyDark10}`,
        borderLeft: "0 none",
        "@media (max-width: 1023px)": {
            borderRight: "0 none",
            borderLeft: "0 none",
        },
    },
    bodyLeft: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: spacing.space2,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actionLeft: {
        backgroundColor: "transparent",
        border: 0,
        outline: 0,
        margin: 0,
        padding: 0,
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.normal,
    },
    bodyRight: {
        display: "flex",
        alignItems: "center",
    },
    btnRemove: {
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
    },
};
