import actionCreators from "./actionCreators";

export const initialState = {
    step: 0,
    errorRes: { error: "", errors: [] },
    isFormSubmitting: false,
    isConfirmationModalOpen: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.nextStep.type:
            return {
                ...state,
                step: state.step + 1,
                errorRes: initialState.errorRes,
            };
        case actionCreators.prevStep.type:
            return {
                ...state,
                step: state.step - 1,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
                isFormSubmitting: false,
            };
        case actionCreators.setStep.type:
            return {
                ...state,
                step: payload,
            };
        case actionCreators.enableModal.type:
            return {
                ...state,
                isConfirmationModalOpen: true,
            };
        case actionCreators.disableModal.type:
            return {
                ...state,
                isConfirmationModalOpen: false,
            };
        case actionCreators.register.type:
            localStorage.setItem(
                "user",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("user")),
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                })
            );
            return {
                ...state,
                isFormSubmitting: true,
            };
        default:
            return state;
    }
};
