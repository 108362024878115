import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import autosize from "autosize";
import { textareaAutosize } from "./FormFieldsStyles";

class RenderTextareaAutosize extends Component {
    componentDidMount() {
        // autosize(this.input);
        autosize(document.querySelector("textarea"));
    }
    componentDidUpdate() {
        autosize.update(document.querySelector("textarea"));
    }

    render() {
        const {
            input,
            errorRes,
            style,
            value,
            label,
            classes,
            placeholder,
            refName,
            noPadding,
            ...custom
        } = this.props;

        return (
            <div css={textareaAutosize.body}>
                <textarea
                    placeholder={placeholder}
                    value={value}
                    {...input}
                    {...custom}
                    ref={refName}
                    css={{
                        ...textareaAutosize.textarea,
                        ...(noPadding && textareaAutosize.textareaNoPadding),
                    }}
                    rows={1}
                />
            </div>
        );
    }
}

export default RenderTextareaAutosize;
