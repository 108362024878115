import actionCreators from "./actionCreators";
import { mergeByIds } from "../../common/services/storeHelpers";

export const initialState = {
    notifications: null,
    unseenCount: 0,
    page: 1,
    hasMore: true,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateNotifications.type: {
            const notifications = mergeByIds(state.notifications || [], payload.data);
            return {
                ...state,
                notifications,
                page: state.page + 1,
                hasMore: payload.nextPage > 0,
            };
        }
        case actionCreators.updateAsReadNotification.type:
            return {
                ...state,
                notifications: state.notifications
                    ? state.notifications.map(notification =>
                          notification.id === payload.id
                              ? { ...notification, wasExecuted: true }
                              : notification
                      )
                    : initialState.notifications,
                unseenCount: state.unseenCount - 1 >= 0 ? state.unseenCount - 1 : 0,
            };
        case actionCreators.updateUnseenNotificationsCount.type:
            return {
                ...state,
                unseenCount: payload.count,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };

        default:
            return state;
    }
};
