import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { searchBar } from "../../Search/SearchStyles";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { connect } from "react-redux";
import actionCreators from "../../../store/SupportMap/actionCreators";
import { Subject } from "rxjs/Subject";
import GTM from "../../../common/services/GTM";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

class SuppportMapField extends Component {
    state = {
        filter$: new Subject(),
    };

    componentDidMount() {
        this.clearSearch();
        this.searchInput.focus();
    }

    clearSearch = () => {
        const { setQuerySearch } = this.props;
        const { filter$ } = this.state;

        this.searchInput.value = "";
        setQuerySearch("");

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (value !== "") {
                setQuerySearch(value);
                GTM.dispatch({
                    event: "newSearch",
                    actionType: "Support map search",
                    querySearch: `${value}`,
                });
            }
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isSearchActive !== this.props.isSearchActive && !this.props.isSearchActive) {
            this.clearSearch();
        }
    }

    componentWillUnmount() {
        this.clearSearch();
    }

    render() {
        const { isSearchActive, toggleSearchActive, mode, querySearch } = this.props;
        const { filter$ } = this.state;

        const { clearSearch } = this;

        return (
            <div
                css={[
                    searchBar.body,
                    searchBar.bodySupportMap,
                    isSearchActive && searchBar.bodyIsSearchActiveSupportMap,
                ]}
            >
                <div css={searchBar.inner}>
                    <div css={searchBar.elementLeft}>
                        <Icon
                            name="search"
                            fill={colors.black20}
                            width={18}
                            height={18}
                            css={searchBar.iconSearch}
                        />
                        {isSearchActive ? (
                            <button
                                css={[searchBar.btnBack]}
                                onClick={() => toggleSearchActive()}
                                type="button"
                            >
                                <Icon
                                    name="arrowLeft"
                                    fill={colors.black87}
                                    width={18}
                                    height={18}
                                />
                            </button>
                        ) : (
                            mode !== "DETAIL" && (
                                <Icon
                                    name="search"
                                    fill={colors.black20}
                                    width={18}
                                    height={18}
                                    css={searchBar.iconSearchMobile}
                                />
                            )
                        )}
                    </div>
                    <div css={searchBar.elementCenter}>
                        <input
                            onInput={e => filter$.next(e.target.value)}
                            css={searchBar.input}
                            placeholder="Search"
                            onFocus={() => !isSearchActive && toggleSearchActive()}
                            ref={e => (this.searchInput = e)}
                        />
                    </div>

                    {querySearch && (
                        <div css={searchBar.elementRight}>
                            <button type="button" css={searchBar.btn} onClick={() => clearSearch()}>
                                <Icon name="times" fill={colors.black87} width={16} height={16} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ SupportMap: { isSearchActive, querySearch } }) => ({
    isSearchActive,
    querySearch,
});

const mapDispatchToProps = {
    toggleSearchActive: actionCreators.toggleSearchActive.create,
    setQuerySearch: actionCreators.setQuerySearch.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppportMapField);
