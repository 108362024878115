import { spacing } from "../../common/styles/Spacing";
import { fontSize, fontWeight, fontFamily } from "../../common/styles/Typography";
import { colors } from "../../common/styles/Colors";

export const linksPage = {
    body: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: `106px 0 ${spacing.space6 - 1}px`,
        },
    },
    bodySubpage: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: "56px 0 0",
        },
    },
    bodyNoPadding: {
        position: "relative",
        padding: 0,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space6 - 1}px`,
        },
    },
    inner: {
        // paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
    },
    innerNoPaddingOnMobile: {
        // paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
};

export const addGroupPage = {
    body: {
        position: "relative",
        padding: `130px 0 ${spacing.space2}px`,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space8}px`,
        },
    },
    form: {
        paddingTop: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 0,
        },
    },
    actions: {
        display: "flex",
        maxWidth: 560,
        justifyContent: "center",
        marginTop: spacing.space3,
        marginRight: "auto",
        marginBottom: spacing.space3,
        marginLeft: "auto",
        paddingTop: spacing.space5,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        borderTop: `1px solid ${colors.black12}`,

        "@media (max-width: 1023px)": {
            paddingTop: spacing.space4,
            marginTop: spacing.space1,
            marginBottom: spacing.space1,
        },
    },
};

export const formContainer = {
    body: {
        backgroundColor: colors.white,
        flexGrow: 1,
        flex: 1,
    },
    paddingTabBar: {
        paddingBottom: spacing.space6,
    },
    section: {
        width: "100%",
        display: "flex",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        margin: 0,
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: "0 none",
        borderLeft: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        textAlign: "left",
        whiteSpace: "pre-line",
    },
    sectionColumn: {
        flexDirection: "column",
    },
    sectionBig: {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space3,
    },
    sectionDate: {
        paddingTop: spacing.space2comma5 - 8,
        paddingBottom: spacing.space1,
    },
    sectionBorder: {
        borderBottom: `1px solid #8d8d8d`,
    },
    sectionUpload: {
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    icon: {
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        marginRight: spacing.space2,
    },
    avatar: {
        width: 40,
        height: 40,
        alignItems: "center",
        // justifyContent: "center",
        marginRight: spacing.space2,
        "@media (max-width: 1023px)": {
            width: 32,
            height: 32,
        },
    },
    inner: {
        flex: 1,
    },
    innerTextarea: {
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${colors.black12}`,
        "@media (max-width: 1023px)": {
            borderBottom: " 0 none",
        },
    },
    innerIndent: {
        paddingLeft: 20 + spacing.space2,
    },
    label: {
        color: colors.black54,
        fontSize: fontSize.text,
        lineHeight: 1.42,
        marginBottom: spacing.space1comma5,
    },
    text: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
        lineHeight: 1.25,
    },
    textPlaceholder: {
        color: colors.black50,
    },
    textBigLineHeight: {
        lineHeight: 1.5,
    },
    input: {
        width: "100%",
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: "0 none",
        borderLeft: "0 none",
        outline: "0 none",
        padding: 0,
        backgroundColor: "transparent",
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
    },
    selectionItem: {
        alignItems: "start",
        marginBottom: spacing.space2,
        marginRight: 0,
        marginTop: -16,
    },
    selectionItemNoMargin: {
        alignItems: "start",
        marginBottom: 0,
        marginRight: 0,
        marginTop: -16,
    },
    selectionLabel: {
        display: "block",
    },
    selectionLabelTitle: {
        display: "block",
        lineHeight: 1.2,
        paddingTop: 16,
    },
    selectionLabelText: {
        display: "block",
        fontSize: fontSize.textSmall,
        color: colors.black54,
        lineHeight: 1.3,
        marginTop: spacing.space0,
    },
};
