import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";
import { fontFamily, fontSize, fontWeight } from "../../styles/Typography";

export const contextMenu = {
    backdrop: {
        backgroundColor: colors.black50,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1500,
    },
    container: {
        position: "fixed",
        zIndex: 1500,
        top: 0,
        left: "50%",
    },
    dialog: {
        width: 242,
        backgroundColor: colors.white,
        boxShadow: "0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24)",
        borderRadius: 2,
        outline: "0 none",
        overflow: "hidden",
    },
};

export const contextMenuTrigger = {
    body: {
        width: 24,
        height: 24,
        marginLeft: spacing.space1,
    },
    btn: {
        width: 24,
        height: 24,
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        lineHeight: 1,
        border: "0 none",
        outline: "0 none",
    },
};

export const contextMenuButton = {
    body: {
        backgroundColor: colors.white,
        paddingTop: spacing.space2,
        paddingRight: spacing.space1comma5 + 2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space1comma5 + 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        textDecoration: "none",
        border: "0 none",
        outline: "0 none",
        width: "100%",
    },
    icon: {
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        marginRight: spacing.space2,
    },
    text: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        fontWeight: fontWeight.regular,
        textAlign: "left",
    },
};
