/** @jsx jsx */
import { jsx } from "@emotion/core";
import { serverError } from "./ServerErrorStyles";

const MaintenancePage = () => (
    <div css={serverError.body}>
        <h1>Come back soon!</h1>
        <h3>
            Student Hub is currently undergoing scheduled maintenance and upgrades but will return
            shortly.
        </h3>
        <p>Thank you for your patience.</p>
    </div>
);
export default MaintenancePage;
