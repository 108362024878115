import { Component, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { Container } from "../../../../common/components/UI/Container";
import { feedPage } from "../../../Feed/FeedStyles";
import actionCreators from "../../../../store/Channel/Group/actionCreators";
import channelActionCreators from "../../../../store/Channel/actionCreators";
import HeaderGroupMembers from "../../../../common/components/Header/HeaderGroupMembers";
import NoResults from "../../../../common/components/NoResults";
import ButtonFloating from "../../../../common/components/UI/ButtonFloating";
import MemberBar from "../../../../common/components/MemberBar";
import GTM from "../../../../common/services/GTM";
import ChannelsPlaceholder from "../../../../common/components/Skeletons/ChannelsPlaceholder";
import * as config from "../values";
import { groupMembersPage } from "../../ChannelStyles";
import history from "../../../../common/services/history";

const renderMembersList = (
    groupId,
    memberFilter,
    filter,
    members,
    updateGroupMemberPendingState,
    changeGroupMemberRole,
    removeGroupMember,
    groupManagedBy,
    isUserOwner
) => {
    const items = [];
    if (members && members.length > 0) {
        for (const member of members) {
            const { id, photoUrl, firstName, lastName, userGroupStatus, isBackendUser } = member;

            // apply filters
            if (memberFilter) {
                if ("isBackendUser" in memberFilter && isBackendUser !== memberFilter.isBackendUser)
                    continue;
                else if (
                    "userGroupStatus" in memberFilter &&
                    userGroupStatus !== memberFilter.userGroupStatus
                )
                    continue;
            }

            let memberButtons = [];

            if (
                (groupManagedBy === config.MANAGED_BY_OWNERS && isUserOwner) ||
                groupManagedBy === config.MANAGED_BY_MEMBERS
            ) {
                switch (filter) {
                    case config.USER_TYPE_PENDING:
                        if (userGroupStatus === config.USER_GROUP_STATUS_JOINREQUEST) {
                            memberButtons = [
                                {
                                    icon: { name: "check", width: 10, height: 10 },
                                    action: () =>
                                        updateGroupMemberPendingState({
                                            groupId,
                                            userId: id,
                                            status: config.ROLE_ACTION_APPROVE,
                                        }),
                                    label: "Approve",
                                },
                                {
                                    icon: { name: "times", width: 9, height: 9 },
                                    action: () =>
                                        updateGroupMemberPendingState({
                                            groupId,
                                            userId: id,
                                            status: config.ROLE_ACTION_DECLINE,
                                        }),
                                    label: "Decline",
                                },
                            ];
                        } else if (userGroupStatus === config.USER_GROUP_STATUS_INVITATION) {
                            memberButtons = [
                                {
                                    icon: { name: "times", width: 9, height: 9 },
                                    action: () =>
                                        updateGroupMemberPendingState({
                                            groupId,
                                            userId: id,
                                            status: config.ROLE_ACTION_DECLINE,
                                        }),
                                    label: "Cancel",
                                },
                            ];
                        }
                        break;
                    case config.USER_TYPE_MEMBER:
                        if (isUserOwner) {
                            memberButtons = [
                                {
                                    icon: { name: "chevronUp", width: 10, height: 10 },
                                    action: () =>
                                        changeGroupMemberRole({
                                            groupId,
                                            userId: id,
                                            type: config.USER_TYPE_OWNER,
                                        }),
                                    label: "Make Owner",
                                },
                                {
                                    icon: { name: "times", width: 9, height: 9 },
                                    action: () =>
                                        removeGroupMember({
                                            groupId,
                                            userId: id,
                                        }),
                                    label: "Remove",
                                },
                            ];
                        }
                        break;
                    default:
                        // USER_TYPE_OWNER

                        if (!isBackendUser) {
                            if (isUserOwner) {
                                memberButtons = [
                                    {
                                        icon: { name: "chevronDown", width: 10, height: 10 },
                                        action: () =>
                                            changeGroupMemberRole({
                                                groupId,
                                                userId: id,
                                                type: config.USER_TYPE_MEMBER,
                                            }),
                                        label: "Make Member only",
                                    },
                                ];
                            }
                        }
                        break;
                }
            }

            items.push(
                <MemberBar
                    key={id}
                    buttons={memberButtons}
                    {...{ id, photoUrl, firstName, lastName }}
                />
            );
        }
    }
    return items;
};

const renderMembersContainer = (
    groupId,
    groupType,
    filter,
    members,
    updateGroupMemberPendingState,
    changeGroupMemberRole,
    removeGroupMember,
    groupManagedBy,
    isUserOwner
) => {
    // group & add group headers
    if (filter === config.USER_TYPE_PENDING) {
        const invitations = renderMembersList(
            groupId,
            {
                userGroupStatus: config.USER_GROUP_STATUS_INVITATION,
            },
            filter,
            members,
            updateGroupMemberPendingState,
            changeGroupMemberRole,
            removeGroupMember,
            groupManagedBy,
            isUserOwner
        );

        const requests = renderMembersList(
            groupId,
            {
                userGroupStatus: config.USER_GROUP_STATUS_JOINREQUEST,
            },
            filter,
            members,
            updateGroupMemberPendingState,
            changeGroupMemberRole,
            removeGroupMember,
            groupManagedBy,
            isUserOwner
        );

        // add invitations header
        return (
            <Fragment>
                {invitations && invitations.length > 0 && (
                    <Fragment>
                        <div css={groupMembersPage.title}>Invitations:</div>
                        {invitations}
                    </Fragment>
                )}
                {requests && requests.length > 0 && (
                    <Fragment>
                        <div css={groupMembersPage.title}>Join requests:</div>
                        {requests}
                    </Fragment>
                )}
            </Fragment>
        );
    } else if (filter === config.USER_TYPE_OWNER) {
        // add admin / standard users sections
        const admins = renderMembersList(
            groupId,
            {
                isBackendUser: true,
            },
            filter,
            members,
            updateGroupMemberPendingState,
            changeGroupMemberRole,
            removeGroupMember,
            groupManagedBy,
            isUserOwner
        );
        const owners = renderMembersList(
            groupId,
            {
                isBackendUser: false,
            },
            filter,
            members,
            updateGroupMemberPendingState,
            changeGroupMemberRole,
            removeGroupMember,
            groupManagedBy,
            isUserOwner
        );

        return (
            <Fragment>
                {admins && admins.length > 0 && (
                    <Fragment>
                        <div css={groupMembersPage.title}>Admin Users:</div>
                        {admins}
                    </Fragment>
                )}
                {owners && owners.length > 0 && (
                    <Fragment>
                        <div css={groupMembersPage.title}>Student Users:</div>
                        {owners}
                    </Fragment>
                )}
            </Fragment>
        );
    } else {
        // standard simple list of regular members
        return (
            <div>
                {renderMembersList(
                    groupId,
                    null,
                    filter,
                    members,
                    updateGroupMemberPendingState,
                    changeGroupMemberRole,
                    removeGroupMember,
                    groupManagedBy,
                    isUserOwner
                )}
            </div>
        );
    }
};

class GroupMembersContainer extends Component {
    componentDidMount() {
        const {
            name,
            getChannel,
            match: {
                params: { id },
            },
        } = this.props;
        if (!name) getChannel({ id });

        if (!(this.props.location.state && this.props.location.state.noRefresh === true)) {
            this.onLoadMore();
        } else {
            history.replace({
                state: {},
            });
        }
    }

    onLoadMore = () => {
        const {
            match: {
                params: { id },
            },
            getGroupMembers,
            page,
            filter,
        } = this.props;

        if (!(filter === config.USER_TYPE_PENDING && page > 1))
            getGroupMembers({ id, page, filter });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/group/${id}/members/${filter}/${page}`,
        });
    };

    getFilterDescription = () => {
        switch (this.props.filter) {
            case config.USER_TYPE_PENDING:
                return "pending users";
            case config.USER_TYPE_MEMBER:
                return "members";
            default:
                return "owners";
        }
    };

    componentWillUnmount() {
        this.props.clearGroupMembers();
    }

    render() {
        const {
            setGroupMembersFilter,
            id,
            members,
            hasMore,
            name,
            groupType,
            filter,
            updateGroupMemberPendingState,
            changeGroupMemberRole,
            removeGroupMember,
            groupManagedBy,
            isUserOwner,
        } = this.props;
        const { onLoadMore } = this;

        const filters = handler => {
            const tabs =
                groupType !== config.GROUP_TYPE_OPEN &&
                (isUserOwner || groupManagedBy === config.MANAGED_BY_MEMBERS)
                    ? [
                          {
                              name: "Pending",
                              value: { name: config.USER_TYPE_PENDING, id },
                              handler,
                          },
                      ]
                    : [];

            return [
                ...tabs,
                {
                    name: "Members",
                    value: { name: config.USER_TYPE_MEMBER, id },
                    handler,
                },
                {
                    name: "Owners",
                    value: { name: config.USER_TYPE_OWNER, id },
                    handler,
                },
            ];
        };

        let tabIndex =
            filter === config.USER_TYPE_PENDING ? 0 : filter === config.USER_TYPE_MEMBER ? 1 : 2;
        if (
            groupType === config.GROUP_TYPE_OPEN ||
            !(isUserOwner || groupManagedBy === config.MANAGED_BY_MEMBERS)
        )
            tabIndex--;

        return (
            <div css={[feedPage.body, feedPage.bodyIsSearch]}>
                <HeaderGroupMembers
                    filters={filters(setGroupMembersFilter)}
                    title={name}
                    initial={tabIndex}
                />
                <ChannelsPlaceholder isLoaded={Boolean(members)}>
                    {members && members.length > 0 ? (
                        <Container width={592} noPadding>
                            <InfiniteScroll
                                dataLength={members.length}
                                next={filter === config.USER_TYPE_PENDING ? null : onLoadMore}
                                {...{ hasMore }}
                            >
                                <div css={feedPage.infiniteScroll}>
                                    <div css={feedPage.inner}>
                                        {renderMembersContainer(
                                            id,
                                            groupType,
                                            filter,
                                            members,
                                            updateGroupMemberPendingState,
                                            changeGroupMemberRole,
                                            removeGroupMember,
                                            groupManagedBy,
                                            isUserOwner
                                        )}
                                    </div>
                                </div>
                            </InfiniteScroll>
                            {groupType !== config.GROUP_TYPE_OPEN &&
                                (isUserOwner || groupManagedBy === config.MANAGED_BY_MEMBERS) && (
                                    <ButtonFloating to={`/group/${id}/add-member`} icon="add" />
                                )}
                        </Container>
                    ) : (
                        <Container width={592}>
                            <NoResults
                                heading="No results!"
                                message={`This group doesn't have any ${this.getFilterDescription()} ...`}
                            />
                            {groupType !== config.GROUP_TYPE_OPEN && (
                                <ButtonFloating to={`/group/${id}/add-member`} icon="add" />
                            )}
                        </Container>
                    )}
                </ChannelsPlaceholder>
            </div>
        );
    }
}

const mapStateToProps = ({
    Channel: {
        channel: { id, name, groupType, groupManagedBy, isUserOwner },
    },
    MyGroups: { members, page, filter, hasMore },
}) => ({
    id,
    name,
    groupType,
    groupManagedBy,
    isUserOwner,
    members,
    page,
    filter,
    hasMore,
});

const mapDispatchToProps = {
    getChannel: channelActionCreators.getChannel.create,
    setGroupMembersFilter: actionCreators.setGroupMembersFilter.create,
    getGroupMembers: actionCreators.getGroupMembers.create,
    updateGroupMemberPendingState: actionCreators.updateGroupMemberPendingState.create,
    changeGroupMemberRole: actionCreators.changeGroupMemberRole.create,
    removeGroupMember: actionCreators.removeGroupMember.create,
    clearGroupMembers: actionCreators.clearGroupMembers.create,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersContainer);
