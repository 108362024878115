import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarSync, calendarPage } from "./CalendarStyles";
import actionCreators from "../../store/Calendar/Sync/actionCreators";
import CalendarBar from "./CalendarBar";
import HeaderCalendarMobile from "../../common/components/Header/HeaderCalendarMobile";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { Container } from "../../common/components/UI/Container";
import ButtonsGroup from "../../common/components/UI/ButtonsGroup";
import { spacing } from "../../common/styles/Spacing";
import ButtonShape from "../../common/components/UI/ButtonShape";
import GTM from "../../common/services/GTM";

class CalendarSyncContainer extends Component {
    componentDidMount() {
        const { getSyncDetails } = this.props;
        getSyncDetails();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/sync`,
        });
    }

    render() {
        const { details } = this.props;

        return (
            <div css={calendarSync.body}>
                <HeaderCalendarMobile />
                <CalendarBar />
                <Container onMobileNoPadding width={592} css={calendarPage.content}>
                    <div css={calendarSync.paddingTabBar}>
                        {details && (
                            <div css={calendarSync.content}>
                                <p>
                                    You can sync your Student Hub calendar to your personal devices!
                                </p>

                                <hr css={calendarSync.divider} />

                                <p>See instructions for:</p>

                                <ButtonsGroup
                                    marginTopValue={spacing.space5}
                                    marginBottomValue={spacing.space5}
                                >
                                    <ButtonShape to="/calendar/sync-ios" fullWidth noCaps>
                                        APPLE IPHONE OR IPAD (iOS)
                                    </ButtonShape>
                                </ButtonsGroup>

                                <ButtonsGroup marginBottomValue={spacing.space5}>
                                    <ButtonShape to="/calendar/sync-macos" fullWidth noCaps>
                                        APPLE MAC OR MACBOOK (macOS)
                                    </ButtonShape>
                                </ButtonsGroup>

                                <ButtonsGroup marginBottomValue={spacing.space5}>
                                    <ButtonShape to="/calendar/sync-android" fullWidth noCaps>
                                        GOOGLE CALENDAR
                                    </ButtonShape>
                                </ButtonsGroup>

                                <ButtonsGroup marginBottomValue={spacing.space5}>
                                    <ButtonShape
                                        to="/calendar/sync-office-outlook"
                                        fullWidth
                                        noCaps
                                    >
                                        OFFICE 365 OR OUTLOOK
                                    </ButtonShape>
                                </ButtonsGroup>

                                <br />
                                <br />
                            </div>
                        )}
                    </div>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        sync: { details },
    },
}) => ({
    details,
});

const mapDispatchToProps = {
    getSyncDetails: actionCreators.getSyncDetails.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncContainer);
