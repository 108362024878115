/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Swipeable } from "react-swipeable";
import { TransitionGroup, CSSTransition } from "react-transition-group"; // Dodane
import actionCreators from "../../../store/Calendar/CalendarBoard/actionCreators";
import { getDay, formatdateLength } from "../utils";
import CalendarDay from "./CalendarDay";
import WeekDayLabels from "./WeekDayLabels";
import { Container } from "../../../common/components/UI/Container";
import Icon from "../../../common/components/UI/Icon";
import { calendarBoard, calendarBoardNav } from "../CalendarStyles";
import { colors } from "../../../common/styles/Colors";
import "./styles.css"; // Dodane - styl animacji
import Button from "../../../common/components/UI/Button";

class CalendarBoard extends Component {
    state = {
        swipeDirection: null,
    };

    componentDidMount() {
        const height = this.calendarBoardElement ? this.calendarBoardElement.clientHeight : 0;
        const { setCalendarBoardHeight } = this.props;
        setCalendarBoardHeight(height);
    }

    componentDidUpdate() {
        const height = this.calendarBoardElement.clientHeight;
        const { setCalendarBoardHeight } = this.props;
        setCalendarBoardHeight(height);
    }

    hasEventCallback = events => day =>
        events && events.some(event => Number(moment(event.startAt).format("DD")) === day);

    render() {
        const {
            currentDay,
            currentMonth,
            currentYear,
            weeks,
            setDay,
            setMonth,
            setYear,
            events,
            swipeLeft,
            swipeRight,
            hasEventCallback,
            hasAltEventCallback,
            disablePastDates,
            isScheduleDayCalendar,
            toggleCalendarBoard,
            calendarBoardVisible,
        } = this.props;

        const isCurrentMonth =
            currentMonth &&
            currentYear &&
            formatdateLength(currentMonth + 1) === moment().format("MM") &&
            currentYear.toString() === moment().format("YYYY");

        const isPastMonth =
            currentYear < Number(moment().format("YYYY")) ||
            (currentYear.toString() === moment().format("YYYY") &&
                currentMonth + 1 < Number(moment().format("MM")));

        return (
            <div
                className="calendar"
                css={calendarBoard.bg}
                ref={calendarBoardElement => {
                    this.calendarBoardElement = calendarBoardElement;
                }}
            >
                <Container width={592}>
                    <div
                        css={[calendarBoard.row, !isScheduleDayCalendar && calendarBoard.rowCenter]}
                    >
                        {isScheduleDayCalendar && (
                            <div css={calendarBoard.rowLeft}>
                                <div css={calendarBoard.rowTitle}>
                                    <button
                                        css={calendarBoard.rowBtn}
                                        onClick={() => toggleCalendarBoard()}
                                    >
                                        <h3>
                                            {moment(
                                                `${currentYear}-${formatdateLength(
                                                    currentMonth + 1
                                                )}-${formatdateLength(currentDay)}`
                                            ).format("ddd Do MMM")}
                                        </h3>
                                        <div
                                            css={calendarBoard.rowBtnIcon}
                                            onClick={() => toggleCalendarBoard()}
                                        >
                                            <Icon
                                                name={
                                                    calendarBoardVisible
                                                        ? "triangleUp"
                                                        : "triangleDown"
                                                }
                                                width={12}
                                                height={12}
                                                fill={colors.black54}
                                            />
                                        </div>
                                    </button>
                                </div>
                                {calendarBoardVisible && (
                                    <div css={calendarBoard.rowTodayBtn}>
                                        <Button
                                            small
                                            onClick={() => {
                                                setYear(
                                                    parseInt(moment(new Date()).format("YYYY"))
                                                );
                                                setMonth(
                                                    parseInt(moment(new Date()).format("M") - 1)
                                                );
                                                setDay(parseInt(moment(new Date()).format("D")));
                                            }}
                                        >
                                            Today
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            css={[
                                calendarBoard.rowRight,
                                {
                                    display: isScheduleDayCalendar
                                        ? calendarBoardVisible
                                            ? "block"
                                            : "none"
                                        : "block",
                                },
                            ]}
                        >
                            <Container width={360}>
                                <div css={calendarBoardNav.body}>
                                    <button
                                        onClick={() => {
                                            swipeRight();
                                            this.setState({ swipeDirection: "right" });
                                        }}
                                        css={calendarBoardNav.nav}
                                    >
                                        <Icon
                                            name="chevronLeft"
                                            fill={colors.grey3}
                                            width={12}
                                            height={12}
                                        />
                                    </button>
                                    <div css={calendarBoardNav.title}>
                                        {moment.months()[currentMonth]} {currentYear}
                                    </div>
                                    <button
                                        onClick={() => {
                                            swipeLeft();
                                            this.setState({ swipeDirection: "left" });
                                        }}
                                        css={calendarBoardNav.nav}
                                    >
                                        <Icon
                                            name="chevronRight"
                                            fill={colors.grey3}
                                            width={12}
                                            height={12}
                                        />
                                    </button>
                                </div>
                            </Container>
                            <Container width={360}>
                                <Swipeable
                                    onSwipedLeft={() => swipeLeft()}
                                    onSwipedRight={() => swipeRight()}
                                >
                                    <div css={calendarBoard.body}>
                                        <WeekDayLabels />
                                        <TransitionGroup className="calendar-group">
                                            <CSSTransition
                                                key={`${currentMonth}-${currentYear}`}
                                                classNames={{
                                                    enter:
                                                        this.state.swipeDirection === "left"
                                                            ? "calendar-transition-enter-left"
                                                            : "calendar-transition-enter-right",
                                                    enterActive:
                                                        this.state.swipeDirection === "left"
                                                            ? "calendar-transition-enter-left-active"
                                                            : "calendar-transition-enter-right-active",
                                                    exit:
                                                        this.state.swipeDirection === "left"
                                                            ? "calendar-transition-exit-left"
                                                            : "calendar-transition-exit-right",
                                                    exitActive:
                                                        this.state.swipeDirection === "left"
                                                            ? "calendar-transition-exit-left-active"
                                                            : "calendar-transition-exit-right-active",
                                                }}
                                                timeout={300}
                                            >
                                                <div>
                                                    {weeks.map((week, index) => (
                                                        <div
                                                            key={index}
                                                            css={calendarBoard.weekRow}
                                                        >
                                                            {week.map((day, i) => {
                                                                return (
                                                                    <CalendarDay
                                                                        key={i}
                                                                        hasEventCallback={
                                                                            hasEventCallback ||
                                                                            this.hasEventCallback(
                                                                                events
                                                                            )
                                                                        }
                                                                        hasAltEventCallback={
                                                                            hasAltEventCallback
                                                                                ? hasAltEventCallback
                                                                                : () => false
                                                                        }
                                                                        isWeekend={
                                                                            i === 5 || i === 6
                                                                        }
                                                                        {...{
                                                                            day,
                                                                            currentDay,
                                                                            getDay,
                                                                            setDay,
                                                                            isCurrentMonth,
                                                                            isPastMonth,
                                                                            disablePastDates: disablePastDates
                                                                                ? disablePastDates
                                                                                : false,
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    ))}
                                                </div>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                </Swipeable>
                            </Container>
                        </div>
                    </div>
                </Container>
                <div css={calendarBoard.bottomShape}>
                    <svg preserveAspectRatio="none" viewBox="0 0 360 16" width="100%" height="16">
                        <path d="M0 8l360-8v16H0z" fill="#ebebeb" />
                    </svg>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        board: { weeks, currentMonth, currentDay, currentYear, calendarBoardVisible },
    },
}) => ({
    weeks,
    currentMonth,
    currentDay,
    currentYear,
    calendarBoardVisible,
});

const mapDispatchToProps = {
    setDay: actionCreators.setDay.create,
    setMonth: actionCreators.setMonth.create,
    setYear: actionCreators.setYear.create,
    swipeLeft: actionCreators.swipeLeft.create,
    swipeRight: actionCreators.swipeRight.create,
    setCalendarBoardHeight: actionCreators.setCalendarBoardHeight.create,
    toggleCalendarBoard: actionCreators.toggleCalendarBoard.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarBoard);
