import actionCreators from "./actionCreators";
import GTM from "../../common/services/GTM";
import { mergeByIds } from "../../common/services/storeHelpers";
import moment from "moment";

export const initialState = {
    feed: null,
    page: 1,
    filter: "source=all",
    from: moment().format("YYYY-MM-DD"),
    hasMore: true,
    errorRes: { error: "", errors: {} },
};

const trackAddedFeedPosts = posts => {
    posts.map(post => {
        const gtmTypeDesc =
            post.kind === "text"
                ? post.article
                    ? "long post"
                    : post.imageUrl
                    ? "image post"
                    : "short post"
                : "event";
        GTM.dispatch({
            event: "postAddedToFeed",
            actionType: `Added to main feed - ${gtmTypeDesc}`,
            postId: `${post.id}`,
            postName: `${
                post.kind === "text"
                    ? post.description
                          .replace(/<[^>]+>/g, " ")
                          .replace(/\s\s+/g, " ")
                          .trim()
                          .split(/\s+/)
                          .slice(0, 10)
                          .join(" ")
                          .concat([" ..."].join(""))
                    : post.name
            }`,
            channelId: `${post.channel.id}`,
            channelName: `${post.channel.name}`,
        });

        return true;
    });
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createFeed.type:
            trackAddedFeedPosts(payload);
            return {
                ...state,
                feed: mergeByIds(state.feed || [], payload),
                page: state.page + 1,
                hasMore: payload && payload.length === 25,
            };
        case actionCreators.initializeFeed.type:
            return initialState;
        case actionCreators.refreshFeed.type:
            return {
                ...state,
                isRefreshing: payload.isRefreshing,
                from: payload.from ? payload.from : state.from,
            };
        case actionCreators.resetFeed.type:
            return {
                ...state,
                feed: initialState.feed,
                page: initialState.page,
                hasMore: initialState.hasMore,
                isRefreshing: initialState.isRefreshing,
            };
        case actionCreators.cleanUpdateFeed.type:
            trackAddedFeedPosts(payload);
            return {
                ...state,
                feed: payload,
                page: 2,
                hasMore: payload && payload.length === 25,
                isRefreshing: false,
            };
        case actionCreators.updateBookmarkedFeedPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === payload.id ? { ...post, isFavourite: true } : post
                      )
                    : initialState.feed,
            };
        case actionCreators.updateUnbookmarkedFeedPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === payload.id ? { ...post, isFavourite: false } : post
                      )
                    : initialState.feed,
            };
        case actionCreators.updateLikedFeedPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === payload.id
                              ? { ...post, isLiked: true, numberOfLikes: post.numberOfLikes + 1 }
                              : post
                      )
                    : initialState.feed,
            };
        case actionCreators.updateUnlikedFeedPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === payload.id
                              ? { ...post, isLiked: false, numberOfLikes: post.numberOfLikes - 1 }
                              : post
                      )
                    : initialState.feed,
            };
        case actionCreators.updateBookedFeedPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === payload.id ? { ...post, isBooked: true } : post
                      )
                    : initialState.feed,
            };
        case actionCreators.setFeedFilter.type:
            return {
                ...state,
                filter: payload,
                hasMore: false,
                page: 1,
                feed: null,
            };
        case actionCreators.updateNumCommentsPost.type:
            return {
                ...state,
                feed: state.feed
                    ? state.feed.map(post =>
                          post.id === Number(payload.id)
                              ? {
                                    ...post,
                                    numComments: post.numComments + payload.count,
                                }
                              : post
                      )
                    : initialState.feed,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
