import { colors } from "../../common/styles/Colors";
import { spacing } from "../../common/styles/Spacing";
import { fontSize, fontWeight, fontFamily, lineHeight } from "../../common/styles/Typography";
import { resetLineHeight } from "../../common/styles/Globals";

export const calendarPage = {
    body: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space8}px`,
        },
    },
    bodyMonth: {
        position: "relative",
        // padding: "328px 0 0",
        marginTop: 56,
        "@media (max-width: 1023px)": {
            marginTop: 56,
            paddingBottom: spacing.space8,
        },
    },
    bodyAddEvent: {
        position: "relative",
        padding: `130px 0 ${spacing.space2}px`,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space8}px`,
        },
    },
    bodyMobileWhite: {
        "@media (max-width: 1023px)": {
            backgroundColor: colors.white,
            minHeight: "100vh",
        },
    },
    inner: {
        position: "relative",
        paddingTop: spacing.space3,
    },
    fullList: {
        paddingTop: spacing.space1comma5,
        "@media (max-width: 1023px)": {
            paddingTop: spacing.space1,
        },
    },
    noResults: {
        paddingTop: spacing.space4,
        "@media (max-width: 1023px)": {
            paddingTop: spacing.space2,
        },
    },
    list: {
        // paddingTop: spacing.space2comma5,
        // "@media (max-width: 1023px)": {
        //     paddingTop: spacing.space1,
        // },
    },
    listActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 16,
        paddingBottom: 8,
        "@media (max-width: 1023px)": {
            paddingTop: 8,
            paddingBottom: 0,
        },
        "& button": {
            color: colors.grey3,
            backgroundColor: "transparent",
            border: "0 none",
            padding: 8,
            fontSize: 12,
            fontWeight: fontWeight.normal,
            display: "flex",
            alignItems: "center",
            gap: 16,
        },
        "& b": {
            fontWeight: fontWeight.normal,
            letterSpacing: 0.22,
        },
        "& span": {
            fontSize: 24,
            paddingBottom: 4,
        },
    },
    event: {
        paddingTop: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 10,
            backgroundColor: "white",
        },
    },
    invitees: {
        paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 10,
            backgroundColor: "white",
        },
    },
    text: {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space2,
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textBig,
        fontWeight: fontWeight.medium,
        marginBottom: spacing.space3,
    },
    content: {
        backgroundColor: colors.white,
        minHeight: "100vh",
    },
    introText: {
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    calendarType: {
        width: 214,
        position: "absolute",
        top: spacing.space2,
        right: 0,
        "@media (max-width: 1023px)": {
            width: "auto",
            position: "relative",
            top: "auto",
            right: "auto",
            marginBottom: spacing.space1,
        },
    },
    officeHoursList: {
        // marginTop: spacing.space2,
    },
};

export const calendar = {
    body: {
        flex: 1,
        backgroundColor: colors.black5,
    },
    list: {
        paddingBottom: spacing.space8,
    },
    listInner: {
        paddingBottom: spacing.space6,
    },
};

export const calendarList = {
    body: {},
    month: {
        paddingTop: spacing.space1,
        paddingBottom: spacing.space1,
    },
    monthText: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: 20,
        fontWeight: fontWeight.bold,
    },
};

export const calendarType = {
    root: {
        display: "flex",
        alignItems: "center",
    },
    dot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginRight: 6,
    },
    label: {
        fontSize: 12,
        color: colors.black54,
    },
};

export const dayItem = {
    body: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    date: {
        width: 50,
    },
    dateNumber: {
        color: "#FF2D55",
        fontSize: 28,
        fontWeight: fontWeight.black,
        lineHeight: 1,
    },
    dateDay: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.light,
        lineHeight: 1,
    },
    event: {
        flex: 1,
        paddingTop: spacing.space1comma5,
    },
    empty: {
        width: "100%",
    },
};

export const eventItem = {
    body: {
        display: "flex",
        flexDirection: "column",
        color: colors.black87,
        marginBottom: spacing.space1,
        textDecoration: "none",
    },
    bodyContainer: {
        backgroundColor: colors.white,
        display: "flex",
        flexDirection: "row",
        paddingTop: spacing.space1comma5,
        paddingRight: 10,
        paddingBottom: spacing.space0,
        paddingLeft: 10,
    },
    inner: {
        flex: 1,
    },
    kad: {
        textAlign: "right",
        marginLeft: spacing.space2,
        fontSize: 1,
        lineHeight: 1,
    },
    kadLabel: {
        color: colors.black50,
        fontSize: 10,
        fontWeight: fontWeight.normal,
        lineHeight: 1.1,
        paddingTop: spacing.space0 + 2,
    },
    title: {
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
    },
    timeLocation: {
        color: colors.black54,
        display: "flex",
        alignItems: "center",
        fontSize: fontSize.textSmall,
        paddingTop: spacing.space0,
    },
    timeLocationColumn: {
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: spacing.space1comma5,
    },
    time: {
        marginRight: spacing.space0 + 2,
    },
    timeColumn: {
        marginBottom: spacing.space0,
    },
    location: {
        fontSize: fontSize.textSmall,
        fontStyle: "italic",
    },
    desc: {
        lineHeight: 1.4,
    },
    bottomShape: {
        ...resetLineHeight,
        backgroundColor: colors.white,
        height: 8,
    },
    calendarType: {
        marginTop: spacing.space0comma5,
    },
};

export const eventFull = {
    body: {
        backgroundColor: colors.white,
        flexGrow: 1,
        flex: 1,
    },
    paddingTabBar: {
        paddingBottom: spacing.space6,
    },
    section: {
        width: "100%",
        display: "flex",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        margin: 0,
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: "0 none",
        borderLeft: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        textAlign: "left",
        whiteSpace: "pre-line",
    },
    sectionDate: {
        paddingTop: spacing.space2comma5 - 8,
        paddingBottom: spacing.space1,
    },
    sectionBorder: {
        borderBottom: `1px solid #8d8d8d`,
    },
    icon: {
        width: 20,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        marginRight: spacing.space2,
    },
    inner: {
        flex: 1,
    },
    innerIndent: {
        paddingLeft: 20 + spacing.space2,
    },
    label: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        marginBottom: spacing.space1comma5,
    },
    text: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
        lineHeight: 1.25,
        textDecoration: "none",
    },
    textPlaceholder: {
        color: colors.black50,
    },
    textBigLineHeight: {
        lineHeight: 1.5,
    },
    calendarType: {
        marginTop: 4,
    },
    field: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: spacing.space2,
    },
    fieldDate: {
        flex: 1,
        borderBottom: `1px solid #8d8d8d`,
        paddingBottom: spacing.space1,
        "@media (max-width: 370px)": {
            width: "48%",
            // flex: 0,
        },
    },
    fieldDateMobile: {
        "@media (max-width: 370px)": {
            width: "100%",
            flex: 1,
        },
    },
    fieldTime: {
        width: "40%",
        marginLeft: spacing.space3,
        borderBottom: `1px solid #8d8d8d`,
        "@media (max-width: 375px)": {
            // width: "70%",
            width: "45%",
            marginLeft: spacing.space2,
            flex: 1,
        },
    },
    fieldTimeDivider: {
        display: "inline-flex",
        paddingRight: spacing.space1,
        paddingLeft: spacing.space1,
        "@media (max-width: 370px)": {
            paddingRight: spacing.space0,
            paddingLeft: spacing.space0,
        },
    },
    fieldTimeAmPm: {
        marginLeft: spacing.space1,
    },
};

export const inviteesList = {
    list: {
        flex: 1,
    },
    listInner: {
        paddingBottom: spacing.space6,
    },
    title: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        padding: spacing.space2,
        backgroundColor: colors.black2,
        borderBottom: `1px solid ${colors.greyDark10}`,
    },
};

export const invitedPerson = {
    body: {
        display: "flex",
        width: "100%",
        backgroundColor: colors.white,
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        alignItems: "stretch",
        outline: "0 none",
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: `1px solid ${colors.greyDark10}`,
        borderLeft: "0 none",
        "@media (max-width: 1023px)": {
            borderRight: "0 none",
            borderLeft: "0 none",
        },
    },
    bodyLeft: {
        width: 40,
        height: 40,
        borderRadius: 20,
        overflow: "hidden",
        marginRight: spacing.space2,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.normal,
    },
    bodyRight: {
        display: "flex",
        alignItems: "center",
    },
    btnRemove: {
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
    },
};

export const calendarBoard = {
    bg: {
        backgroundColor: colors.white,
        boxShadow: "0 1px 1px 0 rgba(0,0,0,0.06)",
        paddingLeft: 300,
        paddingBottom: spacing.space4,
        width: "100%",
        // height: 370,
        position: "fixed",
        top: 56,
        right: 0,
        left: 0,
        zIndex: 100,
        overflow: "hidden",
        "@media (max-width: 1023px)": {
            paddingLeft: 0,
            top: 56,
            paddingBottom: 0,
            // height: 305,
        },
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 1023px)": {
            justifyContent: "center",
        },
    },
    rowCenter: {
        justifyContent: "center",
    },
    rowLeft: {
        paddingLeft: spacing.space2,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    rowRight: {
        width: 340,
        overflow: "hidden",
        paddingTop: spacing.space1 + 2,
        paddingBottom: spacing.space1,
        "@media (max-width: 1023px)": {
            paddingTop: 0,
            paddingBottom: spacing.space2,
        },
    },
    rowTitle: {
        display: "flex",
        alignItems: "center",
        marginTop: spacing.space4 - 2,
        marginBottom: spacing.space0 + 2,
        "& h3": {
            fontSize: 20,
            marginTop: 0,
            marginBottom: 0,
        },
    },
    rowBtn: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        border: 0,
        backgroundColor: "transparent",
        padding: 0,
        marginTop: 0,
    },
    rowBtnIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
    },
    rowTodayBtn: {
        paddingTop: spacing.space1comma5,
    },
    body: {
        backgroundColor: colors.white,
        // padding: spacing.space2,
        "@media (max-width: 1023px)": {
            // paddingTop: spacing.space2,
        },
    },
    weekHead: {
        display: "flex",
        justifyContent: "space-between",
    },
    dayHead: {
        display: "flex",
        flex: 1,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: spacing.space0comma5,
    },
    dayHeadText: {
        color: colors.accentF50,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.black,
        lineHeight: 1,
        // "@media (max-width: 1023px)": {
        //     color: colors.accentF,
        //     fontWeight: fontWeight.bold,
        // },
    },
    dayHeadTextWeekend: {
        color: colors.black20,
    },
    weekRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    dayRow: {
        cursor: "pointer",
        display: "flex",
        flex: 1,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        "@media (max-width: 1023px)": {
            height: 32,
        },
    },
    dayRowItem: {
        display: "flex",
        flexDirection: "column",
        borderRadius: 20,
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 1023px)": {
            height: 32,
        },
    },
    dayRowText: {
        color: colors.black87,
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1,
        paddingTop: 6,
        position: "relative",
        zIndex: 2,
    },
    dayRowTextWeekend: {
        color: colors.black54,
    },
    currentDay: {
        backgroundColor: colors.black5,
        width: 36,
        height: 36,
        borderRadius: 18,
        pointerEvents: "none",
        position: "absolute",
        zIndex: 1,
    },
    currentDayText: {
        color: colors.error,
        fontWeight: fontWeight.bold,
    },
    pastDate: {
        opacity: 0.5,
    },
    hasEvent: {
        display: "flex",
        height: 4,
        marginTop: 1,
        position: "relative",
        zIndex: 2,
    },
    bullet: {
        display: "flex",
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: colors.accentF,
    },
    bulletGray: {
        display: "flex",
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: colors.greyDark30,
    },
    bottomShape: {
        height: 16,
        paddingLeft: 300,
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        "@media (max-width: 1023px)": {
            paddingLeft: 0,
            display: "none",
        },
    },
};

export const calendarBoardNav = {
    body: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: spacing.space3,
        // paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        // paddingLeft: spacing.space2,
        // "@media (max-width: 1023px)": {
        //     display: "none",
        // },
    },
    bodyTitle: {
        display: "none",
        justifyContent: "center",
        paddingTop: spacing.space3,
        "@media (max-width: 1023px)": {
            display: "flex",
        },
    },
    nav: {
        cursor: "pointer",
        display: "flex",
        width: 24,
        height: 24,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
        padding: 0,
    },
    title: {
        color: colors.grey3,
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.normal,
    },
};

export const calendarSync = {
    body: {
        flex: 1,
        minHeight: "100vh",
    },
    paddingTabBar: {
        paddingTop: spacing.space10,
        paddingBottom: spacing.space9,
        "@media (max-width: 1023px)": {
            paddingTop: spacing.space10,
            paddingRight: spacing.space2,
            paddingLeft: spacing.space2,
        },
    },
    paddingBottom: {
        paddingBottom: spacing.space2,
    },
    thinBorder: {
        border: "solid 1px #eee",
    },
    content: {},
    divider: {
        marginTop: spacing.space3,
        marginBottom: spacing.space3,
    },
};

export const slotItem = {
    container: {
        display: "flex",
        flexDirection: "column",
        color: colors.black87,
        marginBottom: spacing.space1,
        textDecoration: "none",
    },
    inner: {
        backgroundColor: colors.white,
        display: "flex",
        flexDirection: "row",
        paddingTop: spacing.space1comma5,
        paddingRight: 10,
        paddingBottom: spacing.space1comma5,
        paddingLeft: 10,
    },
    body: {
        display: "flex",
        flex: 1,
        "@media (max-width: 480px)": {
            flexDirection: "column",
        },
    },
    textBorder: {
        borderLeft: `2px solid ${colors.black5}`,
        marginLeft: spacing.space1comma5,
        paddingLeft: spacing.space2,
        paddingRight: spacing.space1comma5,
    },
    text: {
        paddingLeft: spacing.space0comma5,
        paddingRight: spacing.space1comma5,
        "@media (max-width: 480px)": {
            marginTop: spacing.space1comma5,
            marginLeft: 0,
        },
    },
    textItem: {
        marginTop: spacing.space1,
        fontSize: fontSize.textSmall,
        color: colors.black54,
    },
    time: {
        fontSize: fontSize.textSmall,
        whiteSpace: "nowrap",
        "@media (min-width: 768px)": {
            maxWidth: 65,
            whiteSpace: "normal",
        },
    },
    title: {
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
    },
    subtitle: {
        fontSize: fontSize.textSmall,
        fontStyle: "italic",
    },
    bottomShape: {
        ...resetLineHeight,
        backgroundColor: colors.white,
        height: 8,
    },
};

export const bookingConfirmedContainer = {
    body: {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space3,
    },
    title: {
        marginTop: spacing.space5,
        marginBottom: spacing.space7,
        "@media (max-width: 1023px)": {
            marginTop: spacing.space3,
            marginBottom: spacing.space3,
        },
    },
    titleText: {
        fontSize: fontSize.text,
        lineHeight: 1.25,
        textAlign: "center",
    },
    card: {
        backgroundColor: colors.white,
        padding: spacing.space2,
        borderRadius: 4,
        boxShadow: " 0 0 2px 0 rgba(0,0,0,0.12)",
        position: "relative",
    },
    label: {
        fontSize: fontSize.textSmall,
        fontWeight: "normal",
        color: colors.black20,
        marginBottom: spacing.space0comma5,
    },
    labelTwo: {
        fontSize: fontSize.textSmall,
        fontWeight: "normal",
        color: colors.black50,
        marginTop: spacing.space2,
        marginBottom: spacing.space0comma5,
    },
    content: {
        fontSize: fontSize.text,
        lineHeight: 1.5,
        fontWeight: "normal",
        color: colors.black87,
    },
    icon: {
        width: 28,
        height: 28,
        position: "absolute",
        top: spacing.space2,
        right: spacing.space2,
    },
};

export const iconTextBox = {
    list: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "1fr",
        gap: 24,
        margin: "24px 0",
        padding: "0 0 24px",
        listStyle: "none",
        borderBottom: `1px solid ${colors.black5}`,
        "@media (max-width: 640px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 8,
        },
    },
    item: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        border: `1px solid ${colors.black12}`,
        padding: 16,
        "@media (max-width: 640px)": {
            padding: 8,
        },
    },
    title: {
        fontSize: "1.5rem",
        // marginBottom: 8,
    },
    text: {
        fontSize: "0.75rem",
        marginBottom: 4,
    },
    textAlt: {
        color: colors.black54,
        fontSize: "0.9rem",
    },
};

export const ohListItem = {
    body: {
        display: "flex",
        width: "100%",
        backgroundColor: colors.white,
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        alignItems: "stretch",
        outline: "0 none",
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottom: `1px solid ${colors.greyDark10}`,
        borderLeft: "0 none",
        "@media (max-width: 1023px)": {
            borderRight: "0 none",
            borderLeft: "0 none",
        },
    },
    bodyLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        marginRight: spacing.space1,
        marginLeft: -spacing.space1,
    },
    bodyCenter: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actionLeft: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        backgroundColor: "transparent",
        border: 0,
        outline: 0,
        margin: 0,
        padding: 0,
    },
    title: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.bold,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textSmall,
        fontWeight: fontWeight.normal,
    },
    bodyRight: {
        display: "flex",
        alignItems: "center",
    },
    btnRemove: {
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
    },
};
