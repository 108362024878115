import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { selectField } from "./FormFieldsStyles";

class RenderSelectField extends Component {
    render() {
        const { children, classes, onChange, ...custom } = this.props;

        return (
            <Select
                native
                disableUnderline
                classes={{
                    root: classes.root,
                    select: classes.select,
                }}
                onChange={onChange}
                {...custom}
            >
                {children}
            </Select>
        );
    }
}

export default withStyles(selectField)(RenderSelectField);
