import { spacing } from "../../styles/Spacing";
import { fontWeight } from "../../styles/Typography";
import { colors } from "../../styles/Colors";

export const buttonFloating = {
    body: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: 1,
        fontWeight: fontWeight.normal,
        lineHeight: 1,
        color: colors.black87,
        textDecoration: "none",
        minWidth: 56,
        height: 56,
        borderRadius: 28,
        backgroundColor: "#E0112B",
        boxShadow: "0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24)",
        border: "0 none",
        position: "fixed",
        right: spacing.space3,
        bottom: spacing.space3,
        zIndex: 500,
        "@media (max-width: 1023px)": {
            right: spacing.space2,
            bottom: spacing.space8,
        },
    },
    bodyLabel: {
        paddingRight: spacing.space3,
        paddingLeft: spacing.space3,
    },
    icon: {},
    label: {
        fontSize: 16,
        fontWeight: fontWeight.medium,
        color: colors.white,
        marginLeft: spacing.space1comma5,
    },
};
