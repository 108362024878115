import { spacing } from "../../common/styles/Spacing";
import { colors } from "../../common/styles/Colors";
import { fontSize, lineHeight, fontWeight } from "../../common/styles/Typography";

export const myProgrammeStyles = {
    body: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: `106px 0 ${spacing.space6 - 1}px`,
        },
    },
    bodySubpage: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: "56px 0 0",
        },
    },
    bodyNoPadding: {
        position: "relative",
        padding: 0,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space6 - 1}px`,
        },
    },
    inner: {
        // paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
    },
    innerNoPaddingOnMobile: {
        // paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    programme: {
        marginTop: spacing.space1,
        color: colors.accentA,
        fontSize: fontSize.text,
        fontWeight: fontWeight.black,
        lineHeight: lineHeight.textSmaller,
        textAlign: "center",
    },
    department: {
        color: colors.black87,
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.light,
        marginTop: spacing.space1,
        lineHeight: lineHeight.text,
        textAlign: "center",
    },
    programmeYear: {
        color: colors.black87,
        fontSize: fontSize.title,
        fontWeight: fontWeight.black,
        lineHeight: lineHeight.text,
        textAlign: "center",
    },
    titleBox: {
        marginTop: spacing.space6,
        marginBottom: spacing.space3,
    },
    studentBox: {
        marginTop: spacing.space2,
        marginBottom: spacing.space4,
    },
    bodyPlaceholderPadding: {
        position: "relative",
        padding: 0,
        paddingTop: 140,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space6 - 1}px`,
        },
    },
    studentProfile: {
        position: "relative",
        padding: 0,
        paddingTop: 120,
        "@media (max-width: 1023px)": {
            padding: `56 100 56`,
        },
        textAlign: "center",
    },
    studentPhoto: {
        margin: "0 auto 16px auto",
    },
    studentName: {
        color: colors.black87,
        fontSize: fontSize.textHuge,
        fontWeight: fontWeight.black,
        lineHeight: lineHeight.text,
        textAlign: "center",
    },
    divider: {
        margin: "24px 100px",
        borderColor: colors.black10,
        border: "none",
        height: 1,
        color: colors.black10,
        backgroundColor: colors.black10,

        "@media (max-width: 767px)": {
            margin: "16px 0",
        },
    },
    studentAbout: {
        color: colors.black87,
        fontSize: fontSize.text,
        fontWeight: fontWeight.normal,
        lineHeight: lineHeight.text,
        textAlign: "center",
        margin: "0 60px",
    },
    socialBox: {
        marginTop: spacing.space4,
        marginBottom: spacing.space6,
    },
    socialBoxButton: {
        border: 0,
        borderColor: "transparent",
        background: "none",
        marginRight: spacing.space5,
        "&:last-of-type": {
            marginRight: 0,
        },
        maxWidth: 44,
    },
};
