import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarSync, calendarPage } from "./CalendarStyles";
import actionCreators from "../../store/Calendar/Sync/actionCreators";
import CalendarBar from "./CalendarBar";
import HeaderCalendarMobile from "../../common/components/Header/HeaderCalendarMobile";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { Container } from "../../common/components/UI/Container";
import GTM from "../../common/services/GTM";

class CalendarSynciOS extends Component {
    componentDidMount() {
        const { getSyncDetails } = this.props;
        getSyncDetails();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/sync-ios`,
        });
    }

    render() {
        const { details } = this.props;

        return (
            <div css={calendarSync.body}>
                <HeaderCalendarMobile />
                <CalendarBar />
                <Container onMobileNoPadding width={592} css={calendarPage.content}>
                    <div css={calendarSync.paddingTabBar}>
                        {details && (
                            <div css={calendarSync.content} className="entryText">
                                <p>
                                    Here’s how to sync your Student Hub calendars with the Calendar
                                    app on your iPhone or iPad.
                                </p>
                                <hr />
                                <p>
                                    1. On your iPhone or iPad tap on <strong>Settings</strong>.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fc44503243.png"
                                        css={{
                                            width: 300,
                                            height: 170,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    2. From the Setting menu choose <strong>Calendar</strong> and
                                    then <strong>Accounts</strong>
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_accounts.PNG"
                                        css={{
                                            width: 300,
                                            height: 467,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    3. Tap on <strong>Add Account</strong>.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_addacc.PNG"
                                        css={{
                                            width: 300,
                                            height: 276,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    4. Choose <strong>Other</strong> from the types of accounts.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fc5568ce8a.png"
                                        css={{
                                            width: 300,
                                            height: 406,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    5. Select <strong>Add Subscribed Calendar</strong>.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_addsub.PNG"
                                        css={{
                                            width: 300,
                                            height: 402,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    6. In the <strong>Server</strong> field copy and paste the
                                    following link. This will sync all your Student Hub calendar
                                    events with your iOS Calendar.
                                </p>
                                <div className="dataBlock">{details.export_all_calendars}</div>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_addsub_url.PNG"
                                        css={{
                                            width: 300,
                                            height: 187,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />

                                <p>
                                    7. On the follow-up screen accept all default settings and tap
                                    on <strong>Save</strong>
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_addsub_followup.PNG"
                                        css={{
                                            width: 300,
                                            height: 350,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>8. You will see a new calendar subscription in the app.</p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_ios_subadded.png"
                                        css={{
                                            width: 300,
                                            height: 309,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <p>You&apos;re done!</p>
                                <hr />

                                <p>
                                    <strong>Advanced option</strong>
                                </p>
                                <p>
                                    If you have subscribed to the calendar URL in step 6, this will
                                    already include <strong>all</strong> the categories of events
                                    shown below.
                                </p>
                                <p>
                                    If instead you want to sync specific calendars (e.g. only your
                                    Timetables or Appointments calendars), then follow steps 1 to 8
                                    to subscribe to each calendar, using the following URLs:
                                </p>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Timetables</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_timetables}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>My Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_my_events}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Appointments</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_appointments}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Moodle dates</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_key_academic_dates}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>LSE Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_lse_events}</div>
                            </div>
                        )}
                    </div>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        sync: { details },
    },
}) => ({
    details,
});

const mapDispatchToProps = {
    getSyncDetails: actionCreators.getSyncDetails.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSynciOS);
