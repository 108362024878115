import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import { buttonShape } from "./ButtonShapeStyles";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

class ButtonShape extends Component {
    render() {
        const {
            to,
            type,
            children,
            style,
            fullWidth,
            disabled,
            primary,
            noCaps,
            ...other
        } = this.props;

        return (
            <ButtonBase
                // component={to && Link}
                // to={to}
                onClick={() => history.push(to)}
                type={type}
                disabled={disabled}
                disableRipple={true}
                css={{
                    ...buttonShape.body,
                    ...(disabled
                        ? buttonShape.bodyDisabled
                        : primary
                        ? buttonShape.bodyPrimary
                        : buttonShape.bodyMain),
                    ...(fullWidth && buttonShape.bodyFullWidth),
                    ...(primary && buttonShape.bodyPrimary),
                    ...(disabled && buttonShape.bodyDisabled),
                    ...style,
                }}
                {...other}
            >
                <span css={buttonShape.topShape}>
                    <svg
                        focusable="false"
                        preserveAspectRatio="none"
                        viewBox="0 0 360 16"
                        width="100%"
                        height="16"
                    >
                        <path
                            d="M0 0l360 16H0z"
                            fill={disabled ? colors.black10 : primary ? "#00866F" : "#00565F"}
                        />
                    </svg>
                </span>
                <span
                    css={{
                        ...buttonShape.text,
                        ...(!noCaps && buttonShape.textUpper),
                        ...(disabled && buttonShape.textDisabled),
                    }}
                >
                    {children}
                </span>
            </ButtonBase>
        );
    }
}

export default ButtonShape;
