import { spacing } from "../../../../common/styles/Spacing";
import { colors } from "../../../../common/styles/Colors";

export const GroupFeedbackFormStyle = {
    body: {
        paddingTop: spacing.space4,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    row: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: -spacing.space2,
        marginRight: -spacing.space2,
    },
    col: {
        flexBasis: "50%",
        maxWidth: "50%",
        flex: 1,
        paddingLeft: spacing.space2,
        paddingRight: spacing.space2,
        "@media (max-width: 768px)": {
            flexBasis: "100%",
            maxWidth: "100%",
        },
    },
    colFullWidth: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    selectWrap: {
        color: colors.black87,
        minHeight: 80,
    },
    select: {
        backgroundColor: colors.transparent,
        width: "100%",
        border: 0,
        borderRadius: 0,
        boxShadow: "none",
        margin: 0,
        borderBottom: `1px solid ${colors.black12}`,
        outline: 0,
        font: "inherit",
        padding: "6px 30px 7px 0",
        display: "block",
        verticalAlign: "middle",
        WebkitAppearance: "none",
    },
    selectLabel: {
        color: colors.black54,
        fontSize: 12,
    },
    selectBox: {
        position: "relative",
    },
    selectBoxIcon: {
        pointerEvents: "none",
        position: "absolute",
        top: "50%",
        right: spacing.space1,
        zIndex: 10,
        transform: "translateY(-50%)",
    },
};
