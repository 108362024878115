import { truncate, resetLineHeight } from "../../common/styles/Globals";
import { spacing } from "../../common/styles/Spacing";
import { colors, gradients } from "../../common/styles/Colors";
import { fontWeight, fontSize, lineHeight, fontFamily } from "../../common/styles/Typography";
import channelShapeDefault from "../../common/images/channel-shape-default.svg";
import channelShape from "../../common/images/channel-shape.svg";

export const channel = {
    body: {
        backgroundColor: colors.white,
        // marginTop: spacing.space2,
        // marginBottom: spacing.space2,
        // boxShadow: "0 0 2px 0 rgba(0,0,0,0.12)",
        overflow: "hidden",
        position: "relative",
        marginBottom: spacing.space1,
        "@media (max-width: 1023px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    backButtonContainer: {
        width: 24,
        height: 24,
    },
    backButton: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
    inner: {
        "@media (max-width: 1023px)": {
            paddingBottom: spacing.space6,
        },
    },
};

export const channelCover = {
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        backgroundColor: "#34CCB8",
        backgroundPosition: "50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        minHeight: 250,
    },
    bodyBig: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        backgroundColor: "#34CCB8",
        backgroundPosition: "50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        minHeight: 300,
    },
    inner: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100%",
        paddingTop: spacing.space9,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        position: "relative",
        zIndex: 10,
    },
    shape: {
        backgroundPosition: "0 0",
        backgroundRepeat: "no-repeat",
        backgroundSize: "560px 176px",
        width: "100%",
        height: 176,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 8,
    },
    shapeDefault: {
        backgroundImage: `url(${channelShapeDefault})`,
    },
    shapeImg: {
        backgroundImage: `url(${channelShape})`,
    },
    gradient: {
        // display: "none",
        backgroundImage: gradients.black,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 5,
    },
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 36,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        position: "absolute",
        top: spacing.space2,
        right: 0,
        left: 0,
        zIndex: 20,
    },
    avatar: {
        marginBottom: spacing.space2comma5,
    },
    title: {
        color: colors.white,
        fontSize: 20,
        fontWeight: fontWeight.black,
        lineHeight: 1.2,
        paddingRight: "50%",
        "@media (max-width: 1023px)": {
            paddingRight: 0,
        },
    },
};

export const channelFollow = {
    body: {
        width: "100%",
        paddingTop: spacing.space1,
        paddingRight: spacing.space1,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space1,
        position: "relative",
        zIndex: 10,
    },
    inner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 32,
        // borderBottom: defaultBorder,

        "@media (max-width: 1023px)": {
            // alignItems: "flex-end",
        },
    },
    elementLeft: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
    },
    elementRight: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 1023px)": {
            justifyContent: "flex-end",
            // width: "50%",
            // minHeight: 32,
            position: "relative",
        },
    },
    numFollowers: {
        color: colors.white,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.bold,
        marginLeft: spacing.space1,
    },
    activity: {
        color: colors.white80,
        fontSize: fontSize.textSmaller,
        marginTop: spacing.space0comma5,
        marginLeft: spacing.space1,

        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    activityMobile: {
        display: "none",
        color: colors.white80,
        fontSize: fontSize.textSmaller,
        marginTop: spacing.space1,
        marginLeft: spacing.space1,
        marginBottom: spacing.space1,

        "@media (max-width: 1023px)": {
            display: "block",
        },
    },
    actions: {
        display: "flex",
    },
    btn: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
    },
    btnText: {
        fontSize: fontSize.textSmall,
        lineHeight: "1",
        marginRight: spacing.space1,
    },
    btnIcon: {
        color: colors.accentB,
    },
    followBtn: {
        marginLeft: spacing.space1,
    },
    invitationBtn: {
        marginLeft: spacing.space1,
    },
    memberLabel: {
        marginLeft: spacing.space2comma5,
        marginRight: spacing.space1,
    },
    iconText: {
        display: "flex",
        alignItems: "center",
        icon: {
            ...resetLineHeight,
            marginRight: spacing.space1,
        },
        text: {
            color: colors.white,
            fontSize: fontSize.textSmall,
            fontWeight: fontWeight.bold,
            letterSpacing: 0.4,
            whiteSpace: "nowrap",
        },
        textItalic: {
            color: colors.white,
            fontSize: fontSize.textSmall,
            fontStyle: "italic",
            letterSpacing: 0.4,
            whiteSpace: "nowrap",
        },
    },
    hideOnMobile: {
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
};

export const bookableInfo = {
    body: {
        marginBottom: spacing.space2,
        backgroundColor: colors.white,
    },
    btn: {
        display: "flex",
        backgroundColor: "#f0f1f3",
        padding: spacing.space2,
    },
    btnText: {
        fontSize: fontSize.h4,
        lineHeight: lineHeight.h4,
        fontWeight: fontWeight.black,
        marginRight: spacing.space0,
    },
    btnIcon: {
        display: "inline-flex",
        height: 24,
        width: 24,
        lineHeight: 1,
    },
    text: {
        padding: spacing.space2,
    },
    disclaimer: {
        color: colors.black54,
        fontSize: fontSize.textSmaller,
        fontStyle: "italic",
        padding: spacing.space2,
        borderTop: `1px solid ${colors.black5}`,
    },
    disclaimerLink: {
        color: colors.black54,
    },
};

export const channelContact = {
    body: {
        paddingTop: spacing.space1comma5,
    },
    link: {
        display: "flex",
        alignItems: "center",
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        textDecoration: "none",
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
        position: "relative",
        minWidth: 0,
    },
    linkIcon: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 24,
        width: 24,
        lineHeight: 1,
        marginRight: spacing.space1,
    },
    linkText: {
        display: "inline-flex",
        flexDirection: "column",
        minWidth: 0,
    },
    linkDetails: {
        ...truncate,
    },
};

export const addPostBtn = {
    body: {
        display: "flex",
        backgroundColor: colors.white,
        borderRadius: 4,
        boxShadow: "0 0 2px 0 rgba(0,0,0,0.12)",
        marginTop: spacing.space1,
        marginBottom: spacing.space1,
        padding: spacing.space2,
        textDecoration: "none",
    },
    avatar: {
        marginRight: spacing.space2,
    },
    label: {
        borderBottomColor: colors.black12,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        paddingLeft: spacing.space1,
        display: "flex",
        flex: 1,
        alignItems: "center",
    },
    labelText: {
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
    },
};

export const addPostPage = {
    body: {
        position: "relative",
        padding: `130px 0 ${spacing.space2}px`,
        "@media (max-width: 1023px)": {
            padding: `56px 0 ${spacing.space8}px`,
            backgroundColor: colors.white,
            height: "100vh",
        },
    },
    btn: {
        marginTop: spacing.space3,
        "@media (max-width: 1023px)": {
            marginTop: 0,
            position: "fixed",
            bottom: 48,
            right: 0,
            left: 0,
        },
    },
    btnRelative: {
        "@media (max-width: 1023px)": {
            marginTop: 0,
            position: "relative",
            bottom: "auto",
            right: "auto",
            left: "auto",
        },
    },
};

export const addPostImg = {
    body: {},
    img: {
        height: 240,
    },
    removePhotoBtn: {
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        // borderRadius: 18,
        // backgroundColor: colors.white,
        // borderColor: colors.black10,
        // borderWidth: 1,
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10,
    },
};

export const addPhotoBtn = {
    body: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        paddingTop: spacing.space1comma5,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1comma5,
        paddingLeft: spacing.space2,
        borderTop: "0 none",
        borderRight: "0 none",
        borderBottomWidth: 1,
        borderBottomColor: colors.black12,
        borderBottomStyle: "solid",
        borderLeft: "0 none",
        width: "100%",
        outline: 0,
        "@media (max-width: 1023px)": {
            backgroundColor: colors.black5,
        },
    },
    icon: {
        width: 40,
        marginRight: spacing.space2,
        "@media (max-width: 1023px)": {
            width: "auto",
        },
    },
    text: {
        color: colors.black50,
        fontSize: fontSize.text,
        "@media (max-width: 1023px)": {
            color: colors.black87,
            fontSize: fontSize.textSmall,
        },
    },
};

export const photoBtn = {
    body: {
        position: "relative",
    },
    btn: {
        // display: "flex",
        // alignItems: "center",
        backgroundColor: "transparent",
        padding: 0,
        // paddingTop: spacing.space1comma5,
        // paddingRight: spacing.space2,
        // paddingBottom: spacing.space1comma5,
        // paddingLeft: spacing.space2,
        border: 0,
        // borderBottomWidth: 1,
        // borderBottomColor: colors.black12,
        // borderBottomStyle: "solid",
        width: "100%",
        outline: 0,
        // "@media (max-width: 1023px)": {
        //     backgroundColor: colors.black5,
        // },
    },
    img: {
        display: "block",
        width: "100%",
        height: "auto",
    },
    btnRemove: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: 0,
        outline: 0,
        width: 24,
        height: 24,
        padding: 0,
        margin: 0,
        position: "absolute",
        top: spacing.space3,
        right: spacing.space3,
        zIndex: 10,
    },
};

export const groupMembersPage = {
    body: {
        padding: "112px 0 0",
        "@media (max-width: 1023px)": {
            padding: "104px 0 64px",
        },
    },
    bodyAddPeople: {
        position: "relative",
        padding: "112px 0 0",
        "@media (max-width: 1023px)": {
            backgroundColor: colors.white,
            minHeight: "100vh",
            padding: "56px 0 64px",
        },
    },
    title: {
        fontFamily: fontFamily.primary,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        padding: spacing.space2,
        marginRight: spacing.space2,
        marginLeft: spacing.space2,
        backgroundColor: colors.black2,
        borderBottom: `1px solid ${colors.greyDark10}`,

        "@media (max-width: 1023px)": {
            marginRight: 0,
            marginLeft: 0,
        },
    },
};
