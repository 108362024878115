/** @jsx jsx */
import { jsx } from "@emotion/core";
import { notificationsStyles } from "../NotificationsStyles";
import Parser from "html-react-parser";
import { useDispatch } from "react-redux";
import actionCreators from "../../../store/Notifications/actionCreators";

const NotificationItem = ({
    notification: {
        id,
        wasSeen,
        deeplinkId,
        deeplinkType,
        deeplinkCalendarType,
        channelId,
        message,
    },
    push,
}) => {
    const dispatch = useDispatch();

    const onPress = (id, deeplinkId, deeplinkType, deeplinkCalendarType, channelId, push) => {
        switch (deeplinkType) {
            case "page":
                if (deeplinkId) push(`/channel/${deeplinkId}`);
                break;

            case "post":
                if (deeplinkId && channelId) push(`/post/${deeplinkId}/${channelId}`);
                break;

            case "event":
                if (deeplinkId && channelId) push(`/event/${deeplinkId}/${channelId}`);
                break;

            case "calendar":
                if (deeplinkCalendarType && deeplinkId)
                    push(`/calendar/event/${deeplinkCalendarType}/${deeplinkId}`);
                break;

            case "channel-office-hours":
                if (deeplinkCalendarType && deeplinkId)
                    push(`office-hours/${deeplinkCalendarType}/${deeplinkId}`);
                break;

            case "channelMemberRequest":
                if (deeplinkId) push(`/group/${deeplinkId}/members`);
                break;

            default:
                break;
        }

        dispatch(actionCreators.setAsReadNotification.create({ id }));
    };

    const NotificationElement = [
        "page",
        "post",
        "event",
        "calendar",
        "channel-office-hours",
        "channelMemberRequest",
    ].includes(deeplinkType)
        ? "a"
        : "div";

    return (
        <NotificationElement
            onClick={() => {
                onPress(id, deeplinkId, deeplinkType, deeplinkCalendarType, channelId, push);
            }}
            css={[notificationsStyles.notification, !wasSeen && notificationsStyles.notSeen]}
            key={id}
        >
            {message && Parser(message)}
            <div css={notificationsStyles.bottomShape}>
                <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                    <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                </svg>
            </div>
        </NotificationElement>
    );
};

export default NotificationItem;
