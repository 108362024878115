import React from "react";
import { TextBlock, RoundShape } from "react-placeholder/lib/placeholders";
import { avatarStyles } from "../SkeletonStyles";
import { colors } from "../../../styles/Colors";

const AvatarPlaceholder = () => {
    return (
        <div>
            <TextBlock rows={1} color={colors.greyDark10} style={avatarStyles.title} />
            <RoundShape color={colors.greyDark10} style={avatarStyles.img} />
        </div>
    );
};

export default AvatarPlaceholder;
