import actionCreators from "./actionCreators";

export const initialState = {
    data: null,
    isDefaultCalendar: true,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateEventsList.type: {
            return {
                ...state,
                data: payload.data.map(subset => {
                    subset.hash = `${payload.start}_${subset.month}`;
                    return subset;
                }),
                errorRes: initialState.errorRes,
            };
        }
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.clearEventsList.type:
            return {
                ...state,
                data: null,
            };

        case actionCreators.setDefaultStatus.type:
            return {
                ...state,
                isDefaultCalendar: payload,
            };
        default:
            return state;
    }
};
