export default {
    id: null,
    name: "",
    page: 1,
    imageUrl: "",
    iconUrl: "",
    description: "",
    groupType: "",
    groupManagedBy: "",
    lastActiveDate: "",
};
