import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import history from "../common/services/history";
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./reducers";
import { rootEpics } from "./epics";

const configureStore = () => {
    const epicMiddleware = createEpicMiddleware();

    const store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(epicMiddleware, routerMiddleware(history)))
    );

    epicMiddleware.run(rootEpics);

    return store;
};

export const store = configureStore();
