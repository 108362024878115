import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import actionCreators from "../../store/Discussion/actionCreators";
import Comment from "./Comment";
import { entryShort } from "../Feed/FeedStyles";

class CommentListContainer extends Component {
    componentDidMount() {
        this.onLoadMore();
    }

    componentWillUnmount() {
        this.props.clearComments();
    }

    onLoadMore = () => {
        const {
            getComments,
            page,
            match: {
                params: { id },
            },
        } = this.props;
        getComments({ page, id });
    };

    render() {
        const {
            comments,
            match: {
                params: { id },
            },
            hasMore,
        } = this.props;
        const { onLoadMore } = this;
        const items = comments.map(comment => (
            <Comment key={comment.id} postId={id} {...{ comment }} />
        ));

        return (
            <Fragment>
                <div css={entryShort.bottomShape}>
                    <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                        <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                    </svg>
                </div>
                <InfiniteScroll
                    dataLength={items.length}
                    next={onLoadMore}
                    {...{ hasMore }}
                    scrollableTarget="scrollableParent"
                >
                    <div>{items}</div>
                </InfiniteScroll>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ Discussion: { comments, page, hasMore, postId } }) => ({
    comments,
    page,
    hasMore,
    postId,
});

const mapDispatchToProps = {
    getComments: actionCreators.getComments.create,
    postComment: actionCreators.postComment.create,
    clearComments: actionCreators.clearComments.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentListContainer);
