import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { reduxForm, Field, change, submit } from "redux-form";
import ContainerImg from "../../../common/components/UI/ContainerImg";
import Icon from "../../../common/components/UI/Icon";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import Avatar from "../../../common/components/UI/Avatar";
import { auth, imageForm } from "../AuthStyles";
import { colors } from "../../../common/styles/Colors";
import { spacing } from "../../../common/styles/Spacing";
import ButtonText from "../../../common/components/UI/ButtonText";
import BaseFileInput from "../../../common/components/FormFields/FileInputBase64";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../../common/components/UI/ModalBox";
import TextError from "../../../common/components/UI/TextError";
import { textFieldInline } from "../../../common/components/FormFields/FormFieldsStyles";
import {
    ContainerHeader,
    ContainerActions,
    Container,
} from "../../../common/components/UI/Container";
import { welcomeStyle } from "../../Dashboard/DashboardStyles";
import AuthContextMenu from "../AuthContextMenu";
import ButtonShape from "../../../common/components/UI/ButtonShape";

class FileInputFields extends Component {
    state = {
        isModalOpen: false,
        photoUrl: null,
    };
    componentDidMount() {
        const {
            user: { photoUrl },
        } = this.props;
        this.setState({ photoUrl });
    }
    changePhoto = photoUrl => {
        this.setState({ photoUrl });
    };
    handleClose = () => {
        this.setState({ isModalOpen: false });
    };
    handleInput = () => {
        document.querySelector("input[type=file]").click();
    };
    confirmRemovePhoto = () => {
        this.props.dispatch(change("register", "profilePhoto", null));
        this.setState({ isModalOpen: false });
        this.setState({ photoUrl: this.props.user.photoUrl });
    };
    componentDidUpdate({ registerForm }) {
        if (
            registerForm !== this.props.registerForm &&
            this.props.registerForm.values.profilePhoto
        ) {
            this.changePhoto(this.props.registerForm.values.profilePhoto.base64);
        }
    }
    render() {
        const {
            handleSubmit,
            submitForm,
            register,
            isLoading,
            errorRes: { errors },
        } = this.props;
        const { handleInput, handleClose, confirmRemovePhoto } = this;
        const { isModalOpen, photoUrl } = this.state;

        return (
            <ContainerImg fullWidth>
                <ContainerHeader height={208} justifyBottom>
                    <div css={welcomeStyle.btnMore}>
                        <AuthContextMenu />
                    </div>
                    <div css={welcomeStyle.subtitle}>Welcome to the</div>
                    <div css={welcomeStyle.title}>Student Hub</div>
                    <div css={welcomeStyle.text}>Personalise your profile with a photo</div>
                </ContainerHeader>
                <form onSubmit={handleSubmit}>
                    <Container width={352} css={auth.content}>
                        <div css={{ display: "none" }}>
                            <Field name="profilePhoto" component={BaseFileInput} />
                        </div>
                        <div css={auth.avatar}>
                            <button type="button" onClick={handleInput} css={imageForm.photoUpload}>
                                <span css={imageForm.icoEdit}>
                                    <Icon name="edit" fill={colors.black} width={17} height={17} />
                                </span>
                                <Avatar
                                    src={photoUrl}
                                    huge
                                    initials={
                                        register.values.firstName.charAt(0) +
                                        register.values.lastName.charAt(0)
                                    }
                                />
                            </button>
                        </div>
                        <TextError css={[textFieldInline.error, imageForm.error]}>
                            {errors.profilePhoto}
                        </TextError>
                    </Container>
                    <ContainerActions>
                        <ButtonsGroup
                            marginBottomValue={spacing.space3}
                            marginBottomValueMobile={1}
                        >
                            {photoUrl !== this.props.user.photoUrl ? (
                                <ButtonShape type="submit" fullWidth disabled={isLoading}>
                                    Continue
                                </ButtonShape>
                            ) : (
                                <ButtonShape onClick={handleInput} fullWidth disabled={isLoading}>
                                    Upload a photo
                                </ButtonShape>
                            )}
                        </ButtonsGroup>
                        <ButtonsGroup
                            marginTopValueMobile={spacing.space1comma5}
                            marginBottomValue={spacing.space3}
                            marginBottomValueMobile={1}
                        >
                            {photoUrl !== this.props.user.photoUrl ? (
                                <ButtonText
                                    onClick={() => this.setState({ isModalOpen: true })}
                                    bold
                                    textStyle={auth.actionLinkBold}
                                >
                                    Remove photo
                                </ButtonText>
                            ) : (
                                <ButtonText
                                    onClick={() => submitForm("register")}
                                    bold
                                    textStyle={auth.actionLinkBold}
                                >
                                    Skip for now
                                </ButtonText>
                            )}
                        </ButtonsGroup>
                    </ContainerActions>
                </form>
                <ModalBox open={isModalOpen} onClose={this.handleClose} modalWidth={310}>
                    <ModalBoxText>
                        <p>
                            <strong>Remove your photo</strong>
                        </p>
                        <p>Are you sure that you want to remove your profile photo?</p>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space6}>
                        <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                            <ButtonShape primary onClick={() => confirmRemovePhoto()} fullWidth>
                                Yes, remove my profile photo
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText color={colors.black50} bold onClick={handleClose}>
                                Cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
            </ContainerImg>
        );
    }
}

const mapStateToProps = ({
    form: { register },
    MainView: { isLoading },
    Register: { errorRes },
}) => ({
    register,
    isLoading,
    errorRes,
});

const mapDispatchToProps = {
    formUpdate: change,
    submitForm: submit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "register",
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })(FileInputFields)
);
