import ActionCreator from "../actionCreator";

export default {
    initializeChannelPosts: ActionCreator("INITIALIZE_CHANNEL_POSTS"),
    refreshChannelPosts: ActionCreator("REFRESH_CHANNEL_POSTS"),
    cleanUpdateChannelPosts: ActionCreator("CLEAN_UPDATE_CHANNEL_POSTS"),
    getChannelPosts: ActionCreator("GET_CHANNEL_POSTS"),
    createChannelPosts: ActionCreator("CREATE_CHANNEL_POSTS"),
    toggleChannelTracking: ActionCreator("TOGGLE_CHANNEL_TRACKING"),
    getChannel: ActionCreator("GET_CHANNEL"),
    createChannel: ActionCreator("CREATE_CHANNEL"),
    updateChannel: ActionCreator("UPDATE_CHANNEL"),
    updateChannelPosts: ActionCreator("UPDATE_CHANNEL_POSTS"),
    loadMoreChannel: ActionCreator("LOAD_MORE_CHANNEL"),
    postFollowStatus: ActionCreator("POST_FOLLOW_STATUS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CHANNEL"),
    updateBookmarkedChannelPost: ActionCreator("UPDATE_BOOKMARKED_CHANNEL_POST"),
    updateUnbookmarkedChannelPost: ActionCreator("UPDATE_UNBOOKMARKED_CHANNEL_POST"),
    updateLikedChannelPost: ActionCreator("UPDATE_LIKED_CHANNEL_POST"),
    updateUnlikedChannelPost: ActionCreator("UPDATE_LIKED_CHANNEL_POST"),
    updateNumCommentsPost: ActionCreator("UPDATE_NUM_COMMENTS_CHANNEL_POST"),
    setChannelOrigin: ActionCreator("SET_CHANNEL_ORIGIN"),
    redirectToChannel: ActionCreator("REDIRECT_TO_CHANNEL"),
    setChannelAsFollowed: ActionCreator("SET_CHANNEL_AS_FOLLOWED"),
    getRecommendedChannels: ActionCreator("GET_RECOMMENED_CHANNELS"),
    updateRecommendedChannels: ActionCreator("UPDATE_RECOMMENED_CHANNELS"),
    updateBookedChannelPost: ActionCreator("UPDATE_BOOKED_CHANNEL_POST"),
};
