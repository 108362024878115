import React, { Component } from "react";
import { connect } from "react-redux";
import { startSubmit, stopSubmit } from "redux-form";
import authActionCreators from "../../../store/Auth/actionCreators";
import registerActionCreators from "../../../store/Auth/Register/actionCreators";
import MainAccountFields from "./MainAccountFields";
import FileInputsFields from "./FileInputFields";
import Credentials from "./Credentials";
import getCurrentFormName from "../../../common/services/currentFormName";
import GTM from "../../../common/services/GTM";

class RegisterForm extends Component {
    componentDidMount() {
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/register`,
        });
    }

    componentDidUpdate(prevProps) {
        const { isFormSubmitting, step } = prevProps;

        if (isFormSubmitting !== this.props.isFormSubmitting) {
            if (this.props.isFormSubmitting) {
                startSubmit(getCurrentFormName(step));
            } else {
                stopSubmit(getCurrentFormName(step));
            }
        }
    }
    submit = values => {
        const { register } = this.props;
        register(values);
    };

    credentialsRegister = ({ toc, ...rest }) => {
        const { login } = this.props;
        rest.allowRedirectToRegistration = true;
        login(rest);
    };

    render() {
        const { step, nextStep, prevStep, user, initialValues, registerForm } = this.props;

        const { credentialsRegister } = this;
        switch (step) {
            case 0:
                return (
                    <Credentials
                        {...{
                            onSubmit: credentialsRegister,
                        }}
                    />
                );
            case 1:
                return (
                    <MainAccountFields
                        {...{
                            onSubmit: nextStep,
                            user,
                            initialValues,
                        }}
                    />
                );
            case 2:
                return (
                    <FileInputsFields
                        {...{
                            onSubmit: this.submit,
                            user,
                            prevStep,
                            registerForm,
                        }}
                    />
                );
            default:
                return <div />;
        }
    }
}

const dispatchToProps = {
    register: registerActionCreators.register.create,
    login: authActionCreators.login.create,
    nextStep: registerActionCreators.nextStep.create,
    prevStep: registerActionCreators.prevStep.create,
};

const mapStateToProps = ({ Register, Auth, form: { register } }) => ({
    step: Register.step,
    registerForm: register,
    user: Auth.user,
    initialValues: {
        firstName: Auth.user.firstName,
        lastName: Auth.user.lastName,
        email: Auth.user.email,
    },
    isFormSubmitting: Auth.isFormSubmitting,
});

export default connect(mapStateToProps, dispatchToProps)(RegisterForm);
