import ActionCreator from "../actionCreator";

export default {
    getComments: ActionCreator("GET_COMMENTS"),
    updateComments: ActionCreator("UPDATE_COMMENTS"),
    postComment: ActionCreator("POST_COMMENT"),
    putComment: ActionCreator("PUT_COMMENT"),
    deleteComment: ActionCreator("DELETE_COMMENT"),
    removeComment: ActionCreator("REMOVE_COMMENT"),
    updateCommentNew: ActionCreator("UPDATE_COMMENT_NEW"),
    updateCommentEdit: ActionCreator("UPDATE_COMMENT_EDIT"),
    setFormParrentId: ActionCreator("SET_FORM_PARRENT_ID"),
    setComment: ActionCreator("SET_COMMENT"),
    toggleActiveForm: ActionCreator("TOGGLE_ACTIVE_FORM"),
    setFormType: ActionCreator("SET_FORM_TYPE"),
    errorResponse: ActionCreator("ERROR_RESPONSE_COMMENTS"),
    clearComments: ActionCreator("CLEAR_COMMENTS"),
};
