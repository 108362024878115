import { spacing } from "../../styles/Spacing";
import { colors } from "../../styles/Colors";

export default {
    // FeedPlaceholder
    feedContainer: {
        flex: 1,
    },
    feedPlaceholder: {
        padding: spacing.space2,
    },

    // CalendarPlaceholder
    calendarContainer: {
        flex: 1,
    },
    calendarPlaceholder: {
        padding: spacing.space2,
    },

    // PostPlaceholder
    postContainer: {
        flex: 1,
    },
    postPlaceholder: {
        padding: spacing.space2,
    },

    // EventPlaceholder
    eventContainer: {
        flex: 1,
    },
    eventPlaceholder: {
        padding: spacing.space2,
    },

    // ChannelsPlaceholder
    channelsContainer: {
        flex: 1,
    },
    channelsPlaceholder: {
        padding: spacing.space2,
    },

    channelContainer: {
        flex: 1,
    },
    channelPlaceholder: {
        padding: spacing.space2,
    },
    channelHeader: {
        height: 306,
        backgroundColor: colors.white,
        padding: spacing.space2,
        marginBottom: spacing.space1,
        "@media (max-width: 1023px)": {
            height: 298,
            marginRight: 0,
            marginLeft: 0,
        },
        "@media (min-width: 1023px)": {
            width: 529,
            // marginRight: spacing.space2,
            // marginLeft: spacing.space2,
            margin: "0 auto 8px",
        },
    },
    // NotificationPlaceholder
    notificationContainer: {
        flex: 1,
    },
    notificationPlaceholder: {
        padding: spacing.space2,
    },

    // TimelinePlaceholder
    timelineContainer: {
        flex: 1,
        backgroundColor: colors.white,
        borderLeftWidth: 3,
        borderLeftColor: colors.greyDark10,
        borderLeftStyle: "solid",
        paddingTop: spacing.space1comma5,
        paddingRight: spacing.space4,
        paddingLeft: spacing.space2,
        height: 60,
        top: 18,
        left: 72,
        right: 0,
        position: "absolute",
        zIndex: 50,
    },
    timelineRow: {
        display: "flex",
        flex: 1,
        marginTop: spacing.space1,
    },
    timelineDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginTop: 3,
        marginRight: spacing.space1,
        backgroundColor: colors.greyDark10,
    },
};

export const avatarStyles = {
    title: {
        marginBottom: spacing.space2,
    },
    img: {
        marginRight: "auto",
        marginLeft: "auto",
        width: 160,
        height: 160,
    },
};

export const coverStyles = {
    title: {
        marginBottom: spacing.space2,
    },
    img: {
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
        height: 236,
    },
};
