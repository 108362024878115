import React from "react";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { coverStyles } from "../SkeletonStyles";
import { colors } from "../../../styles/Colors";

const CoverPlaceholder = () => {
    return (
        <div>
            <TextBlock rows={1} color={colors.greyDark10} style={coverStyles.title} />
            <RectShape color={colors.greyDark10} style={coverStyles.img} />
        </div>
    );
};

export default CoverPlaceholder;
