/** @jsx jsx */
import { jsx } from "@emotion/core";
import { reduxForm, Field } from "redux-form";
import RenderTextFieldInline from "../../../../../common/components/FormFields/TextFieldInline";
import ButtonsGroup from "../../../../../common/components/UI/ButtonsGroup";
import ButtonShape from "../../../../../common/components/UI/ButtonShape";
import { spacing } from "../../../../../common/styles/Spacing";
import { ModalBoxText, ModalBoxActions } from "../../../../../common/components/UI/ModalBox";
import ButtonText from "../../../../../common/components/UI/ButtonText";
import FileInputButton from "../../../../../common/components/FormFields/FileInputButton";
import RenderCheckboxField from "../../../../../common/components/FormFields/CheckboxField";
import { useState, useEffect } from "react";
import { formatDate } from "../../../utils";
import { connect } from "react-redux";
import TextError from "../../../../../common/components/UI/TextError";

const AppointmentForm = ({
    handleSubmit,
    onCancel,
    submitting,
    session,
    isLoading,
    rebookAppointment,
    change,
    formErrors,
}) => {
    const [confirmRebook, setConfirmRebook] = useState(false);

    useEffect(() => {
        if (rebookAppointment) change("rebookAppointment", confirmRebook);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <ModalBoxText>
                <div
                    css={{
                        marginTop: spacing.space2,
                        marginBottom: spacing.space2,
                        paddingTop: spacing.space1,
                        paddingRight: spacing.space1,
                        paddingBottom: spacing.space1 - 2,
                        paddingLeft: spacing.space1,
                        border: "1px solid rgba(0,0,0,0.05)",
                    }}
                >
                    <Field
                        component={RenderTextFieldInline}
                        name="message"
                        placeholder={
                            session.requireBookingNote
                                ? "Booking message (required)"
                                : "Booking message"
                        }
                        multiline
                        meta={{ error: false }} // force-silence errors... (we wil display separatelly below instead)
                    />
                </div>
                {formErrors && formErrors.message && (
                    <div css={{ marginBottom: spacing.space3 }}>
                        <TextError>{formErrors.message}</TextError>
                    </div>
                )}
                {session.allowFileUpload && (
                    <Field
                        name="file"
                        component={FileInputButton}
                        label="Choose file"
                        id="fileBtn"
                    />
                )}
                {rebookAppointment && (
                    <div
                        css={{
                            marginTop: spacing.space3,
                        }}
                    >
                        <Field
                            name="rebookAppointment"
                            label={`This appointment will replace your existing booking on ${formatDate(
                                "dddd D MMMM YYYY",
                                rebookAppointment.startAt
                            )} at ${formatDate("h.mma", rebookAppointment.startAt)}`}
                            selected={confirmRebook}
                            component={RenderCheckboxField}
                            onChange={() => {
                                change("rebookAppointment", !confirmRebook);
                                setConfirmRebook(!confirmRebook);
                            }}
                            topAlign
                        />
                    </div>
                )}
            </ModalBoxText>
            <ModalBoxActions marginTopValue={spacing.space6}>
                <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                    <ButtonShape primary fullWidth type="submit" disabled={submitting || isLoading}>
                        Confirm booking
                    </ButtonShape>
                </ButtonsGroup>
                <ButtonsGroup>
                    <ButtonText bold onClick={onCancel}>
                        Cancel
                    </ButtonText>
                </ButtonsGroup>
            </ModalBoxActions>
        </form>
    );
};

const FORM_NAME = "OfficeBooking";

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values, props) => {
        if (values.rebookAppointment !== undefined) {
            if (!values.rebookAppointment)
                return { rebookAppointment: "Please confirm your appointment change" };
        } else if (props.session.requireBookingNote && !values.message) {
            return { message: "A booking message is required" };
        }

        return {};
    },
})(AppointmentForm);

const mapStateToProps = ({ form }) => ({
    formErrors:
        form[FORM_NAME] && form[FORM_NAME].submitFailed
            ? form[FORM_NAME].syncErrors
                ? form[FORM_NAME].syncErrors
                : {}
            : {},
});

export default connect(mapStateToProps, null)(form);
