export const post = {
    id: null,
    kind: "",
    name: "",
    imageUrl: "",
    description: "",
    article: "",
    location: "",
    startAt: null,
    updatedAt: null,
    channel: {
        id: null,
        name: "",
    },
    isFavourite: null,
    isBooked: null,
};

export const testError = { error: "Error", errors: { name: "Error name", status: "Error status" } };
