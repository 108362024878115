/** @jsx jsx */
import { jsx } from "@emotion/core";
import { memberBar } from "./MemberBarStyles";
import Avatar from "../../components/UI/Avatar";
import Button from "../../components/UI/Button";

const MemberBar = ({ id, photoUrl, firstName, lastName, buttons }) => {
    return (
        <div css={memberBar.body}>
            <span css={memberBar.bodyLeft}>
                <Avatar src={photoUrl} />
            </span>
            <span css={memberBar.bodyRow}>
                <span css={memberBar.bodyCenter}>
                    <span css={memberBar.title}>
                        {firstName} {lastName}
                    </span>
                </span>
                <span css={memberBar.actions}>
                    {buttons.map((current, index) => (
                        <Button
                            key={`${id}-${index}`}
                            outline
                            smaller
                            textIcon={current.icon}
                            onClick={current.action}
                            css={memberBar.btnAction}
                        >
                            {current.label}
                        </Button>
                    ))}
                </span>
            </span>
        </div>
    );
};

export default MemberBar;
