import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { serverError } from "./ServerErrorStyles";

class ServerError extends Component {
    render() {
        return (
            <div css={serverError.body}>
                <h1>Oops...</h1>
                <h3>Something went wrong.</h3>
                {!!this.props.location &&
                this.props.location.state &&
                this.props.location.state.error ? (
                    this.props.location.state.error
                ) : (
                    <p>
                        If it continues please report it using &quot;Feedback&quot; section in main
                        menu!
                    </p>
                )}
            </div>
        );
    }
}

export default ServerError;
