import actionCreators from "./actionCreators";

export const initialState = {
    isAuthenticated: false,
    token: null,
    user: {
        firstName: "",
        lastName: "",
        email: "",
        firstNameLocked: false,
        lastNameLocked: false,
        emailLocked: false,
        photoUrl: "",
    },
    errorRes: { error: "", errors: {} },
    isFormSubmitting: false,
    askForRegistration: false,
    showResetPasswordModal: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.login.type:
            return {
                ...state,
                isFormSubmitting: true,
            };
        case actionCreators.setUser.type:
            return {
                ...state,
                isFormSubmitting: false,
                user: payload || initialState.user,
            };
        case actionCreators.setAuthAndRegistered.type:
            return {
                ...state,
                isAuthenticated: true,
                token: localStorage.getItem("token"),
                errorRes: initialState.errorRes,
                isFormSubmitting: false,
            };
        case actionCreators.setAuthNotRegistered.type:
            return {
                ...state,
                isAuthenticated: true,
                token: localStorage.getItem("token"),
                errorRes: initialState.errorRes,
                isFormSubmitting: false,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                errorRes: payload,
                isFormSubmitting: false,
            };
        case actionCreators.logout.type:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                user: initialState.user,
                errorRes: initialState.errorRes,
                isFormSubmitting: false,
            };
        case actionCreators.updateUser.type:
            return {
                ...state,
                user: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateUserProfile.type:
            return {
                ...state,
                user: {
                    ...state.user,
                    photoUrl: payload.photo ? payload.photo : state.user.photoUrl,
                    firstName: payload.firstName ? payload.firstName : state.user.firstName,
                    lastName: payload.lastName ? payload.lastName : state.user.lastName,
                    email: payload.email ? payload.email : state.user.email,
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.requireRegistrationsTerms.type:
            return {
                ...state,
                askForRegistration: true,
            };
        case actionCreators.displayResetPasswordModal.type:
            return {
                ...state,
                showResetPasswordModal: true,
            };
        case actionCreators.hideResetPasswordModal.type:
            return {
                ...state,
                showResetPasswordModal: false,
            };
        default:
            return state;
    }
};
