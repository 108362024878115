export const calendarView = [
    {
        to: "/calendar/list",
        text: "Schedule view",
        icon: "calendarSchedule",
    },
    {
        to: "/calendar/day-schedule",
        text: "Day view",
        icon: "calendarDay",
    },
];
