/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarBoard } from "../CalendarStyles";
import moment from "moment";

const CalendarDay = ({
    day,
    currentDay,
    getDay,
    setDay,
    hasEventCallback,
    hasAltEventCallback,
    isCurrentMonth,
    isPastMonth,
    disablePastDates,
    isWeekend,
}) => (
    <button
        onClick={() => {
            if (
                !(isPastMonth || (isCurrentMonth && day < Number(moment().format("D")))) ||
                !disablePastDates
            )
                setDay(day);
        }}
        aria-hidden
        css={calendarBoard.dayRow}
    >
        <span css={calendarBoard.dayRowItem}>
            {(!isPastMonth || !disablePastDates) && day === currentDay && (
                <span css={calendarBoard.currentDay} />
            )}
            <span
                css={[
                    calendarBoard.dayRowText,
                    (!isPastMonth || !disablePastDates) &&
                        day === currentDay &&
                        calendarBoard.currentDayText,
                    disablePastDates &&
                        (isPastMonth || (isCurrentMonth && day < Number(moment().format("D")))) &&
                        calendarBoard.pastDate,
                    isWeekend && calendarBoard.dayRowTextWeekend,
                ]}
            >
                {getDay(day)}
            </span>
            {(!isPastMonth || !disablePastDates) && (
                <span css={calendarBoard.hasEvent}>
                    {hasEventCallback(day) ? (
                        <span css={calendarBoard.bullet} />
                    ) : (
                        hasAltEventCallback(day) && <span css={calendarBoard.bulletGray} />
                    )}
                </span>
            )}
        </span>
    </button>
);

export default CalendarDay;
