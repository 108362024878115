import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Form from "./FeedbackForm";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../../common/components/UI/ModalBox";
import actionCreators from "../../../store/Channel/Group/actionCreators";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import { spacing } from "../../../common/styles/Spacing";
import ButtonShape from "../../../common/components/UI/ButtonShape";
import GTM from "../../../common/services/GTM";

class GiveGroupFeedbackModal extends Component {
    componentDidUpdate(prevProps) {
        if (
            prevProps.showFeedbackForm !== this.props.showFeedbackForm &&
            this.props.showFeedbackForm === true
        ) {
            GTM.dispatch({
                event: "pageView",
                pageUrl: `/group-feedback`,
            });
        }
    }

    onSubmit = values => {
        const { sendGroupFeedback, channelId } = this.props;
        sendGroupFeedback({
            id: channelId,
            data: {
                subject: values.requestType,
                body: values.body,
            },
        });
    };

    closeModal = () => {
        const { hideGroupFeedbackForm, clearGroupFeedback } = this.props;
        hideGroupFeedbackForm();
        clearGroupFeedback();
    };

    render() {
        const {
            feedbackSent,
            showFeedbackForm,
            errorRes: { errors },
        } = this.props;
        const { closeModal, onSubmit } = this;

        return (
            <Fragment>
                <ModalBox
                    open={showFeedbackForm}
                    // open={true}
                    onClose={closeModal}
                    modalWidth={feedbackSent === false ? 768 : 400}
                    disableBackdropClick
                    maxWidth="md"
                    scroll="body"
                >
                    {feedbackSent === false ? (
                        <Fragment>
                            <Form
                                {...{
                                    onSubmit,
                                    errors,
                                    closeModal,
                                }}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <ModalBoxText>
                                <h2>Thank you</h2>
                                <p>
                                    Thank you for reporting your concerns. The Student Hub team will
                                    review your feedback and take the necessary steps to resolve any
                                    issues. We&apos;ll contact you in the next 5 working days to
                                    update you on the outcome.
                                </p>
                            </ModalBoxText>
                            <ModalBoxActions marginTopValue={spacing.space6}>
                                <ButtonsGroup marginBottomValue={spacing.space3}>
                                    <ButtonShape primary fullWidth onClick={() => closeModal()}>
                                        Close
                                    </ButtonShape>
                                </ButtonsGroup>
                            </ModalBoxActions>
                        </Fragment>
                    )}
                </ModalBox>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    showFeedbackForm: state.MyGroups.showFeedbackForm,
    errorRes: state.MyGroups.errorRes,
    feedbackSent: state.MyGroups.feedbackSent,
    channelId: state.Channel.channel.id,
});

const dispatchToProps = {
    sendGroupFeedback: actionCreators.sendGroupFeedback.create,
    clearGroupFeedback: actionCreators.clearGroupFeedback.create,
    hideGroupFeedbackForm: actionCreators.hideGroupFeedbackForm.create,
};

export default connect(mapStateToProps, dispatchToProps)(GiveGroupFeedbackModal);
