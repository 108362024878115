import { Link } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import ChannelTitle from "../ChannelTitle";
import FeedItemSummary from "../FeedItemSummary";
import { entryShort } from "../FeedStyles";
import Button from "../../../common/components/UI/Button";
import HTML from "../../../common/components/UI/Wysiwyg";
import GTM from "../../../common/services/GTM";
import history from "../../../common/services/history";

const PostItem = ({
    post: {
        id,
        name,
        description,
        imageUrl,
        publicationDate,
        isFavourite,
        numComments,
        isLiked,
        numberOfLikes,
        channel,
        article,
        kind,
        author,
    },
}) => (
    <div css={entryShort.body}>
        <ChannelTitle channel={channel} author={author} postId={id} {...{ publicationDate }} />

        {name && <div css={entryShort.title}>{name}</div>}

        {imageUrl && (
            <div css={entryShort.imgWrapper}>
                <Link to={`/post/${id}/${channel.id}`}>
                    <span css={entryShort.imgShape}>
                        <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                            <path d="M375 0H0v8L375 0z" fill="#fff" />
                        </svg>
                    </span>
                    <img alt="" src={imageUrl} css={entryShort.img} />
                </Link>
            </div>
        )}
        {description && (
            <HTML
                html={description}
                style={entryShort.post}
                onLinkClick={href => {
                    GTM.dispatch({
                        event: "postLinkClick",
                        actionType: href,
                        postId: `${id}`,
                        postName: description
                            .replace(/<[^>]+>/g, " ")
                            .replace(/\s\s+/g, " ")
                            .trim()
                            .split(/\s+/)
                            .slice(0, 10)
                            .join(" ")
                            .concat([" ..."].join("")),
                        channelId: `${channel.id}`,
                        channelName: `${channel.name}`,
                    });
                }}
            />
        )}
        {article && (
            <Button
                small
                textIcon={{ name: "link", width: 8, height: 8 }}
                css={entryShort.continueReadingBtn}
                onClick={() => {
                    GTM.dispatch({
                        event: "postClick",
                        actionType: "Continue reading",
                        postId: `${id}`,
                        postName: description
                            .replace(/<[^>]+>/g, " ")
                            .replace(/\s\s+/g, " ")
                            .trim()
                            .split(/\s+/)
                            .slice(0, 10)
                            .join(" ")
                            .concat([" ..."].join("")),
                        channelId: `${channel.id}`,
                        channelName: `${channel.name}`,
                    });
                    history.push(`/post/${id}/${channel.id}`);
                    return true;
                }}
            >
                Continue reading
            </Button>
        )}

        <FeedItemSummary
            postId={id}
            postName={description
                .replace(/<[^>]+>/g, " ")
                .replace(/\s\s+/g, " ")
                .trim()
                .split(/\s+/)
                .slice(0, 10)
                .join(" ")
                .concat([" ..."].join(""))}
            channel={{ id: channel.id, name: channel.name }}
            {...{ isFavourite, numComments, isLiked, numberOfLikes, kind, article, imageUrl }}
        />
        <div css={entryShort.bottomShape}>
            <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
            </svg>
        </div>
    </div>
);

export default PostItem;
