import { Component } from "react";
import { Link } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "./Icon";
import { buttonText } from "./ButtonTextStyle";
import { colors } from "../../styles/Colors";

class ButtonText extends Component {
    render() {
        const {
            children,
            to,
            icon,
            iconColor,
            iconSize,
            bold,
            color,
            underline,
            textStyle,
            ...other
        } = this.props;
        const ButtonElement = to ? Link : "a";

        return (
            <ButtonElement to={to} {...other} css={buttonText.body}>
                <span
                    css={[
                        buttonText.text,
                        color && { color },
                        bold && buttonText.textBold,
                        underline && buttonText.textUnderline,
                        textStyle && textStyle,
                    ]}
                >
                    {children}
                </span>
                {icon && (
                    <Icon
                        name={icon}
                        fill={iconColor || colors.black87}
                        width={iconSize || 12}
                        height={iconSize || 12}
                        css={buttonText.icon}
                    />
                )}
            </ButtonElement>
        );
    }
}

export default ButtonText;
