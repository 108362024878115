import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import actionCreators from "./actionCreators";

import { apiUrl } from "../../../common/services/utils";
import { default as ajax } from "../../../common/services/utils";
import errorHandler from "../../../common/services/ajaxErrorHandler";

const getNotifications = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationBanners.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/notification-banner/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.setNotifications.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getNotification = action$ =>
    action$.pipe(
        ofType(actionCreators.getNotificationBanner.type),
        mergeMap(({ payload }) =>
            ajax.get(apiUrl(`api/notification-banner/${payload}/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.setNotification.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const setNoShowAgain = action$ =>
    action$.pipe(
        ofType(actionCreators.setNoShowAgain.type),
        mergeMap(({ payload }) =>
            ajax.post(apiUrl(`api/notification-banner/${payload}/`)).pipe(
                mergeMap(() => of(actionCreators.removeNotification.create(payload))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(getNotifications, getNotification, setNoShowAgain);
