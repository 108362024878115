import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import actionCreators from "../../../store/Search/actionCreators";
import { headerMobile } from "./HeaderStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

class HeaderFeedMobile extends Component {
    showSearchBar = () => {
        const { toggleActiveForm, enableResults } = this.props;
        toggleActiveForm(true);
        enableResults();
    };

    render() {
        const { showSearchBar } = this;
        const BackButton = () => (
            <button onClick={history.goBack} css={headerMobile.actionLeft}>
                <Icon name="arrowLeft" fill={colors.white} width={18} height={18} />
            </button>
        );

        return (
            <div css={headerMobile.body}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        <Switch>
                            <Route exact path="/post/:id/:channelId" component={BackButton} />
                            <Route exact path="/event/:id/:channelId" component={BackButton} />
                        </Switch>
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>
                                <Switch>
                                    <Route
                                        exact
                                        path="/feed"
                                        render={() => <div>Student Hub</div>}
                                    />
                                    <Route
                                        exact
                                        path="/post/:id/:channelId"
                                        render={() => <div>Post</div>}
                                    />
                                    <Route
                                        exact
                                        path="/event/:id/:channelId"
                                        render={() => <div>Event</div>}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                    <div css={headerMobile.elementRight}>
                        <button onClick={() => showSearchBar()} css={headerMobile.actionRight}>
                            <Icon name="search" width="18" height="18" fill={colors.white} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    toggleActiveForm: actionCreators.toggleSearchBar.create,
    enableResults: actionCreators.enableResults.create,
};

export default connect(null, mapDispatchToProps)(HeaderFeedMobile);
