/** @jsx jsx */
import { jsx } from "@emotion/core";
import { searchMapResultBar } from "./SearchResultBarStyles";

const SearchMapResultBar = ({ item: { id, name, address, city, postcode }, selectBuilding }) => {
    return (
        <button onClick={() => selectBuilding(id)} css={searchMapResultBar.body}>
            <div css={searchMapResultBar.bodyInner}>
                <div css={searchMapResultBar.name}>{name}</div>
                <div css={searchMapResultBar.address}>{address}</div>
                <div css={searchMapResultBar.address}>
                    {city} {postcode}
                </div>
            </div>
            <div css={searchMapResultBar.bottomShape}>
                <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                    <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                </svg>
            </div>
        </button>
    );
};

export default SearchMapResultBar;
