import ActionCreator from "../actionCreator";

export default {
    getUserChannel: ActionCreator("GET_USER_CHANNEL"),
    updateUserChannel: ActionCreator("UPDATE_USER_CHANNEL"),
    loadMoreUserChannel: ActionCreator("LOAD_MORE_USER_CHANNEL"),
    getUserFavouritePosts: ActionCreator("GET_USER_FAVOURITE_POSTS"),
    updateUserFavouritePosts: ActionCreator("UPDATE_USER_FAVOURITE_POSTS"),
    loadMoreUserFavouritePosts: ActionCreator("LOAD_MORE_USER_FAVOURITE_POSTS"),
    errorResponse: ActionCreator("ERROR_RESPONSE_USER_CHANNEL"),
    setUserChannelFilter: ActionCreator("SET_USER_CHANNEL_FILTER"),
    filterMyGroups: ActionCreator("FILTER_MY_GROUPS"),
    updateUnbookmarkedSavedPost: ActionCreator("UPDATE_UNBOOKMARKED_FAVOURITE_POST"),
    initializeFavoritePosts: ActionCreator("INITIALIZE_FAVORITE_POSTS"),
    setFavouritePostsFilter: ActionCreator("SET_SAVED_POSTS_FILTER"),
};
