import ActionCreator from "../actionCreator";

export default {
    getLocations: ActionCreator("GET_LOCATIONS_CAMPUS_MAPPING"),
    updateLocations: ActionCreator("UPDATE_LOCATIONS_CAMPUS_MAPPING"),
    getLocationDetails: ActionCreator("GET_LOCATION_DETAILS_CAMPUS_MAPPING"),
    clearLocationDetails: ActionCreator("CLEAR_LOCATION_DETAILS_CAMPUS_MAPPING"),
    updateLocationDetails: ActionCreator("UPDATE_LOCATION_DETAILS_CAMPUS_MAPPING"),
    toggleSearchActive: ActionCreator("TOGGLE_SEARH_CAMPUS_MAPPING"),
    getSearchResults: ActionCreator("GET_SEARCH_CAMPUS_MAPPING"),
    updateSearchResults: ActionCreator("UPDATE_SEARCH_CAMPUS_MAPPING"),
    clearSearchResults: ActionCreator("CLEAR_SEARCH_CAMPUS_MAPPING"),
    setQuerySearch: ActionCreator("SET_QUERY_SEARCH"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CAMPUS_MAPPING"),
};
