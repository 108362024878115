import { ofType, combineEpics } from "redux-observable";
import { of, concat, empty } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { default as ajax } from "../../common/services/utils";
import { push } from "connected-react-router";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import registerActionCreators from "./Register/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { switchCase } from "../../common/services/utils";
import { default as UIActionCreators } from "../../store/MainView/actionCreators";
import { default as eventActionCreators } from "../../store/Calendar/Event/actionCreators";
import { default as notificationsActionCreators } from "../../store/Notifications/actionCreators";
import { default as myProgrammeActionCreators } from "../../store/MyProgramme/actionCreators";
import { default as calendarActionCreators } from "../../store/Calendar/FullList/actionCreators";
import GTM from "../../common/services/GTM";

const authStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.login.type,
            actionCreators.checkStorage.type,
            actionCreators.logout.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const authClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.setAuthAndRegistered.type,
            actionCreators.setAuthNotRegistered.type,
            actionCreators.redirectToHome.type,
            actionCreators.errorResponse.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const loginEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.login.type),
        mergeMap(action =>
            ajax
                .post(apiUrl("auth/login"), action.payload, {
                    "Content-Type": "application/json",
                })
                .pipe(
                    map(res => ({ data: res.response, status: res.status })),
                    mergeMap(res => {
                        if (res.status === 200 || res.status === 201) {
                            localStorage.setItem("token", res.data.id);
                        }
                        if (res.status === 200) {
                            localStorage.setItem("user", JSON.stringify(res.data.user));
                        }
                        const uid = localStorage.getItem("uid");
                        const isActionPost = localStorage.getItem("redirectAction") === "post";
                        const redirectUrl = localStorage.getItem("redirect")
                            ? localStorage.getItem("redirect") === "/login"
                                ? "/"
                                : localStorage.getItem("redirect")
                            : "/";
                        localStorage.removeItem("redirect");
                        localStorage.removeItem("uid");
                        localStorage.removeItem("redirectAction");

                        GTM.setUserId(res.data.user.id);

                        return switchCase({
                            200: concat(
                                of(actionCreators.setAuthAndRegistered.create()),
                                of(actionCreators.setUser.create(res.data.user)),
                                of(
                                    notificationsActionCreators.getUnseenNotificationsCount.create()
                                ),
                                of(myProgrammeActionCreators.getStudentsList.create()),
                                of(push(redirectUrl)),
                                isActionPost
                                    ? of(eventActionCreators.confirmEvent.create(uid))
                                    : empty()
                            ),
                            201: concat(
                                of(actionCreators.setAuthNotRegistered.create()),
                                of(registerActionCreators.nextStep.create()),
                                of(actionCreators.setUser.create(res.data.user)),
                                action.payload.allowRedirectToRegistration
                                    ? of(push("/register"))
                                    : of(
                                          actionCreators.requireRegistrationsTerms.create(
                                              res.data.user
                                          )
                                      )
                            ),
                            204: of(
                                actionCreators.errorResponse.create({
                                    errorRes:
                                        "Something seems to have gone wrong - we're experiencing some technical difficulties. Please try again later!",
                                })
                            ),
                        })()(res.status);
                    }),
                    catchError(errorHandler(actionCreators.errorResponse.create))
                )
        )
    );

const forgotPasswordEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.forgotPassword.type),
        mergeMap(action =>
            ajax.post(apiUrl("auth/forgot"), action.payload).pipe(
                map(res => res.response),
                mergeMap(() => concat(of(push("/forgot-confirmation")))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const checkStorageEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.checkStorage.type),
        mergeMap(() => {
            const token = localStorage.getItem("token");
            const user = JSON.parse(localStorage.getItem("user"));
            const calendarLast = localStorage.getItem("calendarLast");

            if (token && user && !!user.email) {
                return concat(
                    of(actionCreators.setAuthAndRegistered.create()),
                    of(myProgrammeActionCreators.getStudentsList.create()),
                    of(actionCreators.setUser.create(user)),
                    of(notificationsActionCreators.getUnseenNotificationsCount.create()),
                    calendarLast
                        ? of(
                              calendarActionCreators.setDefaultStatus.create(
                                  calendarLast === "list"
                              )
                          )
                        : empty()
                );
            } else if (token && user && !user.email) {
                return concat(
                    of(actionCreators.setAuthNotRegistered.create()),
                    of(actionCreators.setUser.create(user))
                );
            } else {
                return of(actionCreators.logout.create());
            }
        })
    );

const logoutEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.logout.type),
        mergeMap(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return concat(of(actionCreators.redirectToHome.create()));
        })
    );

const redirectToHomeEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToHome.type),
        mergeMap(() => concat(of(push("/"))))
    );

export const epics = combineEpics(
    authStartLoadingEpic,
    authClearLoadingEpic,
    loginEpic,
    checkStorageEpic,
    forgotPasswordEpic,
    logoutEpic,
    redirectToHomeEpic
);
