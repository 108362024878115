import { colors } from "../../common/styles/Colors";
import { spacing } from "../../common/styles/Spacing";

export const supportMap = {
    body: {
        position: "relative",
        padding: "92px 0 32px",
        "@media (max-width: 1023px)": {
            padding: `${spacing.space11}px 0 ${spacing.space8}px`,
        },
    },
    bodyMobileWhite: {
        "@media (max-width: 1023px)": {
            minHeight: "100vh",
        },
    },
    root: {
        padding: "0 0 32px",
        "@media (max-width: 1023px)": {
            padding: "0 0 32px",
        },
    },
    container: {
        margin: "0 auto",
        padding: "0 16px",
        maxWidth: 844,
        width: "100%",
    },
    breadcrumbs: {
        color: "#000",
        marginTop: 32,
        fontSize: 12,
        "@media (max-width: 1023px)": {
            padding: `0 ${spacing.space2}px`,
        },
        "& b": {
            color: "inherit",
            opacity: 0.4,
            padding: "0 8px",
        },
        "& span": {
            color: "inherit",
            opacity: 0.8,
        },
        "& a": {
            color: "inherit",
            opacity: 0.4,
            textDecoration: "none",
        },
    },
    title: {
        fontSize: 24,
        margin: "0 0 8px",
        textAlign: "center",
        "@media (max-width: 1023px)": {
            padding: `0 ${spacing.space2}px`,
        },
    },
    intro: {
        fontSize: 14,
        lineHeight: 1.42,
        textAlign: "center",
        margin: 0,
        "@media (max-width: 1023px)": {
            padding: `0 ${spacing.space2}px`,
        },
    },
    list: {
        margin: "32px 0 0",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "25px 25px",
        "@media (max-width: 1023px)": {
            gridTemplateColumns: "1fr",
            gap: "24px 24px",
            padding: `0 ${spacing.space2}px ${spacing.space5}px`,
        },
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: spacing.space8,
        span: {
            display: "block",
            marginRight: 8,
            fontSize: 12,
            opacity: 0.4,
        },
        img: {
            width: 60,
            height: "auto",
        },
        "@media (max-width: 1023px)": {
            marginTop: 0,
            marginBottom: spacing.space10,
        },
    },
};

export const supportMapItem = {
    root: {
        color: colors.black,
        textDecoration: "none",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative",
        wordBreak: "normal !important",
        transition: "box-shadow ease 0.45s",
        "@media (max-width: 1023px)": {
            flexDirection: "row",
        },
        "&:hover": {
            boxShadow: "0 0 20px rgba(0,0,0,0.15)",
        },
    },
    img: {
        display: "block",
        width: "100%",
        aspectRatio: "747 / 420",
        margin: 0,
        overflow: "hidden",
        backgroundColor: "#ebebeb",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "@media (max-width: 1023px)": {
            width: "35%",
            flexShrink: 0,
            aspectRatio: "1 / 1",
        },
    },
    inner: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: "30px 48px",
        "@media (max-width: 1023px)": {
            flex: 1,
            padding: "20px",
        },
    },
    body: {
        display: "block",
        flex: 1,
        "& > strong": {
            display: "block",
            color: colors.black,
            fontSize: 18,
            textAlign: "center",
        },
        "& > span": {
            display: "block",
            color: colors.black54,
            fontSize: 14,
            lineHeight: 1.28,
            textAlign: "center",
            marginTop: spacing.space1,
        },
        "@media (max-width: 1023px)": {
            flex: 0,
            "& > strong": {
                textAlign: "left",
                marginTop: spacing.space1,
            },
            "& > span": {
                textAlign: "left",
            },
        },
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#777677",
        fontSize: 16,
        height: 32,
        marginTop: spacing.space3,
        "@media (max-width: 1023px)": {
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: spacing.space1comma5,
        },
    },
    btn: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 16px",
        height: 32,
        border: `1px solid ${colors.grey3}`,
        borderRadius: 4,
        color: colors.black87,
        fontSize: 14,
        "& svg": {
            marginLeft: spacing.space1,
        },
    },
    shape: {
        display: "block",
        fontSize: 1,
        lineHeight: 1,
        height: 8,
        position: "absolute",
        top: "100%",
        right: 0,
        left: 0,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
};
