/** @jsx jsx */
import { jsx } from "@emotion/core";
import { reduxForm, Field } from "redux-form";
import validate from "./validate";
import RenderTextField from "../../../common/components/FormFields/TextField";
import ContainerImg from "../../../common/components/UI/ContainerImg";
import TextError from "../../../common/components/UI/TextError";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import { auth } from "../AuthStyles";
import { spacing } from "../../../common/styles/Spacing";
import { connect } from "react-redux";
import {
    ContainerHeader,
    Container,
    ContainerActions,
} from "../../../common/components/UI/Container";
import { welcomeStyle } from "../../Dashboard/DashboardStyles";
import AuthContextMenu from "../AuthContextMenu";
import ButtonShape from "../../../common/components/UI/ButtonShape";

const MainAccountFields = ({
    error,
    submitting,
    handleSubmit,
    user,
    isLoading,
    errorRes: { errors },
}) => (
    <ContainerImg fullWidth>
        <ContainerHeader height={208} justifyBottom>
            <div css={welcomeStyle.btnMore}>
                <AuthContextMenu />
            </div>
            <div css={welcomeStyle.subtitle}>Welcome to the</div>
            <div css={welcomeStyle.title}>Student Hub</div>
            <div css={welcomeStyle.text}>Please confirm your personal details</div>
        </ContainerHeader>
        <form onSubmit={handleSubmit}>
            <Container width={352} css={auth.content}>
                <Field
                    name="firstName"
                    component={RenderTextField}
                    label="First name"
                    disabled={user.firstNameLocked}
                />
                {errors.firstName && (
                    <TextError css={auth.fieldError}>{errors.firstName}</TextError>
                )}
                <Field
                    name="lastName"
                    component={RenderTextField}
                    label="Last name"
                    disabled={user.lastNameLocked}
                />
                {errors.lastName && <TextError css={auth.fieldError}>{errors.lastName}</TextError>}
                <p css={auth.textMainAccount}>
                    Communication is normally sent to your LSE email address. If you would prefer to
                    use another email address, please enter it below
                </p>
                <Field name="email" component={RenderTextField} label="Preferred email adress" />
                {error && <TextError css={auth.fieldError}>{error}</TextError>}
                {errors.email && <TextError css={auth.fieldError}>{errors.email}</TextError>}
            </Container>
            <ContainerActions>
                <ButtonsGroup marginBottomValue={spacing.space3} marginBottomValueMobile={1}>
                    <ButtonShape type="submit" disabled={submitting || isLoading} fullWidth>
                        Continue
                    </ButtonShape>
                </ButtonsGroup>
            </ContainerActions>
        </form>
    </ContainerImg>
);

const form = reduxForm({
    form: "register",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
})(MainAccountFields);

const mapStateToProps = state => {
    return {
        isLoading: state.MainView.isLoading,
        errorRes: state.Register.errorRes,
    };
};

export default connect(mapStateToProps)(form);
