import { Component } from "react";
import { connect } from "react-redux";
import { startSubmit, stopSubmit } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Auth/actionCreators";
import Form from "./Form";
import AskForRegistration from "./AskForRegistration";
import ContainerImg from "../../../common/components/UI/ContainerImg";
import { welcomeStyle } from "../../Dashboard/DashboardStyles";
import { ContainerHeader } from "../../../common/components/UI/Container";
import AuthContextMenu from "../AuthContextMenu";
import GTM from "../../../common/services/GTM";

class LoginForm extends Component {
    componentDidMount() {
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/login`,
        });
    }

    onSubmit = values => {
        const { login } = this.props;
        login(values);
    };

    componentDidUpdate(prevProps) {
        const { isFormSubmitting, startSubmit, stopSubmit } = prevProps;
        if (isFormSubmitting !== this.props.isFormSubmitting) {
            if (this.props.isFormSubmitting) startSubmit("login");
            else stopSubmit("login");
        }
    }

    render() {
        const {
            errorRes: { error },
            askForRegistration,
            history: { push },
        } = this.props;
        const { onSubmit } = this;

        const errorSystem = error;

        return (
            <ContainerImg fullWidth>
                <ContainerHeader height={208} justifyBottom>
                    <div css={welcomeStyle.btnMore}>
                        <AuthContextMenu />
                    </div>
                    <div css={welcomeStyle.subtitle}>Welcome to the</div>
                    <div css={welcomeStyle.title}>Student Hub</div>
                    <div css={welcomeStyle.text}>
                        The best way to communicate with <br />
                        and connect to the LSE community
                    </div>
                </ContainerHeader>
                <AskForRegistration {...{ askForRegistration, push }} />
                <Form {...{ onSubmit, error, errorSystem }} />
            </ContainerImg>
        );
    }
}
const dispatchToProps = {
    login: actionCreators.login.create,
    startSubmit: startSubmit,
    stopSubmit: stopSubmit,
};

const mapStateToProps = ({ Auth }) => ({
    errorRes: Auth.errorRes,
    isFormSubmitting: Auth.isFormSubmitting,
    askForRegistration: Auth.askForRegistration,
});

export default connect(mapStateToProps, dispatchToProps)(LoginForm);
