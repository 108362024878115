import ActionCreator from "../../actionCreator";

export default {
    getGroup: ActionCreator("GET_GROUP"),
    createGroup: ActionCreator("CREATE_GROUP"),
    putGroup: ActionCreator("PUT_GROUP"),
    trackingGroup: ActionCreator("TRACKING_GROUP"),
    setGroupMembersFilter: ActionCreator("SET_GROUP_MEMBERS_FILTER"),
    getGroupMembers: ActionCreator("GET_GROUP_MEMBERS"),
    createGroupMembers: ActionCreator("CREATE_GROUP_MEMBERS"),
    clearGroupMembers: ActionCreator("CLEAR_GROUP_MEMBERS"),
    updateGroupMemberPendingState: ActionCreator("UPDATE_GROUP_MEMBER_PENDING_STATE"),
    changeGroupMemberRole: ActionCreator("CHANGE_GROUP_MEMBER_ROLE"),
    removeGroupMember: ActionCreator("REMOVE_GROUP_MEMBER"),
    deleteGroupMember: ActionCreator("DELETE_GROUP_MEMBER"),
    postPost: ActionCreator("POST_POST"),
    setValidateError: ActionCreator("SET_VALIDATE_ERROR"),
    clearValidateError: ActionCreator("CLEAR_VALIDATE_ERROR"),
    errorResponse: ActionCreator("ERROR_RESPONSE_GROUP"),
    redirectToEditGroup: ActionCreator("REDIRECT_TO_EDIT_GROUP"),
    searchForUsers: ActionCreator("GROUP_MEMBERS_SEARCH_FOR_USERS"),
    updateSearchedUsers: ActionCreator("GROUP_MEMBERS_UPDATE_SEARCHED_USERS"),
    addUserToSelected: ActionCreator("GROUP_MEMBERS_ADD_TO_SELECTED"),
    removeUserFromSelected: ActionCreator("GROUP_MEMBERS_REMOVE_FROM_SELECTED"),
    setSearchFilter: ActionCreator("GROUP_MEMBERS_SET_SEARCH_FILTER"),
    clearSearchedUsers: ActionCreator("GROUP_MEMBERS_CLEAR_SEARCHED_USERS"),
    resetAddMembers: ActionCreator("GROUP_MEMBERS_RESET_ADD_MEMBER"),
    inviteUserToGroup: ActionCreator("INVITE_USER_TO_GROUP"),
    redirectToGroupMembers: ActionCreator("REDIRECT_TO_GROUP_MEMBERS"),
    sendJoinRequestToGroup: ActionCreator("SEND_JOIN_REQUEST_TO_GROUP"),
    deleteGroup: ActionCreator("DELETE_GROUP"),
    answerGroupInvitation: ActionCreator("ANSWER_GROUP_INVITATION"),
    sendGroupFeedback: ActionCreator("POST_GROUP_FEEDBACK"),
    updateGroupFeedback: ActionCreator("UPDATE_GROUP_FEEDBACK"),
    clearGroupFeedback: ActionCreator("CLEAR_GROUP_FEEDBACK"),
    displayGroupFeedbackForm: ActionCreator("SHOW_GROUP_FEEDBACK"),
    hideGroupFeedbackForm: ActionCreator("HIDE_GROUP_FEEDBACK"),
};
