import ActionCreator from "../../actionCreator";

export default {
    getNotificationBanners: ActionCreator("GET_NOTIFICATION_BANNERS"),
    getNotificationBanner: ActionCreator("GET_NOTIFICATION_BANNER"),
    setNoShowAgain: ActionCreator("DONT_SHOW_AGAIN"),
    setNotifications: ActionCreator("SET_NOTIFICATIONS"),
    setNotification: ActionCreator("SET_NOTIFICATION"),
    removeNotification: ActionCreator("REMOVE_NOTIFICATION"),
    errorResponse: ActionCreator("ERROR_RESPONSE_SYNC_CALENDAR"),
};
