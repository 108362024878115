import history from "./../../../common/services/history";
import styles from "../../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { useSelector } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const HeaderMyProgramme = () => {
    const { studentProfile } = useSelector(state => state.MyProgramme);
    const { lseIdNumber } = useSelector(state => state.Auth.user);
    const { studentsList } = useSelector(state => state.MyProgramme);

    return (
        <div css={[styles.body, styles.bodyCalendar]}>
            <Container width={592} noPadding>
                <div css={styles.bodySpaceBetween}>
                    <div css={styles.bodyLeft}>
                        {studentsList &&
                            studentsList[0] &&
                            studentsList[0].students &&
                            studentsList[0].students.length > 0 && (
                                <HeaderBackButton
                                    icon="arrowLeft"
                                    iconSize={18}
                                    onClick={() =>
                                        history.push({
                                            pathname: "/myprogramme",
                                        })
                                    }
                                />
                            )}
                    </div>
                    <div css={styles.bodyRight}>
                        {studentProfile &&
                            studentProfile.playRef &&
                            studentProfile.playRef === lseIdNumber && (
                                <button
                                    type="button"
                                    css={styles.btnSearch}
                                    onClick={() => history.push("/profile")}
                                >
                                    <Icon
                                        name="edit"
                                        width={18}
                                        height={18}
                                        fill={colors.black87}
                                    />
                                </button>
                            )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeaderMyProgramme;
