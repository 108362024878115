import { Component } from "react";
import { Link } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { contextMenuButton } from "./ContextMenuStyles";
import { colors } from "../../styles/Colors";
import Icon from "../UI/Icon";

class ContextMenuLink extends Component {
    render() {
        const { icon, text, color, iconColor, iconSize, to, onClick, ...props } = this.props;

        const ButtonElement = to !== "" ? Link : "button";

        return (
            <ButtonElement
                css={contextMenuButton.body}
                to={to !== "" ? to : undefined}
                onClick={onClick}
                {...props}
            >
                {icon && (
                    <div css={contextMenuButton.icon}>
                        <Icon
                            name={icon}
                            fill={iconColor || colors.black87}
                            width={iconSize || 18}
                            height={iconSize || 18}
                        />
                    </div>
                )}
                <div css={[contextMenuButton.text, color && { color: `${color}` }]}>{text}</div>
            </ButtonElement>
        );
    }
}

export default ContextMenuLink;
