import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
import { fontSize, fontWeight, lineHeight } from "../../styles/Typography";
import { resetLineHeight } from "../../styles/Globals";

export const styles = {
    card: {
        marginTop: spacing.space2,
        marginBottom: spacing.space2,
        overflow: "hidden",
        "@media (max-width: 1023px)": {
            marginTop: spacing.space1,
            marginBottom: spacing.space1,
        },
    },
    inner: {
        backgroundColor: colors.white,
        padding: spacing.space2,
    },
    cardOnMobileMargin: {
        "@media (max-width: 1023px)": {
            marginRight: spacing.space1,
            marginLeft: spacing.space1,
        },
    },
    cardNoMarginTop: {
        marginTop: 0,
    },
    heading: {
        color: colors.black87,
        fontSize: fontSize.textMedium,
        lineHeight: lineHeight.textMedium,
        fontWeight: fontWeight.bold,
        marginBottom: spacing.space1,
    },
    message: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        lineHeight: lineHeight.textMedium,
        fontWeight: fontWeight.normal,
    },
    box: {
        paddingTop: spacing.space1,
    },
    bottomShape: {
        ...resetLineHeight,
        backgroundColor: colors.white,
        height: 8,
    },
};
