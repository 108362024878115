import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Feed/Post/actionCreators";
import discussionActionCreators from "../../../store/Discussion/actionCreators";
import { entry, feedPage, replyLabel } from "../FeedStyles";
import { Container } from "../../../common/components/UI/Container";
import HeaderFeedMobile from "../../../common/components/Header/HeaderFeedMobile";
import HeaderFeed from "../../../common/components/Header/HeaderFeed";
import CommentListContainer from "../../Discussion/";
import CommentForm from "../../Discussion/CommentForm";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { change } from "redux-form";
import { Scrollbars } from "react-custom-scrollbars";
import { Event } from "./EventDetails";
import PostPlaceholder from "../../../common/components/Skeletons/PostPlaceholder";
import GTM from "../../../common/services/GTM";

class EventContainer extends Component {
    componentDidMount() {
        const {
            getPost,
            match: {
                params: { id, channelId },
            },
        } = this.props;
        getPost({ id, channelId });

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/event/${id}/${channelId}`,
        });
    }

    componentWillUnmount() {
        this.props.clearPost();
    }

    onSubmit = values => {
        const {
            postComment,
            putComment,
            comment,
            formParentId,
            formType,
            toggleActiveForm,
            formUpdate,
            setFormType,
            setFormParrentId,
            match: {
                params: { id },
            },
        } = this.props;
        if (formType === "NEW") {
            postComment({
                id,
                body: {
                    parent: formParentId,
                    commentary: values[Object.keys(values)[0]],
                },
            });
        } else if (formType === "EDIT") {
            putComment({
                id: comment.id,
                body: {
                    commentary: values[Object.keys(values)[0]],
                },
            });
        }
        toggleActiveForm();
        formUpdate("comment", "comment", "");
        values[Object.keys(values)[0]] = "";
        setFormType("NEW");
        setFormParrentId(0);
    };

    hideReplyLabel = () => {
        const { setFormType, setFormParrentId, toggleActiveForm, formUpdate } = this.props;
        setFormType("NEW");
        formUpdate("comment", "comment", "");
        toggleActiveForm(null);
        setFormParrentId(0);
    };

    render() {
        const {
            post,
            activeForm,
            formType,
            formParentId,
            comment: { user },
            putBookEvent,
        } = this.props;

        const { onSubmit, hideReplyLabel } = this;
        const isFocused = (formType === "NEW" && formParentId > 0) || formType === "EDIT";

        return (
            <div css={feedPage.bodySubpage}>
                <Container width={592} onMobileNoPadding>
                    <HeaderFeed />
                    <HeaderFeedMobile />
                    <PostPlaceholder isLoaded={Boolean(post.id)}>
                        <div css={entry.body}>
                            <Scrollbars
                                autoHide
                                css={entry.inner}
                                renderView={props => <div {...props} id="scrollableParent" />}
                            >
                                <Event {...{ post, putBookEvent }} />
                                {post.numComments > 0 && <CommentListContainer {...this.props} />}
                            </Scrollbars>
                            <div css={entry.commentsForm}>
                                {activeForm && (
                                    <div css={replyLabel.body}>
                                        <div css={replyLabel.text}>
                                            <Icon
                                                name="editAlt"
                                                width="12"
                                                height="12"
                                                fill={colors.black20}
                                            />

                                            {formType === "NEW" ? (
                                                formParentId > 0 && (
                                                    <div css={replyLabel.user}>
                                                        Replying to {user.firstName} {user.lastName}
                                                    </div>
                                                )
                                            ) : (
                                                <div css={replyLabel.user}>Edit comment</div>
                                            )}
                                        </div>
                                        <button
                                            css={replyLabel.btn}
                                            type="button"
                                            onClick={() => hideReplyLabel()}
                                        >
                                            <Icon
                                                name="times"
                                                width="15"
                                                height="15"
                                                fill={colors.greyDark50}
                                            />
                                        </button>
                                    </div>
                                )}
                                <CommentForm
                                    index={0}
                                    isFocused={isFocused}
                                    id={post.id}
                                    name={post.name}
                                    {...{ onSubmit, formType, formParentId }}
                                />
                            </div>
                        </div>
                    </PostPlaceholder>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    Post: { post },
    Discussion: { formParentId, formType, comment, activeForm },
}) => ({
    post,
    formParentId,
    formType,
    comment: comment || { user: {} },
    activeForm,
});

const mapDispatchToProps = {
    getPost: actionCreators.getPost.create,
    clearPost: actionCreators.clearPost.create,
    postComment: discussionActionCreators.postComment.create,
    toggleActiveForm: discussionActionCreators.toggleActiveForm.create,
    formUpdate: change,
    putComment: discussionActionCreators.putComment.create,
    setFormParrentId: discussionActionCreators.setFormParrentId.create,
    setFormType: discussionActionCreators.setFormType.create,
    putBookEvent: actionCreators.putBookEvent.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventContainer);
