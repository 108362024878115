import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import SkeletonStyles from "./SkeletonStyles";
import { colors } from "../../styles/Colors";

const TimelinePlaceholder = () => {
    return (
        <div css={SkeletonStyles.timelineContainer}>
            <div css={skeletonStyles.timelineBody}>
                <ReactPlaceholder type="text" rows={1} color={colors.greyDark10} />
                <div css={skeletonStyles.timelineRow}>
                    <div css={skeletonStyles.timelineDot} />
                    <div css={{ flex: 1 }}>
                        <ReactPlaceholder
                            type="text"
                            rows={1}
                            color={colors.greyDark10}
                            style={{ height: 12, width: "35%" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimelinePlaceholder;
