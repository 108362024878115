import { validateRequiredFields } from "../../../../common/services/formHelpers";

export default values => {
    const requiredFields = {
        name: "Required",
    };
    let errors = validateRequiredFields(requiredFields, values);

    if (!!values.location && values.location !== "") {
        if (values.location.length > 255) {
            errors.location = "Location is too long (max 255 chars).";
        }
    }

    return errors;
};
