import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { colors } from "../../styles/Colors";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { spacing } from "../../styles/Spacing";
import AvatarPlaceholder from "./common/AvatarPlaceholder";
import CoverPlaceholder from "./common/CoverPlaceholder";

const FormPlaceholder = ({ children, isLoaded }) => {
    return isLoaded ? (
        children
    ) : (
        <div css={[feedPage.infiniteScroll, skeletonStyles.eventContainer]}>
            <div css={feedPage.inner}>
                <div
                    css={[
                        entryShort.body,
                        { marginBottom: spacing.space2, marginTop: spacing.space2 },
                    ]}
                >
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            type="rect"
                            ready={false}
                            color={colors.greyDark10}
                            css={{ width: "100%", height: 50 }}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>

                <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            type="rect"
                            ready={false}
                            color={colors.greyDark10}
                            css={{ width: "100%", height: 50 }}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>

                <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            type="rect"
                            ready={false}
                            color={colors.greyDark10}
                            css={{ width: "100%", height: 50 }}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>

                <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            type="rect"
                            ready={false}
                            color={colors.greyDark10}
                            css={{ width: "100%", height: 50 }}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>

                <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            ready={false}
                            color={colors.greyDark10}
                            customPlaceholder={<AvatarPlaceholder />}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>

                <div css={[entryShort.body, { marginBottom: spacing.space2 }]}>
                    <div css={[skeletonStyles.eventPlaceholder]}>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            ready={false}
                            color={colors.greyDark10}
                            customPlaceholder={<CoverPlaceholder />}
                        >
                            <div />
                        </ReactPlaceholder>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormPlaceholder;
