import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Field, reduxForm } from "redux-form";
import RenderTextField from "../../../common/components/FormFields/TextField";
import RenderPasswordInputField from "../../../common/components/FormFields/PasswordInputField";
import validate from "./validate";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import { spacing } from "../../../common/styles/Spacing";
import TextError from "../../../common/components/UI/TextError";
import { connect } from "react-redux";
import { Container, ContainerActions } from "../../../common/components/UI/Container";
import ButtonText from "../../../common/components/UI/ButtonText";
import ButtonShape from "../../../common/components/UI/ButtonShape";
import { auth } from "../AuthStyles";
import GTM from "../../../common/services/GTM";
import HTML from "../../../common/components/UI/Wysiwyg";
import authActions from "../../../store/Auth/actionCreators";

class LoginForm extends Component {
    render() {
        const { handleSubmit, onSubmit, submitting, errorSystem, isLoading } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)} name="loginForm">
                <Container width={352} css={auth.content}>
                    <Field
                        name="username"
                        component={RenderTextField}
                        label="LSE Username"
                        autoComplete="off"
                    />
                    <Field
                        name="password"
                        component={RenderPasswordInputField}
                        label="LSE Password"
                        autoComplete="off"
                    />
                    {errorSystem && (
                        <TextError>
                            <HTML html={errorSystem} />
                        </TextError>
                    )}
                </Container>
                <ContainerActions>
                    <ButtonsGroup
                        marginBottomValue={spacing.space3}
                        marginBottomValueMobile={spacing.space1comma5}
                    >
                        <ButtonShape type="submit" disabled={submitting || isLoading} fullWidth>
                            Log in
                        </ButtonShape>
                    </ButtonsGroup>
                    <ButtonsGroup
                        marginBottomValue={spacing.space6}
                        marginBottomValueMobile={spacing.space1}
                    >
                        <ButtonText to="/register" bold textStyle={auth.actionLinkBold}>
                            Not registered? Start here
                        </ButtonText>
                    </ButtonsGroup>
                    <ButtonsGroup>
                        <ButtonText
                            target="_blank"
                            rel="noopener noreferrer"
                            icon="link"
                            iconSize={9}
                            textStyle={auth.actionLink}
                            onClick={() => {
                                this.props.displayResetPasswordModal();
                                GTM.dispatch({
                                    event: "genericClick",
                                    targetName: "Forgotten password",
                                });
                                return true;
                            }}
                        >
                            Forgotten username or password?
                        </ButtonText>
                    </ButtonsGroup>
                </ContainerActions>
            </form>
        );
    }
}

const form = reduxForm({
    form: "login",
    validate,
})(LoginForm);

const mapStateToProps = state => {
    return {
        isLoading: state.MainView.isLoading,
    };
};

const dispatchToProps = {
    displayResetPasswordModal: authActions.displayResetPasswordModal.create,
};

export default connect(mapStateToProps, dispatchToProps)(form);
