import { Component } from "react";
import { feedPage, entryShort } from "../../../app/Feed/FeedStyles";
import skeletonStyles from "./SkeletonStyles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Container } from "../UI/Container";
import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
/** @jsx jsx */
import { jsx } from "@emotion/core";

class ChannelPlaceholder extends Component {
    constructor(props) {
        super(props);
        this.state = { windowWidth: null };
    }

    componentDidMount() {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        const { children, isLoaded } = this.props;
        const { windowWidth } = this.state;
        return isLoaded ? (
            children
        ) : (
            <div>
                <div css={skeletonStyles.channelHeader}>
                    <ReactPlaceholder
                        type="rect"
                        ready={false}
                        color={colors.greyDark10}
                        css={{
                            width: "100%",
                            height: windowWidth > 1023 ? 274 : 266,
                        }}
                        showLoadingAnimation={true}
                    >
                        <div />
                    </ReactPlaceholder>
                </div>
                <Container width={592} onMobileNoPadding>
                    <div css={[feedPage.infiniteScroll, skeletonStyles.channelContainer]}>
                        <div css={feedPage.inner}>
                            <div css={entryShort.body}>
                                <div css={[skeletonStyles.channelPlaceholder]}>
                                    <ReactPlaceholder
                                        type="media"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                        showLoadingAnimation={true}
                                        css={{ marginBottom: spacing.space2 }}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>
                            <div css={entryShort.bottomShape}>
                                <svg
                                    preserveAspectRatio="none"
                                    viewBox="0 0 375 8"
                                    width="100%"
                                    height="8"
                                >
                                    <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                                </svg>
                            </div>
                            <div css={entryShort.body}>
                                <div css={[skeletonStyles.channelPlaceholder]}>
                                    <ReactPlaceholder
                                        type="media"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                        showLoadingAnimation={true}
                                        css={{ marginBottom: spacing.space2 }}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>
                            <div css={entryShort.bottomShape}>
                                <svg
                                    preserveAspectRatio="none"
                                    viewBox="0 0 375 8"
                                    width="100%"
                                    height="8"
                                >
                                    <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                                </svg>
                            </div>
                            <div css={entryShort.body}>
                                <div css={[skeletonStyles.channelPlaceholder]}>
                                    <ReactPlaceholder
                                        type="media"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                        showLoadingAnimation={true}
                                        css={{ marginBottom: spacing.space2 }}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>
                            <div css={entryShort.bottomShape}>
                                <svg
                                    preserveAspectRatio="none"
                                    viewBox="0 0 375 8"
                                    width="100%"
                                    height="8"
                                >
                                    <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
                                </svg>
                            </div>
                            <div css={entryShort.body}>
                                <div css={[skeletonStyles.channelPlaceholder]}>
                                    <ReactPlaceholder
                                        type="media"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                        showLoadingAnimation={true}
                                        css={{ marginBottom: spacing.space2 }}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        ready={false}
                                        rows={3}
                                        color={colors.greyDark10}
                                    >
                                        <div />
                                    </ReactPlaceholder>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ChannelPlaceholder;
