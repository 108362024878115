import { ofType, combineEpics } from "redux-observable";
import {
    of,
    // concat
} from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { default as ajax } from "../../common/services/utils";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import authActionCreators from "../Auth/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as UIActionCreators } from "../../store/MainView/actionCreators";
// import { default as snackbarActions } from "../../store/Snackbar/actionCreators";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postProfilePhoto.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(authActionCreators.updateUserProfile.type, actionCreators.errorResponse.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getUserEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUser.type),
        mergeMap(() =>
            ajax.get(apiUrl("api/user")).pipe(
                map(res => res.response),
                tap(res => {
                    localStorage.setItem("user", JSON.stringify(res));
                }),
                mergeMap(res => of(authActionCreators.updateUser.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postProfilePhotoEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postProfilePhoto.type),
        mergeMap(({ payload }) =>
            ajax.patch(apiUrl("api/user"), payload).pipe(
                map(res => res.response),
                tap(res => {
                    const user = JSON.parse(localStorage.getItem("user"));
                    localStorage.setItem(
                        "user",
                        JSON.stringify({
                            ...user,
                            photoUrl:
                                payload.profilePhoto != null ? res.photoUrl : payload.photoUrl,
                            firstName: res.firstName,
                            email: res.email,
                        })
                    );
                }),
                mergeMap(res =>
                    //concat(
                    /*
                        of(
                            snackbarActions.enqueueSnackbar.create({
                                message: "Saved!",
                            })
                        ),
                        */
                    of(
                        authActionCreators.updateUserProfile.create({
                            photo: payload.profilePhoto != null ? res.photoUrl : payload.photoUrl,
                            firstName: res.firstName,
                            email: res.email,
                        })
                        //)
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    getUserEpic,
    startLoadingEpic,
    clearLoadingEpic,
    postProfilePhotoEpic
);
