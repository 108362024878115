import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
// import { BeatLoader } from "react-spinners";

class MainView extends Component {
    // eslint-disable-next-line no-unused-vars
    getLoadingScreen = isLoading => {
        return; //  loading overlay is now disabled

        /*
        if (isLoading) {
            return (
                <div className="loading">
                    <BeatLoader color={'#ffffff'} width={'70px'}/>
                </div>
            )
        }
        else
            return;
        */
    };

    render() {
        const { isLoading } = this.props;
        return <div>{this.getLoadingScreen(isLoading)}</div>;
    }
}

const mapStateToProps = state => ({
    isLoading: state.MainView.isLoading,
});

export default connect(mapStateToProps, null)(MainView);
