import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import feedbackActionCreators from "../../store/Feedback/actionCreators";
import Icon from "../../common/components/UI/Icon";
import ContextMenu from "../../common/components/ContextMenu";

class AuthContextMenu extends Component {
    render() {
        const { displayFeedbackForm } = this.props;

        const helpMore = [
            {
                to: "",
                text: "Send us feedback",
                icon: "envelope",
                onClick: () => displayFeedbackForm(),
            },
        ];

        return (
            <ContextMenu itemsList={helpMore}>
                <Icon name="help" width={24} height={24} fill="#34CCB8" />
            </ContextMenu>
        );
    }
}
const dispatchToProps = {
    displayFeedbackForm: feedbackActionCreators.displayFeedbackForm.create,
};

export default connect(null, dispatchToProps)(AuthContextMenu);
