import { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Links/actionCreators";
// import FilterBar from "../../../common/components/FilterBar";
import { Container } from "../../../common/components/UI/Container";
import HeaderMobile from "../../../common/components/Header/HeaderMobile";
import { linksPage } from "../LinksStyles";
import ChannelBar from "../../../common/components/ChannelBar";
// import filters from "./filters";
import NoResults from "../../../common/components/NoResults/";
import ChannelsPlaceholder from "../../../common/components/Skeletons/ChannelsPlaceholder";
import GTM from "../../../common/services/GTM";

const ChannelItems = ({ channels }) => (
    <div>
        {channels.map(({ id, iconUrl, name }) => (
            <ChannelBar key={`${id}`} {...{ id, iconUrl, name }} />
        ))}
    </div>
);

class MyChannels extends Component {
    componentDidMount() {
        this.props.setUserChannelFilter("page");

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/my-channels`,
        });
    }

    onLoadMore = () => {
        const { getUserChannel, filter, page } = this.props;
        getUserChannel({ page, filter });
    };

    render() {
        const { channels, hasMore } = this.props;
        const { onLoadMore } = this;
        return (
            <div css={linksPage.bodyNoPadding}>
                {/* <div css={linksPage.body}> */}
                <HeaderMobile title="My Channels" />

                <ChannelsPlaceholder isLoaded={Boolean(channels)}>
                    {channels &&
                        (channels.length > 0 ? (
                            <Container width={592} noPadding>
                                <InfiniteScroll
                                    dataLength={channels.length}
                                    next={onLoadMore}
                                    {...{ hasMore }}
                                >
                                    <div css={linksPage.innerNoPaddingOnMobile}>
                                        <ChannelItems {...{ channels }} />
                                    </div>
                                </InfiniteScroll>
                            </Container>
                        ) : (
                            <Container width={592} noPadding>
                                {
                                    <NoResults
                                        heading="No results!"
                                        message="Please try to follow some channels first ..."
                                    />
                                }
                            </Container>
                        ))}
                </ChannelsPlaceholder>
            </div>
        );
    }
}

const mapStateToProps = ({ Links: { channelPage, channels, filter, channelsHasMore } }) => ({
    page: channelPage,
    channels,
    filter,
    hasMore: channelsHasMore,
});

const mapDispatchToProps = {
    getUserChannel: actionCreators.getUserChannel.create,
    setUserChannelFilter: actionCreators.setUserChannelFilter.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyChannels);
