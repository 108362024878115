import "core-js/stable";
import "regenerator-runtime/runtime";
import { bugsnagClient } from "./common/services/bugsnagClient";
import createPlugin from "bugsnag-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
// import MomentUtils from "material-ui-pickers/utils/moment-utils";
import history from "./common/services/history";
import "./index.css";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { store } from "./store";
import { lseTheme } from "./common/components/FormFields/FormFieldsStyles";
import ErrorFallback from "./common/components/ErrorFallback";
import { LastLocationProvider } from "react-router-last-location";

import AppContent from "./AppContent";

import "froala-editor/css/froala_style.min.css";
import "font-awesome/css/font-awesome.css";

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <MuiThemeProvider theme={lseTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <LastLocationProvider>
                            <AppContent />
                        </LastLocationProvider>
                    </ConnectedRouter>
                </Provider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);
unregisterServiceWorker();
