import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { reduxForm, Field } from "redux-form";
import Icon from "../../common/components/UI/Icon";
import RenderTextareaAutosize from "../../common/components/FormFields/TextareaAutosize";
import { commentsForm } from "../Feed/FeedStyles";
import { colors } from "../../common/styles/Colors";
import { connect } from "react-redux";
import GTM from "../../common/services/GTM";

class CommentForm extends Component {
    componentDidUpdate() {
        if (this.props.isFocused === true) this.input.focus();
    }

    render() {
        const { onSubmit, handleSubmit, isLoading, formType, formParentId, id, name } = this.props;
        return (
            <div css={commentsForm.body}>
                <form css={commentsForm.inner} onSubmit={handleSubmit(onSubmit)}>
                    <div css={commentsForm.textField}>
                        <Field
                            component={RenderTextareaAutosize}
                            name="comment"
                            placeholder="Write comment"
                            refName={ref => (this.input = ref)}
                        />
                    </div>
                    <button
                        css={commentsForm.btn}
                        type="submit"
                        disabled={isLoading}
                        onClick={() => {
                            GTM.dispatch({
                                event: "postComment",
                                actionType:
                                    formType === "NEW"
                                        ? formParentId > 0
                                            ? "Reply to comment"
                                            : "New comment"
                                        : "Edit comment",
                                postId: `${id}`,
                                postName: `${name}`,
                            });
                            return true;
                        }}
                    >
                        <Icon name="submit" width="21" height="21" fill={colors.greyDark} />
                    </button>
                </form>
            </div>
        );
    }
}

const form = reduxForm({
    form: "comment",
})(CommentForm);

const mapStateToProps = state => {
    return {
        isLoading: state.MainView.isLoading,
    };
};

export default connect(mapStateToProps)(form);
