/** @jsx jsx */
import { jsx } from "@emotion/core";
import { channelBar } from "./ChannelBarStyles";
import Avatar from "../../components/UI/Avatar";
import { connect } from "react-redux";
import actionCreators from "../../../store/Channel/actionCreators";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

const ChannelBar = ({ id, iconUrl, name, redirectToChannel, note }) => {
    return (
        <button onClick={() => redirectToChannel({ id })} css={channelBar.body}>
            <span css={channelBar.bodyLeft}>
                <Avatar src={iconUrl} />
            </span>
            <span css={channelBar.bodyRow}>
                <span css={channelBar.bodyCenter}>
                    <span css={channelBar.title}>{name}</span>
                </span>
                {note && (
                    <span css={channelBar.note}>
                        <span css={[channelBar.status, channelBar.statusPending]}>
                            <span css={channelBar.statusIcon}>
                                <Icon name="clock" width={12} height={12} fill={colors.black40} />
                            </span>
                            <span>{note}</span>
                        </span>
                    </span>
                )}
            </span>
        </button>
    );
};

const mapDispatchToProps = {
    redirectToChannel: actionCreators.redirectToChannel.create,
};

export default connect(null, mapDispatchToProps)(ChannelBar);
