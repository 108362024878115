/** @jsx jsx */
import { jsx } from "@emotion/core";
import { studentBar } from "./StudentBarStyles";
import Avatar from "../../../common/components/UI/Avatar";
import { titleCase } from "../../../common/services/utils";

const StudentBar = ({ id, photoUrl, firstName, lastName, push }) => {
    return (
        <button onClick={() => push(`/myprogramme/student/${id}`)} css={studentBar.body}>
            <span css={studentBar.bodyLeft}>
                <Avatar src={photoUrl} initials={firstName.charAt(0) + lastName.charAt(0)} />
            </span>
            <span css={studentBar.bodyRow}>
                <span css={studentBar.bodyCenter}>
                    <span css={studentBar.title}>
                        {titleCase(firstName)} {titleCase(lastName)}
                    </span>
                </span>
                <span css={studentBar.actions}></span>
            </span>
        </button>
    );
};

export default StudentBar;
