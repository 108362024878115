import { of } from "rxjs";
import actionCreators from "../../store/Auth/actionCreators";
import mainViewActionCreators from "../../store/MainView/actionCreators";
import { switchCase } from "./utils";
import { bugsnagClient } from "./bugsnagClient";

export default action => errorRes => {
    const error = errorRes.response
        ? {
              error: errorRes.response.error ? errorRes.response.error : "",
              errors: errorRes.response.errors ? errorRes.response.errors : {},
          }
        : { error: "An unknown error occurred ...", errors: {} };

    const metadata =
        errorRes.status < 500
            ? {
                  ajax: {
                      error,
                      status: errorRes.status,
                      url: errorRes.request && errorRes.request.url,
                      method: errorRes.request && errorRes.request.method,
                  },
              }
            : errorRes;

    if (process.env.REACT_APP_BUGSNAG_KEY !== "DEV") {
        bugsnagClient.notify(errorRes, {
            severity: errorRes.status < 500 ? "info" : "error",
            beforeSend: report => {
                report.groupingHash = `${report.errorClass}:${report.errorMessage}`;
            },
            metaData: metadata,
        });
    }

    return switchCase({
        401: () => of(actionCreators.logout.create()),
        500: () => of(mainViewActionCreators.errorPageRedirect.create()),
        503: () => of(mainViewActionCreators.maintenancePageRedirect.create()),
    })(() => of(action(error, errorRes)))(errorRes.status)();
};
