import { colors } from "../../common/styles/Colors";
import { spacing } from "../../common/styles/Spacing";
import { fontSize, fontWeight } from "../../common/styles/Typography";
import { resetLineHeight } from "../../common/styles/Globals";

export const campusMapping = {
    body: {
        // backgroundColor: colors.black5,
        // minHeight: "100vh",
        height: "100vmin",
    },
    searchContainer: {
        paddingTop: 58 + spacing.space2,
        "@media (max-width: 1023px)": {
            paddingTop: 58,
            // paddingRight: spacing.space1,
            // paddingLeft: spacing.space1,
        },
    },
    mapContainer: {
        height: "55%",
        position: "fixed",
        top: 0,
        left: 300,
        right: 0,
        zIndex: 10,
        "@media (max-width: 1023px)": {
            left: 0,
        },
    },
    contentContainer: {
        backgroundColor: colors.white,
        paddingTop: "55vh",
        minHeight: "100%",
        position: "relative",
        "@media (max-width: 1023px)": {
            paddingBottom: 48,
        },
    },
    map: {
        height: "100%",
    },
    contentHeading: {
        fontSize: 24,
        fontWeight: fontWeight.black,
        lineHeight: 1,
        paddingTop: spacing.space3,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    buildingsList: {
        // paddingBottom: spacing.space8,
    },
    buildingInfo: {
        // paddingBottom: spacing.space8,
    },
    btnFullscreen: {
        backgroundColor: colors.white,
        position: "absolute",
        top: spacing.space1,
        right: spacing.space1,
        bottom: "auto",
        "@media (max-width: 1023px)": {
            top: "auto",
            right: spacing.space2,
            bottom: spacing.space2,
        },
    },
    btnFullscreenActive: {
        "@media (max-width: 1023px)": {
            bottom: spacing.space8,
        },
    },
    btnBack: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: 0,
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: spacing.space4,
        position: "absolute",
        top: "55vh",
        left: spacing.space2,
        "@media (max-width: 1023px)": {
            position: "fixed",
            top: spacing.space2,
            left: spacing.space3,
            zIndex: 1000,
            marginTop: 0,
        },
    },
};

export const buildingShort = {
    body: {
        display: "flex",
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: spacing.space2,
        textAlign: "left",
        width: "100%",
    },
    elementLeft: {
        flex: 1,
    },
    elementRight: {
        ...resetLineHeight,
        width: 32,
        marginLeft: spacing.space3,
    },
    name: {
        display: "block",
        color: colors.black87,
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
        lineHeight: 1.25,
    },
    address: {
        display: "block",
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.light,
        lineHeight: 1.42,
    },
    code: {
        width: 32,
        height: 36,
    },
    codeInner: {
        display: "flex",
        width: 32,
        height: 32,
        backgroundColor: "#E0112B",
        justifyContent: "center",
        alignItems: "center",
    },
    codeText: {
        color: colors.white,
        fontSize: fontSize.textSmaller,
        fontWeight: fontWeight.bold,
    },
    shapeBottomCode: {
        ...resetLineHeight,
        height: 4,
    },
};

export const buildingInfo = {
    body: {
        paddingTop: spacing.space4,
        paddingBottom: spacing.space4,
        "@media (max-width: 1023px)": {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    header: {
        display: "flex",
        flexDirection: "row-reverse",
        marginBottom: spacing.space4,
        "@media (max-width: 1023px)": {
            flexDirection: "column",
            marginBottom: 0,
        },
    },
    headerInner: {
        flex: 1,
        paddingLeft: spacing.space3,
        "@media (max-width: 1023px)": {
            display: "flex",
            paddingTop: spacing.space3,
            paddingRight: spacing.space1,
            paddingBottom: spacing.space3,
            paddingLeft: spacing.space1,
        },
    },
    headerInfo: {
        marginTop: spacing.space2,
        "@media (max-width: 1023px)": {
            marginTop: 0,
            marginLeft: spacing.space2,
        },
    },
    code: {
        width: 48,
        height: 52,
    },
    codeInner: {
        display: "flex",
        width: 48,
        height: 46,
        backgroundColor: "#E0112B",
        justifyContent: "center",
        alignItems: "center",
    },
    codeText: {
        color: colors.white,
        fontSize: 17,
        fontWeight: fontWeight.medium,
    },
    shapeBottomCode: {
        ...resetLineHeight,
        height: 8,
    },
    name: {
        display: "block",
        color: colors.black87,
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
        lineHeight: 1.25,
        marginBottom: spacing.space1,
    },
    address: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1.42,
    },
    imgContainer: {
        width: 280,
        "@media (max-width: 1023px)": {
            width: "auto",
            marginRight: -spacing.space1,
            marginLeft: -spacing.space1,
        },
    },
    img: {
        width: "100%",
        height: "auto",
        display: "block",
    },
    text: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        "@media (max-width: 1023px)": {
            paddingTop: spacing.space2,
            paddingRight: spacing.space1,
            paddingBottom: spacing.space2,
            paddingLeft: spacing.space1,
        },
    },
    directions: {
        marginTop: spacing.space1comma5,
    },
};
