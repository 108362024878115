import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ButtonBase } from "@material-ui/core";
import { button } from "./ButtonStyles";
import Icon from "./Icon";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

class Button extends Component {
    render() {
        const {
            to,
            type,
            children,
            style,
            fullWidth,
            small,
            smaller,
            channel,
            textIcon,
            disabled,
            outline,
            accent,
            isFollow,
            ...other
        } = this.props;

        return (
            <ButtonBase
                css={{
                    ...(small ? button.bodySmall : button.body),
                    ...(smaller && button.bodySmaller),
                    ...(outline && button.bodyOutline),
                    ...(fullWidth && button.bodyFullWidth),
                    ...(accent && button.bodyAccent),
                    ...(disabled && button.bodyDisabled),
                    ...(isFollow && button.bodySmallDark),
                    ...(channel && button.bodyChannel),
                    ...style,
                }}
                // component={to && Link}
                // to={to}
                onClick={() => history.push(to)}
                type={type}
                disabled={disabled}
                disableRipple={accent}
                {...other}
            >
                {textIcon && (
                    <span css={button.textIcon}>
                        <Icon
                            name={textIcon.name}
                            width={textIcon.width}
                            height={textIcon.height}
                            fill={textIcon.fill ? textIcon.fill : colors.black87}
                        />
                    </span>
                )}
                <span
                    css={{
                        ...(small ? button.textSmall : button.text),
                        ...(smaller && button.textSmaller),
                        ...(accent && button.textAccent),
                        ...(channel && button.textChannel),
                    }}
                >
                    {children}
                </span>
            </ButtonBase>
        );
    }
}

export default Button;
