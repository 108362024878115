import actionCreators from "./actionCreators";

export default (state = { isLoading: false }, { type, payload }) => {
    switch (type) {
        case actionCreators.setLoading.type:
            return {
                ...state,
                isLoading: true,
            };
        case actionCreators.clearLoading.type:
            return {
                ...state,
                isLoading: false,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        default:
            return state;
    }
};
