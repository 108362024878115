export default handler => [
    {
        nameDesktop: "All",
        nameMobile: "All",
        value: "",
        handler,
    },
    {
        nameDesktop: "Posts",
        nameMobile: "Posts",
        value: "post=text",
        handler,
    },
    {
        nameDesktop: "Events",
        nameMobile: "Events",
        value: "post=event",
        handler,
    },
];
