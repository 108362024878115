import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";

const monthStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getMonthEvents.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const monthClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateMonthEvents.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getMonthEventsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getMonthEvents.type),
        mergeMap(({ payload: { month } }) =>
            ajax.get(apiUrl(`api/calendar/month-events/?month=${month || ""}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateMonthEvents.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(monthStartLoadingEpic, monthClearLoadingEpic, getMonthEventsEpic);
