import actionCreators from "./actionCreators";
import { mergeByIds } from "../../common/services/storeHelpers";

export const initialState = {
    channels: null,
    channelPage: 1,
    channelsHasMore: true,
    favouritePosts: null,
    favouritePostsPage: 1,
    favouritePostsHasMore: true,
    favouritePostsFilter: "",
    filter: "page",
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateUserChannel.type:
            return {
                ...state,
                channels: mergeByIds(state.channels || [], payload),
                channelPage: state.channelPage + 1,
                errorRes: initialState.error,
                channelsHasMore: payload && payload.length === 25,
            };
        case actionCreators.updateUserFavouritePosts.type:
            return {
                ...state,
                favouritePosts: mergeByIds(state.favouritePosts || [], payload),
                favouritePostsPage: state.favouritePostsPage + 1,
                errorRes: initialState.error,
                favouritePostsHasMore: payload && payload.length === 25,
            };
        case actionCreators.filterMyGroups.type:
            return {
                ...state,
                channels: state.channels
                    ? state.channels.filter(({ id }) => id !== payload)
                    : state.channels,
            };
        case actionCreators.errorResponse.type:
            return {
                errorRes: payload,
            };
        case actionCreators.setUserChannelFilter.type:
            return {
                ...state,
                filter: payload,
                channels: null,
                channelPage: 1,
                channelsHasMore: true,
            };
        case actionCreators.initializeFavoritePosts.type:
            return {
                favouritePosts: null,
                favouritePostsPage: 1,
                favouritePostsHasMore: true,
            };
        case actionCreators.updateUnbookmarkedSavedPost.type:
            return {
                ...state,
                favouritePosts: state.favouritePosts
                    ? state.favouritePosts.filter(post => post.id !== payload.id)
                    : initialState.favouritePosts,
            };
        case actionCreators.setFavouritePostsFilter.type:
            return {
                ...state,
                favouritePostsFilter: payload,
                favouritePostsHasMore: true,
                favouritePostsPage: 1,
                favouritePosts: null,
            };
        default:
            return state;
    }
};
