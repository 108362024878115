import ActionCreator from "../../actionCreator";

export default {
    getPost: ActionCreator("GET_POST"),
    updatePost: ActionCreator("UPDATE_POST"),
    clearPost: ActionCreator("CLEAR_POST"),
    getComments: ActionCreator("GET_COMMENTS"),
    updateComments: ActionCreator("UPDATE_COMMENTS"),
    loadMoreComments: ActionCreator("LOAD_MORE_COMMENTS"),
    putBookEvent: ActionCreator("PUT_BOOK_EVENT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_POST"),
    bookmarkPost: ActionCreator("BOOKMARK_POST"),
    unbookmarkPost: ActionCreator("UNBOOKMARK_POST"),
    updateBookmarkedPost: ActionCreator("UPDATE_BOOKMARKED_POST"),
    updateUnbookmarkedPost: ActionCreator("UPDATE_UNBOOKMARKED_POST"),
    likePost: ActionCreator("LIKE_POST"),
    unlikePost: ActionCreator("UNLIKE_POST"),
    updateLikedPost: ActionCreator("UPDATE_LIKED_POST"),
    updateUnlikedPost: ActionCreator("UPDATE_LIKED_POST"),
    updateNumCommentsPost: ActionCreator("UPDATE_NUM_COMMENTS_POST"),
};
