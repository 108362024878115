import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../../store/Calendar/Event/actionCreators";
import EventForm from "../Form";
import { calendarPage } from "../../CalendarStyles";
import HeaderCalendar from "../../../../common/components/Header/HeaderCalendar";
import { Container } from "../../../../common/components/UI/Container";
import HeaderCalendarMobile from "../../../../common/components/Header/HeaderCalendarMobile";

import GTM from "../../../../common/services/GTM";
import { getUrlSearchParams } from "../../utils";

class EditEventNotes extends Component {
    componentDidMount() {
        const {
            location: { search },
        } = this.props;
        const urlParams = getUrlSearchParams(search);
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/event/edit-notes/${urlParams.calendarType}/${urlParams.uid}`,
        });
    }

    onSubmit = values => {
        const { putEventNotes } = this.props;
        putEventNotes({
            ...values,
        });
    };

    render() {
        const { form, errorRes, saveFormData, item } = this.props;
        const { onSubmit } = this;
        return (
            <div css={calendarPage.body}>
                <HeaderCalendar {...{ uid: item.uid, calendarType: item.calendarType }} />
                <HeaderCalendarMobile {...{ uid: item.uid, calendarType: item.calendarType }} />
                <Container onMobileNoPadding width={592}>
                    <EventForm
                        initialValues={item}
                        formState={form}
                        form={"editEventNotes"}
                        {...{
                            onSubmit,
                            saveFormData,
                            errorRes,
                        }}
                    />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    form,
    calendar: {
        event: { item, errorRes },
    },
}) => ({
    form,
    item,
    errorRes,
});

const mapDispatchToProps = {
    putEventNotes: actionCreators.putEventNotes.create,
    saveFormData: actionCreators.saveFormData.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEventNotes);
