import { Component } from "react";
import { connect } from "react-redux";
import { Subject } from "rxjs/Subject";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../store/Search/actionCreators";
import { searchBar } from "./SearchStyles";
import Icon from "../../common/components/UI/Icon";
import { colors } from "../../common/styles/Colors";
import GTM from "../../common/services/GTM";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

class SearchField extends Component {
    state = {
        filter$: new Subject(),
    };

    componentDidMount() {
        this.clearSearchInput();
    }

    clearSearchInput = () => {
        const {
            clearTextInput,
            getSearchResults,
            show,
            enableResults,
            setQuerySearch,
        } = this.props;
        const { filter$ } = this.state;

        clearTextInput();
        this.searchInput.value = "";

        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
            if (!show) {
                enableResults();
            }
            getSearchResults({ filter: value });
            setQuerySearch(value);
            if (value !== "") {
                GTM.dispatch({
                    event: "newSearch",
                    actionType: "Feed search",
                    searchQuery: `${value}`,
                });
            }
        });
    };

    hideSearchBar = () => {
        const { toggleActiveForm, disableResults, setQuerySearch } = this.props;
        toggleActiveForm(false);
        disableResults();
        setQuerySearch("");
    };

    render() {
        const { querySearch } = this.props;
        const { hideSearchBar, clearSearchInput } = this;
        const { filter$ } = this.state;

        return (
            <div css={searchBar.body}>
                <div css={searchBar.inner}>
                    <div css={searchBar.elementLeft}>
                        <Icon
                            name="search"
                            fill={colors.black20}
                            width={18}
                            height={18}
                            css={searchBar.iconSearch}
                        />
                        <button
                            type="button"
                            css={searchBar.btnBack}
                            onClick={() => hideSearchBar()}
                        >
                            <Icon name="arrowLeft" fill={colors.black87} width={18} height={18} />
                        </button>
                    </div>
                    <div css={searchBar.elementCenter}>
                        <input
                            onInput={e => filter$.next(e.target.value)}
                            css={searchBar.input}
                            placeholder="Search"
                            ref={e => (this.searchInput = e)}
                        />
                    </div>
                    <div css={searchBar.elementRight}>
                        {querySearch !== "" && (
                            <button
                                type="button"
                                onClick={() => clearSearchInput()}
                                css={searchBar.btn}
                            >
                                <Icon name="times" fill={colors.black87} width={16} height={16} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ Search: { results, show, querySearch } }) => ({
    results,
    show,
    querySearch,
});

const mapDispatchToProps = {
    getSearchResults: actionCreators.getSearchResults.create,
    disableResults: actionCreators.disableResults.create,
    enableResults: actionCreators.enableResults.create,
    toggleActiveForm: actionCreators.toggleSearchBar.create,
    setQuerySearch: actionCreators.setQuerySearch.create,
    clearTextInput: actionCreators.clearSearchInput.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
