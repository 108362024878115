import { Component } from "react";
import { connect } from "react-redux";
import { Subject } from "rxjs/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/distinctUntilChanged";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { TextFieldInline } from "../../../common/components/FormFields/TextFieldInline";
import actionCreators from "../../../store/Calendar/Event/actionCreators";
import { calendarPage, eventFull, inviteesList, invitedPerson } from "../CalendarStyles";
import HeaderCalendar from "../../../common/components/Header/HeaderCalendar";
import { Container } from "../../../common/components/UI/Container";
import { colors } from "../../../common/styles/Colors";
import Avatar from "../../../common/components/UI/Avatar";
import Icon from "../../../common/components/UI/Icon";
import NoResults from "../../../common/components/NoResults/";
import HeaderCalendarMobile from "../../../common/components/Header/HeaderCalendarMobile";
import GTM from "../../../common/services/GTM";

class AddInvitees extends Component {
    state = {
        filter$: new Subject(),
    };
    componentDidMount() {
        const { searchInvitees, clearInvitees, page } = this.props;
        const { filter$ } = this.state;
        filter$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(filter => {
            filter !== "" ? searchInvitees({ page, filter }) : clearInvitees();
        });
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/invitees/add`,
        });
    }

    onInput = ({ target: { value } }) => {
        const { setFilter } = this.props;
        const { filter$ } = this.state;
        setFilter(value);
        filter$.next(value);
    };

    isOriginal = id => {
        const { original } = this.props;
        return original.findIndex(invitee => invitee.id === id) !== -1;
    };

    handleaddInvitee = invitee => {
        const { addInviteeToSelected, removeInviteeFromSelected, selectedInvitees } = this.props;
        const remove =
            selectedInvitees.findIndex(selectedInvitee => selectedInvitee.id === invitee.id) !== -1;
        remove ? removeInviteeFromSelected(invitee.id) : addInviteeToSelected(invitee);
    };

    render() {
        const { filter, invitees, selectedInvitees } = this.props;
        const { onInput, handleaddInvitee, isOriginal } = this;

        return (
            <div css={[calendarPage.body, calendarPage.bodyMobileWhite]}>
                <HeaderCalendar />
                <HeaderCalendarMobile />
                <Container onMobileNoPadding width={592}>
                    <div css={calendarPage.invitees}>
                        <div css={[eventFull.section, eventFull.sectionBorder]}>
                            <div css={eventFull.inner}>
                                <TextFieldInline
                                    placeholder="Search..."
                                    value={filter}
                                    onInput={onInput}
                                />
                            </div>
                        </div>
                        {filter && invitees ? (
                            <div css={inviteesList.list}>
                                {invitees.map(
                                    invitee =>
                                        !isOriginal(invitee.id) && (
                                            <button
                                                onClick={() => handleaddInvitee(invitee)}
                                                key={invitee.id}
                                                css={invitedPerson.body}
                                            >
                                                <div css={invitedPerson.bodyLeft}>
                                                    <Avatar
                                                        src={invitee.photoUrl}
                                                        initials={
                                                            invitee.firstName.charAt(0) +
                                                            invitee.lastName.charAt(0)
                                                        }
                                                    />
                                                </div>
                                                <div css={invitedPerson.bodyCenter}>
                                                    <div css={invitedPerson.title}>
                                                        {invitee.firstName} {invitee.lastName}
                                                    </div>
                                                    {selectedInvitees &&
                                                        selectedInvitees.map(selectedInvitee =>
                                                            selectedInvitee.id === invitee.id ? (
                                                                <Icon
                                                                    name="check"
                                                                    width="18"
                                                                    height="18"
                                                                    fill={colors.black40}
                                                                    key={invitee.id}
                                                                />
                                                            ) : null
                                                        )}
                                                </div>
                                            </button>
                                        )
                                )}
                            </div>
                        ) : null}
                        {!filter && selectedInvitees && selectedInvitees.length > 0 && (
                            <div css={inviteesList.list}>
                                <div css={inviteesList.title}>Invited guests</div>
                                {selectedInvitees &&
                                    selectedInvitees.map(invitee => (
                                        <div key={invitee.id} css={invitedPerson.body}>
                                            <div css={invitedPerson.bodyLeft}>
                                                <Avatar
                                                    uri={invitee.photoUrl}
                                                    initials={
                                                        invitee.firstName.charAt(0) +
                                                        invitee.lastName.charAt(0)
                                                    }
                                                />
                                            </div>
                                            <div css={invitedPerson.bodyCenter}>
                                                <div css={invitedPerson.title}>
                                                    {invitee.firstName} {invitee.lastName}
                                                </div>
                                            </div>
                                            <div css={invitedPerson.bodyRight}>
                                                {isOriginal(invitee.id) ? (
                                                    <Icon
                                                        name="check"
                                                        width="18"
                                                        height="18"
                                                        fill={colors.black40}
                                                        key={invitee.id}
                                                    />
                                                ) : (
                                                    <button
                                                        onClick={() => handleaddInvitee(invitee)}
                                                        css={invitedPerson.btnRemove}
                                                    >
                                                        <Icon
                                                            name="times"
                                                            width="16"
                                                            height="16"
                                                            fill={colors.black40}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                        {filter !== "" && invitees && invitees.length === 0 && (
                            <NoResults
                                heading="Sorry!"
                                message={`We couldn't find any user(s) matching your search query ...`}
                                onMobileMargin
                            />
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { filter, page, invitees, item, selectedInvitees, stateStatus },
    },
}) => ({
    filter,
    page,
    invitees,
    selectedInvitees,
    original: item.invitees,
    stateStatus,
});

const mapDispatchToProps = {
    searchInvitees: actionCreators.searchInvitees.create,
    addInviteeToSelected: actionCreators.addInviteeToSelected.create,
    removeInviteeFromSelected: actionCreators.removeInviteeFromSelected.create,
    setFilter: actionCreators.setFilter.create,
    clearInvitees: actionCreators.clearInvitees.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInvitees);
