import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as channelActionCreators } from "../../Channel/actionCreators";
import { default as linksActionCreators } from "../../Links/actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import { default as feedActionCreators } from "../../Feed/actionCreators";
import { default as searchActionCreators } from "../../Search/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";
import history from "../../../common/services/history";
import * as config from "../../../app/Channel/Group/values";

const startLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getGroup.type,
            actionCreators.putGroup.type,
            actionCreators.postPost.type,
            actionCreators.trackingGroup.type,
            actionCreators.getGroupMembers.type,
            actionCreators.updateGroupMemberPendingState.type,
            actionCreators.changeGroupMemberRole.type,
            actionCreators.removeGroupMember.type,
            actionCreators.searchForUsers.type,
            actionCreators.updateSearchedUsers.type,
            actionCreators.inviteUserToGroup.type,
            actionCreators.answerGroupInvitation.type,
            actionCreators.sendGroupFeedback.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const clearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createGroup.type,
            actionCreators.clearValidateError.type,
            actionCreators.createGroupMembers.type,
            actionCreators.deleteGroupMember.type,
            actionCreators.redirectToGroupMembers.type,
            actionCreators.updateSearchedUsers.type,
            actionCreators.clearSearchedUsers.type,
            actionCreators.updateGroupFeedback.type,
            actionCreators.errorResponse.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getGroup.type),
        mergeMap(({ payload: { id } }) =>
            ajax.get(apiUrl(`api/my-groups/${id}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.createGroup.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getGroupMembersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getGroupMembers.type),
        mergeMap(({ payload: { id, page, filter } }) =>
            ajax.get(apiUrl(`api/group/${id}/members/${page}/?type=${filter || ""}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.createGroupMembers.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const setGroupMembersFilterEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setGroupMembersFilter.type),
        mergeMap(({ payload }) =>
            of(
                actionCreators.getGroupMembers.create({
                    id: payload.id,
                    page: 1,
                    filter: payload.name,
                })
            )
        )
    );

const putGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putGroup.type),
        mergeMap(({ payload }) =>
            ajax.put(apiUrl(`api/my-groups/${payload.id}/`), payload).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(UIActionCreators.clearLoading.create()),
                        of(push(`/channel/${payload.id}`))
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const postPostEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.postPost.type),
        mergeMap(({ payload: { id, values } }) =>
            ajax.post(apiUrl(`api/my-groups/${id}/posts/`), values).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(actionCreators.clearValidateError.create()),
                        of(
                            channelActionCreators.redirectToChannel.create({
                                id,
                                meta: { postPost: true },
                            })
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const TrackingGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.trackingGroup.type),
        mergeMap(({ payload: { id, action } }) =>
            ajax.post(apiUrl(`api/channel/${id}/tracking/${action}/`)).pipe(
                map(res => res.response),
                mergeMap(() => {
                    if (action === "unfollow") {
                        history.goBack();
                    }
                    return concat(
                        of(channelActionCreators.toggleChannelTracking.create()),
                        of(searchActionCreators.toggleSearchBar.create(false)),
                        of(searchActionCreators.disableResults.create()),
                        of(feedActionCreators.initializeFeed.create()),
                        action === "unfollow"
                            ? of(linksActionCreators.filterMyGroups.create(id))
                            : of(
                                  linksActionCreators.getUserChannel.create({
                                      page: 1,
                                      filter: "group",
                                  })
                              )
                    );
                }),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const redirectToEditGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToEditGroup.type),
        mergeMap(({ payload: { id, meta } }) => of(push(`/group/${id}/edit-group`, meta)))
    );

const updateGroupMemberPendingStateEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateGroupMemberPendingState.type),
        mergeMap(({ payload: { groupId, userId, status } }) =>
            ajax.post(apiUrl(`api/group/${groupId}/pending-user/${userId}/${status}/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(
                            actionCreators.deleteGroupMember.create({
                                id: userId,
                            })
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const changeGroupMemberRoleEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.changeGroupMemberRole.type),
        mergeMap(({ payload: { groupId, userId, type } }) =>
            ajax.post(apiUrl(`api/group/${groupId}/change-role/${userId}/${type}/`)).pipe(
                map(res => res.response),
                mergeMap(channel =>
                    concat(
                        of(
                            actionCreators.deleteGroupMember.create({
                                id: userId,
                            })
                        ),
                        of(channelActionCreators.createChannel.create(channel))
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const removeGroupMemberEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeGroupMember.type),
        mergeMap(({ payload: { groupId, userId } }) =>
            ajax.remove(apiUrl(`api/group/${groupId}/remove-user/${userId}/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(
                        of(
                            actionCreators.deleteGroupMember.create({
                                id: userId,
                            })
                        )
                    )
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const searchForUsersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.searchForUsers.type),
        mergeMap(({ payload: { id, filter } }) =>
            ajax.get(apiUrl(`api/group/${id}/search-user/?filter=${filter}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateSearchedUsers.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const inviteUserToGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.inviteUserToGroup.type),
        mergeMap(({ payload: { groupId, userId, isLast } }) =>
            ajax.post(apiUrl(`api/group/${groupId}/invite/${userId}/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    isLast
                        ? concat(
                              of(
                                  actionCreators.setGroupMembersFilter.create({
                                      id: groupId,
                                      name: "pending",
                                  })
                              ),
                              of(
                                  actionCreators.redirectToGroupMembers.create({
                                      id: groupId,
                                      meta: { noRefresh: true },
                                  })
                              )
                          )
                        : of({ type: null })
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const sendJoinRequestToGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.sendJoinRequestToGroup.type),
        mergeMap(({ payload: { groupId } }) =>
            ajax.post(apiUrl(`api/group/${groupId}/join/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    concat(of(channelActionCreators.getChannel.create({ id: groupId })))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const redirectToGroupMembersEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.redirectToGroupMembers.type),
        mergeMap(({ payload: { id, meta } }) => of(push(`/group/${id}/members`, meta)))
    );

const deleteGroupEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.deleteGroup.type),
        mergeMap(({ payload: { id } }) =>
            ajax.remove(apiUrl(`api/my-groups/${id}/`)).pipe(
                map(res => res.response),
                mergeMap(() => concat(of(push("/my-groups")))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const answerGroupInvitationEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.answerGroupInvitation.type),
        mergeMap(({ payload: { groupId, status } }) =>
            ajax.post(apiUrl(`api/group/${groupId}/my-invitation/${status}/`)).pipe(
                map(res => res.response),
                mergeMap(() =>
                    status === config.ROLE_ACTION_DECLINE
                        ? concat(of(push("/my-groups")))
                        : concat(of(channelActionCreators.setChannelAsFollowed.create()))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const sendGroupFeedbackEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.sendGroupFeedback.type),
        mergeMap(({ payload: { id, data } }) =>
            ajax.post(apiUrl(`api/group/${id}/report/`), data).pipe(
                map(res => res.response),
                mergeMap(() => of(actionCreators.updateGroupFeedback.create())),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    startLoadingEpic,
    clearLoadingEpic,
    getGroupEpic,
    putGroupEpic,
    TrackingGroupEpic,
    postPostEpic,
    getGroupMembersEpic,
    setGroupMembersFilterEpic,
    redirectToEditGroupEpic,
    updateGroupMemberPendingStateEpic,
    changeGroupMemberRoleEpic,
    removeGroupMemberEpic,
    searchForUsersEpic,
    inviteUserToGroupEpic,
    redirectToGroupMembersEpic,
    sendJoinRequestToGroupEpic,
    deleteGroupEpic,
    answerGroupInvitationEpic,
    sendGroupFeedbackEpic
);
