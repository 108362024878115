import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const linksStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getUserChannel.type,
            actionCreators.getUserFavouritePosts.type,
            actionCreators.loadMoreUserChannel.type
        ),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const linksClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.updateUserChannel.type,
            actionCreators.updateUserFavouritePosts.type,
            actionCreators.errorResponse
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getUserChannelEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUserChannel.type),
        mergeMap(({ payload: { page, filter } }) =>
            ajax.get(apiUrl(`api/user/followed-channels/${page}/?source=${filter || ""}`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateUserChannel.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getUserFavouritePostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getUserFavouritePosts.type),
        mergeMap(({ payload: { page, filter } }) =>
            ajax.get(apiUrl(`api/user/favourite-posts/${page}/?${filter}`)).pipe(
                map(res =>
                    res.response.map(({ channelContext, ...rest }) => ({
                        ...rest,
                        channel: channelContext,
                    }))
                ),
                mergeMap(res => of(actionCreators.updateUserFavouritePosts.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const setUserChannelFilterEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setUserChannelFilter.type),
        mergeMap(({ payload }) =>
            of(actionCreators.getUserChannel.create({ page: 1, filter: payload }))
        )
    );

const initializeFavoritePostsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.initializeFavoritePosts.type),
        mergeMap(() => of(actionCreators.getUserFavouritePosts.create({ page: 1, filter: "" })))
    );

const setFavouritePostsFilterEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setFavouritePostsFilter.type),
        mergeMap(({ payload }) =>
            of(actionCreators.getUserFavouritePosts.create({ page: 1, filter: payload }))
        )
    );

export const epics = combineEpics(
    linksStartLoadingEpic,
    linksClearLoadingEpic,
    getUserChannelEpic,
    getUserFavouritePostsEpic,
    setUserChannelFilterEpic,
    initializeFavoritePostsEpic,
    setFavouritePostsFilterEpic
);
