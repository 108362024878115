import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../MainView/actionCreators";
import errorHandler from "../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../common/services/utils";

const feedStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getFeed.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const feedClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.createFeed.type, actionCreators.errorResponse.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getFeedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getFeed.type),
        mergeMap(({ payload: { page, param } }) => {
            let query = "";
            if (param) query = `${query}${param || ""}`;
            return ajax.get(apiUrl(`api/feed/${page}/?${query}`)).pipe(
                map(res =>
                    res.response.map(({ channelContext, ...rest }) => ({
                        ...rest,
                        channel: channelContext,
                    }))
                ),
                mergeMap(res => of(actionCreators.createFeed.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            );
        })
    );

const initializeFeedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.initializeFeed.type),
        mergeMap(() => of(actionCreators.refreshFeed.create({ filter: "", isRefreshing: false })))
    );

const refreshFeedEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.refreshFeed.type),
        mergeMap(({ payload: { filter } }) => {
            let query = "";
            if (filter) query = `${query}${filter || ""}`;
            return ajax.get(apiUrl(`api/feed/1/?${query}`)).pipe(
                map(res =>
                    res.response.map(({ channelContext, ...rest }) => ({
                        ...rest,
                        channel: channelContext,
                    }))
                ),
                mergeMap(res => of(actionCreators.cleanUpdateFeed.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            );
        })
    );

const setFeedFilterEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.setFeedFilter.type),
        mergeMap(({ payload }) => of(actionCreators.getFeed.create({ page: 1, param: payload })))
    );

export const epics = combineEpics(
    feedStartLoadingEpic,
    feedClearLoadingEpic,
    initializeFeedEpic,
    getFeedEpic,
    refreshFeedEpic,
    setFeedFilterEpic
);
