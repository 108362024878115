/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { supportMapItem } from "../SupportMapStyles";
import GTM from "../../../common/services/GTM";
import { useMediaQuery } from "react-responsive";

const SupportMapItem = ({ item, path, isSearchResult, toggleSearch }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

    const cardImage = isMobile
        ? item.imagesquare
            ? `url(${process.env.REACT_APP_CONTENSIS_SITE_URL}/${item.imagesquare.asset.sys.uri})`
            : item.imagefull
            ? `url(${process.env.REACT_APP_CONTENSIS_SITE_URL}/${item.imagefull.asset.sys.uri})`
            : item.image
            ? `url(${process.env.REACT_APP_CONTENSIS_SITE_URL}/${item.image.asset.sys.uri})`
            : null
        : item.imagefull
        ? `url(${process.env.REACT_APP_CONTENSIS_SITE_URL}/${item.imagefull.asset.sys.uri})`
        : item.image
        ? `url(${process.env.REACT_APP_CONTENSIS_SITE_URL}/${item.image.asset.sys.uri})`
        : null;

    const getCardContent = (item, path) => {
        return (
            <Fragment>
                <span
                    css={[
                        supportMapItem.img,
                        isSearchResult || path ? { borderWidth: "20px" } : { borderWidth: "8px" },
                        item.colourHex && { borderColor: item.colourHex },
                        cardImage && {
                            backgroundImage: cardImage,
                        },
                    ]}
                />

                <span css={supportMapItem.inner}>
                    <span css={supportMapItem.body}>
                        <strong>{item.entryTitle}</strong>
                        <span>{item.entryDescription}</span>
                    </span>
                    <span css={supportMapItem.footer}>
                        {item.subitem && item.subitem.length > 0 ? (
                            <em>{item.subitem.length} items</em>
                        ) : item.link ? (
                            <span css={supportMapItem.btn}>
                                Go
                                <Icon name="link" fill={colors.greyDark} width={9} height={9} />
                            </span>
                        ) : (
                            <Fragment></Fragment>
                        )}
                    </span>
                </span>
                <span css={supportMapItem.shape}>
                    <svg preserveAspectRatio="none" viewBox="0 0 360 8" width="100%" height="8">
                        <path d="M0 0h360L0 8z" fill="#fff" />
                    </svg>
                </span>
            </Fragment>
        );
    };

    return item.subitem && item.subitem.length > 0 ? (
        <Link
            css={supportMapItem.root}
            key={item.slug}
            onClick={() => (isSearchResult ? toggleSearch() : null)}
            to={isSearchResult ? `/support-map${item.path}` : `/support-map${path}/${item.slug}`}
        >
            {getCardContent(item, path)}
        </Link>
    ) : (
        <a
            css={supportMapItem.root}
            key={item.slug}
            href={`${item.link}`}
            target={"_blank"}
            rel={"noopener noreferrer"}
            onClick={() => {
                GTM.dispatch({
                    event: "supportMapClick",
                    targetName: item.name,
                });
                return true;
            }}
        >
            {getCardContent(item, path)}
        </a>
    );
};

export default SupportMapItem;
