/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarType } from "../CalendarStyles";

export const CalendarType = ({ type }) => {
    let color = "#000000";
    let text = "";

    switch (type) {
        case "my-events":
            color = "#9051A6";
            text = "My events";
            break;
        case "timetables":
            color = "#9A9896";
            text = "Timetables";
            break;
        case "exam-dates":
            color = "#9A9896";
            text = "Exams";
            break;
        case "key-academic-dates":
            color = "#31C0AD";
            text = "Moodle dates";
            break;
        case "appointments":
            color = "#115760";
            text = "Appointments";
            break;
        case "lse-events":
            color = "#FF2D55";
            text = "LSE events";
            break;
        default:
            break;
    }

    return (
        <span css={calendarType.root}>
            <span css={[calendarType.dot, { backgroundColor: color }]} />
            <span css={calendarType.label}>{text}</span>
        </span>
    );
};
