import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../store/Search/actionCreators";
import SearchResultBar from "../../common/components/SearchResultBar";
import { Container } from "../../common/components/UI/Container";
import NoResults from "../../common/components/NoResults";
import { searchResults } from "./SearchStyles";
import channelActionCreators from "../../store/Channel/actionCreators";
import ChannelBar from "../../common/components/ChannelBar";

const ChannelItems = ({ channels }) => (
    <div>
        {channels.map(({ id, iconUrl, name }) => (
            <ChannelBar key={`${id}`} {...{ id, iconUrl, name }} />
        ))}
    </div>
);

class ResultsList extends Component {
    componentDidMount() {
        this.props.getRecommendedChannels();
    }

    render() {
        const { results, querySearch, recommended } = this.props;

        return querySearch !== "" && results ? (
            <Fragment>
                {results.length > 0 ? (
                    <div css={searchResults.body}>
                        {results.map(results => (
                            <SearchResultBar
                                key={results.id}
                                id={results.id}
                                name={results.name}
                                iconUrl={results.iconUrl}
                            />
                        ))}
                    </div>
                ) : (
                    querySearch !== "" && (
                        <Container width={592}>
                            <NoResults
                                heading="No results!"
                                message={`We couldn't find any channel that matches your search query: "${querySearch}"`}
                            />
                        </Container>
                    )
                )}
            </Fragment>
        ) : (
            <Fragment>
                <Container width={592}>
                    <NoResults
                        heading="Discover something new"
                        message="Search for your department or areas of the School that you’re interested in following."
                    />
                </Container>
                {recommended && recommended.length > 0 && (
                    <Container width={592} noPadding>
                        <div css={searchResults.recommendedHeading}>Recommended</div>
                        <ChannelItems {...{ channels: recommended }} />
                    </Container>
                )}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getSearchResults: actionCreators.getSearchResults.create,
    getRecommendedChannels: channelActionCreators.getRecommendedChannels.create,
};

const mapStateToProps = ({ Search: { results, querySearch }, Channel: { recommended } }) => ({
    results,
    querySearch,
    recommended,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsList);
