import actionCreators from "./actionCreators";

export const initialState = {
    locations: null,
    location: null,
    isSearchActive: false,
    searchResults: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.updateLocations.type:
            return {
                ...state,
                locations: payload,
            };
        case actionCreators.updateLocationDetails.type:
            return {
                ...state,
                location: payload,
            };
        case actionCreators.clearLocationDetails.type:
            return {
                ...state,
                location: initialState.location,
            };
        case actionCreators.toggleSearchActive.type:
            return {
                ...state,
                isSearchActive: !state.isSearchActive,
            };
        case actionCreators.updateSearchResults.type:
            return {
                ...state,
                searchResults: payload,
            };
        case actionCreators.clearSearchResults.type:
            return {
                ...state,
                searchResults: initialState.searchResults,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.setQuerySearch.type:
            return {
                ...state,
                querySearch: payload,
            };
        default:
            return state;
    }
};
