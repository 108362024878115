import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { modal } from "./ModalBoxStyles";
import Icon from "./Icon";
import { colors } from "../../styles/Colors";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export const ModalBoxText = props => {
    const {
        children,
        marginTopValue,
        marginBottomValue,
        paddingTopValue,
        paddingBottomValue,
        ...other
    } = props;

    return (
        <div
            css={{
                ...modal.text,
                ...(marginTopValue && { marginTop: marginTopValue }),
                ...(marginBottomValue && { marginBottom: marginTopValue }),
                ...(paddingTopValue && { paddingTop: paddingTopValue }),
                ...(paddingBottomValue && {
                    paddingBottom: paddingBottomValue,
                }),
            }}
            {...other}
        >
            {children}
        </div>
    );
};

export const ModalBoxActions = props => {
    const {
        children,
        marginTopValue,
        marginBottomValue,
        paddingTopValue,
        paddingBottomValue,
        style,
        ...other
    } = props;

    return (
        <div
            css={{
                ...modal.actions,
                ...(marginTopValue && { marginTop: marginTopValue }),
                ...(marginBottomValue && { marginBottom: marginTopValue }),
                ...(paddingTopValue && { paddingTop: paddingTopValue }),
                ...(paddingBottomValue && {
                    paddingBottom: paddingBottomValue,
                }),
                ...(style && style),
            }}
            {...other}
        >
            {children}
        </div>
    );
};

class ModalBox extends Component {
    render() {
        const { children, title, modalWidth, classes, ...props } = this.props;
        return (
            <Dialog
                TransitionComponent={Transition}
                classes={{
                    root: classes.rootModal,
                    paper: classes.paperModal,
                    paperScrollBody: classes.paperScrollBodyModal,
                }}
                css={modal.body}
                {...props}
            >
                <div
                    css={[
                        modal.inner,
                        modalWidth && { maxWidth: modalWidth },
                        modalWidth && props.fullWidth && { width: modalWidth },
                    ]}
                >
                    {props.fullScreen && (
                        <button onClick={props.onClose} css={modal.closeBtn}>
                            <Icon name="times" fill={colors.black54} width={18} height={18} />
                        </button>
                    )}
                    {title && <div css={modal.title}>{title}</div>}
                    {children}
                </div>
            </Dialog>
        );
    }
}

export default withStyles(modal)(ModalBox);
