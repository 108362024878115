import { ajax } from "rxjs/observable/dom/ajax";
import { store } from "../../store";
import authActions from "../../store/Auth/actionCreators";
import qs from "query-string";

const baseHeaders = url => {
    if (
        !(
            url.includes("api/register") ||
            url.includes("auth/login") ||
            url.includes("all/frontend/report-issue/") ||
            url.includes("all/frontend/request-types/")
        ) &&
        !store.getState().Auth.token
    ) {
        // no token = logout
        store.dispatch(authActions.logout.create());
    } else
        return {
            ...(store.getState().Auth.token != null
                ? { "x-api-token": store.getState().Auth.token }
                : {}),
            "Content-Type": "application/json",
            "X-Api-Version": process.env.REACT_APP_API_VERSION
                ? process.env.REACT_APP_API_VERSION
                : "1",
        };
};

const withQuery = (url, querystring) => [url, querystring].filter(item => !!item).join("?");

const get = (url, query, headers) =>
    ajax.get(
        withQuery(url, prepareFilterQuery(query)),
        Object.assign({}, baseHeaders(url), headers)
    );

const post = (url, body, headers) =>
    ajax.post(url, body, Object.assign({}, baseHeaders(url), headers));

const put = (url, body, headers) =>
    ajax.put(url, body, Object.assign({}, baseHeaders(url), headers));

const patch = (url, body, headers) =>
    ajax.patch(url, body, Object.assign({}, baseHeaders(url), headers));

const remove = (url, headers) => ajax.delete(url, Object.assign({}, baseHeaders(url), headers));

const blob = (url, query, headers) =>
    ajax({
        url: withQuery(url, prepareFilterQuery(query)),
        method: "GET",
        headers: Object.assign({}, baseHeaders(url), headers),
        responseType: "blob",
    });

export default {
    get,
    post,
    put,
    patch,
    remove,
    blob,
};

export const switchCase = cases => defaultCase => key =>
    // eslint-disable-next-line no-prototype-builtins
    cases.hasOwnProperty(key) ? cases[key] : defaultCase;

export const curry = (f, arr = []) => (...args) =>
    (a => (a.length === f.length ? f(...a) : curry(f, a)))([...arr, ...args]);

export const compose = (...fns) => fns.reduce((f, g) => f(g(fns)));

export const prepareFilterQuery = params => qs.stringify(params);

export const reduceReducers = (...reducers) => (previous, current) =>
    reducers.reduce((p, r) => r(p, current), previous);

export const apiUrl = endpoint => `https://${process.env.REACT_APP_API_HOST}/${endpoint}`;

export const lseAppUrl = endpoint => `https://${process.env.REACT_APP_API_HOST}/${endpoint}`;

export const isUpperCase = str => /^(?=[A-Z])[A-Z\s]+$/.test(str);

export const titleCase = str => {
    // convert only if original is all-CAPS (eg. names in LSE_HUB_PERSON)
    return isUpperCase(str) ? str.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : str;
};
