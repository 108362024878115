/** @jsx jsx */
import { jsx } from "@emotion/core";
import ChannelTitle from "../ChannelTitle";
import FeedItemSummary from "../FeedItemSummary";
import { entry } from "../FeedStyles";
import Parser from "html-react-parser";

const LongPost = ({
    post: {
        id,
        name,
        article,
        channel,
        publicationDate,
        isFavourite,
        numComments,
        isLiked,
        numberOfLikes,
        author,
        kind,
        description,
        imageUrl,
    },
}) => (
    <div css={entry.post}>
        <ChannelTitle
            channel={channel}
            author={author}
            postId={id}
            {...{ publicationDate, isFavourite }}
        />
        {name && <div css={entry.title}>{name}</div>}
        <div css={entry.textLongPost} className="entryText">
            {article && Parser(article)}
        </div>
        <FeedItemSummary
            postId={id}
            postName={description
                .replace(/<[^>]+>/g, " ")
                .replace(/\s\s+/g, " ")
                .trim()
                .split(/\s+/)
                .slice(0, 10)
                .join(" ")
                .concat([" ..."].join(""))}
            channel={{ id: channel.id, name: channel.name }}
            {...{ isFavourite, numComments, isLiked, numberOfLikes, kind, article, imageUrl }}
        />
    </div>
);

export default LongPost;
