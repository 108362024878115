import { ofType, combineEpics } from "redux-observable";
import { of, concat } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { default as ajax } from "../../../common/services/utils";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "../Register/actionCreators";
import authActionCreators from "../actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import { default as notificationsActionCreators } from "../../../store/Notifications/actionCreators";
import { default as myProgrammeActionCreators } from "../../../store/MyProgramme/actionCreators";

const registerStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.register.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const registerClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            authActionCreators.setAuthAndRegistered.type,
            authActionCreators.updateUser.type,
            actionCreators.errorResponse.type
        ),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const registerEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.register.type),
        mergeMap(action =>
            ajax.post(apiUrl("api/register"), action.payload).pipe(
                map(res => ({ data: res.response, status: res.status })),
                mergeMap(res => {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    return concat(
                        of(authActionCreators.setAuthAndRegistered.create()),
                        of(authActionCreators.setUser.create(res.data)),
                        of(myProgrammeActionCreators.getStudentsList.create()),
                        of(notificationsActionCreators.getUnseenNotificationsCount.create()),
                        of(push("/feed"))
                    );
                }),

                catchError(res => {
                    // handle validation errors ...
                    if (res.status === 400) {
                        const validationErrors = {
                            error: res.response.error ? res.response.error : "",
                            errors: res.response.errors ? res.response.errors : {},
                        };
                        if (
                            res.response.errors.firstName ||
                            res.response.errors.lastName ||
                            res.response.errors.email
                        ) {
                            return concat(
                                of(actionCreators.setStep.create(1)),
                                of(actionCreators.errorResponse.create(validationErrors)),
                                of(push("/register"))
                            );
                        } else if (res.response.errors.profilePhoto) {
                            return concat(
                                of(actionCreators.setStep.create(2)),
                                of(actionCreators.errorResponse.create(validationErrors)),
                                of(push("/register"))
                            );
                        }
                    } else {
                        const handler = errorHandler(actionCreators.errorResponse.create);
                        return handler(res);
                    }
                })
            )
        )
    );

export const epics = combineEpics(registerStartLoadingEpic, registerClearLoadingEpic, registerEpic);
