import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarSync, calendarPage } from "./CalendarStyles";
import actionCreators from "../../store/Calendar/Sync/actionCreators";
import CalendarBar from "./CalendarBar";
import HeaderCalendarMobile from "../../common/components/Header/HeaderCalendarMobile";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { Container } from "../../common/components/UI/Container";
import GTM from "../../common/services/GTM";

class CalendarSyncAndroidPC extends Component {
    componentDidMount() {
        const { getSyncDetails } = this.props;
        getSyncDetails();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/sync-android`,
        });
    }

    render() {
        const { details } = this.props;

        return (
            <div css={calendarSync.body}>
                <HeaderCalendarMobile />
                <CalendarBar />
                <Container onMobileNoPadding width={592} css={calendarPage.content}>
                    <div css={calendarSync.paddingTabBar}>
                        {details && (
                            <div css={calendarSync.content} className="entryText">
                                <p>
                                    Here’s how to sync your LSE Student Hub calendars with your
                                    Google Calendar.
                                </p>
                                <hr />
                                <p>
                                    1. Open <strong>Google Calendar</strong> in your web browser:{" "}
                                    <br />
                                    <a
                                        href="https://calendar.google.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://calendar.google.com
                                    </a>
                                    . You’ll need to do this on a desktop computer.
                                </p>
                                <p>Login to your Google Account.</p>
                                <p>
                                    Choose <strong>Settings</strong> from the menu in the top-right
                                    corner.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fc8f4bbd90.png"
                                        css={{
                                            width: 400,
                                            height: 103,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    2. In <strong>Settings</strong> choose the{" "}
                                    <strong>Add Calendar -&nbsp;</strong>
                                    <strong>From URL</strong> option.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd133c0a31.png"
                                        css={{
                                            width: 300,
                                            height: 337,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    3. In the <strong>URL of calendar</strong> field copy and paste
                                    the following link. This will sync all your Student Hub calendar
                                    events with your Google Calendar.
                                </p>
                                <div className="dataBlock">{details.export_all_calendars}</div>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/5b7fd16aaaeb9.png"
                                        css={{
                                            width: 400,
                                            height: 127,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    4. <strong>Add calendar</strong>, and you&apos;re done!
                                </p>
                                <hr />
                                <p>
                                    <strong>Advanced option</strong>
                                </p>
                                <p>
                                    If you have subscribed to the calendar URL in step 3, this will
                                    already include <strong>all</strong> the categories of events
                                    shown below.
                                </p>
                                <p>
                                    If instead you want to sync specific calendars (e.g. only your
                                    Timetables or Appointments calendars), then follow steps 1 to 3
                                    to subscribe to each calendar, using the following URLs:
                                </p>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Timetables</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_timetables}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>My Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_my_events}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Appointments</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_appointments}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Moodle dates</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_key_academic_dates}</div>

                                <p>
                                    For{" "}
                                    <strong>
                                        <i>LSE Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_lse_events}</div>
                                <hr />

                                <p>
                                    <i>
                                        If you’re an Android user, your calendars will be
                                        automatically be synced with your Google Calendar app - so
                                        there’s no need to repeat the process.
                                    </i>
                                </p>
                                <p>
                                    <i>Need help? More information on adding Google Calendars:</i>{" "}
                                    <br />
                                    <a
                                        href="https://support.google.com/calendar/answer/37100"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://support.google.com/calendar/answer/37100
                                    </a>
                                </p>
                            </div>
                        )}
                    </div>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        sync: { details },
    },
}) => ({
    details,
});

const mapDispatchToProps = {
    getSyncDetails: actionCreators.getSyncDetails.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncAndroidPC);
