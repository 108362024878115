import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { addGroupPage } from "../../Links/LinksStyles";
import HeaderGroups from "../../../common/components/Header/HeaderGroups";
import HeaderGroupsMobile from "../../../common/components/Header/HeaderGroupsMobile";
import { header } from "../../../common/components/Header/HeaderStyles";
import actionCreators from "../../../store/Channel/Group/actionCreators";
import GroupForm from "./Form";
import GTM from "../../../common/services/GTM";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../../common/components/UI/ModalBox";
import ButtonsGroup from "../../../common/components/UI/ButtonsGroup";
import ButtonText from "../../../common/components/UI/ButtonText";
import ButtonShape from "../../../common/components/UI/ButtonShape";
import { colors } from "../../../common/styles/Colors";
import { spacing } from "../../../common/styles/Spacing";

class EditGroup extends Component {
    state = {
        isModalOpen: false,
    };

    componentDidMount() {
        const {
            getGroup,
            match: {
                params: { id },
            },
        } = this.props;
        getGroup({ id });
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/group/${id}/edit-group`,
        });
    }

    onSubmit = values => {
        const { putGroup } = this.props;
        putGroup({ ...values });
    };

    render() {
        const { errorRes, initialValues, deleteGroup } = this.props;
        const { onSubmit } = this;
        const formName = "editGroup";

        return (
            <div css={addGroupPage.body}>
                <HeaderGroups {...{ formName }} />
                <HeaderGroupsMobile {...{ formName }} title="Edit group" />
                <div css={header.titleBig}>Edit group</div>
                <GroupForm form={formName} {...{ onSubmit, initialValues, errorRes }} />
                <div css={addGroupPage.actions}>
                    <ButtonShape fullWidth onClick={() => this.setState({ isModalOpen: true })}>
                        Delete Group
                    </ButtonShape>
                </div>
                <ModalBox
                    open={this.state.isModalOpen}
                    onClose={() => this.setState({ isModalOpen: false })}
                    modalWidth={310}
                >
                    <ModalBoxText>
                        <p>Are you sure you want to delete this group?</p>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space5}>
                        <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                            <ButtonShape
                                primary
                                fullWidth
                                onClick={() => deleteGroup({ id: initialValues.id })}
                            >
                                Yes, delete
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText
                                bold
                                onClick={() => this.setState({ isModalOpen: false })}
                                color={colors.black50}
                            >
                                No, cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
            </div>
        );
    }
}

const mapStateToProps = ({ MyGroups, MyGroups: { group } }) => ({
    errorRes: MyGroups.errorRes,
    initialValues: group,
});
const mapDispatchToProps = {
    getGroup: actionCreators.getGroup.create,
    putGroup: actionCreators.putGroup.create,
    deleteGroup: actionCreators.deleteGroup.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
