import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { headerMobile } from "./HeaderStyles";
import HeaderBackButton from "./HeaderBackButton";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

class HeaderOfficeHoursMobile extends Component {
    render() {
        const { title, hideLeftElement, customBackAction } = this.props;

        return (
            <div css={headerMobile.body}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        {!hideLeftElement &&
                            history.location.pathname.includes("/office-hours") && (
                                <HeaderBackButton
                                    iconColor={colors.white}
                                    onClick={customBackAction ? customBackAction : history.goBack}
                                />
                            )}
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>{title}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderOfficeHoursMobile;
