import { defaultBorder, truncate, resetLineHeight } from "../../common/styles/Globals";
import { spacing } from "../../common/styles/Spacing";
import { colors } from "../../common/styles/Colors";
import { fontWeight, fontSize, lineHeight } from "../../common/styles/Typography";

export const filterBar = {
    body: {
        backgroundColor: colors.white,
        boxShadow: "0 2px 2px -2px rgba(0, 0, 0, 0.12)",
        position: "fixed",
        top: 0,
        right: 0,
        left: 300,
        zIndex: 110,
        "@media (max-width: 1023px)": {
            backgroundColor: colors.greyDark,
            left: 0,
            top: 56,
        },
    },
    bodyInner: {
        height: 56,
        display: "flex",
        justifyContent: "flex-start",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        // "@media (min-width: 480px)": {
        //     justifyContent: "flex-start",
        // },
    },
    btn: {
        flex: 1,
        cursor: "pointer",
        background: "none",
        border: "0 none",
        outline: "0 none",
        color: colors.black87,
        fontSize: 14,
        fontWeight: fontWeight.normal,
        lineHeight: "1",
        margin: 0,
        padding: "0 30px",
        position: "relative",
        "@media (max-width: 1023px)": {
            color: colors.white70,
        },
        "@media (max-width: 767px)": {
            flex: 1,
            padding: "0 10px",
        },
    },
    btnBorder: {
        opacity: 0,
        backgroundColor: colors.black87,
        width: "100%",
        height: 4,
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        "@media (max-width: 1023px)": {
            backgroundColor: colors.white,
        },
    },
    btnBorderActive: {
        opacity: 1,
    },
    btnActive: {
        fontWeight: fontWeight.bold,
        "@media (max-width: 1023px)": {
            color: colors.white,
        },
    },
};

export const feedPage = {
    body: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: "104px 0 0",
        },
    },
    bodyIsSearch: {
        // padding: "112px 0 0",
        "@media (max-width: 1023px)": {
            padding: "104px 0 0",
        },
    },
    bodySubpage: {
        position: "relative",
        padding: "56px 0 0",
        "@media (max-width: 1023px)": {
            padding: "56px 0 0",
        },
    },
    bodyEvents: {
        padding: "159px 0 0",
        "@media (max-width: 1023px)": {
            padding: "200px 0 0",
        },
    },
    btnSearch: {
        width: 50,
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
        margin: 0,
        padding: 0,
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 200,
        "@media (max-width: 1023px)": {
            width: 56,
            height: 56,
        },
    },
    infiniteScroll: {},
    inner: {
        "@media (max-width: 1023px)": {
            paddingBottom: spacing.space6,
        },
    },
};

export const channelTitle = {
    body: {
        display: "flex",
        // alignItems: "center",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
        maxWidth: "100%",
    },
    bodyLeft: {
        paddingRight: spacing.space2,
    },
    bodyCenter: {
        flex: 1,
        maxWidth: "100%",
    },
    bodyRight: {
        paddingLeft: spacing.space2,
    },
    name: {
        marginTop: spacing.space0comma5,
    },
    nameLink: {
        display: "block",
        color: colors.black87,
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
        lineHeight: lineHeight.text,
        textDecoration: "none",
    },
    sign: {
        color: colors.black40,
    },
    date: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.light,
        lineHeight: lineHeight.textSmall,
    },
    btn: {
        cursor: "pointer",
        border: 0,
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
        fontSize: "inherit",
        fontFamily: "inherit",
    },
};

export const entry = {
    body: {
        backgroundColor: colors.white,
        height: "calc(100vh - 56px)",
        overflow: "hidden",
        "@media (max-width: 1023px)": {
            marginBottom: spacing.space6,
            height: "calc(100vh - 104px)",
        },
    },
    inner: {
        height: "calc(100% - 51px) !important",
        marginBottom: 51,
        overflowY: "hidden",
    },
    post: {
        "@media (max-width: 1023px)": {
            paddingTop: 10,
        },
    },
    img: {
        display: "block",
    },
    title: {
        marginBottom: spacing.space2,
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
        color: colors.black87,
    },
    textNoImg: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    textLongPost: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    text: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        paddingLeft: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
    },
    details: {
        marginBottom: spacing.space2,
    },
    detailsLocation: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: "1.42",
    },
    detailsDate: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: "1.42",
    },
    link: {
        color: colors.black,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        textDecoration: "none",
    },
    actions: {
        marginBottom: spacing.space3,
        // borderTop: defaultBorder,
        // borderBottom: defaultBorder,
        // paddingTop: spacing.space2,
        // paddingBottom: spacing.space2,
        // marginTop: spacing.space3,
        // marginBottom: spacing.space3,
    },
    actionsInner: {
        // display: "flex",
        // marginRight: spacing.space0 * -1,
        // marginLeft: spacing.space0 * -1,
    },
    detailsBtn: {
        marginRight: spacing.space2,
    },
    imageWrapper: {
        marginBottom: spacing.space2,
    },
    event: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
    },
    commentsForm: {
        backgroundColor: colors.white,
        borderTopColor: colors.black5,
        borderTopWidth: 1,
        borderTopStyle: "solid",
        width: "100%",
        maxWidth: 560,
        position: "fixed",
        // right: 0,
        bottom: 0,
        // left: 300,
        zIndex: 100,
        "@media (max-width: 1023px)": {
            width: "auto",
            maxWidth: "none",
            right: 0,
            bottom: 48,
            left: 0,
        },
    },
};

export const entryShort = {
    body: {
        backgroundColor: colors.white,
        overflow: "hidden",
    },
    imgWrapper: {
        marginBottom: spacing.space2,
        position: "relative",
    },
    imgShape: {
        ...resetLineHeight,
        height: 8,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 10,
    },
    img: {
        display: "block",
    },
    title: {
        marginBottom: spacing.space1,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        fontSize: fontSize.text,
        fontWeight: fontWeight.bold,
        color: colors.black87,
    },
    post: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    event: {
        fontSize: fontSize.textSmall,
        lineHeight: 1.42,
        padding: spacing.space2,
        paddingTop: 0,
    },
    link: {
        color: colors.black,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        textDecoration: "none",
    },
    details: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        marginBottom: spacing.space1,
    },
    detailsText: {
        flex: 1,
        minWidth: 0,
        paddingRight: spacing.space2,
    },
    detailsDate: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: "1.42",
    },
    detailsLocation: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: "1.42",
        ...truncate,
    },
    detailsBtns: {
        display: "flex",
    },
    detailsBtn: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: spacing.space4,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: spacing.space0,
        borderColor: colors.black54,
        paddingTop: spacing.space0,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space0,
        paddingLeft: spacing.space2,
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        lineHeight: 1,
        textDecoration: "none",
    },
    findOutBtn: {
        color: colors.black87,
        marginLeft: spacing.space2,
        marginBottom: spacing.space2,
        background: "#fff",
        borderWidth: 1,
        borderColor: "#949494",
        borderStyle: "solid",
    },
    continueReadingBtn: {
        marginLeft: spacing.space2,
        marginBottom: spacing.space2,
    },
    bottomShape: {
        ...resetLineHeight,
        height: 8,
        backgroundColor: colors.white,
    },
};

export const feedItemSummary = {
    body: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.white,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    favourite: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
        margin: 0,
        padding: 0,
        height: 20,
        "& > span": {
            whiteSpace: "nowrap",
            color: colors.black54,
            fontSize: fontSize.textSmall,
            marginLeft: spacing.space1,
        },
    },
    comments: {
        display: "flex",
        alignItems: "center",
        color: colors.black54,
        textDecoration: "none",
    },
    commentsIcon: {
        marginRight: spacing.space1,
    },
    commentsText: {
        color: colors.black54,
        fontSize: fontSize.textSmall,
    },
};

export const commentsBar = {
    body: {
        backgroundColor: colors.black2,
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
    },
    inner: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
    },
    innerSmall: {
        width: "100%",
        display: "flex",
        paddingTop: spacing.space1,
        paddingBottom: spacing.space1,
        borderBottom: defaultBorder,
    },
    lastCommented: {
        display: "flex",
        alignItems: "center",
        minWidth: 0,
    },
    lastCommentedLabel: {
        fontSize: fontSize.textSmall,
        marginLeft: spacing.space1,
        ...truncate,
    },
    lastCommentedName: {
        fontWeight: fontWeight.medium,
    },
    amount: {
        display: "flex",
        alignItems: "center",
    },
    amountText: {
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
    },
    icon: {
        width: 16,
        height: 18,
        marginRight: spacing.space1,
    },
    link: {
        color: colors.black,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.medium,
        textDecoration: "none",
    },
};

export const comment = {
    body: {
        backgroundColor: colors.white,
        display: "flex",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    bodyLvl: {
        marginLeft: 64,
        paddingTop: spacing.space1,
        paddingLeft: 0,
        paddingBottom: spacing.space1,
    },
    avatar: {
        marginRight: spacing.space2,
    },
    avatarLvl: {
        marginRight: spacing.space1,
    },
    inner: {
        flex: 1,
    },
    user: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.bold,
        marginBottom: spacing.space0,
    },
    text: {
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1.42,
    },
    textItalic: {
        fontStyle: "italic",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing.space0,
    },
    date: {
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
    },
    actions: {
        display: "flex",
    },
    actionsInline: {
        display: "flex",
    },
    btnItem: {
        marginLeft: spacing.space2,
    },
    btn: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        outline: "none",
        padding: 0,
        cursor: "pointer",
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        textDecoration: "none",
    },
};

export const replyLabel = {
    body: {
        backgroundColor: colors.white,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: colors.black5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
    },
    text: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    user: {
        color: colors.black50,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        lineHeight: 1.14,
        marginLeft: spacing.space1,
    },
    btn: {
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
    },
};

export const commentsForm = {
    body: {
        backgroundColor: colors.white,
        borderRadius: "0 0 4px 4px",
    },
    inner: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        // margin: "0 auto",
    },
    textField: {
        display: "flex",
        flex: 1,
        minHeight: 50,
        alignItems: "center",
    },
    btn: {
        backgroundColor: "transparent",
        border: "0 none",
        outline: "0 none",
        padding: 0,
        margin: 0,
        width: 50,
        height: 50,
        alignItems: "center",
        justifyContent: "center",
    },
};
