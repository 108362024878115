import { Component, Fragment } from "react";
import { reduxForm, Field } from "redux-form";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import RenderCheckboxField from "../../../../common/components/FormFields/CheckboxField";
import RenderTextFieldInline from "../../../../common/components/FormFields/TextFieldInline";
import RenderSelectField from "../../../../common/components/FormFields/SelectField";
import RenderDatePicker from "../../../../common/components/FormFields/DatePicker";
import validate from "./validate";
import { eventFull, calendarPage } from "../../CalendarStyles";
import Icon from "../../../../common/components/UI/Icon";
import { colors } from "../../../../common/styles/Colors";
import moment from "moment";
import { formatdateLength } from "../../utils";

class EventForm extends Component {
    constructor(props) {
        super(props);
        const { initialValues } = props;
        this.state = {
            startAt: new Date(initialValues.startAt),
            startAtDATE: moment(initialValues.startAt).format("YYYY-MM-DD"),
            startAtHH: moment(initialValues.startAt).format("HH"),
            startAtMM: formatdateLength(
                Math.floor(moment(initialValues.startAt).format("mm") / 5) * 5
            ),
            startAtAmPm: moment(initialValues.startAt).format("a"),
            endAt: new Date(initialValues.endAt),
            endAtDATE: moment(initialValues.endAt).format("YYYY-MM-DD"),
            endAtHH: moment(initialValues.endAt).format("HH"),
            endAtMM: formatdateLength(Math.floor(moment(initialValues.endAt).format("mm") / 5) * 5),
            endAtAmPm: moment(initialValues.endAt).format("a"),
            isAllDay: null,
        };
    }

    componentDidMount() {
        const { initialValues } = this.props;
        this.setState({ isAllDay: initialValues.isAllDay });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.initialValues.startAt !== prevProps.initialValues.startAt &&
            !this.isNonEditable(this.props.initialValues, this.props.form)
        ) {
            this.setState(state => ({
                startAt: {
                    ...state,
                    startAt: new Date(this.props.initialValues.startAt),
                    startAtDATE: moment(this.props.initialValues.startAt).format("YYYY-MM-DD"),
                    startAtHH: moment(this.props.initialValues.startAt).format("HH"),
                    startAtMM: formatdateLength(
                        Math.floor(moment(this.props.initialValues.startAt).format("mm") / 5) * 5
                    ),
                    startAtAmPm: moment(this.props.initialValues.startAt).format("a"),
                },
            }));
            this.props.change("startAt", this.props.initialValues.startAt);
        }
        if (
            this.props.initialValues.endAt !== prevProps.initialValues.endAt &&
            !this.isNonEditable(this.props.initialValues, this.props.form)
        ) {
            this.setState(state => ({
                startAt: {
                    ...state,
                    endAt: new Date(this.props.initialValues.endAt),
                    endAtDATE: moment(this.props.initialValues.endAt).format("YYYY-MM-DD"),
                    endAtHH: moment(this.props.initialValues.endAt).format("HH"),
                    endAtMM: formatdateLength(
                        Math.floor(moment(this.props.initialValues.endAt).format("mm") / 5) * 5
                    ),
                    endAtAmPm: moment(this.props.initialValues.endAt).format("a"),
                },
            }));
            this.props.change("endAt", this.props.initialValues.endAt);
        }
    }

    isNonEditable = (values, formName) => {
        return (
            formName !== "addEvent" &&
            (values.source === "Channel" || values.calendarType !== "my-events")
        );
    };

    addInvitees = () => {
        const { form, formState, saveFormData, push } = this.props;
        const event = formState[form];
        saveFormData(event.values);
        push("/calendar/invitees/add");
    };

    recalculateDate = field => {
        const {
            startAtDATE,
            startAtHH,
            startAtMM,
            endAtDATE,
            endAtHH,
            endAtMM,
            isAllDay,
        } = this.state;
        const fieldDate =
            field === "startAt"
                ? new moment(`${startAtDATE} ${startAtHH}:${startAtMM}`, "YYYY-MM-DD HH:mm")
                : new moment(`${endAtDATE} ${endAtHH}:${endAtMM}`, "YYYY-MM-DD HH:mm");
        const newValue = isAllDay ? fieldDate.format("YYYY-MM-DD") : fieldDate.toISOString();
        this.setState(() => ({
            [field]: newValue,
        }));

        this.props.change(field === "startAt" ? "startAt" : "endAt", newValue);
    };

    resetStartAt = () => {
        const { endAtDATE, endAtHH, endAtMM, isAllDay } = this.state;
        if (isAllDay) {
            this.setState(
                {
                    [`startAtDATE`]: endAtDATE,
                },
                () => this.recalculateDate("startAt")
            );
        } else {
            const isAtBreakpoint = (endAtHH === 23 && endAtMM === "00") || endAtHH < 23;
            const newDate = !isAtBreakpoint
                ? new moment(`${endAtDATE}`, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
                : endAtDATE;
            const newTime = !isAtBreakpoint ? "00" : formatdateLength(~~endAtHH - 1);

            this.setState(() => ({ [`startAtDATE`]: newDate }));
            this.setState(
                {
                    [`startAtHH`]: newTime,
                    [`startAtMM`]: endAtMM,
                    [`startAtAmPm`]: Number(newTime) > 12 ? "pm" : "am",
                },
                () => this.recalculateDate("startAt")
            );
        }
    };

    resetEndAt = () => {
        const { startAtDATE, startAtHH, startAtMM, isAllDay } = this.state;
        if (isAllDay) {
            this.setState(
                {
                    [`endAtDATE`]: startAtDATE,
                },
                () => this.recalculateDate("endAt")
            );
        } else {
            const isAtBreakpoint = (startAtHH === 23 && startAtMM === "00") || startAtHH < 23;
            const newDate = !isAtBreakpoint
                ? new moment(`${startAtDATE}`, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD")
                : startAtDATE;
            const newTime = !isAtBreakpoint ? "00" : formatdateLength(~~startAtHH + 1);

            this.setState(() => ({ [`endAtDATE`]: newDate }));
            this.setState(
                {
                    [`endAtHH`]: newTime,
                    [`endAtMM`]: startAtMM,
                    [`endAtAmPm`]: Number(newTime) > 12 ? "pm" : "am",
                },
                () => this.recalculateDate("endAt")
            );
        }
    };

    handleDatePicked = field => value => {
        value = moment(value).format("YYYY-MM-DD");
        this.setState({ [`${field}DATE`]: value }, () => {
            this.recalculateDate(field);
            if (field === "startAt") {
                this.resetEndAt();
            } else if (field === "endAt") {
                if (moment(value).isBefore(moment(this.state.startAtDATE))) {
                    this.resetStartAt();
                }
            }
        });
    };

    convertToHH = value => (Number(value) < 10 ? `0${value}` : `${value}`);
    convert12hTo24hTime = (value, normalize) => {
        let res = Number(value) < 12 ? Number(value) + 12 : Number(value);
        if (res === 0) res = 12;
        return normalize ? this.convertToHH(res) : res;
    };
    convert24hTo12hTime = (value, normalize = true) => {
        let res = Number(value) > 12 ? Number(value) - 12 : Number(value);
        if (res === 0) res = 12;
        return normalize ? this.convertToHH(res) : res;
    };

    handleTimePicked = event => {
        const {
            endAtHH,
            endAtMM,
            endAtDATE,
            startAtDATE,
            startAtHH,
            startAtMM,
            startAtAmPm,
            endAtAmPm,
        } = this.state;

        if (event) {
            const stateFieldName = event.target.name;
            const stateFieldValue = event.target.name.includes("HH")
                ? event.target.value < 12
                    ? event.target.value
                    : 0
                : event.target.value;

            this.setState(
                {
                    [stateFieldName]:
                        (stateFieldName === "startAtHH" && startAtAmPm === "pm") ||
                        (stateFieldName === "endAtHH" && endAtAmPm === "pm")
                            ? this.convert12hTo24hTime(stateFieldValue)
                            : stateFieldValue,
                },
                () => {
                    this.recalculateDate(stateFieldName.includes("startAt") ? "startAt" : "endAt");
                    if (stateFieldName.includes("startAt")) {
                        this.resetEndAt();
                    } else if (stateFieldName.includes("endAt") && startAtDATE === endAtDATE) {
                        const fieldFullTimeValue =
                            stateFieldName === "endAtHH"
                                ? moment(
                                      `${
                                          endAtAmPm === "pm"
                                              ? this.convert12hTo24hTime(stateFieldValue)
                                              : stateFieldValue
                                      } ${endAtMM}`,
                                      "HH:mm"
                                  )
                                : moment(`${endAtHH} ${stateFieldValue}`, "HH:mm");

                        if (
                            fieldFullTimeValue.isBefore(
                                moment(`${startAtHH} ${startAtMM}`, "HH:mm")
                            )
                        ) {
                            this.resetStartAt();
                        }
                    }
                }
            );
        }
    };

    handleAmPmChanged = event => {
        const { startAtHH, endAtHH } = this.state;
        const { handleTimePicked, convert24hTo12hTime } = this;

        if (event) {
            const stateFieldName = event.target.name;
            const stateFieldValue = event.target.value;
            this.setState({ [stateFieldName]: stateFieldValue }, () => {
                if (stateFieldName.includes("startAt")) {
                    handleTimePicked({
                        target: {
                            name: "startAtHH",
                            value:
                                stateFieldValue === "pm"
                                    ? startAtHH
                                    : convert24hTo12hTime(startAtHH),
                        },
                    });
                } else {
                    handleTimePicked({
                        target: {
                            name: "endAtHH",
                            value:
                                stateFieldValue === "pm" ? endAtHH : convert24hTo12hTime(endAtHH),
                        },
                    });
                }
            });
        }
    };

    handleCheckboxChange = () => {
        const { isAllDay } = this.state;
        const value = !isAllDay;
        this.props.change("isAllDay", value);
        this.setState({ isAllDay: value }, () =>
            this.handleDatePicked("startAt")(this.state.startAtDATE)
        );
    };

    render() {
        const {
            handleDatePicked,
            handleCheckboxChange,
            addInvitees,
            handleTimePicked,
            handleAmPmChanged,
            convert24hTo12hTime,
        } = this;

        const {
            handleSubmit,
            onSubmit,
            selectedInvitees,
            initialValues,
            form,
            errorRes: { errors },
        } = this.props;
        const {
            startAtHH,
            startAtMM,
            endAtHH,
            endAtMM,
            isAllDay,
            startAtAmPm,
            endAtAmPm,
        } = this.state;

        // event created from event-post - that should be non-editable for all attendees
        const isNonEditable = this.isNonEditable(initialValues, form);

        return (
            <div css={calendarPage.event}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* name */}
                    {isNonEditable ? (
                        <div css={[eventFull.section, eventFull.sectionBorder]}>
                            <div css={eventFull.inner}>
                                <div css={eventFull.text}>{initialValues.name}</div>
                            </div>
                        </div>
                    ) : (
                        <div css={[eventFull.section, eventFull.sectionBorder]}>
                            <div css={eventFull.inner}>
                                <Field
                                    name="name"
                                    placeholder="Event name"
                                    component={RenderTextFieldInline}
                                    error={errors.name}
                                    darkPlaceholder
                                />
                            </div>
                        </div>
                    )}

                    {/* startAt, endAt, isAllDay */}
                    {isNonEditable ? (
                        <div css={eventFull.section}>
                            <div css={eventFull.icon}>
                                <Icon name="clock" fill={colors.black87} width={18} height={18} />
                            </div>
                            <div css={eventFull.inner}>
                                {/* all-day event  */}
                                {initialValues.isAllDay ? (
                                    <div css={eventFull.text}>
                                        {initialValues.startAt === initialValues.endAt
                                            ? moment(initialValues.startAt).format(
                                                  "dddd D MMMM YYYY"
                                              )
                                            : `from ${moment(initialValues.startAt).format(
                                                  "dddd D MMMM YYYY"
                                              )} \nto ${moment(initialValues.endAt).format(
                                                  "dddd D MMMM YYYY"
                                              )}`}
                                    </div>
                                ) : /* NON-all-day event  */
                                moment(initialValues.startAt).format("DD MMM YYYY") ===
                                  moment(initialValues.endAt).format("DD MMM YYYY") ? (
                                    /* short event  */
                                    <div>
                                        <div css={eventFull.text}>
                                            {moment(initialValues.startAt).format(
                                                "dddd D MMMM YYYY"
                                            )}
                                        </div>
                                        <div css={eventFull.text}>
                                            {`from ${moment(initialValues.startAt).format(
                                                "h.mma"
                                            )} to ${moment(initialValues.endAt).format("h.mma")}`}
                                        </div>
                                    </div>
                                ) : (
                                    /* long event  */
                                    <div>
                                        <div css={eventFull.text}>
                                            {`from ${moment(initialValues.startAt).format(
                                                "dddd D MMMM YYYY h.mma"
                                            )}`}
                                        </div>
                                        <div css={eventFull.text}>
                                            {`to ${moment(initialValues.endAt).format(
                                                "dddd D MMMM YYYY h.mma"
                                            )}`}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            css={[
                                eventFull.section,
                                eventFull.sectionBorder,
                                eventFull.sectionDate,
                            ]}
                        >
                            <div css={eventFull.inner}>
                                <div css={{ marginBottom: 8 }}>
                                    <Field
                                        name="isAllDay"
                                        label="All Day"
                                        selected={isAllDay}
                                        component={RenderCheckboxField}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                                {!isAllDay && <div css={eventFull.label}>From</div>}
                                <div css={[eventFull.field, eventFull.fieldMargin]}>
                                    <div
                                        css={[
                                            eventFull.fieldDate,
                                            isAllDay && eventFull.fieldDateMobile,
                                        ]}
                                    >
                                        <Field
                                            name="startAt"
                                            component={RenderDatePicker}
                                            onChange={handleDatePicked("startAt")}
                                            dateFormat="dddd D MMMM YYYY"
                                            error={errors.startAt}
                                        />
                                    </div>
                                    {!isAllDay && (
                                        <div css={eventFull.fieldTime}>
                                            <RenderSelectField
                                                onChange={handleTimePicked}
                                                name="startAtHH"
                                                value={convert24hTo12hTime(startAtHH, false)}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </RenderSelectField>
                                            <div css={eventFull.fieldTimeDivider}>.</div>
                                            <RenderSelectField
                                                onChange={handleTimePicked}
                                                name="startAtMM"
                                                value={startAtMM}
                                            >
                                                <option value="00">00</option>
                                                <option value="05">05</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                                <option value="30">30</option>
                                                <option value="35">35</option>
                                                <option value="40">40</option>
                                                <option value="45">45</option>
                                                <option value="50">50</option>
                                                <option value="55">55</option>
                                            </RenderSelectField>
                                            <RenderSelectField
                                                onChange={handleAmPmChanged}
                                                name="startAtAmPm"
                                                value={startAtAmPm}
                                                css={eventFull.fieldTimeAmPm}
                                            >
                                                <option value="am">am</option>
                                                <option value="pm">pm</option>
                                            </RenderSelectField>
                                        </div>
                                    )}
                                </div>
                                <Fragment>
                                    <div css={eventFull.label}>To</div>
                                    <div css={eventFull.field}>
                                        <div
                                            css={[
                                                eventFull.fieldDate,
                                                isAllDay && eventFull.fieldDateMobile,
                                            ]}
                                        >
                                            <Field
                                                name="endAt"
                                                component={RenderDatePicker}
                                                onChange={handleDatePicked("endAt")}
                                                dateFormat="dddd D MMMM YYYY"
                                                error={errors.endAt}
                                            />
                                        </div>
                                        {!isAllDay && (
                                            <div css={eventFull.fieldTime}>
                                                <RenderSelectField
                                                    onChange={handleTimePicked}
                                                    name="endAtHH"
                                                    value={convert24hTo12hTime(endAtHH, false)}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </RenderSelectField>
                                                <div css={eventFull.fieldTimeDivider}>.</div>
                                                <RenderSelectField
                                                    onChange={handleTimePicked}
                                                    name="endAtMM"
                                                    value={endAtMM}
                                                >
                                                    <option value="00">00</option>
                                                    <option value="05">05</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25">25</option>
                                                    <option value="30">30</option>
                                                    <option value="35">35</option>
                                                    <option value="40">40</option>
                                                    <option value="45">45</option>
                                                    <option value="50">50</option>
                                                    <option value="55">55</option>
                                                </RenderSelectField>
                                                <RenderSelectField
                                                    onChange={handleAmPmChanged}
                                                    name="endAtAmPm"
                                                    value={endAtAmPm}
                                                    css={eventFull.fieldTimeAmPm}
                                                >
                                                    <option value="am">am</option>
                                                    <option value="pm">pm</option>
                                                </RenderSelectField>
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            </div>
                        </div>
                    )}

                    {/* location */}
                    {isNonEditable ? (
                        initialValues.location ? (
                            <div css={eventFull.section}>
                                <div css={eventFull.icon}>
                                    <Icon
                                        name="marker"
                                        fill={colors.black87}
                                        width={19}
                                        height={19}
                                    />
                                </div>
                                <div css={eventFull.inner}>
                                    <div css={eventFull.text}>{initialValues.location}</div>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )
                    ) : (
                        <div css={[eventFull.section, eventFull.sectionBorder]}>
                            <div css={eventFull.icon}>
                                <Icon name="marker" fill={colors.black87} width={19} height={19} />
                            </div>
                            <div css={eventFull.inner}>
                                <Field
                                    name="location"
                                    placeholder="Add a location"
                                    component={RenderTextFieldInline}
                                    darkPlaceholder
                                    error={errors.location}
                                />
                            </div>
                        </div>
                    )}

                    {/* addInvitees */}
                    {isNonEditable ? (
                        initialValues.inviteesData && initialValues.inviteesData.length > 0 ? (
                            <div css={[eventFull.section, eventFull.sectionBorder]}>
                                <div css={eventFull.icon}>
                                    <Icon
                                        name="users"
                                        fill={colors.black87}
                                        width={20}
                                        height={20}
                                    />
                                </div>
                                <div>
                                    <div css={eventFull.text}>
                                        {initialValues.inviteesData.map((invitee, index, arr) => (
                                            <span key={invitee.id}>
                                                {index === arr.length - 1
                                                    ? `${invitee.firstName} ${invitee.lastName}`
                                                    : `${invitee.firstName} ${invitee.lastName}, `}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )
                    ) : (
                        <button
                            type="button"
                            onClick={addInvitees}
                            css={[eventFull.section, eventFull.sectionBorder]}
                        >
                            <div css={eventFull.icon}>
                                <Icon name="users" fill={colors.black87} width={20} height={20} />
                            </div>
                            <div css={eventFull.inner}>
                                <div
                                    css={[
                                        eventFull.text,
                                        (!selectedInvitees || !selectedInvitees.length > 0) &&
                                            eventFull.textPlaceholder,
                                    ]}
                                >
                                    {selectedInvitees && selectedInvitees.length > 0 ? (
                                        <div>
                                            {selectedInvitees.map(
                                                ({ firstName, lastName }, index, arr) =>
                                                    index === arr.length - 1
                                                        ? `${firstName} ${lastName}`
                                                        : `${firstName} ${lastName}, `
                                            )}
                                        </div>
                                    ) : (
                                        <div>Invite guests</div>
                                    )}
                                </div>
                            </div>
                        </button>
                    )}

                    {/* notes */}
                    <div css={[eventFull.section, eventFull.sectionBorder]}>
                        <div css={eventFull.icon}>
                            <Icon name="editAlt" fill={colors.black87} width={18} height={18} />
                        </div>
                        <div css={eventFull.inner}>
                            <Field
                                name="notes"
                                placeholder="Add notes"
                                multiline
                                component={RenderTextFieldInline}
                                darkPlaceholder
                                autoFocus={isNonEditable}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default reduxForm({ enableReinitialize: true, validate })(EventForm);
