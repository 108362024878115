import { Component } from "react";
import { Route, Switch } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import { header } from "./HeaderStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";

class HeaderFeed extends Component {
    render() {
        const BackButton = () => (
            <button onClick={history.goBack} css={header.actionLeft}>
                <Icon name="arrowLeft" fill={colors.black87} width={18} height={18} />
            </button>
        );

        return (
            <div css={header.body} id="top-small">
                <div css={header.inner}>
                    <div css={header.elementLeft}>
                        <Switch>
                            <Route exact path="/post/:id/:channelId" component={BackButton} />
                            <Route exact path="/event/:id/:channelId" component={BackButton} />
                        </Switch>
                    </div>
                    <div css={header.elementCenter} />
                    <div css={header.elementRight} />
                </div>
            </div>
        );
    }
}

export default HeaderFeed;
