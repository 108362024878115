import actionCreators from "./actionCreators";
import group from "../../../fixtures/GroupInit";
import { mergeByIds } from "../../../common/services/storeHelpers";

export const initialState = {
    group,
    members: null,
    page: 1,
    hasMore: true,
    filter: "follower",
    searchedUsers: null,
    selectedUsers: [],
    searchFilter: "",
    showFeedbackForm: false,
    feedbackSent: false,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createGroup.type:
            return {
                ...state,
                group: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.redirectToEditGroup.type:
            return {
                ...initialState,
                origin: state.origin,
            };
        case actionCreators.redirectToGroupMembers.type:
            return payload.meta
                ? state
                : {
                      ...initialState,
                      members: null,
                  };
        case actionCreators.setGroupMembersFilter.type:
            return {
                ...state,
                members: null,
                filter: payload.name,
                page: 1,
                hasMore: true,
            };
        case actionCreators.createGroupMembers.type:
            return {
                ...state,
                members: mergeByIds(state.members || [], payload.data),
                page: state.page + 1,
                hasMore: payload.nextPage > 0,
            };
        case actionCreators.clearGroupMembers.type:
            return {
                ...state,
                members: initialState.members,
                page: initialState.page,
                hasMore: initialState.hasMore,
            };
        case actionCreators.setValidateError.type:
            return {
                ...state,
                errorRes: { error: "Required description or Photo", errors: {} },
            };
        case actionCreators.clearValidateError.type:
            return {
                ...state,
                errorRes: initialState.errorRes,
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };
        case actionCreators.deleteGroupMember.type:
            return {
                ...state,
                members: state.members.filter(member => member.id !== payload.id),
            };
        case actionCreators.setSearchFilter.type:
            return {
                ...state,
                searchFilter: payload,
            };
        case actionCreators.updateSearchedUsers.type:
            return {
                ...state,
                searchedUsers: payload,
            };
        case actionCreators.clearSearchedUsers.type:
            return {
                ...state,
                searchedUsers: null,
            };
        case actionCreators.addUserToSelected.type:
            return {
                ...state,
                selectedUsers: state.selectedUsers.concat(payload),
            };
        case actionCreators.removeUserFromSelected.type:
            return {
                ...state,
                selectedUsers: state.selectedUsers.filter(user => user.id !== payload),
            };
        case actionCreators.resetAddMembers.type:
            return {
                ...state,
                searchedUsers: initialState.searchedUsers,
                selectedUsers: initialState.selectedUsers,
                searchFilter: initialState.searchFilter,
            };
        case actionCreators.updateGroupFeedback.type:
            return {
                ...state,
                feedbackSent: true,
            };
        case actionCreators.clearGroupFeedback.type:
            return {
                ...state,
                feedbackSent: false,
            };
        case actionCreators.displayGroupFeedbackForm.type:
            return {
                ...state,
                showFeedbackForm: true,
            };
        case actionCreators.hideGroupFeedbackForm.type:
            return {
                ...state,
                showFeedbackForm: false,
            };
        default:
            return state;
    }
};
