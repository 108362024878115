import { Component, Fragment } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { formatDate } from "../../utils";
import actionCreators from "../../../../store/Calendar/OfficeHours/actionCreators";
import CalendarBoard from "../../CalendarBoard";
import calendarBoardActionCreator from "../../../../store/Calendar/CalendarBoard/actionCreators";
import { dayItem, calendarPage, iconTextBox } from "../../CalendarStyles";
import NoResults from "../../../../common/components/NoResults";
import ModalBox, { ModalBoxText } from "../../../../common/components/UI/ModalBox";
import { Container } from "../../../../common/components/UI/Container";
import HeaderOfficeHours from "../../../../common/components/Header/HeaderOfficeHours";
import HeaderOfficeHoursMobile from "../../../../common/components/Header/HeaderOfficeHoursMobile";
import GTM from "../../../../common/services/GTM";
import DaySlot from "../components/DaySlot";
import AppointmentForm from "../components/AppointmentForm";
import { spacing } from "../../../../common/styles/Spacing";
import TextError from "../../../../common/components/UI/TextError";
import CalendarPlaceholder from "../../../../common/components/Skeletons/CalendarPlaceholder";
import history from "../../../../common/services/history";
import { modal } from "../../../../common/components/UI/ModalBoxStyles";
import Button from "../../../../common/components/UI/Button";

class OfficeHoursCalendarContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBookingConfirmation: false,
            slot: null,
            hasCheckedFutureAvailability: false,
            showDropInSummary: false,
        };
    }

    componentDidMount() {
        const {
            resetBoard,
            resetAppointmentError,
            removeRebookApointment,
            match: {
                params: { id },
            },
        } = this.props;

        // not rebooking? clear state
        if (!history.location.search) removeRebookApointment();

        resetBoard();
        resetAppointmentError();
        this.refreshData();
        GTM.dispatch({
            event: "pageView",
            pageUrl: `/office-hours/${id}`,
        });
    }

    componentDidUpdate(prevProps) {
        const { currentMonth, currentYear, currentDay, bookable, slotsByMonth } = this.props;

        if (
            prevProps.bookable !== bookable ||
            prevProps.currentYear !== currentYear ||
            prevProps.currentMonth !== currentMonth ||
            prevProps.currentDay !== currentDay ||
            prevProps.slotsByMonth !== slotsByMonth
        ) {
            this.refreshData();
        }
    }

    refreshData() {
        const {
            match: {
                params: { id, type },
            },
            getBookableInfo,
            getUnavailableSessionsByMonth,
            bookable,
            subject,
            currentYear: year,
            currentMonth: month,
            rebookAppointment,
            checkFutureAvailability,
            hasFutureAvailability,
        } = this.props;
        const { hasCheckedFutureAvailability } = this.state;

        const bookableId = parseInt(id);
        if (!bookable || bookable === undefined) {
            getBookableInfo({
                type,
                bookableId,
            });
        } else {
            if (
                (subject.bookableId !== bookableId ||
                    subject.year !== year ||
                    subject.month !== month + 1) &&
                hasFutureAvailability
            ) {
                if (!hasCheckedFutureAvailability) {
                    this.setState({ hasCheckedFutureAvailability: true }, () =>
                        checkFutureAvailability({
                            type: bookable.subjectType,
                            bookableId,
                            year,
                            month: month + 1,
                        })
                    );
                }

                getUnavailableSessionsByMonth({
                    type: bookable.subjectType,
                    bookableId,
                    year,
                    month: month + 1,
                    rebookSlotUid:
                        Object.keys(rebookAppointment).length && rebookAppointment.uid
                            ? rebookAppointment.uid
                            : undefined,
                });
            }
        }
    }

    setSlot = item => {
        const { getBookingLimits, rebookAppointment } = this.props;
        const { start, session } = item;

        if (
            !rebookAppointment ||
            (rebookAppointment && Object.keys(rebookAppointment).length === 0)
        ) {
            getBookingLimits({
                sessionId: session.id,
                start: encodeURIComponent(start),
            });
        }

        this.setState({
            showBookingConfirmation: true,
            slot: item,
        });
    };

    closeModal = () => {
        if (this.state.showBookingConfirmation) {
            this.setState({ showBookingConfirmation: false });
            this.props.clearBookingLimits();
        }
    };

    confirmBooking = values => {
        const {
            slot: { start, session },
        } = this.state;

        const {
            bookable: { teamId, academicId, subjectType },
            postAppointment,
            clearBookingLimits,
            rebookAppointment,
            removeRebookApointment,
        } = this.props;

        postAppointment({
            id: teamId ? teamId : academicId,
            type: subjectType,
            sessionId: session.id,
            start,
            ...values,
            rebookSlotUid:
                Object.keys(rebookAppointment).length && rebookAppointment.uid
                    ? rebookAppointment.uid
                    : undefined,
        });
        clearBookingLimits();

        // was this re-book action? then delete previous appointment
        if (Object.keys(rebookAppointment).length) {
            removeRebookApointment();
        }
    };

    renderNoTimeSlot = () => {
        return (
            <div css={dayItem.body}>
                <div css={dayItem.empty}>
                    <NoResults message="No time slots available on this day" noMargin />
                </div>
            </div>
        );
    };

    renderTimeSlots = () => {
        const {
            currentDay,
            slotsByDay,
            bookable: { subjectType },
            getUserCalendarEvent,
        } = this.props;
        return (
            <div>
                <div>
                    <div css={dayItem.event}>
                        {slotsByDay[currentDay].map((item, index) => (
                            <DaySlot
                                key={index}
                                slot={{ ...item, fullWidth: false }}
                                subtitleProp={
                                    subjectType === "academic" ? "teamName" : "publicAcademicName"
                                }
                                onClick={() =>
                                    item.session.mode === "bookable"
                                        ? this.setSlot(item)
                                        : this.setState(
                                              { showDropInSummary: true, slot: item },
                                              () => {
                                                  if (item.slotUid)
                                                      getUserCalendarEvent(item.slotUid);
                                              }
                                          )
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    renderUnavailableSessions = () => {
        const { currentDay, unavailableSessionsByDay } = this.props;

        return (
            <div css={dayItem.body}>
                <div css={dayItem.event}>
                    {unavailableSessionsByDay[currentDay].map((session, index) => (
                        <DaySlot key={index} slot={session} />
                    ))}
                </div>
            </div>
        );
    };

    renderEventsForSelectedDay() {
        const {
            currentDay,
            slotsByMonth,
            slotsByDay,
            unavailableSessionsByDay,
            unavailableSessionsByMonth,
            bookable,
            hasFutureAvailability,
        } = this.props;

        return (
            <CalendarPlaceholder
                isLoaded={!(slotsByDay === null || unavailableSessionsByDay === null)}
                short={true}
            >
                {!hasFutureAvailability ? (
                    <div css={dayItem.body}>
                        <div css={dayItem.empty}>
                            <NoResults
                                message={`${bookable.subjectName} has not set up times for future bookings.`}
                                noMargin
                            />
                        </div>
                    </div>
                ) : !slotsByMonth.includes(currentDay) &&
                  !unavailableSessionsByMonth.includes(currentDay) ? (
                    // no slots available for this day
                    this.renderNoTimeSlot()
                ) : (
                    <div>
                        {!!slotsByMonth.includes(currentDay) &&
                            // available slots found
                            this.renderTimeSlots()}
                        {unavailableSessionsByMonth.includes(currentDay) &&
                            // unavailable sessions
                            this.renderUnavailableSessions()}
                    </div>
                )}
            </CalendarPlaceholder>
        );
    }

    render() {
        const {
            subject,
            slotsByMonth,
            unavailableSessionsByMonth,
            bookable,
            bookingError,
            calendarBoardHeight,
            isLoading,
            rebookAppointment,
            bookingLimits,
            userCalendarEvent,
            addUserCalendarEvent,
            clearUserCalendarEvent,
        } = this.props;
        const { slot, showBookingConfirmation, showDropInSummary } = this.state;

        const hasEventCallback = day => slotsByMonth.includes(day);
        const hasAltEventCallback = day => unavailableSessionsByMonth.includes(day);
        if (
            !bookable ||
            (subject.subjectType === "academic" && subject.academicId !== bookable.id) ||
            (subject.subjectType === "team" && subject.teamId !== bookable.id)
        )
            return null;

        return (
            <div css={[calendarPage.bodyMonth, { paddingTop: calendarBoardHeight }]}>
                <HeaderOfficeHours title={`Available times for ${bookable.subjectName}`} />
                <HeaderOfficeHoursMobile title={`Available times for ${bookable.subjectName}`} />
                <div>
                    <CalendarBoard
                        {...{ hasEventCallback, hasAltEventCallback, disablePastDates: true }}
                    />
                    <Container width={592}>{this.renderEventsForSelectedDay()}</Container>
                </div>
                <ModalBox
                    title="Make a booking"
                    open={showBookingConfirmation}
                    fullWidth
                    modalWidth="100%"
                    fullScreen={false}
                >
                    <ModalBoxText>
                        {bookingError && (
                            <TextError css={{ marginBottom: spacing.space2 }}>
                                {bookingError}{" "}
                            </TextError>
                        )}
                        {!!bookingLimits &&
                            bookingLimits.limits &&
                            bookingLimits.limits.length > 0 && (
                                <Fragment>
                                    <div>
                                        <strong>{bookingLimits.name}</strong> has the following
                                        booking limits per student:
                                        <ul css={iconTextBox.list}>
                                            {bookingLimits.limits.map((item, i) => {
                                                return (
                                                    <li key={`limit${i}`} css={iconTextBox.item}>
                                                        <strong css={iconTextBox.title}>
                                                            {item.appointmens}
                                                        </strong>
                                                        <span css={iconTextBox.text}>
                                                            {`appointment${
                                                                item.appointmens > 1 ? "s " : " "
                                                            }`}
                                                            per {item.type}
                                                        </span>
                                                        <em css={iconTextBox.textAlt}>
                                                            ({item.remaining} remaining)
                                                        </em>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </Fragment>
                            )}
                        {showBookingConfirmation && slot && (
                            <div>
                                <div css={modal.subtitle}>Your booking</div>
                                <div>You have chosen to book an appointment:</div>
                                <div css={{ marginTop: 8 }}>
                                    <strong>With:</strong>{" "}
                                    {slot.session.publicAcademicName || bookable.subjectName}
                                </div>
                                <div>
                                    <strong>Date:</strong>{" "}
                                    {formatDate("dddd D MMMM YYYY", slot.start)}
                                </div>
                                <div>
                                    <strong>From:</strong> {formatDate("h.mma", slot.start)} -{" "}
                                    {formatDate("h.mma", slot.end)} (
                                    {Intl.DateTimeFormat().resolvedOptions().timeZone})
                                </div>
                                {slot.session.location && (
                                    <div>
                                        <strong>Where:</strong> {slot.session.location}
                                    </div>
                                )}
                                {slot.session.appointmentForm && (
                                    <div>
                                        <strong>Appointment type:</strong>{" "}
                                        {slot.session.appointmentForm}
                                    </div>
                                )}
                                {slot.session.meetingUrl && (
                                    <div
                                        css={{
                                            marginTop: spacing.space2,
                                        }}
                                    >
                                        <strong>Online meeting details:</strong>{" "}
                                        <em>
                                            <p css={{ marginBottom: spacing.space4 }}>
                                                {slot.session.meetingUrl
                                                    .split("\n")
                                                    .map((item, key) => {
                                                        return (
                                                            <span key={key}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                            </p>
                                        </em>
                                    </div>
                                )}
                                {slot.session.note && (
                                    <div
                                        css={{
                                            marginTop: spacing.space2,
                                        }}
                                    >
                                        <strong>Note:</strong>{" "}
                                        <em>
                                            <p css={{ marginBottom: spacing.space4 }}>
                                                {slot.session.note.split("\n").map((item, key) => {
                                                    return (
                                                        <span key={key}>
                                                            {item}
                                                            <br />
                                                        </span>
                                                    );
                                                })}
                                            </p>
                                        </em>
                                    </div>
                                )}
                            </div>
                        )}
                    </ModalBoxText>
                    {showBookingConfirmation && slot && (
                        <div
                            css={{
                                marginTop: spacing.space4,
                            }}
                        >
                            <AppointmentForm
                                session={slot.session}
                                onSubmit={values => {
                                    this.closeModal();
                                    this.confirmBooking(values);
                                }}
                                onCancel={() => this.closeModal()}
                                isLoading={isLoading}
                                rebookAppointment={
                                    rebookAppointment && Object.keys(rebookAppointment).length > 0
                                        ? rebookAppointment
                                        : false
                                }
                            />
                        </div>
                    )}
                </ModalBox>

                <ModalBox
                    open={!!showDropInSummary}
                    onClose={() =>
                        this.setState({ slot: null, showDropInSummary: false }, () =>
                            clearUserCalendarEvent()
                        )
                    }
                    fullWidth
                    modalWidth="100%"
                    fullScreen={false}
                >
                    <ModalBoxText>
                        {slot && (
                            <div>
                                <div css={modal.subtitle}>Drop-in session</div>
                                <div css={{ marginTop: 8 }}>
                                    <strong>With:</strong>{" "}
                                    {slot.session.publicAcademicName || bookable.subjectName}
                                </div>
                                <div>
                                    <strong>Date:</strong>{" "}
                                    {formatDate("dddd D MMMM YYYY", slot.start)}
                                </div>
                                <div>
                                    <strong>From:</strong> {formatDate("h.mma", slot.start)} -{" "}
                                    {formatDate("h.mma", slot.end)} (
                                    {Intl.DateTimeFormat().resolvedOptions().timeZone})
                                </div>
                                {slot.session.location && (
                                    <div>
                                        <strong>Where:</strong> {slot.session.location}
                                    </div>
                                )}
                                {slot.session.appointmentForm && (
                                    <div>
                                        <strong>Appointment type:</strong>{" "}
                                        {slot.session.appointmentForm}
                                    </div>
                                )}
                                {slot.session.meetingUrl && (
                                    <div
                                        css={{
                                            marginTop: spacing.space2,
                                        }}
                                    >
                                        <strong>Online meeting details:</strong>{" "}
                                        <em>
                                            <p css={{ marginBottom: spacing.space4 }}>
                                                {slot.session.meetingUrl
                                                    .split("\n")
                                                    .map((item, key) => {
                                                        return (
                                                            <span key={key}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                            </p>
                                        </em>
                                    </div>
                                )}
                                {slot.session.note && (
                                    <div
                                        css={{
                                            marginTop: spacing.space2,
                                        }}
                                    >
                                        <strong>Note:</strong>{" "}
                                        <em>
                                            <p css={{ marginBottom: spacing.space4 }}>
                                                {slot.session.note.split("\n").map((item, key) => {
                                                    return (
                                                        <span key={key}>
                                                            {item}
                                                            <br />
                                                        </span>
                                                    );
                                                })}
                                            </p>
                                        </em>
                                    </div>
                                )}

                                {userCalendarEvent !== null && (
                                    <div
                                        css={{
                                            marginTop: spacing.space2,
                                        }}
                                    >
                                        {userCalendarEvent ? (
                                            <Button
                                                small
                                                accent
                                                textIcon={{ name: "check", width: 9, height: 9 }}
                                            >
                                                Added to calendar
                                            </Button>
                                        ) : (
                                            <Button
                                                small
                                                gray
                                                textIcon={{ name: "add", width: 7, height: 7 }}
                                                onClick={() => {
                                                    addUserCalendarEvent(slot.slotUid);

                                                    GTM.dispatch({
                                                        event: "bookingsClick",
                                                        actionType: "Add to calendar",
                                                        academicId: bookable.academicId
                                                            ? `${bookable.academicId}`
                                                            : null,
                                                        teamId: !bookable.academicId
                                                            ? `${bookable.teamId}`
                                                            : null,
                                                        targetName: `${slot.session
                                                            .publicAcademicName ||
                                                            bookable.subjectName}`,
                                                        sessionId: `${slot.session.id}`,
                                                    });
                                                }}
                                            >
                                                Add to calendar
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </ModalBoxText>
                </ModalBox>
            </div>
        );
    }
}

const mapStateToProps = (
    {
        calendar: {
            officeHours: {
                subject,
                slotsByMonth,
                slotsByDay,
                unavailableSessionsByDay,
                unavailableSessionsByMonth,
                hasFutureAvailability,
                bookingError,
                bookableInfo,
                rebookAppointment,
                bookingLimits,
                userCalendarEvent,
            },
            board: { currentMonth, currentDay, currentYear, calendarBoardHeight },
        },
        MainView: { isLoading },
    },
    {
        match: {
            params: { id },
        },
    }
) => ({
    currentMonth,
    currentDay,
    currentYear,
    calendarBoardHeight,
    subject,
    slotsByMonth,
    slotsByDay,
    unavailableSessionsByDay,
    unavailableSessionsByMonth,
    hasFutureAvailability,
    bookingError,
    bookable: bookableInfo[id],
    isLoading,
    rebookAppointment,
    bookingLimits,
    userCalendarEvent,
});

const mapDispatchToProps = {
    getBookableInfo: actionCreators.getBookableInfo.create,
    getUnavailableSessionsByMonth: actionCreators.getUnavailableSessionsByMonth.create,
    postAppointment: actionCreators.postAppointment.create,
    resetAppointmentError: actionCreators.resetAppointmentError.create,
    resetBoard: calendarBoardActionCreator.resetBoard.create,
    removeRebookApointment: actionCreators.removeRebookApointment.create,
    getBookingLimits: actionCreators.getBookingLimits.create,
    clearBookingLimits: actionCreators.clearBookingLimits.create,
    checkFutureAvailability: actionCreators.checkFutureAvailability.create,
    addUserCalendarEvent: actionCreators.addUserCalendarEvent.create,
    getUserCalendarEvent: actionCreators.getUserCalendarEvent.create,
    clearUserCalendarEvent: actionCreators.clearUserCalendarEvent.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeHoursCalendarContainer);
