import ActionCreator from "../../actionCreator";

export default {
    setDay: ActionCreator("SET_DAY"),
    setMonth: ActionCreator("SET_MONTH"),
    setYear: ActionCreator("SET_YEAR"),
    setWeeks: ActionCreator("SET_WEEKS"),
    swipeLeft: ActionCreator("SWIPE_LEFT"),
    swipeRight: ActionCreator("SWIPE_RIGHT"),
    errorResponse: ActionCreator("ERROR_RESPONSE_CALENDAR_MONTH"),
    setCalendarBoardHeight: ActionCreator("SET_CALENDAR_BOARD_HEIGHT"),
    resetBoard: ActionCreator("RESET_CALENDAR_BOARD"),
    toggleCalendarBoard: ActionCreator("TOGGLE_CALENDAR_BOARD_MONTH_EVENTS"),
};
