import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { textField } from "./FormFieldsStyles";
import Icon from "../UI/Icon";

class RenderTextField extends Component {
    render() {
        const {
            input,
            label,
            meta: { touched, error, active },
            classes,
            ...custom
        } = this.props;

        return (
            <FormControl
                classes={{
                    root: classes.root,
                }}
                fullWidth
                error={touched && error && true}
                variant="filled"
            >
                {label && (
                    <InputLabel
                        FormLabelClasses={{
                            root: classes.labelRoot,
                            error: classes.labelError,
                            focused: classes.labelFocused,
                            filled: classes.labelFilled,
                        }}
                        variant="filled"
                        shrink={false} // WTF https://github.com/mui/material-ui/issues/13898
                    >
                        {label}
                    </InputLabel>
                )}
                <FilledInput
                    classes={{
                        root: classes.inputRoot,
                        focused: classes.inputFocused,
                        disabled: classes.inputDisabled,
                        underline: classes.inputUnderline,
                        error: classes.inputError,
                        input: classes.inputInput,
                    }}
                    endAdornment={
                        touched &&
                        error && (
                            <InputAdornment position="end">
                                <span css={textField.iconError}>
                                    <Icon name="error" fill="#E0112B" width={18} height={18} />
                                </span>
                            </InputAdornment>
                        )
                    }
                    startAdornment={
                        (active || input.value) &&
                        !!custom.prefix && (
                            <InputAdornment position="start" css={textField.inputPrefixContainer}>
                                <div css={textField.inputPrefix}>{custom.prefix}</div>
                            </InputAdornment>
                        )
                    }
                    {...input}
                    {...custom}
                />
                {touched && error && (
                    <FormHelperText
                        classes={{
                            root: classes.helperRoot,
                            error: classes.helperError,
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(textField)(RenderTextField);
