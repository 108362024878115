import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { apiUrl } from "../../../common/services/utils";
import actionCreators from "./actionCreators";
import { default as UIActionCreators } from "../../MainView/actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import { default as ajax } from "../../../common/services/utils";

const syncDetailsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSyncDetails.type),
        mergeMap(() => of(UIActionCreators.setLoading.create()))
    );

const syncDetailsStopLoadingEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.updateSyncDetails.type),
        mergeMap(() => of(UIActionCreators.clearLoading.create()))
    );

const getSyncDetailsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getSyncDetails.type),
        mergeMap(() =>
            ajax.get(apiUrl(`api/calendar/sync/`)).pipe(
                map(res => res.response),
                mergeMap(res => of(actionCreators.updateSyncDetails.create(res))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    syncDetailsStartLoadingEpic,
    syncDetailsStopLoadingEpic,
    getSyncDetailsEpic
);
