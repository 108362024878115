/** @jsx jsx */
import { jsx } from "@emotion/core";

const shouldRenderHeaders = (columnsAmount, headersEnabled) =>
    headersEnabled === undefined ? columnsAmount > 1 : headersEnabled;

const renderDefaultHeader = day => {
    const date = day.date.getDate();
    const month = day.date.getMonth() + 1;
    return `${date < 9 ? "0" + date : date}.${month < 9 ? "0" + month : month}`;
};

const Headers = ({
    headersContainer,
    columnDays,
    columnWidth,
    linesTopOffset,
    linesLeftOffset,
    renderHeader,
    headerContainerStyle = { marginVertical: 5 },
    headerTextStyle,
}) => {
    return (
        <div css={[styles.row, headersContainer]}>
            {shouldRenderHeaders(columnDays.length, !!renderHeader) &&
                columnDays.map((day, columnIndex) => (
                    <div
                        key={String(columnIndex)}
                        css={[
                            {
                                width: columnWidth,
                                top: linesTopOffset,
                                marginLeft: columnIndex === 0 ? linesLeftOffset : undefined,
                                alignItems: "center",
                            },
                            headerContainerStyle,
                        ]}
                    >
                        <div css={headerTextStyle}>
                            {(typeof renderHeader === "function"
                                ? renderHeader
                                : renderDefaultHeader)(day)}
                        </div>
                    </div>
                ))}
        </div>
    );
};

const styles = {
    row: {
        flexDirection: "row",
    },
};

export default Headers;
