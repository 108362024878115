/** @jsx jsx */
import { jsx } from "@emotion/core";
import { spacing } from "../../../../common/styles/Spacing";
import { fontSize } from "../../../../common/styles/Typography";
import { Fragment } from "react";

const HourNumbers = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
];

const Hours = ({
    offsetX,
    columnDays,
    columnWidth,
    linesTopOffset,
    linesLeftOffset,
    fromHour,
    toHour,
    hourHeight,
    timeWidth,
    is12Hour,
}) => {
    const renderTime = hour => {
        if (is12Hour) {
            switch (hour) {
                case 0:
                case 24:
                    return "12 am";
                case 12:
                    return "12 pm";
                default:
                    return hour > 12 ? hour - 12 + " pm" : hour + " am";
            }
        }
        return (hour > 9 ? "" : "0") + (hour === 24 ? "00" : hour) + ":00";
    };

    return (
        <div css={styles.root}>
            {HourNumbers.map(
                (hour, rowIndex) =>
                    hour >= fromHour &&
                    hour <= toHour && (
                        <div key={rowIndex} css={styles.row}>
                            <div css={[styles.timeContainer, { width: timeWidth, left: offsetX }]}>
                                <div css={styles.time}>{renderTime(hour)}</div>
                            </div>
                            {/* hour lines */}
                            {columnDays.map((day, columnIndex) => (
                                <Fragment key={String(columnIndex)}>
                                    <div
                                        css={{
                                            position: "relative",
                                            width: columnWidth,
                                            height: hourHeight,
                                            top: linesTopOffset,
                                            marginLeft:
                                                columnIndex === 0 ? linesLeftOffset : undefined,
                                            borderTop: "1px solid #e2e4e5",
                                            borderLeft: `${
                                                rowIndex === toHour ? 0 : 1
                                            }px solid #e2e4e5`,
                                            borderRight: `${
                                                columnIndex === columnDays.length - 1 &&
                                                rowIndex !== toHour
                                                    ? 1
                                                    : 0
                                            } solid #e2e4e5`,
                                        }}
                                    >
                                        <div
                                            css={{
                                                width: 10,
                                                height: hourHeight,
                                                position: "absolute",
                                                top: -1,
                                                left: -10,
                                                borderTop: "1px solid #e2e4e5",
                                            }}
                                        />
                                        {rowIndex !== toHour && (
                                            <div
                                                css={{
                                                    width: columnWidth - 1,
                                                    height: hourHeight / 2,
                                                    top: hourHeight / 2 - 1,
                                                    position: "absolute",
                                                    borderTop: "1px solid #e2e4e5",
                                                }}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    )
            )}
        </div>
    );
};

const styles = {
    root: {
        // overflow: "hidden",
    },
    row: {
        display: "flex",
        position: "relative",
    },
    timeContainer: {
        position: "absolute",
        zIndex: 3,
        top: 9,
        justifyContent: "center",
        paddingHorizontal: 1,
        paddingLeft: spacing.space2,
    },
    time: {
        fontSize: fontSize.textSmaller,
    },
};

export default Hours;
