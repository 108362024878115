/** @jsx jsx */
import { jsx } from "@emotion/core";
import { textError } from "./TextErrorStyles";

const TextError = props => {
    const {
        children,
        style,
        marginValue,
        marginTopValue,
        marginRightValue,
        marginBottomValue,
        marginLeftValue,
        paddingValue,
        paddingTopValue,
        paddingRightValue,
        paddingBottomValue,
        paddingLeftValue,
        center,
    } = props;

    return (
        <div
            css={[
                textError.body,
                marginValue && { margin: marginValue },
                marginTopValue && { marginTop: marginTopValue },
                marginRightValue && { marginRight: marginRightValue },
                marginBottomValue && { marginBottom: marginBottomValue },
                marginLeftValue && { marginLeft: marginLeftValue },
                paddingValue && { padding: paddingValue },
                paddingTopValue && { paddingTop: paddingTopValue },
                paddingRightValue && { paddingRight: paddingRightValue },
                paddingBottomValue && { paddingBottom: paddingBottomValue },
                paddingLeftValue && { paddingLeft: paddingLeftValue },
                center && textError.bodyCenter,
                style && style,
            ]}
        >
            {children}
        </div>
    );
};

export default TextError;
