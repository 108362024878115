import { compose, partial } from "ramda";

const hasParent = ({ parentId }) => !!parentId;

const getParent = (comments, parentId) => comments.find(({ id }) => id === parentId);

const parentHasChildren = (comments, id) => comments.some(({ parentId }) => id === parentId);

const getIndex = (comments, prevId) => comments.findIndex(({ id }) => id === prevId);

const getId = ({ id }) => id;

const getPrev = (comments, comment) =>
    parentHasChildren(comments, comment.parentId)
        ? comments
              .filter(({ parentId }) => parentId === comment.parentId)
              .reduce((curr, prev) => (curr.createdAt > prev.createdAt ? curr : prev), [])
        : getParent(comments, comment.parentId) || {};

const updateAddComments = (comments, comment, index) => [
    ...comments.slice(0, index + 1),
    comment,
    ...comments.slice(index + 1),
];

const updateEditComments = (comments, comment, index) => [
    ...comments.slice(0, index),
    comment,
    ...comments.slice(index + 1),
];

const addAtStart = (comments, comment) => [comment, ...comments];

const addInside = (comments, comment) =>
    compose(
        partial(updateAddComments, [comments, comment]),
        partial(getIndex, [comments]),
        getId,
        partial(getPrev, [comments])
    )(comment);

export const addComment = (comments, comment) =>
    hasParent(comment) ? addInside(comments, comment) : addAtStart(comments, comment);

export const editComment = (comments, comment) =>
    compose(
        partial(updateEditComments, [comments, comment]),
        partial(getIndex, [comments])
    )(comment.id);

export const removeComment = (comments, removedId) =>
    comments.map(comment => ({
        ...comment,
        isDeleted: !comment.isDeleted ? comment.id === removedId : comment.isDeleted,
    }));
