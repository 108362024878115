import moment from "moment";

export const getDay = day => day || "";

const getDTSMoment = (date, referenceDate) => {
    // date summer/winter time different then referenceDate summer/winter time?
    if (moment(date).isDST() !== moment(referenceDate).isDST()) {
        if (!moment(date).isDST()) return moment(date).add(1, "hours");
        if (moment(date).isDST()) return moment(date).subtract(1, "hours");
    }
    return moment(date);
};

export const formatDate = (format, date, referenceDate = null) => {
    return getDTSMoment(date, referenceDate ? referenceDate : date).format(format);
};

export const formatdateLength = day => (day.toString().length < 2 ? `0${day}` : `${day}`);

export const typeWeek = (i, length) => {
    switch (i) {
        case 0:
            return "flex-end";
        case length - 1:
            return "flex-start";
        default:
            return "space-between";
    }
};

export const getUrlSearchParams = url =>
    url
        .slice(1)
        .split("&")
        .map(p => p.split("="))
        .reduce(
            (acc, [key, val]) => ({
                ...acc,
                [key]: val,
            }),
            {}
        );
