import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { calendarSync, calendarPage } from "./CalendarStyles";
import actionCreators from "../../store/Calendar/Sync/actionCreators";
import CalendarBar from "./CalendarBar";
import HeaderCalendarMobile from "../../common/components/Header/HeaderCalendarMobile";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { Container } from "../../common/components/UI/Container";
import GTM from "../../common/services/GTM";

class CalendarSyncMacOS extends Component {
    componentDidMount() {
        const { getSyncDetails } = this.props;
        getSyncDetails();

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/sync-macos`,
        });
    }

    render() {
        const { details } = this.props;

        return (
            <div css={calendarSync.body}>
                <HeaderCalendarMobile />
                <CalendarBar />
                <Container onMobileNoPadding width={592} css={calendarPage.content}>
                    <div css={calendarSync.paddingTabBar}>
                        {details && (
                            <div css={calendarSync.content} className="entryText">
                                <p>
                                    Here’s how to sync your LSE Student Hub calendars with your
                                    Apple Calendar.
                                </p>
                                <hr />
                                <p>
                                    1. Open your <strong>Apple Calendar</strong> app on your Mac.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_macos_icon.png"
                                        css={{ width: 100, height: 118 }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    2. Click on: <strong>File</strong> then choose{" "}
                                    <strong>New Calendar Subscription...</strong>
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_macos_addsubs.png"
                                        css={{
                                            width: 350,
                                            height: 175,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    3. In the <strong>Calendar URL</strong> field copy and paste the
                                    following link. This will sync all your Student Hub calendar
                                    events with your Mac Calendar.
                                </p>
                                <div className="dataBlock">{details.export_all_calendars}</div>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_macos_addsub_input.png"
                                        css={{
                                            width: 350,
                                            height: 91,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>
                                    4. On the follow-up window accept all default settings or select
                                    a calendar account you want to associated this calendar
                                    subscription with.
                                    <br />
                                    <br />
                                    Note that for each subscription you can choose a different{" "}
                                    <strong>Auto-refresh</strong> period - for faster updates,
                                    choose a smaller value eg. <strong>5 min</strong>.
                                </p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_macos_addsub_followup.png"
                                        css={{
                                            width: 350,
                                            height: 218,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <hr />
                                <p>5. You will see a new calendar subscription in the app</p>
                                <figure>
                                    <img
                                        alt=""
                                        src="https://d2l48n9bd2cpp9.cloudfront.net/750x440/editor/38765669/2021_macos_subadded.png"
                                        css={{
                                            width: 300,
                                            height: 412,
                                            ...calendarSync.thinBorder,
                                        }}
                                    />
                                </figure>
                                <p>You&apos;re done!</p>
                                <hr />
                                <p>
                                    <strong>Advanced option</strong>
                                </p>
                                <p>
                                    If you have subscribed to the calendar URL in step 3, this will
                                    already include <strong>all</strong> the categories of events
                                    shown below.
                                </p>
                                <p>
                                    If instead you want to sync specific calendars (e.g. only your
                                    Timetables or Appointments calendars), then follow steps 1 to 5
                                    to subscribe to each calendar, using the following URLs:
                                </p>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Timetables</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_timetables}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>My Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_my_events}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Appointments</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_appointments}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>Moodle dates</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_key_academic_dates}</div>
                                <p>
                                    For{" "}
                                    <strong>
                                        <i>LSE Events</i>
                                    </strong>{" "}
                                    copy this URL:
                                </p>
                                <div className="dataBlock">{details.export_lse_events}</div>
                                <hr />

                                <p>
                                    <i>
                                        Need help? More information on adding subscribed calendars
                                        on macOS:
                                    </i>
                                    <br />
                                    <a
                                        href="https://support.apple.com/en-gb/guide/calendar/icl1022/mac"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://support.apple.com/en-gb/guide/calendar/icl1022/mac
                                    </a>
                                </p>
                            </div>
                        )}
                    </div>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        sync: { details },
    },
}) => ({
    details,
});

const mapDispatchToProps = {
    getSyncDetails: actionCreators.getSyncDetails.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncMacOS);
