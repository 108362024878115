import ActionCreator from "../../actionCreator";

export default {
    nextStep: ActionCreator("NEXT"),
    prevStep: ActionCreator("PREV"),
    register: ActionCreator("REGISTER"),
    setStep: ActionCreator("SET_STEP"),
    enableModal: ActionCreator("ENABLE_MODAL"),
    disableModal: ActionCreator("DISABLE_MODAL"),
    errorResponse: ActionCreator("ERROR_RESPONSE_REGISTER"),
};
