import { Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatDate } from "../utils";
import { eventItem } from "../CalendarStyles";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { CalendarType } from "./CalendarType";

const EventItem = ({ item: { uid, calendarType, name, startAt, endAt, isAllDay, location } }) => (
    <Link to={`/calendar/event/${calendarType}/${uid}`} css={eventItem.body}>
        <div css={eventItem.bodyContainer}>
            <div css={eventItem.inner}>
                <div css={eventItem.title}>{name}</div>
                <div css={eventItem.timeLocation}>
                    {!isAllDay && (
                        <div css={eventItem.time}>
                            {moment(startAt).format("dddd D MMMM YYYY") ===
                            moment(endAt).format("dddd D MMMM YYYY")
                                ? /* short event  */
                                  `${formatDate("h.mma", startAt)} - ${formatDate("h.mma", endAt)}`
                                : /* long event  */
                                  `${formatDate("h.mma", startAt)}`}
                        </div>
                    )}
                    {calendarType === "timetables" && (
                        <Fragment>
                            {location && <div css={eventItem.location}>@ {location}</div>}
                        </Fragment>
                    )}
                </div>
                <div css={eventItem.calendarType}>
                    <CalendarType type={calendarType} />
                </div>
            </div>
            {calendarType === "key-academic-dates" && (
                <div css={eventItem.kad}>
                    <div css={eventItem.icon}>
                        <Icon name="calendarAlt" fill={colors.black40} width={18} height={18} />
                    </div>
                    <div css={eventItem.kadLabel}>{moment(startAt).fromNow()}</div>
                </div>
            )}
        </div>
        <div css={eventItem.bottomShape}>
            <svg preserveAspectRatio="none" viewBox="0 0 375 8" width="100%" height="8">
                <path d="M375 0L0 8h375V0z" fill="#f5f5f5" />
            </svg>
        </div>
    </Link>
);

export default EventItem;
