import { ROOT_PAGE_SLUG } from "../config";

export const selectSearchResults = (querySearch, items) => {
    if (!querySearch) return [];

    let found = items.filter(
        e =>
            (e.entryTitle && e.entryTitle.search(new RegExp(querySearch, "i")) !== -1) ||
            (e.entryDescription && e.entryDescription.search(new RegExp(querySearch, "i")) !== -1)
    );

    // de-duplicate outbound links
    let dedup = found.reduce((acc, current) => {
        const x = acc.find(item => item.link === current.link);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    // calculate URL
    const results = dedup.map(item => {
        let processed = false;
        let searching = item.slug;
        let itemPath = item.slug;
        while (!processed) {
            // eslint-disable-next-line
            const parent = items.find(
                // eslint-disable-next-line
                i => i.subitem && i.subitem.find(s => s.slug === searching)
            );

            if (parent) {
                itemPath = `${parent.slug === ROOT_PAGE_SLUG ? "" : parent.slug}/${itemPath}`;
                searching = parent.slug;
                if (parent.slug === ROOT_PAGE_SLUG) processed = true; // break
            } else {
                itemPath = `/${itemPath}`;
                processed = true;
            }
        }

        return {
            ...item,
            path: itemPath,
        };
    });

    return results;
};
