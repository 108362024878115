import React from "react";
import ServerErrorPage from "./ServerError";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { lseTheme } from "./FormFields/FormFieldsStyles";

const ErrorFallback = () => (
    <MuiThemeProvider theme={lseTheme}>
        <div>
            <div className="container">
                <ServerErrorPage />
            </div>
        </div>
    </MuiThemeProvider>
);

export default ErrorFallback;
