import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { headerMobile, headerTextButton } from "./HeaderStyles";
import HeaderBackButton from "./HeaderBackButton";
import { colors } from "../../styles/Colors";
import history from "../../services/history";

class HeaderOfficeHoursMobile extends Component {
    render() {
        const { title, submit, isLoading } = this.props;

        return (
            <div css={headerMobile.body}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        {history.location.pathname.includes("/add-new-post") && (
                            <HeaderBackButton
                                icon="times"
                                iconColor={colors.white}
                                onClick={() => history.goBack()}
                            />
                        )}
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>{title}</div>
                        </div>
                    </div>
                    <div css={headerMobile.elementRight}>
                        {history.location.pathname.includes("/add-new-post") && (
                            <button
                                type="submit"
                                onClick={submit}
                                disabled={isLoading}
                                css={[
                                    headerTextButton.bodyMobile,
                                    isLoading && headerTextButton.bodyMobileIsLoading,
                                ]}
                            >
                                SAVE
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderOfficeHoursMobile;
